import './settings.css'
import React, { useEffect, useState } from 'react'
import EditHandlePanel from './edit_handle_panel/edit_handle_panel.js'
import EditEmailMarketingPanel from './edit_email_marketing/edit_email_marketing.js'
import EditFirstNamePanel from './edit_first_name_panel/edit_first_name_panel.js'
import Cookies from 'js-cookie'

function Settings() {

    const [showHandlePanel, setShowHandlePanel] = useState(false);
    // const [showEmailPanel, setShowEmailPanel] = useState(false);
    const [showFirstNamePanel, setShowFirstNamePanel] = useState(false);
    const [showEmailMarketingPanel, setShowEmailMarketingPanel] = useState(false);

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [handle, setHandle] = useState('');
    const [emailMarketing, setEmailMarketing] = useState(false);


    useEffect(() => {
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({session_id: Cookies.get("session_id")})
        }

        fetch(process.env.REACT_APP_API_URL + "/api/getUserSettings", options)
            .then(response => response.json())
            .then(data => { 
                setHandle(data.handle);
                setEmail(data.email);
                setFirstName(data.first_name);
                if (data.email_marketing === 1) {
                    setEmailMarketing("Yes");
                } else {
                    setEmailMarketing("No");
                }
            })
    }, [])


    return (
        <div className="settings_container">
            <div className="settings_container_background"></div>
            <div className="settings_content">
                <div className="settings_header">
                    <h1 className="settings_header_title">Settings</h1>
                </div>
                <div className="settings_body">
                    <div className="settings_body_item">
                        <div className="settings_body_item_header">
                            <p>Email Address</p>
                        </div>
                        <div className="settings_body_item_content">
                            <p className="settings_item_value">{email}</p>
                        </div>
                    </div>
                    <div className="settings_body_item settings_body_item_editable"  onClick={() => setShowFirstNamePanel(true)}>
                        <div className="settings_body_item_header">
                            <p>First Name</p>
                        </div>
                        <div className="settings_body_item_content">
                            <p className="settings_item_value">{firstName}</p>
                        </div>
                    </div>
                    <div className="settings_body_item settings_body_item_editable" onClick={() => setShowHandlePanel(true)}>
                        <div className="settings_body_item_header">
                            <p>Handle</p>
                        </div>
                        <div className="settings_body_item_content">
                            <p className="settings_item_value">{handle}</p>
                        </div>
                    </div>
                    <div className="settings_body_item settings_body_item_editable" onClick={() => setShowEmailMarketingPanel(true)}>
                        <div className="settings_body_item_header">
                            <p>Marketing Emails</p>
                        </div>
                        <div className="settings_body_item_content">
                            <p className="settings_item_value">{emailMarketing}</p>
                        </div>
                    </div>
                </div>
            </div>

            {showHandlePanel && <EditHandlePanel handle={handle} setHandle={setHandle} setShowHandlePanel={setShowHandlePanel}/>}
            {showEmailMarketingPanel && <EditEmailMarketingPanel emailMarketing={emailMarketing} setEmailMarketing={setEmailMarketing} setShowEmailMarketingPanel={setShowEmailMarketingPanel}/>}
            {showFirstNamePanel && <EditFirstNamePanel firstName={firstName} setFirstName={setFirstName} setShowFirstNamePanel={setShowFirstNamePanel}/>}
        </div>
    )

}

export default Settings;