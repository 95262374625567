import './upload_recipe.css';
import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import ButtonPrimary from '../../components/button/button_primary.js';
import ButtonSecondary from '../../components/button/button_secondary.js';
import LoadingBar from '../../components/loading_bar/loading_bar.js';
import PopUp from '../../components/pop_up/pop_up.js';

function UploadRecipe(props) {

    const [showUploadRecipe, setShowUploadRecipe] = useState(false);
    const [hideUpload, setHideUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [generationCompleted, setGenerationCompleted] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showIngredientsNotFound, setShowIngredientsNotFound] = useState(false);
    const [ingredientsNotFound, setIngredientsNotFound] = useState([]);
    const [importStatus, setImportStatus] = useState('Generate Recipe');
    const [importCount, setImportCount] = useState(0);
    const [showImportRemaining, setShowImportRemaining] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [generateErrorMessage, setGenerateErrorMessage] = useState('');
    const [completedSentence, setCompletedSentence] = useState("Generate a recipe for ");
    const recipeTextRef = useRef();
    const session_id = Cookies.get('session_id');
    const [generateDisabled, setGenerateDisabled] = useState(true);
    
    const [showRecipeOptions, setShowRecipeOptions] = useState(false);
    const [recipeOptions, setRecipeOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [imageGenerated, setImageGenerated] = useState(true);

    useEffect(() => {

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createGetUserDailyImportCount', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setImportCount(data.daily_import_count);
                    if (data.daily_import_count >= 10) {
                        setErrorMessage(<>You have reached your daily limit of 10 generations. Please use this feature tomorrow.</>);
                    } else {
                        setShowImportRemaining(true)
                    }
                }
            })

    }, [session_id]);


    const handleInputChange = () => {
        const userInput = recipeTextRef.current.value; // Get the value from the textarea
        if (userInput === "Generate a recipe for ") {
            setGenerateDisabled(true);
            setCompletedSentence(userInput);
        } else {
            if (userInput.startsWith("Generate a recipe for ")) {
                setGenerateDisabled(false);
                setCompletedSentence(userInput); // If it already starts with "this is", use the input as is 
            }
        }
    };

    function uploadTextRecipe() {

        setShowErrorMessage(false);
        setShowRecipeOptions(false);
        setShowUploadRecipe(true);
        setLoading(true);
        setHideUpload(false);

        setImportStatus('Prepping the ingredients..');
        const timer10 = setTimeout(() => {
            setImportStatus('Heating up the pan..');
        }, 10000);
        const timer20 = setTimeout(() => {
            setImportStatus('Drizzling olive oil..');
        }, 20000);
        const timer30 = setTimeout(() => {
            setImportStatus('Sautéing the garlic..');
        }, 30000);
        const timer40 = setTimeout(() => {
            setImportStatus("Oh my goodness, it smells terrific!");
        }, 40000);
        const timer50 = setTimeout(() => {
            setImportStatus("Dressing the table..");
        }, 50000);
        const timer60 = setTimeout(() => {
            setImportStatus("Pouring the wine..");
        }, 60000);
        const timer70 = setTimeout(() => {
            setImportStatus("Is it ready yet?");
        }, 70000);
        const timer80 = setTimeout(() => {
            setImportStatus("Any moment now..");
        }, 80000); 

        const recipePrompt = recipeTextRef.current.value + " " + selectedDescription;

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, recipe_text: recipePrompt })
        }

        if (props.createRecipeID !== "0") {
            // fetch(process.env.REACT_APP_API_URL + '/api/createGenerateRecipe', options)
            fetch('https://thesiliconkitchenfunctions.azurewebsites.net/api/generate_recipe', options)
                .then(response => response.json())
                .then(data => { 
                    if (data.recipe_response.status === true) {
                        setLoading(false); 
                        const ingredientsNotFound = data.recipe_response.ingredientNotFound;
                        setIngredientsNotFound(ingredientsNotFound);
                        if (ingredientsNotFound.length > 0 || data.recipe_response.imageSuccess === false) {
                            setShowUploadRecipe(false);
                            setImageGenerated(data.recipe_response.imageSuccess);
                            setShowIngredientsNotFound(true);
                        } else { 
                            setGenerationCompleted(true); 
                            setTimeout(() => {
                                window.location.reload(); 
                            }, 1000);
                        }
                    } else {
                        clearTimeout(timer10)
                        clearTimeout(timer20)
                        clearTimeout(timer30)
                        clearTimeout(timer40)
                        clearTimeout(timer50)
                        clearTimeout(timer60)
                        clearTimeout(timer70)
                        clearTimeout(timer80)
                        setImportStatus("Generate Recipe")
                        setLoading(false);
                        if (data.recipe_response.message === "You have reached the daily import limit") {
                            setGenerateErrorMessage("Oh no, you have reached your daily import limit. Please try again tomorrow.");
                        } else if (data.recipe_response.message === "You are not the recipe owner") {
                            setGenerateErrorMessage("Oh no, you are not the recipe owner. You can not generate a recipe for this recipe.");
                        } else if (data.recipe_response.message === "Invalid session id") {
                            setGenerateErrorMessage("Oh no, your session has expired. Please log in again.");
                        } else {
                            setGenerateErrorMessage("Oh no, something went wrong. Please try again. Error: " + data.recipe_response.message + " Error ID: " + data.id);
                        }
                        setShowErrorMessage(true);
                    }
                });

        } else {
            // fetch(process.env.REACT_APP_API_URL + '/api/createUploadTextRecipeNonMember', options)
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data.status === true) {
            //             Cookies.set("create_name", data.create_name);
            //             Cookies.set("create_introduction", data.create_introduction);
            //             let create_ingredients = [];
            //             for (let i = 0; i < data.create_ingredients.length; i++) {
            //                 create_ingredients.push([0, data.create_ingredients[i][0], data.create_ingredients[i][1], data.create_ingredients[i][2]]);
            //             }
            //             Cookies.set("create_ingredients", JSON.stringify(create_ingredients));

            //             let create_instructions = (data.create_instructions);
            //             Cookies.set("create_instructions", JSON.stringify(create_instructions));
            //             window.location.reload();
            //         } else {
            //             setLoading(false);
            //             setShowErrorMessage(true);
            //         }
            //     });
        }
        return () => clearTimeout(timer10, timer20, timer30, timer40, timer50, timer60, timer70, timer80); // Clear the timer on component unmount

    }

    function generateOptions() {

        setLoadingButton(true);
        
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, recipe_text: recipeTextRef.current.value })
        }

        fetch('https://thesiliconkitchenfunctions.azurewebsites.net/api/generate_recipe_options', options)
            .then(response => response.json())
            .then(data => {
                if (data.status === true && data.recipe_options.recipes.length > 0) { 
                    setShowRecipeOptions(true);
                    setHideUpload(true);
                    setRecipeOptions(data.recipe_options.recipes);
                } else {
                    setGenerateErrorMessage("Oh no, something went wrong. Please try again. Error: " + data.message);
                    setShowErrorMessage(true);
                }
                setLoadingButton(false);
            });
    }

    function uploadRecipePopUpBody() {

        return (
            <div className="upload_recipe_content">

                {loading ? null :
                    <>
                        {errorMessage !== '' ?
                            <>
                                <p className="publish_recipe_remaining_count">{errorMessage}</p> 
                            </>
                            :
                            <>
                                <p className="upload_recipe_content_info">Describe the recipe you want to create and we will generate it for you. Please ensure you review the content before publishing the recipe.</p>

                                {showImportRemaining ? <p className="publish_recipe_remaining_count">You have <span style={{ fontWeight: '500' }}>{10 - importCount} {10 - importCount === 1 ? "generation" : "generations"}</span> remaining today.</p> : null}

                                <div className="upload_recipe_content_body_container_generate" >
                                    <textarea className="upload_recipe_content_body" value={completedSentence} onChange={handleInputChange} ref={recipeTextRef}></textarea>
                                </div>
                                {loading ? null :
                                    <div className="upload_recipe_content_header_buttons_container">
                                        <ButtonPrimary name={window.innerWidth < 768 ? "Generate":"Generate Recipe"} action={() => {setSelectedOption('');uploadTextRecipe()}} icon="ambient_screen" isLoading={loading} isDisabled={generateDisabled || loadingButton} />
                                        <ButtonSecondary name={window.innerWidth < 768 ? "Get Options":"Generate Options"} action={() => generateOptions()} icon="list" isLoading={loadingButton} isDisabled={generateDisabled} />
                                        {/* <ButtonCancelSecondary name="Nevermind" action={() => setShowUploadRecipe(false)} icon="undo" isLoading={loading} /> */}
                                    </div>
                                }

                            </>
                        }
                    </>
                }
                <div className="upload_recipe_content_footer_container">
                    {loading && <LoadingBar isLoading={true} completed={generationCompleted}/>}
                    {showErrorMessage && <p className="upload_recipe_content_generate_error_message">{generateErrorMessage}</p>}
                </div>
            </div>
        )

    }

    function recipeOptionsBody() {

        return (
            <div className="upload_recipe_content">
                {recipeOptions.map((recipeOption, index) => { 
                        return (
                            <div key={index} className={index === selectedOption ? "upload_recipe_content_recipe_option_container_selected" : "upload_recipe_content_recipe_option_container"} 
                                onClick={() => {
                                    setSelectedOption(index);
                                    setSelectedDescription(recipeOption.name);
                                }}>
                                <span className="upload_recipe_content_recipe_option_name">{recipeOption.name} </span>
                            </div>
                        )})} 
                <ButtonPrimary name="Generate Recipe" width="100%" action={() => uploadTextRecipe()} icon="ambient_screen" isLoading={loadingButton} isDisabled={selectedOption === '' ? true: false} />
            </div>
        )

    }

    function ingredientsNotFoundPopUpBody() {

        return (
            <div className="upload_recipe_content_ingredients_not_found">
                <p className="upload_recipe_content_info">We have generated your recipe, but there's a few missing items! Please review the recipe before publishing.</p>
                {imageGenerated ? null : <p>We were unable to generate an image for this recipe.</p>}
                {ingredientsNotFound.length > 0 ? 
                    <>
                        <p>We were unable to add the ingredients listed below. We have made a note of these ingredients, and will add it to our database soon! </p>
                        
                        <ul className="upload_recipe_content_ingredient_not_found_list">
                            {ingredientsNotFound.map((ingredient, index) => {
                                return (
                                    <div key={index}>
                                        <li className="upload_recipe_content_ingredient_item">{ingredient}</li>
                                    </div>
                                )
                            })}
                        </ul>
                    </>
                    : null}
                <ButtonPrimary name="Review Recipe" width={window.innerWidth < 768 ? '100%': null} action={() => window.location.reload()} icon="task_alt" />
            </div>
        )
    }

    return (
        <div className="upload_recipe_parent_container" title={props.createRecipeID === "0" ? "Sign up or log in to use the import feature" : false}>
            <ButtonSecondary name={props.createType === "variation" ? "Generate": "Generate Recipe"} width='100%' action={() => {setHideUpload(false);setShowUploadRecipe(true)}} icon="ambient_screen" isDisabled={props.createRecipeID === "0" ? true : false} />
            <div className={hideUpload && "upload_recipe_parent_container_hide"}>
                <PopUp showPopUp={showUploadRecipe} hidePopUp={() => {setShowUploadRecipe(false); setShowErrorMessage(false)}} title={importStatus} body={uploadRecipePopUpBody()} preventHide={loading ? true: false} width="600px" />
            </div>
            <PopUp showPopUp={showRecipeOptions} hidePopUp={() => {setShowRecipeOptions(false)}} title="Choose your recipe to generate" body={recipeOptionsBody()} preventHide={false} width="600px" />
            <PopUp showPopUp={showIngredientsNotFound} hidePopUp={() => setShowIngredientsNotFound(false)} title="Oh no, trouble in the kitchen." body={ingredientsNotFoundPopUpBody()} />
        </div>
    )

}

export default UploadRecipe;