import './recipes_search.css';
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import RecipesFilter from './recipes_filter/recipes_filter_desktop/recipes_filter.js'
import RecipesFilterMobile from './recipes_filter/recipes_filter_mobile/recipes_filter_mobile.js'
import RecipeResults from './recipes_results/recipes_results.js'
import Cookies from "js-cookie";
import { getFavouriteRecipes } from '../functions/functions';
import { Helmet } from 'react-helmet';
import { translate } from "../translations/translations.js";

let ingredientFilters = [];
let mealTypeFilters = [];
let cuisineFilters = [];
let methodFilters = [];
let proteinFilters = [];
let occasionFilters = [];
let cookingTimeFilters = [];
let favouriteFilter = false;
let searchInput = '';

function RecipesSearch(props) {

    const language = Cookies.get('language') || 'english';
    const isMobile = window.innerWidth < 768 ? true : false;
    const session_id = Cookies.get('session_id')   

    const [allRecipes, setAllRecipes ] = useState([]);
    const [batchNumber, setBatchNumber ] = useState(1);
    const [recipes, setRecipes] = useState([]);
    const [favourite, setFavourite] = useState([]);
    const [activeFilters, setActiveFilters] = useState([])
    const [columnsCount, setColumnsCount] = useState(3)

    const location = useLocation();
    const navigate = useNavigate();
    
    const recipesPerPage = 30;

    const inputRef = useRef(null);
    const [suggestionSent, setSuggestionSent] = useState(false);

    const [searching, setSearching] = useState(false);
    const [searchingFinish, setSearchingFinish] = useState(false);

    const placeHolder = useMemo(() => {
        return [{"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}];
      }, []);

    const getFavouriteRecipesMemoize = useCallback(getFavouriteRecipes, [session_id])
    const getFilteredRecipesMemoize = useCallback(getFilteredRecipes,[session_id, language])
    const getAllRecipesMemoize = useCallback(getAllRecipes, [language])
    const updateRecipesMemoize = useCallback(updateRecipes, [getFavouriteRecipesMemoize, getFilteredRecipesMemoize, getAllRecipesMemoize])

    useEffect(()=> {
        document.title = "Garlic Basil - Recipes"
    },[])

    useEffect(() => {
        setAllRecipes(placeHolder);
        setRecipes(placeHolder);
    }, [placeHolder])

    useEffect(() => {

        window.scrollTo({ top: '0', behavior: 'smooth' });

        const queryParams = new URLSearchParams(location.search)
        const containParameters = location.search.length > 0

        ingredientFilters = [];
        mealTypeFilters = [];
        cuisineFilters = [];
        methodFilters = [];
        proteinFilters = [];
        occasionFilters = [];
        cookingTimeFilters = [];
        favouriteFilter = false;
        searchInput = '';

        if (containParameters) {
            const urlFilters = queryParams.entries();
            for (const [key, value] of urlFilters) {
                const values = value.split(',')
                for (const item of values) {
                    addToFilters(key, item)
                }
                // addToFilters(key, value)
            }
            updateRecipesMemoize()
        } else {
            getAllRecipesMemoize()
        }

        getFavouriteRecipesMemoize(session_id)
        .then((response) => setFavourite(response))
        
    }, [session_id, location.search, getAllRecipesMemoize, getFavouriteRecipesMemoize, updateRecipesMemoize]);

    function getAllRecipes() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({language: language})
        }

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipes", options)
        .then((response) => response.json())
        .then((json) => { 
            setBatchNumber(1)
            const first50 = json.slice(0, recipesPerPage);
            setRecipes(first50)
            setAllRecipes(json)
            setSearching(false);
        });
    }

    function showMoreRecipes() {
        const newBatchNumber = batchNumber + 1
        const newRecipes = allRecipes.slice(0, newBatchNumber * recipesPerPage)
        setRecipes(newRecipes)
        setBatchNumber(newBatchNumber)
    }

    function getFilteredRecipes() {

        setBatchNumber(1)
        setSuggestionSent(false)

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({
                session_id: session_id
            ,   search: searchInput
            ,   favourite: favouriteFilter
            ,   ingredientFilters: ingredientFilters
            ,   mealTypeFilters: mealTypeFilters
            ,   cuisineFilters: cuisineFilters 
            ,   methodFilters: methodFilters
            ,   proteinFilters: proteinFilters
            ,   occasionFilters: occasionFilters  
            ,   cookingTimeFilters: cookingTimeFilters  
            ,   language: language
            })
        };
        
        fetch(process.env.REACT_APP_API_URL + "/api/getFilteredRecipes", options)
        .then((response) => response.json())
        .then((json) => {
            const first50 = json.slice(0, recipesPerPage);
            setRecipes(first50)
            setAllRecipes(json)
            setSearchingFinish(true);
            setTimeout(() => {
                setSearching(false);
                setSearchingFinish(false);
            }, 1000);
            
        });
    }

    function addToFiltersAndUpdateRecipes(type, filter, displayName) {

        addToFilters(type, filter, displayName)

        const filter_parameters = [ingredientFilters, mealTypeFilters, cuisineFilters, methodFilters, proteinFilters, occasionFilters, cookingTimeFilters]

        let parameter_value = ''
        for (let i = 0; i < filter_parameters.length; i++) {
            for (let j = 0; j < filter_parameters[i].length; j++) {
                if (j === 0) {
                    if (parameter_value === '') {
                        parameter_value += '?' + getFilterName(filter_parameters[i])+ '=' + filter_parameters[i][j]
                    } else {
                        parameter_value += '&' + getFilterName(filter_parameters[i])+ '=' + filter_parameters[i][j]
                    }
                } else {
                    parameter_value += ',' + filter_parameters[i][j]
                }
            }
        }

        if (favouriteFilter) {
            if (parameter_value === '') {
                parameter_value += '?' + getFilterName(favouriteFilter) + '=' + favouriteFilter
            } else {
                parameter_value += '&' + getFilterName(favouriteFilter) + '=' + favouriteFilter
            }
        }

        if (searchInput !== '') {
            if (parameter_value === '') {
                parameter_value += '?' + getFilterName(searchInput) + '=' + searchInput
            } else {
                parameter_value += '&' + getFilterName(searchInput) + '=' + searchInput
            }
        }

        setActiveFilters([
            ["mealType", mealTypeFilters]
        ,   ["cuisine", cuisineFilters]
        ,   ["ingredient", ingredientFilters]
        ,   ["method", methodFilters]
        ,   ["protein", proteinFilters]
        ,   ["occasion", occasionFilters]
        ,   ["cooking_time", cookingTimeFilters]
        ])
        
        navigate(parameter_value)
    }

    function getFilterName(type) {
        if (type === ingredientFilters) {
            return 'ingredient'
        } else if (type === mealTypeFilters) {
            return 'mealType'
        }
        else if (type === cuisineFilters) {
            return 'cuisine'
        }
        else if (type === methodFilters) {
            return 'method'
        }
        else if (type === proteinFilters) {
            return 'protein'
        }
        else if (type === occasionFilters) {
            return 'occasion'
        }
        else if (type === cookingTimeFilters) {
            return 'cooking_time'
        }
        else if (type === favouriteFilter) {
            return 'favourite'
        }
        else if (type === searchInput) {
            return 'search'
        }
    }

    function updateRecipes() {

        setActiveFilters([
            ["mealType", mealTypeFilters]
        ,   ["cuisine", cuisineFilters]
        ,   ["ingredient", ingredientFilters]
        ,   ["method", methodFilters]
        ,   ["protein", proteinFilters]
        ,   ["occasion", occasionFilters]
        ,   ["cooking_time", cookingTimeFilters]
        ])

        getFavouriteRecipesMemoize()

        if (searchInput === '' 
            & favouriteFilter === false 
            &  ingredientFilters.length === 0 
            & mealTypeFilters.length === 0 
            & cuisineFilters.length === 0
            & methodFilters.length === 0
            & proteinFilters.length === 0
            & occasionFilters.length === 0
            & cookingTimeFilters.length === 0
            ) {
            getAllRecipesMemoize()
        } else {
            setSearching(true);
            getFilteredRecipesMemoize()
        }
    }

    function addToFilters(type, filter, displayName) {

        if (type === "search") {
            searchInput = filter
        }        

        if (type === "ingredient") {
            ingredientFilters.includes(filter) ? ingredientFilters = ingredientFilters.filter((n) => n !== filter): ingredientFilters.push(filter);
        }

        if (type === "mealType") {
            if (mealTypeFilters.includes(filter)) {
                mealTypeFilters = []
            } else {
                mealTypeFilters = []
                if (filter !== '') {
                    mealTypeFilters.push(filter)
                }
            }
        }

        if (type === "cuisine") {
            cuisineFilters.includes(filter) ? cuisineFilters = cuisineFilters.filter((n) => n !== filter): cuisineFilters.push(filter);
        }

        if (type === "method") {
            if (methodFilters.includes(filter)) {
                methodFilters = []
            } else {
                methodFilters = []
                if (filter !== '') {
                    methodFilters.push(filter)
                }
            }
        }

        if (type === "protein") {
            proteinFilters.includes(filter) ? proteinFilters = proteinFilters.filter((n) => n !== filter): proteinFilters.push(filter);
        }

        if (type === "occasion") {
            if (occasionFilters.includes(filter)) {
                occasionFilters = []
            } else {
                occasionFilters = []
                if (filter !== '') {
                    occasionFilters.push(filter)
                }
            }
        }

        if (type === "cooking_time") {
            if (cookingTimeFilters.includes(filter)) {
                cookingTimeFilters = []
            } else {
                cookingTimeFilters = []
                if (filter !== '') {
                    cookingTimeFilters.push(filter)
                }
            }
        }

        if (type === "favourite") {
            favouriteFilter = !favouriteFilter
        }
    }

    function sortRecipes(type) {
        
        let tmp_data = allRecipes.slice()
        let sortedArray;

        if (type === "A – Z") {
            sortedArray = tmp_data.sort((a, b) => {
                if (a["recipe_name"] < b["recipe_name"]) {
                    return -1;
                }
                if (a["recipe_name"] > b["recipe_name"]) {
                    return 1;
                }
                    return 0;
            });
        }
        else if (type === "Views") {

            sortedArray = tmp_data.sort((a, b) => {
                if (a["recipe_views"] > b["recipe_views"]) {
                    return -1;
                }
                if (a["recipe_views"] < b["recipe_views"]) {
                    return 1;
                }
                    return 0;
            });
        }
        else if (type === "Ratings") {

            sortedArray = tmp_data.sort((a, b) => {
                if (a["recipe_ratings"] > b["recipe_ratings"]) {
                    return -1;
                }
                if (a["recipe_ratings"] < b["recipe_ratings"]) {
                    return 1;
                }
                    return 0;
            });
        }
        
        setAllRecipes(sortedArray)

        const currentRecipes = tmp_data.slice(0, batchNumber * recipesPerPage)
        setRecipes(currentRecipes)
    }

    function setColumns(columns) {
        setColumnsCount(columns)
    }

    function clearAllFilters() {
        ingredientFilters = [];
        mealTypeFilters = [];
        cuisineFilters = [];
        methodFilters = [];
        proteinFilters = [];
        occasionFilters = [];
        cookingTimeFilters = [];
        favouriteFilter = false;
        searchInput = '';

        setActiveFilters([
            ["mealType", mealTypeFilters]
        ,   ["cuisine", cuisineFilters]
        ,   ["ingredient", ingredientFilters]
        ,   ["method", methodFilters]
        ,   ["protein", proteinFilters]
        ,   ["occasion", occasionFilters]
        ,   ["cooking_time", cookingTimeFilters]
        ])
        getAllRecipes()
    }

    function sendRecipeRequest(recipe_name) {

        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({recipe_name:recipe_name})
        }

        fetch(process.env.REACT_APP_API_URL + "/api/sendRecipeSuggestion", options)
        .then((response) => response.json())
        .then((json) => {
            if (json["status"] === "success") {
                setSuggestionSent(true)
            }
        })
    }

    function showNoResultsMessage() {

        return (
            <div className="recipe_search_no_results_container">
                <div className="recipe_serach_no_results_content">
                    <h2 className="recipe_search_no_results_title">{translate('recipes search', 'no results title')}</h2>
                    <p className="recipe_search_no_results_text">
                    {translate('recipes search', 'no results info')}
                    </p>
                    <label>
                    <input className="recipe_search_no_results_suggestion" type="input" defaultValue={searchInput} ref={inputRef}/>
                    </label><br/>
                    {suggestionSent ? 
                    <p>{translate('recipes search', 'thank you message')}</p>:
                    <button className="recipe_search_no_results_submit" onClick={() => sendRecipeRequest(inputRef.current.value)}>{translate('recipes search', 'send suggestion')}</button>
                    }
                </div>
            </div>
        )
    }

    function returnMobileView() {
        return (
            <div>
                <RecipesFilterMobile activeFilters={activeFilters} sortRecipes={sortRecipes} updateRecipes={addToFiltersAndUpdateRecipes} showAlertMessage={props.showAlertMessage} searching={searching} searchingFinish={searchingFinish}/>
            </div>
        )
    }

    function returnDesktopView() {
        return (
            <div>
                <h2 className="recipe_search_title">{translate("recipes search", "recipes search intro")}</h2>
                <RecipesFilter addToFiltersAndUpdateRecipes={addToFiltersAndUpdateRecipes} clearAll={clearAllFilters} activeFilters={activeFilters} sortRecipes={sortRecipes} setColumns={setColumns} clearAllFilters={clearAllFilters} showAlertMessage={props.showAlertMessage} searching={searching} searchingFinish={searchingFinish}/>  
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="Discover an array of home cook meals with our AI sourced recipe collection. From classic favorites to innovative creations, explore hundreds of recipes and unleash your culinary skills today." />
            </Helmet>
            <div className="recipe_search_container">
                <div className="recipe_search_background"></div>
                <div className="recipe_search_child_container">
                    {isMobile ? returnMobileView(): returnDesktopView()}
                    <RecipeResults recipes={recipes} favourite={favourite} columns={columnsCount} showAlertMessage={props.showAlertMessage}/>
                    {allRecipes.length === 0 && !favouriteFilter ? showNoResultsMessage() : null}
                    {allRecipes.length / recipesPerPage  > batchNumber ? <div style={{width:"100%", position:"relative", textAlign:"center"}}><button className="recipe_search_see_more" onClick={showMoreRecipes}>{translate('recipes search', 'more recipes')}</button></div>: null }
                </div>
            </div>
        </>
    );
}

export default RecipesSearch;
