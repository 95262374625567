import './reset_password.css';
import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ResetPassword() {

    const [validPassword, setValidPassword] = useState(false)
    const [validConfirmPassword, setValidConfirmPassword] = useState(false)
    const [submitActive, setSubmitActive] = useState(false)
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const passwordRegex = /^[\w!@#$%^&*()\-+=[\]{};':"\\|,.<>/?]{8,}$/;

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("reset_token");

    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(()=> {
        document.title = "Garlic Basil - Reset Password"
    },[])


    const handlePasswordChange = () => {
        const isValidPassword = passwordRegex.test(passwordRef.current.value);
        setValidPassword(isValidPassword)
        if (passwordRef.current.value === confirmPasswordRef.current.value) {
            setSubmitActive(true)
            setValidConfirmPassword(true)
        } else {
            setValidConfirmPassword(false)
            setSubmitActive(false)
        }
    }

    const handleConfirmPasswordChange = () => {
        if (passwordRef.current.value === confirmPasswordRef.current.value && passwordRegex.test(confirmPasswordRef.current.value)) {
            setValidConfirmPassword(true)
            setSubmitActive(true)
        } else {
            setSubmitActive(false)
            setValidConfirmPassword(false)
        }
    }

    function handleSubmit(event) {
        // Prevent the default form submission behavior
        event.preventDefault();

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ reset_token: token, password: passwordRef.current.value }),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/resetPassword", options)
        .then((response) => response.json())
        .then((json) => handleResponse(json))

        const handleResponse = (body) => {
            if (body['status'] === 'true') {
                setShowMessage(true)
                setMessage("Success! Your password has been reset. You can now log in with your new password.")
            } else {
                setShowMessage(true)
                setMessage("There was an error resetting your password, your token may have expired. Please try resetting your password again. If the issue persist, please contact us.")
            }
        }

    }

    return (
        <div className="reset_password_container">
            <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/reset_password.jpg" alt="Reset Password Background" className="reset_password_background"/>
            <div className="reset_password_content">
                <h1 className="reset_password_title">Reset Password</h1>
                <p className="reset_password_message">Please enter your new password below:</p>
                <form className="reset_password_form" onSubmit={handleSubmit}>
                    <input type="password" className="reset_password_input" placeholder="Password (minimum 8 characters)" ref={passwordRef} onChange={handlePasswordChange}/><span className="material-symbols-outlined criteria_unconfirmed" style={validPassword ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br/>
                    <input type="password" className="reset_password_input" placeholder="Confirm Password" ref={confirmPasswordRef} onChange={handleConfirmPasswordChange}/><span className="material-symbols-outlined criteria_unconfirmed" style={validConfirmPassword ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br/>
                    <button className={submitActive ? "sign_up_submit":"sign_up_submit_deactivate"} type="submit" disabled={submitActive ? false: true } >Reset Password</button>
                </form>
                {showMessage ? <p className="reset_password_repsonse">{message}</p> : null}
            </div>
        </div>
    )
}

export default ResetPassword;