import './log_in_sign_up.css'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { translate } from '../../translations/translations.js'
import Cookies from 'js-cookie';

function LogInSignUp(props) {

    const navigate = useNavigate()
    const language = Cookies.get('language') || 'english';
    const [navFontSize, setNavFontSize] = useState();

    useEffect(() => {
        if (language === 'chinese' || language === "korean" || language === "japanese") {
            setNavFontSize('16px');
        }
    }, [language]);

    function goToLogInPage() {
        navigate("/log-in")
    }

    function goToSignUpPage() {
        navigate("/sign-up")
    }

    return (
        <div className="log_in_sign_up_container" style={{fontSize:navFontSize}}>
            <span className="log_in_button_container">
                <button className={props.createMode ? "log_in_button_create" : "log_in_button"}onClick={goToLogInPage}>
                    {translate('navigation', 'LOG IN')}
                </button>
            </span>
            <span className="sign_up_button_container">
                <button className={props.createMode ? "sign_up_button_create primary_colour": "sign_up_button primary_colour"} onClick={goToSignUpPage}>
                    <span className="material-symbols-outlined sign_up_button_icon">person</span>
                    {translate('navigation', 'SIGN UP')}
                </button>
            </span>
        </div>
    )
}

export default LogInSignUp;