import './create.css';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Header from './header/header.js';
import Name from './name/name.js';
import Introduction from './introduction/introduction.js';
import Image from './image/image';
import Ingredients from './ingredients/ingredients';
import Instructions from './instructions/instructions';
import DeleteRecipe from './delete_recipe/delete_recipe.js';
import Cookies from "js-cookie"; 
import ButtonSecondary from '../components/button/button_secondary.js';


function Create(props) {

    const navigate = useNavigate();
    const [isOwner, setIsOwner] = useState(true);
    const createRecipeID = useParams().recipeId 
    const session_id = Cookies.get('session_id') 

    useEffect(() => {
        if (createRecipeID !== "0") { 
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({session_id: session_id, create_recipe_id: createRecipeID}),
            };
            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateLastViewed', options)
        }
    }, [createRecipeID, props.session_id, session_id]);

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({session_id: session_id, create_recipe_id: createRecipeID}),
        };

        fetch(process.env.REACT_APP_API_URL + '/api/createIsRecipeOwner', options)
            .then(response => response.json())
            .then(data => { 
                if (data.status) {
                    setIsOwner(true);
                } else {
                    if (createRecipeID === "0") { 
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }
                }
            })
    }, [createRecipeID, props.session_id, session_id]);


    return (
        <div className="create_parent_container">
            {isOwner ?
                <div className="create_content_container">
                    <div className="create_content_header_container">
                        <Header createRecipeID={createRecipeID} />
                    </div>
                    <div className="create_content_background_container">
                        <div className="create_content_name_container">
                            <Name createRecipeID={createRecipeID} />
                        </div>
                        <div className="create_content_introduction_container">
                            <Introduction createRecipeID={createRecipeID}/>
                        </div>
                        <div className="create_content_image_container">
                            <Image createRecipeID={createRecipeID}/>
                        </div> 
                        <div className="create_content_ingredients_container">
                            <Ingredients createRecipeID={createRecipeID} />
                        </div>
                        <div className="recipe_page_mobile_break"></div>
                        <div className="create_content_instructions_container">
                            <Instructions createRecipeID={createRecipeID} />
                        </div>
                    </div>
                    {createRecipeID !== "0" &&
                        <div className="create_content_delete_container">
                            <DeleteRecipe createRecipeID={createRecipeID} showAlertMessage={props.showAlertMessage}/>
                        </div>
                    }
                </div>
            :               
            <div className="create_content_no_access">
                <span className="my_collection_sign_up_title_container">
                    <h1 className="my_collection_sign_up_title">You do not have access to this recipe, if you're the owner please log in.</h1>
                    {/* <ButtonPrimary name="Sign Up" icon="travel_explore" margin="right" className="my_collection_sign_up_button" action={() => navigate('/sign-up')} /> */}
                    <ButtonSecondary name="Log In" icon="login" className="my_collection_sign_up_button" action={() => navigate('/log-in')} />
                </span>
            </div> 
        }
        </div>
    )

}

export default Create;