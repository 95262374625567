import React, { useState, useRef } from 'react'
import './recipe_introduction.css'
import PlaceHolder from '../../../components/placeholder/placeholder.js'
import { translate } from '../../../translations/translations.js'

function RecipeIntroduction(props) {

    const isMobile = window.innerWidth < 768 ? true : false
    const [showIntroduction, setShowIntroduction] = useState(false);
    const [showUpdated, setShowUpdated] = useState(false);
    const recipeIntroductionRef = useRef();

    function toggleShowIntroduciton() {
        setShowIntroduction(preState => !preState);
    }

    function returnMobileView() {
        return (
            props.introductionLoaded ? <div><p className={showIntroduction ? "recipe_introduction_text_show" : "recipe_introduction_text_hide"}>{props.text}</p><span className="recipe_introduction_show_more" onClick={toggleShowIntroduciton} >{showIntroduction ? translate("recipe", "see less") : translate("recipe", "see more")}</span></div> : <div className="recipe_introduction_placeholder"><PlaceHolder /></div>
        )
    }

    function returnDesktopView() {
        return (
            props.introductionLoaded ? <div><textarea className="recipe_introduction_text_show_admin" ref={recipeIntroductionRef} defaultValue={props.text}></textarea></div> : <div className="recipe_introduction_placeholder"><PlaceHolder /></div>
        )
    }

    function updateRecipeIntroduction() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({recipe_id: props.recipe_id, recipe_introduction: recipeIntroductionRef.current.value})
        };

        fetch(process.env.REACT_APP_API_URL + "/api/updateRecipeIntroduction", options)
        .then(reponse => reponse.json())
        .then(json => {
            if (json['status'] === 'success') {
                setShowUpdated(true)
            }
        })
    }


    return (
        <div className="recipe_introduction_container">
            {props.introductionLoaded ?
                <h1 className="recipe_name">{props.name}</h1> 
                :
                <div className="recipe_title_placeholder"><PlaceHolder /></div>
            }
            {isMobile ? returnMobileView() : returnDesktopView()}
            <button className="update_recipe_introduction_button" onClick={updateRecipeIntroduction} >Save</button>
            {showUpdated ? <p>Updated</p> : null}
        </div>
    )

}

export default RecipeIntroduction;