import './language_options.css';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from "react-router-dom";

function LanguageOptions(props) {

    const [language, setLanguage] = useState('english');
    const location = useLocation();

    function changeLanguage(language) {
        
        const urlParams = new URLSearchParams(location.search);
        const lngValue = urlParams.get('lng', language);
        let newUrl;
        if (lngValue) {
            urlParams.delete('lng');
        }
        if (urlParams.toString() === '') {
            newUrl = window.location.pathname
        } else {
            newUrl = window.location.pathname + '?' + urlParams.toString()
        }
        window.history.replaceState(null, '', newUrl);

        Cookies.set('language', language, { expires: 365 });
        setLanguage(language);
        props.hide()
        window.location.reload()
        window.scrollTo(0, 0)
    }

    function hideOptions() {
        props.hide()
    }

    return (
        <div className="language_options_container" onClick={() => hideOptions()}>
            <div className="language_options_selection_container">
                <p className="language_options_title">Switch language:</p>
                <div className="language_options_item" onClick={() => changeLanguage('chinese')}>
                    <span className={language === 'chinese' ? "language_options_item_selected" : "language_options_item_unselected"}>中文</span><span className="language_optioons_flag_icon">🇨🇳</span>
                </div>                
                <div className="language_options_item" onClick={() => changeLanguage('english')}>
                    <span className={language === 'english' ? "language_options_item_selected" : "language_options_item_unselected"}>English</span><span className="language_optioons_flag_icon">🇬🇧</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('french')}>
                    <span className={language === 'french' ? "language_options_item_selected" : "language_options_item_unselected"}>Français</span><span className="language_optioons_flag_icon">🇫🇷</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('german')}>
                    <span className={language === 'german' ? "language_options_item_selected" : "language_options_item_unselected"}>Deutsch</span><span className="language_optioons_flag_icon">🇩🇪</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('italian')}>
                    <span className={language === 'italian' ? "language_options_item_selected" : "language_options_item_unselected"}>Italiano</span><span className="language_optioons_flag_icon">🇮🇹</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('japanese')}>
                    <span className={language === 'japanese' ? "language_options_item_selected" : "language_options_item_unselected"}>日本語</span><span className="language_optioons_flag_icon">🇯🇵</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('korean')}>
                    <span className={language === 'korean' ? "language_options_item_selected" : "language_options_item_unselected"}>한국어</span><span className="language_optioons_flag_icon">🇰🇷</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('spanish')}>
                    <span className={language === 'spanish' ? "language_options_item_selected" : "language_options_item_unselected"}>Español</span><span className="language_optioons_flag_icon">🇪🇸</span>
                </div>
                <div className="language_options_item" onClick={() => changeLanguage('vietnamese')}>
                    <span className={language === 'vietnamese' ? "language_options_item_selected" : "language_options_item_unselected"}>Tiếng Việt</span><span className="language_optioons_flag_icon">🇻🇳</span>
                </div>
            </div>
        </div>
    );
}

export default LanguageOptions;