import './email_confirmation.css'
import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom'

function EmailConfirmation() {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('confirmation_token');

    const [words, setWords] = useState([]);
    const [status, setStatus] = useState("");

    useEffect(()=> {
        document.title = "Garlic Basil - Email Confirmation"
    },[])

    useEffect(() => {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ confirmation_token: token}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/confirmEmail", options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "success") {
                setWords(document.querySelectorAll('.email_confirmation_feature'));
            } else {
                setStatus("failed")
            }
        })

    }, [token])

    useEffect(() => {
      function showWord(wordIndex) {
        if (wordIndex >= words.length) return;
      
        words[wordIndex].classList.add('email_confirmation_feature_active');
      
        setTimeout(() => {
          showWord(wordIndex + 1);
        }, 300);
      }
  
      showWord(0);
    }, [words]);


    return (
        <div className="email_confirmation_container">

            <div className="email_confirmation_content_container">
                <img className="email_confirmation_background" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/email_confirmation.jpg" alt="Email Confirmed Background" />
                    
                {status === "faile" ? 
                <div className="email_confirmation_failed">
                    <div className="email_confirmation_title">
                        Oh no, your link has expired. <br/> Please sign up again and validate your account!
                    </div>
                </div>
                :
                <div className="email_confirmation_failed">
                    <div className="email_confirmation_title">
                        Email Confirmation Successful!
                    </div>
                </div>
                }

                {/* <div className="email_confirmation_feature">
                    <div className="email_confirmation_title">
                        Hurray! Your account is now on the plate. {window.innerWidth > 768 ? <br/> : null} Check out these features with your new account.
                    </div>
                </div>

                <div className="email_confirmation_feature">
                    <div className="email_confirmation_feature_icon"><span className="material-symbols-outlined email_confirmation_feature_icon_text">favorite</span></div>
                    <div className="email_confirmation_feature_text_container">
                        <div className="email_confirmation_feature_text">
                            <h3 className="email_confirmation_feature_title">Save your favourite recipes</h3>
                            <p className="email_confirmation_feature_description">Never lose track of your favourite dishes again! With the click of a button, you can save your favourite recipes and access them easily whenever you want.</p>
                        </div>
                    </div>
                </div>
                <div className="email_confirmation_feature">
                    <div className="email_confirmation_feature_icon"><span className="material-symbols-outlined email_confirmation_feature_icon_text">shopping_basket</span></div>
                    <div className="email_confirmation_feature_text_container">
                        <div className="email_confirmation_feature_text">
                            <h3 className="email_confirmation_feature_title">Add ingredients to your basket</h3>
                            <p className="email_confirmation_feature_description">Say goodbye to the stress of grocery shopping! With our easy-to-use basket feature, you can quickly and easily add all the ingredients you need for your favourite recipes.</p>
                        </div>
                    </div>
                </div>
                <div className="email_confirmation_feature">
                    <div className="email_confirmation_feature_icon"><span className="material-symbols-outlined email_confirmation_feature_icon_text">star</span></div>
                    <div className="email_confirmation_feature_text_container">
                        <div className="email_confirmation_feature_text">
                            <h3 className="email_confirmation_feature_title">Rate our recipes</h3>
                            <p className="email_confirmation_feature_description">Help us improve by rating the recipes you try. Your feedback is incredibly valuable to us, and it helps us to continue providing high-quality content that meets your needs.</p>
                        </div>
                    </div>
                </div> */}
            </div>


        </div>
    )
}

export default EmailConfirmation;