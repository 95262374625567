import './recipe_ingredients.css';
import React, { useState } from 'react';
import IngredientTile from '../../../components/ingredient_tile/ingredient_tile.js';

function RecipeIngredientsItem(props) {

    const [completed, setCompleted] = useState(false);
    
    function toggleComplete(name) {

        if (completed) {
            const element = document.getElementById(name);
            const icon = document.getElementById(name + "_icon");
            element.style.opacity = 1;
            icon.style.display = "none";
        } else {
            const element = document.getElementById(name);
            const icon = document.getElementById(name + "_icon");
            element.style.opacity = 0.4;
            icon.style.display = "block";
        }

        setCompleted(!completed);

    }

    return (
        <div className="recipe_ingredients_parent_container" onClick={() => toggleComplete(props.name)}>
            <span id={props.name}>
                <IngredientTile width={props.width} height={props.height} name={props.name} name_plural={props.name_plural} quantity={props.quantity} uom={props.uom} hash_id={props.hash_id} servings={props.servings} recipeServings={props.recipeServings} />
            </span>
            <div id={props.name + "_icon"} className="recipe_ingredients_completed_overlay">
                <span className="material-symbols-outlined recipe_ingredients_completed_overlay_icon">task_alt</span>
            </div>
        </div>
    );
}

export default RecipeIngredientsItem;