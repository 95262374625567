import './instructions.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import InstructionsItem from './instructions_item.js';

function Instructions(props) {

    const session_id = Cookies.get('session_id');
    const [instructionsList, setInstructionsList] = useState([]);
    const [cookingTime, setCookingTime] = useState();
    const [hideDeleteButton, setHideDeleteButton] = useState(false);
    const cookingTimeRef = useRef(null);

    const refreshInstructionsListMemoize = useCallback(refreshInstructionsList, [props.createRecipeID, session_id]);

    useEffect(() => {
        if (props.createRecipeID !== "0") {
            refreshInstructionsListMemoize();
        } else {
            
            let instruction_list = Cookies.get('create_instructions')
            if (instruction_list) {
                const tmpList = JSON.parse(instruction_list);
                if (tmpList.length > 0) {
                    setInstructionsList(JSON.parse(instruction_list));
                } else {
                    instruction_list = [[0, 'main', 1, '']];
                    setInstructionsList(instruction_list);
                }
            } else {
                instruction_list = [[0, 'main', 1, '']];
                setInstructionsList(instruction_list);
            }
            if (instruction_list.length === 1) {
                setHideDeleteButton(true);
            } else {
                setHideDeleteButton(false);
            }

            let cooking_time = Cookies.get('create_cooking_time');
            if (cooking_time) {
                setCookingTime(cooking_time);
            } else {
                setCookingTime(0);
            }
        }
    }, [refreshInstructionsListMemoize, props.createRecipeID]);

    function addNewStep(stepNumber) {
        
        if (props.createRecipeID !== "0") {
            const newStepNumber = stepNumber + 1;
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, create_step_number: newStepNumber, create_instruction: '' }),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createAddInstruction', options)
                .then(response => response.json())
                .then(() => {
                    refreshInstructionsList();
                    if (window.innerWidth > 768) {
                    const currentStep = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
                    const newStep = document.getElementById('create_instructions_step_text_area_id' + newStepNumber);
                    const actionButtons = document.getElementById('create_instruction_remove_id' + stepNumber);
                    actionButtons.style.display = 'none';
                    currentStep.style.backgroundColor = 'transparent';
                    newStep.focus();
                    }
                });
        } else {

            const newStepNumber = stepNumber + 1;
            const newInstructionList = [...instructionsList];
            for (let i = 0; i < newInstructionList.length; i++) {
                if (newInstructionList[i][2] >= newStepNumber) {
                    newInstructionList[i][2] = newInstructionList[i][2] + 1;
                }
            }
            newInstructionList.splice(stepNumber, 0, [0, 'main', newStepNumber, '']);
            Cookies.set('create_instructions', JSON.stringify(newInstructionList));
            setInstructionsList(newInstructionList);
            const currentStep = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
            // const newStep = document.getElementById('create_instructions_step_text_area_id' + newStepNumber);
            const actionButtons = document.getElementById('create_instruction_remove_id' + stepNumber);
            actionButtons.style.display = 'none';
            currentStep.style.backgroundColor = 'transparent';
            // newStep.focus();
            if (newInstructionList.length === 1) {
                setHideDeleteButton(true);
            } else {
                setHideDeleteButton(false);
            }
        }
    }

    function refreshInstructionsList() {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createGetInstructions', options)
            .then(response => response.json())
            .then(data => {
                const sortedData = [...data].sort((a, b) => a[2] - b[2]);
                setInstructionsList(sortedData); 
                if (data.length === 1) {
                    setHideDeleteButton(true);
                } else {
                    setHideDeleteButton(false);
                }
            });

        fetch(process.env.REACT_APP_API_URL + '/api/createGetCookingTime', options)
            .then(response => response.json())
            .then(data => {   
                if (data.status) { 
                    setCookingTime(data.cooking_time);
                }
            })
    }

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData("text/plain", index);
        // event.dataTransfer.setDragImage(new Image(), 0, 0); // Set a custom drag image
        event.dataTransfer.effectAllowed = "move";

        // Store the initial vertical position
        event.dataTransfer.setData("initialClientY", event.clientY);
    };

    const handleDragOver = (event, index) => {
        event.preventDefault();
    };

    const handleDrop = (event, rawNewIndex) => {
        event.preventDefault();

        if (props.createRecipeID !== "0") {
            const oldIndex = event.dataTransfer.getData("text/plain");

            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, create_new_step_number: parseInt(rawNewIndex, 10) + 1, create_old_step_number: parseInt(oldIndex, 10) + 1 }),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateInstructionStepNumber', options)
                .then(response => response.json())
                .then(() => {
                    refreshInstructionsList();
                }
            );
        } else {
            const oldIndex = parseInt(event.dataTransfer.getData("text/plain"), 10) + 1;
            const newIndex = rawNewIndex + 1;
            
            const newInstructionList = [...instructionsList];
            if (oldIndex < newIndex) {
                newInstructionList[oldIndex - 1][2] = 0;
                newInstructionList.sort((a, b) => a[2] - b[2]);
                for (let i = 0; i < newInstructionList.length; i++) {
                    if (newInstructionList[i][2] > oldIndex && newInstructionList[i][2] <= newIndex) {
                        newInstructionList[i][2] = newInstructionList[i][2] - 1;
                    }
                }
                newInstructionList[0][2] = newIndex;
                newInstructionList.sort((a, b) => a[2] - b[2]);
                Cookies.set('create_instructions', JSON.stringify(newInstructionList));
                setInstructionsList(newInstructionList);

            } else {
                newInstructionList[oldIndex - 1][2] = 0;
                newInstructionList.sort((a, b) => a[2] - b[2]);
                for (let i = 0; i < newInstructionList.length; i++) {
                    if (newInstructionList[i][2] >= newIndex && newInstructionList[i][2] < oldIndex) {
                        newInstructionList[i][2] = newInstructionList[i][2] + 1;
                    }
                }
                newInstructionList[0][2] = newIndex;
                newInstructionList.sort((a, b) => a[2] - b[2]);
                Cookies.set('create_instructions', JSON.stringify(newInstructionList));
                setInstructionsList(newInstructionList);
            }
        }   
    };


    function updateCookingTime() {

        if (props.createRecipeID !== "0") {
            setCookingTime(cookingTimeRef.current.value);
            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateCookingTime', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, create_cooking_time: cookingTimeRef.current.value }),
            })
        } else {
            setCookingTime(cookingTimeRef.current.value);
            Cookies.set('create_cooking_time', cookingTimeRef.current.value);
        }
    
    }

    return (
        <div className="create_instructions_parent_container">
            <div className="create_instructions_content_container">
                <div className="create_instructions_content_header_container">
                    <div className="create_content_instructions_title_container">
                        <h2 className="create_instructions_title">Method</h2>
                    </div>
                    <div className="create_content_instructions_servings_container">
                        <p className="create_content_instructions_servings_label">Cooking Time (Minutes):</p>
                        <input id="create_content_cooking_time_value_element" className="create_content_instructions_servings_input" inputMode="numeric" onBlur={() => updateCookingTime()} ref={cookingTimeRef} style={parseInt(cookingTime, 10) === 0 ? {border: '2px solid var(--cancel-color)', backgroundColor: 'rgb(255,225,225)'} : null } defaultValue={cookingTime === 0 ? null : cookingTime} placeholder="0"/>
                    </div>
                </div>
                <div className="create_instructions_content_instructions_container">
                    <div id="create_instructions_content_list_element" className="create_instructions_content_instructions">
                        {instructionsList.map((instruction, index) => {
                            return (
                                <div key={instruction + "_" + index}
                                    draggable={true} // Enable draggable property
                                    onDragStart={(event) => handleDragStart(event, index)} // Handle drag start event
                                    onDragOver={(event) => handleDragOver(event, index)} // Handle drag over event
                                    onDrop={(event) => handleDrop(event, index)} // Handle drop event 
                                >
                                    <InstructionsItem instructionsList={instructionsList} setInstructionsList={setInstructionsList} data={instruction} createRecipeID={props.createRecipeID} refreshInstructionsList={refreshInstructionsList} addNewStep={addNewStep} hideDelete={hideDeleteButton} setHideDeleteButton={setHideDeleteButton}/>
                                </div>
                            )
                        })}
                        {/* <button className="create_instructions_add_button" onClick={() => addNewStep(numberOfSteps + 1)}>Add step</button> */}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Instructions;