import './key_features.css'
import React from "react"; 

function KeyFeatures(props) {

    return (
        <>
            <div className="key_features_container"> 
                <div className="key_features_content_container">
                    <div className="key_features_content">
                        <div className="key_features_content_image_container">
                            <img className="key_features_content_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/500x500_mascot_discover.webp" alt="recipe_recommendations" />
                        </div>
                        <div className="key_features_content_text_container">
                            <h3 className="key_features_content_title">Generate</h3>
                            <p className="key_features_content_information">Generate your personalised recipe with AI in under a minute.</p>
                        </div>
                    </div>
                    <div className="key_features_content">
                        <div className="key_features_content_image_container">
                            <img className="key_features_content_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/500x500_mascot_create.webp"  alt="recipe_collections" />
                        </div>
                        <div className="key_features_content_text_container">
                            <h3 className="key_features_content_title">Edit</h3>
                            <p className="key_features_content_information">Edit your recipe for your kitchen and taste preferences.</p>
                        </div>
                    </div>
                    <div className="key_features_content">
                        <div className="key_features_content_image_container">
                            <img className="key_features_content_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/500x500_mascot_curate.webp" alt="recipe_search" />
                        </div>
                        <div className="key_features_content_text_container">
                            <h3 className="key_features_content_title">Publish</h3>
                            <p className="key_features_content_information">Publish your recipe and share it with family and friends.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyFeatures;