import './about.css'
import React, { useEffect } from "react";
import { translate } from '../translations/translations.js'

function About() {

    useEffect(()=> {
        document.title = "Garlic Basil - About Us"
    },[])

    return (
        <div className="about_container">
            <img className="about_us_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1920x1152_about_us.webp" alt="about_us_image"/>
            <div className="purpose_container">
                <div className="purpose_container_text_container"></div>
                <div className="purpose_container_text">
                    <div className="purpose_text_title_container"> 
                        <p className="purpose_sub_title">{translate('about us', 'about us subtitle')}</p>
                        {translate('about us', 'about us title')}

                    </div>
                    <p className="purpose_statement">
                        {/* {translate('about us', 'about us text')} */}
                        Here at Garlic Basil we love food and we love cooking.
                        Our purpose is to redefine our relationship with home cooking by providing a platform that inspires and empowers home chefs to explore new recipes, techniques, and ingredients. 
                        <br/><br/>
                        At the heart of our philosophy lies the firm belief that the collective community has the capacity to craft and refine the finest recipes in the world, fostering an environment where we can all partake in a shared journey of exploring, creating and most important of all, tasting.
                        <br/><br/>
                        For enquiries, reach us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a>
                    </p>
                </div>
                
            </div>
        </div>
    );
}

export default About;

