import './collection.css';
import React, { useState, useEffect, useRef } from 'react';
import RecipeTile from '../components/recipe_tile/recipe_tile.js'; 
import ButtonSecondary from '../components/button/button_secondary';
import DropDownSelection from '../components/dropdown_selection/dropdown_selection.js';
import { useParams } from 'react-router-dom';

function Collection(props) {

    const [firstName, setFirstName] = useState('')
    const [handle, setHandle] = useState('')
    const [dataFetched, setDataFetched] = useState(false);
    const [userExists, setUserExists] = useState(true);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [allRecipes, setAllRecipes] = useState([]);
    const [recipes, setRecipes] = useState([]);
    // const [showUserCreations, setShowUserCreations] = useState(false);
    const showUserCreations = false;
    const [showSortDisplay, setShowSortDisplay] = useState(false); 
    const [recipesStats, setRecipesStats] = useState()
    const { userHandle } = useParams();

    useEffect(() => {

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_handle: userHandle }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/getUserNameWithHandle', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setFirstName(data.first_name)
                    setHandle(data.handle)
                }
            })

        fetch(process.env.REACT_APP_API_URL + '/api/getCollection', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setUserLoggedIn(true)
                    const recipes = data.recipes
                    const sortedRecipes = [...recipes]
                        .filter(recipe => recipe.collection === "1")
                        .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
                    setRecipes(sortedRecipes)
                    setAllRecipes(data.recipes)
                    setRecipesStats(data.stats)
                } else {
                    setUserExists(false)
                }
                setDataFetched(true)
            });

    }, [userHandle]);

    function toggleSortDisplay(currentStatus) {
        if (currentStatus) {
            document.removeEventListener('click', handleClickOutside);
        } else {
            document.addEventListener('click', handleClickOutside);
        }
        setShowSortDisplay(!currentStatus);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowSortDisplay(false)
        }
    };

    function sortBy(value) {
        if (value === 'Views') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.views > b.views) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Variations') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.variation_count > b.variation_count) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Ratings') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.ratings > b.ratings) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Date Created') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.date_created > b.date_created) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Last Viewed') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Collections') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.collection_count > b.collection_count) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'A - Z') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.recipe_name > b.recipe_name) ? 1 : -1);
            setRecipes(sortedRecipes);
        }
        setShowSortDisplay(false)
    }

    const dropdownRef = useRef();

    return (
        <div className="collection_parent_container">
            {userLoggedIn ? <div className="collection_title_image_container">
                <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1192x600_my_collection_header_background.webp" alt="My Collection Background" className="collection_title_image" />
            </div>
                : null}
            <div className="collection_background"></div>
            {dataFetched ?
                userExists ?

                    <div className="collection_title_container">
                        <div className="collection_page_header_container">
                            <span className="collection_page_header_title">
                                {window.innerWidth < 768 ? null : <div className="collection_page_header_handle_container">{handle}</div>}
                                {showUserCreations ?
                                    window.innerWidth < 768 ?
                                        <h1 className="collection_title">{firstName}'s<br />Published Recipes</h1>
                                        :
                                        <h1 className="collection_title">{firstName !== '' ? firstName + "'s Published Recipes" : null}</h1>
                                    :
                                    window.innerWidth < 768 ?
                                        <h1 className="collection_title">{firstName}'s<br />Recipe Collection</h1>
                                        :
                                        <h1 className="collection_title">{firstName !== '' ? firstName + "'s Recipe Collection" : null}</h1>
                                }
                            </span>
                            {window.innerWidth < 768 ? null :
                                <span className="collection_statistics">
                                    <span title="This is the number of recipes in this collection.">
                                        {recipesStats.recipes_count} {recipesStats.recipes_count === 1 ? "Recipe" : "Recipes"}
                                    </span>
                                    <span title="This is the number of recipes this user have published.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.recipes_published} {recipesStats.recipes_publisehd === 1 ? "Creation" : "Creations"}
                                        {/* &nbsp;&nbsp;&#8226;&nbsp;&nbsp; 2 Creations */}
                                    </span>
                                    <span title="This is the total unique number of collections all this user's recipes are in.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.collection_count} {recipesStats.collection_count === 1 ? "Collection" : "Collections"}
                                    </span>
                                    <span title="This is the number of published variations of the recipes this user have published.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.variation_count} {recipesStats.variation_count === 1 ? "Variation" : "Variations"}
                                    </span>
                                </span>
                            }
                        </div>
                        <div className="collection_page_header_my_wip">
                            <span className="collection_sort_container" ref={dropdownRef}>
                                <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Sort" : "Sort Recipes"} margin="right" action={() => toggleSortDisplay(showSortDisplay)} icon="sort" />
                                {showSortDisplay ? <DropDownSelection options={[['A - Z', (value) => sortBy(value)], ['Collections', (value) => sortBy(value)], ['Date Created', (value) => sortBy(value)], ['Last Viewed', (value) => sortBy(value)], ['Ratings', (value) => sortBy(value)], ['Variations', (value) => sortBy(value)], ['Views', (value) => sortBy(value)]]} /> : null}
                            </span>

                        </div>
                        <div className="collection_container">
                            {recipes.map((recipe, index) => {
                                const recipe_id = recipe['recipe_id']
                                const name = recipe['recipe_name']
                                const image = recipe['image']
                                return (
                                    <div key={name + "_" + index} className="collection_recipe_tile_container">
                                        <RecipeTile recipe_id={recipe_id} name={name} imageSize="medium" width="100%" creation={false} image={image} />
                                    </div>
                                )
                            })}
                        </div>

                        {recipes.length === 0 ?
                            <div className="collection_no_recipes_container">
                                <h2 className="collection_no_recipes_title">There are no recipes in this collection.</h2>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    <div className="collection_handle_not_exist_container">
                        <h2 className="collection_no_recipes_title">This user handle does not exist.</h2>
                    </div>
                    :
                    null
            }
        </div>

    );
}

export default Collection;