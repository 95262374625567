import React, { useState } from "react"
import './footer.css'
import { useNavigate } from 'react-router-dom';
import LanguageOptions from '../components/language_options/language_options.js';
// import Cookies from 'js-cookie'
import { translate, translateTags } from "../translations/translations.js";

function Footer() {

    const navigate = useNavigate();
    // const language = Cookies.get('language') || 'english';
    
    const meal_filters = translateTags('meal_filters')
    const occasion_filters = translateTags('occasion_filters')
    const cuisine_filters = translateTags('cuisine_filters')
    const ingredient_filters = translateTags('ingredient_filters')
    const method_filters = translateTags('method_filters')
    const protein_filters = translateTags('protein_filters')
    const cooking_time_filters = translateTags('cooking_time_filters')
    // const occasion_filters = ['Appetisers', 'Celebration', 'Cocktail Party', 'Crowd Pleaser', 'Picnic', 'Potluck']
    // const meal_filters = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Dessert', 'Snack', 'Drinks']
    // const cuisine_filters = ['Chinese', 'Ethiopian', 'French',  'Greek', 'Indian', 'Italian', 'Japanese', 'Lebanese', 'Korean', 'Malaysian', 'Mexican', 'Middle Eastern', 'Spanish', 'Thai', 'Vietnamese']
    // const ingredient_filters = ['Avocado', 'Broccoli', 'Cauliflower', 'Carrot', 'Garlic', 'Mushroom', 'Onion', 'Pasta', 'Potatoes', 'Quinoa', 'Rice', 'Spinach', 'Sweet Potatoes', 'Tomatoes', 'Tofu']
    // const method_filters = ['Baking', 'Barbecue', 'Frying', 'Roasting', 'Sauteing', 'Slow cooking', 'Steaming', 'Stir frying']
    // const protein_filters = ['Bean', 'Beef', 'Cheese', 'Chicken', 'Egg', 'Fish', 'Lamb', 'Lentil', 'Nut', 'Pork', 'Quinoa', 'Seafood', 'Tofu', 'Turkey', 'Venison']
    // const cooking_time_filters = ['< 10 mins', '< 20 mins', '< 30 mins', '< 45 mins', '< 60 mins']

    const [showLanguageOptions, setShowLanguageOptions] = useState(false);
    

    function returnItems(array) {
        let type = null;
        switch(array) {
            case cuisine_filters:
                type = "cuisine"
                break;
            case meal_filters:
                type = "mealType"
                break;
            case ingredient_filters:
                type = "ingredient"
                break;
            case method_filters:
                type = "method"
                break;
            case protein_filters:
                type = "protein"
                break;
            case occasion_filters:
                type = "occasion"
                break;
            case cooking_time_filters:
                type = "cooking_time"
                break;
            default:
                break;
        }
        const items = array.map((item, index) => {
            return (
                <button key={item + index} className="footer_tag_item" onClick={() => goToFilteredRecipes(type, item[0])}>{item[1]}</button>
            )});
        return(items)
    }

    function goToFilteredRecipes(type, filter) {
        navigate("/recipes-search?" + type + "=" + filter);
    }

    // function toggleLanguageOptions() {
    //     setShowLanguageOptions(!showLanguageOptions);
    // }

    // const titleCase = (str) => {
    //     const words = str.toLowerCase().split(' ');
    //     const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    //     const titleCaseStr = titleCaseWords.join(' ');
    //     return titleCaseStr;
    // };

    // const emojiFlag = (language) => {

    //     if (language === 'english') {
    //         return '🇬🇧'
    //     } else if (language === 'vietnamese') {
    //         return '🇻🇳'
    //     } else if (language === 'chinese') {
    //         return '🇨🇳'
    //     } else if (language === 'korean') {
    //         return '🇰🇷'
    //     } else if (language === 'japanese') {
    //         return '🇯🇵'
    //     } else if (language === 'french') {
    //         return '🇫🇷'
    //     } else if (language === 'spanish') {
    //         return '🇪🇸'
    //     } else if (language === 'italian') {
    //         return '🇮🇹'
    //     } else if (language === 'german') {
    //         return '🇩🇪'
    //     }
    // }

    return (
        <div className="footer_container">
            {showLanguageOptions ? <LanguageOptions hide={() =>setShowLanguageOptions(false)}/> : null }
            <div className="footer_content_container">

                {/* <div className="mobile_footer_social_media_container">
                    <a title="instagram_link" className="mobile_footer_instagram_link_icon" href="https://www.instagram.com/thesiliconkitchen/" target="_blank" rel="noreferrer">
                        <div className="mobile_instagram_icon_container">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                                <path d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"/>
                                <path d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"/>
                            </svg>
                        </div>
                    </a>

                    <a title="facebook_link" className="mobile_footer_instagram_link_icon" href="https://www.facebook.com/thesiliconkitchen" target="_blank" rel="noreferrer">
                        <div className="mobile_instagram_icon_container">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z" fillRule="evenodd"/></svg>
                        </div>
                    </a>
                    <a title="twitter_link" className="mobile_footer_instagram_link_icon" href="https://twitter.com/tsiliconkitchen" target="_blank" rel="noreferrer">
                        <div className="instagram_icon_container">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                                <path d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"/>
                            </svg>
                        </div>
                    </a>
                </div> */}

                <div className="footer_recipe_tags">
                    <div className="footer_column">
                        <p className="footer_tag_title">{translate('recipes search', 'meal')}</p>
                        {returnItems(meal_filters)}
                        <p className="footer_tag_title">&nbsp;</p>
                        <p className="footer_tag_title">{translate('recipes search', 'occasion')}</p>
                        {returnItems(occasion_filters)}
                    </div>
                    <div className="footer_column">
                        <p className="footer_tag_title">{translate('recipes search', 'cuisine')}</p>
                        {returnItems(cuisine_filters)}
                    </div>
                    <div className="footer_column">
                        <p className="footer_tag_title">{translate('recipes search', 'protein')}</p>
                        {returnItems(protein_filters)}
                    </div>  
                    <div className="footer_column">
                        <p className="footer_tag_title">{translate('recipes search', 'ingredient')}</p>
                        {returnItems(ingredient_filters)}
                    </div>                    
                    <div className="footer_column">
                        <p className="footer_tag_title">{translate('recipes search', 'method')}</p>
                        {returnItems(method_filters)}
                        <p className="footer_tag_title">&nbsp;</p>
                        <p className="footer_tag_title">{translate('recipes search', 'cooking time')}</p>
                        {returnItems(cooking_time_filters)}
                    </div>
                </div>
                <div className="footer_feedback">
                    {translate('footer', 'share feedback')}
                </div>
                <div className="footer_information">
                    <ul className="footer_information_links">
                        {/* <li className="footer_information_links_items footer_information_links_items_hover footer_language_options"  onClick={() => toggleLanguageOptions()}>Language: {titleCase(language)} <span className="footer_language_flag_icon">{emojiFlag(language)}</span></li> */}
                        <li className="footer_information_links_items footer_information_links_items_hover footer_cookie_policy" onClick={() => navigate("/terms-of-use")}>Terms of Use</li>
                        <li className="footer_information_links_items footer_information_links_items_hover footer_cookie_policy" onClick={() => navigate("/cookie-policy")}>{translate('footer', 'cookies policy')}</li>
                        <li className="footer_information_links_items footer_information_links_items_hover footer_privacy_policy"  onClick={() => navigate("/privacy-policy")}>{translate('footer', 'privacy policy')}</li>
                        {/* <li className="footer_information_links_items">Content sourced from artificial intelligence, {window.innerWidth < 768 ? <br />: null} website still designed by humans.</li> */}
                        <li className="footer_information_links_items mobile_footer_information_links_items">© 2023 Garlic Basil. All rights reserved.</li>
                    </ul>
                </div>
            </div>
            
        </div>
    )
}

export default Footer;