import React from "react"
import { translateActiveFilter } from "../../../translations/translations.js"
import './active_filter_item.css'

function ActiveFilterItem(props) {

    function removeFilter() {
        props.removeFilter(props.filterType, props.activeFilter)
    }

    return (
        <div className="active_filter_item" onClick={removeFilter}>
            {translateActiveFilter(props.activeFilter)} <span className="material-symbols-outlined close">close</span>
        </div>
    )
}

export default ActiveFilterItem;