import './mobile_search.css';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../translations/translations.js';

function MobileSearch(props) {

    const [searchOpen, setSearchOpen] = useState(false);
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    function toggleSearch() {
        setSearchOpen(!searchOpen);
        const searchInput = document.getElementById("mobile_search_input");
        searchInput.focus();
    }

    function searchInput(event) {
        event.preventDefault();
        setSearchOpen(false);
        const searchInput = document.getElementById("mobile_search_input");
        searchInput.blur();
        navigate("/recipes-search?search=" + searchInputRef.current.value);
        searchInputRef.current.value = '';
    }

    return (
        <div className="mobile_search_container" >
            <div className="mobile_search_text_container" style={searchOpen ? {width: "100vw"} : {width: "0%"}}>
                <span className="mobile_search_active_icon_container" style={searchOpen ? {opacity: "1"} : {opacity: "0"}}>
                    <span className="material-symbols-outlined mobile_search_text_icon">
                        search
                    </span>
                </span>
                <span className="mobile_search_text"  style={searchOpen ? {opacity: "1"} : {opacity: "0"}}>
                    <form className="mobile_search_form" onSubmit={(e) => searchInput(e)}>
                        <input id="mobile_search_input" className="mobile_search_text_input" type="text" placeholder={translate('recipes search', 'search recipes')} ref={searchInputRef}/>
                    </form>
                </span>
                <span className="mobile_search_text_close_container" style={searchOpen ? {opacity: "1"} : {opacity: "0"}} onClick={() => setSearchOpen(false)}>
                    <span className="material-symbols-outlined mobile_search_text_close_icon">
                        close
                    </span>
                </span>
            </div>
            <div className="mobile_search_icon_container" style={searchOpen ? {display:'none'}:{display:'inline-block'}}onClick={toggleSearch}>
                <span className={props.createMode ? "material-symbols-outlined mobile_search_icon_create" : "material-symbols-outlined mobile_search_icon"}>search</span>
            </div>
        </div>
    )
}

export default MobileSearch;