import './recipe_ingredients.css'
import React, { useState, useEffect, useRef } from 'react'
import Cookies from "js-cookie";
import IngredientTile from '../../../components/ingredient_tile/ingredient_tile.js'

function RecipeIngredients(props) {

    const session_id = Cookies.get('session_id');
    const ingredients = props['text'];
    const [servings, setServings] = useState(4);
    const savedServings = Cookies.get('servings');
    const isMobile = window.innerWidth < 768;
    const ingredientNameRefs = useRef([]);
    const ingredientQuantityRefs = useRef([]);
    const ingredientUOMRefs = useRef([]);

    useEffect(() => {

        if (savedServings !== undefined) {
            setServings(parseInt(savedServings, 10));
        } else {
            setServings(4);
        }
    }, [savedServings])

    if (!ingredients || ingredients.length === 0) {
        return <div></div>;
    }

    const addToShoppingList = () => {

        if (typeof session_id !== "undefined") {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id, servings: servings }),
            };

            fetch(process.env.REACT_APP_API_URL + "/api/addToShoppingList", options)
                .then((response) => response.json())
                .then(json => {
                    if (json['status'] === false) {
                        if (json['error'] === 'Recipe already added') {
                            props.showAlertMessage('Recipe already added to basket.')
                        } else {
                            props.showAlertMessage('to add ingredients to your basket.', 'sign_up_log_in')
                        }
                    } else {
                        props.showAlertMessage('Ingredients have been added to your basket.', 'basket')
                    }
                })
        } else {
            props.showAlertMessage('to add ingredients to your basket.', 'sign_up_log_in')
        }
    }

    const formatQuantity = (quantity) => {

        let quantity_string = "";
        let adjust_servings = servings / 4;

        quantity = quantity * adjust_servings

        quantity === 0.125 ?
            quantity_string = "⅛" :
            quantity === 0.25 ?
                quantity_string = "¼" :
                quantity === 0.5 ?
                    quantity_string = "½" :
                    quantity === 0.75 ?
                        quantity_string = "¾" :
                        quantity === 1 ?
                            quantity_string = "1" :
                            quantity === 1.25 ?
                                quantity_string = "1¼" :
                                quantity === 1.5 ?
                                    quantity_string = "1½" :
                                    quantity === 1.75 ?
                                        quantity_string = "1¾" :
                                        quantity === 2 ?
                                            quantity_string = "2" :
                                            quantity === 2.25 ?
                                                quantity_string = "2¼" :
                                                quantity === 2.5 ?
                                                    quantity_string = "2½" :
                                                    quantity === 2.75 ?
                                                        quantity_string = "2¾" :
                                                        quantity === 3 ?
                                                            quantity_string = "3" :
                                                            quantity === 3.25 ?
                                                                quantity_string = "3¼" :
                                                                quantity === 3.5 ?
                                                                    quantity_string = "3½" :
                                                                    quantity === 3.75 ?
                                                                        quantity_string = "3¾" :
                                                                        quantity === 4 ?
                                                                            quantity_string = "4" :
                                                                            quantity === 4.25 ?
                                                                                quantity_string = "4¼" :
                                                                                quantity === 4.5 ?
                                                                                    quantity_string = "4½" :
                                                                                    quantity === 4.75 ?
                                                                                        quantity_string = "4¾" :
                                                                                        quantity === 5 ?
                                                                                            quantity_string = "5" :
                                                                                            quantity === 5.25 ?
                                                                                                quantity_string = "5¼" :
                                                                                                quantity === 5.5 ?
                                                                                                    quantity_string = "5½" :
                                                                                                    quantity === 5.75 ?
                                                                                                        quantity_string = "5¾" :
                                                                                                        quantity === 6 ?
                                                                                                            quantity_string = "6" :
                                                                                                            quantity === 6.25 ?
                                                                                                                quantity_string = "6¼" :
                                                                                                                quantity === 6.5 ?
                                                                                                                    quantity_string = "6½" :
                                                                                                                    quantity === 6.75 ?
                                                                                                                        quantity_string = "6¾" :
                                                                                                                        quantity === 7 ?
                                                                                                                            quantity_string = "7" :
                                                                                                                            quantity === 7.25 ?
                                                                                                                                quantity_string = "7¼" :
                                                                                                                                quantity === 7.5 ?
                                                                                                                                    quantity_string = "7½" :
                                                                                                                                    quantity === 7.75 ?
                                                                                                                                        quantity_string = "7¾" :
                                                                                                                                        quantity === 8 ?
                                                                                                                                            quantity_string = "8" :
                                                                                                                                            quantity === 8.25 ?
                                                                                                                                                quantity_string = "8¼" :
                                                                                                                                                quantity === 8.5 ?
                                                                                                                                                    quantity_string = "8½" :
                                                                                                                                                    quantity === 8.75 ?
                                                                                                                                                        quantity_string = "8¾" :
                                                                                                                                                        quantity === 9 ?
                                                                                                                                                            quantity_string = "9" :
                                                                                                                                                            quantity === 9.25 ?
                                                                                                                                                                quantity_string = "9¼" :
                                                                                                                                                                quantity === 9.5 ?
                                                                                                                                                                    quantity_string = "9½" :
                                                                                                                                                                    quantity === 9.75 ?
                                                                                                                                                                        quantity_string = "9¾" :
                                                                                                                                                                        quantity === 10 ?
                                                                                                                                                                            quantity_string = "10" :
                                                                                                                                                                            quantity === 10.25 ?
                                                                                                                                                                                quantity_string = "10¼" :
                                                                                                                                                                                quantity === 10.5 ?
                                                                                                                                                                                    quantity_string = "10½" :
                                                                                                                                                                                    quantity === 10.75 ?
                                                                                                                                                                                        quantity_string = "10¾" :
                                                                                                                                                                                        Math.abs(quantity - Math.floor(quantity)) > 0.001 ?
                                                                                                                                                                                            quantity_string = quantity.toFixed(2) :
                                                                                                                                                                                            quantity_string = quantity

        return quantity_string
    }

    const formatUOM = (uom, quantity) => {


        let uom_string;
        switch (uom) {
            case "gram":
                uom_string = "grams";
                break;
            case "kilogram":
                uom_string = "kg";
                break;
            case "millilitre":
                uom_string = "ml";
                break;
            case "litre":
                uom_string = "L";
                break;
                
            case "teaspoon":
                if (quantity > 1) {
                    uom_string = "tsps";
                } else {
                    uom_string = "tsp";
                }
                break;
            case "tablespoon":
                if (quantity > 1) {
                    uom_string = "tbs";
                } else {
                    uom_string = "tb";
                }
                break;
            case "cup":
                if (quantity > 1) {
                    uom_string = "cups";
                } else {
                    uom_string = "cup";
                }
                break;
            case "clove":
                if (quantity > 1) {
                    uom_string = "cloves";
                } else {
                    uom_string = "clove";
                }
                break;
            default:
                uom_string = uom;
        }

        // let uom_string = "";

        // uom === "millilitre" ?
        //     uom_string = "ml" :
        //     uom === "teaspoon" ?
        //         uom_string = "tsp" :
        //         uom === "teaspoons" ?
        //             uom_string = "tsps" :
        //             uom === "tablespoon" ?
        //                 uom_string = "tb" :
        //                 uom === "tablespoons" ?
        //                     uom_string = "tbs" :
        //                     uom === "gram" ?
        //                         uom_string = "grams" :
        //                         uom === "kilogram" ?
        //                             uom_string = "kg" :
        //                             uom === "litre" ?
        //                                 uom_string = "L" :
        //                                 uom_string = uom

        return uom_string
    }

    function decreaseServing() {
        if (servings > 1) {
            Cookies.set('servings', servings - 1);
            setServings(servings - 1);
        }
    }

    function increaseServing() {
        if (servings < 20) {
            Cookies.set('servings', servings + 1);
            setServings(servings + 1);
        }
    }

    function updateIngredients(event, index, old_ingredient_name, old_ingredient_quantity, old_ingredient_uom) {
        event.preventDefault()
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                recipe_id: props.recipe_id,
                old_ingredient_name: old_ingredient_name,
                old_ingredient_quantity: old_ingredient_quantity,
                old_ingredient_uom: old_ingredient_uom,
                new_ingredient_name: ingredientNameRefs.current[index].value,
                new_ingredient_quantity: ingredientQuantityRefs.current[index].value,
                new_ingredient_uom: ingredientUOMRefs.current[index].value
            })
        };

        fetch(process.env.REACT_APP_API_URL + "/api/updateRecipeIngredient", options)
            .then(() => window.location.reload())
    }

    function returnDesktopView() {

        return (
            <div className="recipe_ingredients_container">
                <div className="recipe_ingredients_info_container">
                    <p className="recipe_ingredients_title">Ingredients</p>
                    <div className="recipe_ingredients_action_button_container">
                        <div className="recipe_ingredients_serving_info_container">
                            <button className="serving_adjust_button_minus" onClick={decreaseServing} ><span className="material-symbols-outlined serving_adjust_button_icon">remove</span></button>
                            Servings: {servings}
                            <button className="serving_adjust_button_add" onClick={increaseServing}><span className="material-symbols-outlined serving_adjust_button_icon">add</span></button>
                        </div>
                        <div className="add_shopping_list_container">
                            <button className="add_shopping_list_button" onClick={addToShoppingList}>
                                <span className="material-symbols-outlined add_to_basket_add_icon">add</span> Add to Basket
                            </button>
                        </div>
                    </div>
                </div>

                <div className="recipe_ingredients_all_ingredients_container">
                    {ingredients.map((item, index) => {
                        let old_name = item[1]
                        let old_quantity = item[2]
                        let old_uom = item[3]
                        let name = item[1]
                        let quantity = formatQuantity(item[2])
                        let uom = formatUOM(item[3], item[2])
                        return (
                            <div key={"ingredient_admim_" + item + index} className="recipe_ingredients_container_admin">
                                <IngredientTile key={item + index} width={152} height={132} name={name} quantity={quantity} uom={uom} />
                                <div className="recipe_ingredients_admin_update_container">
                                    <form onSubmit={(event) => updateIngredients(event, index, old_name, old_quantity, old_uom)}>
                                        <label>
                                            Name:
                                            <input ref={ref => ingredientNameRefs.current[index] = ref} id={item + index} />
                                            {/* <input ref={ingredientNameRef} id={item + index}/>  */}
                                        </label>
                                        <label>
                                            Quantity:
                                            <input ref={ref => ingredientQuantityRefs.current[index] = ref} />
                                            {/* <input ref={ingredientQuantityRef} />  */}
                                        </label>
                                        <label>
                                            Uom:
                                            <input ref={ref => ingredientUOMRefs.current[index] = ref} />
                                            {/* <input ref={ingredientUOMRef} />  */}
                                        </label>
                                        <input type="submit" value="Submit" />
                                    </form>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    function returnMobileView() {

        return (
            <div className="recipe_ingredients_container">
                <div className="recipe_ingredients_info_container">
                    <p className="recipe_ingredients_title">Ingredients</p>
                </div>

                <div className="recipe_ingredients_all_ingredients_container">
                    {ingredients.map((item, index) => {
                        let name = item[1]
                        let quantity = formatQuantity(item[2])
                        let uom = formatUOM(item[3], item[2])
                        return (
                            <div key={item + index}>
                                <IngredientTile width={"calc((100vw - 2em - 20px) / 3)"} height={"calc(((100vw - 2em - 20px) / 3) - 20px)"} name={name} quantity={quantity} uom={uom} />
                                <form>
                                    <label>
                                        Name:
                                        <input />
                                    </label>
                                </form>
                            </div>
                        )
                    })}

                </div>
                <div className="recipe_ingredients_serving_info_container">
                    <button className="serving_adjust_button_minus" onClick={decreaseServing} ><span className="material-symbols-outlined serving_adjust_button_icon">remove</span></button>
                    Servings: {servings}
                    <button className="serving_adjust_button_add" onClick={increaseServing}><span className="material-symbols-outlined serving_adjust_button_icon">add</span></button>
                </div>
                <div className="add_shopping_list_container">
                    <button className="add_shopping_list_button" onClick={addToShoppingList}>
                        <span className="material-symbols-outlined add_to_basket_add_icon">add</span> Add to Basket
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div>
            {isMobile ? returnMobileView() : returnDesktopView()}
        </div>
    );

}

export default RecipeIngredients;