import './recipe_image.css'
import React, { useState } from 'react'
// import FavouriteButton from '../../../components/favourite_button/favourite_button.js'
// import { translate } from '../../../translations/translations.js'

function Image(props) {

    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
      setImageError(true);
    };

    return (
        <>
            {imageError ? null :
                <div className="recipe_image_container">
                    <div className="recipe_image_child_container">
                        <picture >
                            <source className="recipe_image"  onError={handleImageError} srcSet={"https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/800x800_" +  props.recipe_id + ".webp"} type="image/webp" />
                            <img className="recipe_image" onError={handleImageError} src={"https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/800x800_" + props.recipe_id + ".jpg"} alt="recipe_images" />
                        </picture>
                        {/* <p className="recipe_image_disclaimer">{translate('recipe', "recipe image disclaimer")}</p> */}
                    </div>
                </div>
            }
        </>
    )

}

export default Image;