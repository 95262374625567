import React, { useState, useEffect } from "react"
import RecipeTile from '../../components/recipe_tile/recipe_tile.js'
import './recipes_results.css'

function RecipeResults(props) {

    const [columns, setColumns] = useState(3)
    const [tileHeight, setTileHeight] = useState('12vw')
    const [minTileHeight, setMinTileHeight] = useState('230px')
    const [favourite, setFavourite] = useState([]);
    const [imageSize, setImageSize] = useState('')

    useEffect(() => {
        setFavourite(props.favourite)
    }, [props.favourite]);

    useEffect(() => {

        if (window.innerWidth > 768) {
            if (props.columns === 2) {
                setTileHeight('18vw')
                setMinTileHeight('280px')
                setImageSize('medium')
                setColumns(2)
            }
            if (props.columns === 3) {
                setTileHeight('12vw')
                setMinTileHeight('190px')
                setImageSize('small')
                setColumns(3)
            }
            if (props.columns === 4) {
                setTileHeight('9vw')
                setMinTileHeight('140px')
                setImageSize('small')
                setColumns(4)
            }
            if (props.columns === 5) {
                setTileHeight('7vw')
                setMinTileHeight('120px')
                setImageSize('small')
                setColumns(5)
            }
        } else {
            setImageSize('small')
            setColumns(1)
        }
    }, [props.columns]); 

    return (
        <div className="results_container" style={{gridTemplateColumns: 'repeat(' + columns + ', 1fr)'}}>
            {props.recipes.map((recipe, index) => (    
                <div key={recipe + index} className="grid_item" style={{height:tileHeight, minHeight:minTileHeight}}>
                    <RecipeTile recipe_id={recipe["recipe_id"]} name={recipe["recipe_name"]} image={recipe["recipe_image"]} favourite={favourite.includes(recipe["recipe_id"]) ? true: false} width={'100%'} imageSize={imageSize} showAlertMessage={props.showAlertMessage}/>
                </div>
            ))}
        </div>
    )
}

export default RecipeResults