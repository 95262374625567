import './loading_bar.css';
import React from 'react';

function LoadingBar(props) {

    return (
        <div className="loading_bar_container">
            <div className="loading_bar">
                <div className="loading_bar_progress"></div>
                {props.completed && <div className="loading_bar_completed"></div>}
            </div>
        </div>
    )
}

export default LoadingBar;