import React, { useRef } from "react"
import './search.css'
import { useNavigate } from 'react-router-dom';
import { translate } from '../../translations/translations.js';

function SearchUI(props) {

    const navigate = useNavigate()
    const search_input = useRef();

    function handleSearch(event) {
        event.preventDefault();
        const search_input_value = search_input.current.value
        navigate('/recipes-search?search=' + search_input_value)
    }

    function handleSearchRefresh(event) {
        event.preventDefault();
        const search_input_value = search_input.current.value
        props.updateRecipes('search', search_input_value)
    }

    return (
        <div className="search_container">
            {/* <div className="material-symbols-outlined search_symbol">search</div> */}
                <form className="search_form" onSubmit={props.recipesSearchUrl ? handleSearchRefresh: handleSearch }>
                    <input className="search_input" type="text" placeholder={translate('recipes search', "search recipes")} ref={search_input} defaultValue={props.currentValue} onBlur={handleSearchRefresh}/>
                    <button className="search_button" type="submit">
                        <div className="material-symbols-outlined search_symbol">search</div>
                    </button>
                </form>
            
        </div>
    )
}

export default SearchUI;