import './studio.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import RecipeTile from '../components/recipe_tile/recipe_tile.js';
import ButtonPrimary from '../components/button/button_primary.js';
import ButtonSecondary from '../components/button/button_secondary.js'; 

function Studio(props) {

    const [creationsView, setCreationsView] = useState(true);
    const [creations, setCreations] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const session_id = Cookies.get('session_id');
    const [userFirstName, setUserFirstName] = useState(''); 
    const [publishedRecipes, setPublishedRecipes] = useState([]);
    const [archivedRecipes, setArchivedRecipes] = useState([]);
    const [recipes, setRecipes] = useState([]); 

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ session_id: session_id }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/getUserNameAndHandle', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setUserFirstName(data.first_name)
                    setTitle(<>{data.first_name}'s <br />Recipe Studio</>);
                }
            })

        fetch(process.env.REACT_APP_API_URL + '/api/createCreationRecipes', options)
            .then(response => response.json())
            .then(data => {
                setCreations(data)
                setRecipes(data)
                setLoading(false);
            });

        fetch(process.env.REACT_APP_API_URL + '/api/getMyCollection', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) { 
                    const recipes = data.recipes
                    // const sortedRecipes = [...recipes]
                    //     .filter(recipe => recipe.collection === "1")
                    //     .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
                    const publishedRecipes = [...recipes]
                        .filter(recipe => recipe.archived === "0")
                        .filter(recipe => recipe.user_creation === "1")
                    setPublishedRecipes(publishedRecipes)

                    const archivedRecipes = [...recipes]
                        .filter(recipe => recipe.archived === "1")

                    setArchivedRecipes(archivedRecipes) 
                }
            });

    }, [session_id]);

    function createNewRecipe() {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ session_id: session_id }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createNewRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    window.location.href = '/create/' + data.create_recipe_id + '/new-recipe';
                } else {
                    props.showAlertMessage(data.message, undefined, undefined, 10000)
                }
            });
    }

    function toggleCreationsAndPublishedRecipes() {
        if (creationsView) {
            setCreationsView(false)
            setRecipes(publishedRecipes)
            setTitle(<>{userFirstName}'s <br /> Published Recipes</>)
        } else {
            setCreationsView(true)
            setRecipes(creations)
            setTitle(<>{userFirstName}'s <br /> Recipe Studio</>)
        }
    }

    function toggleCreationsAndArchivedRecipes() {
        if (creationsView) {
            setCreationsView(false)
            setRecipes(archivedRecipes)
            setTitle(<>{userFirstName}'s <br /> Archived Recipes</>)
        } else {
            setCreationsView(true)
            setRecipes(creations)
            setTitle(<>{userFirstName}'s <br /> Recipe Studio</>)
        }
    }

    return (
        <div className="studio_parent_container">
            {/* <div className="studio_background_container">
                <img className="studio_background_image" src={StudioBackground} alt="Studio Background"/>
            </div> */}
            <div className="studio_background"></div>
            <div className="studio_title_container">
                <div className="studio_page_header_container">
                    <span className="studio_page_header_title">
                        {recipes.length === 0 && creationsView && window.innerWidth < 768 ? null : <h1 className="studio_title">{title}</h1>}
                        {/* <h2 className="studio_subtitle"></h2> */}
                        {loading ?
                            null
                            :
                            recipes.length === 0 && creationsView ?
                                <div className="studio_create_no_recipes_container">
                                    <h2 className="my_collection_no_recipes_title">You have no creations in your studio.</h2>
                                    <p style={{ lineHeight: '150%' }}>You can either create a new recipe from scratch or create a variation of an existing recipe by clicking the <span style={{ fontWeight: '500' }}>Create Variation</span> button in a recipe.</p>
                                    <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Create Recipe" margin={window.innerWidth < 768 ? 'bottom' : 'right'} action={() => createNewRecipe()} icon="new_window" />
                                    {/* <ButtonPrimary width={window.innerWidth < 768 ? '100%':null }  name="Explore Recipes" margin={window.innerWidth < 768 ? 'bottom':'left'}  action={() => navigate('/')} icon="travel_explore"/> */}
                                    <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name="See Published" margin={window.innerWidth < 768 ? 'bottom' : 'right'} action={() => toggleCreationsAndPublishedRecipes()} icon="folder_open" />
                                    <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name="See Archived" margin={window.innerWidth < 768 ? 'bottom' : null} action={() => toggleCreationsAndArchivedRecipes()} icon="folder_open" />
                                </div>
                                :
                                creationsView ?
                                    <div className="studio_create_recipe_container">
                                        <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Create":"Create Recipe"} margin={window.innerWidth < 768 ? null : "left"} action={() => createNewRecipe()} icon="new_window" />
                                        <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Published": "See Published"} margin={window.innerWidth < 768 ? null : "left"} action={() => toggleCreationsAndPublishedRecipes()} icon="folder_open" />
                                        <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Arch.": "See Archived"} margin={window.innerWidth < 768 ? null: "left"} action={() => toggleCreationsAndArchivedRecipes()} icon="folder_open" />
                                    </div>
                                    :
                                    <div className="studio_create_recipe_container">
                                        <ButtonSecondary name="My Studio" margin="left" action={() => toggleCreationsAndPublishedRecipes()} icon="undo" />
                                    </div>
                        }

                    </span>
                </div>


                <div className="studio_container">
                    {recipes.map((recipe, index) => {
                        const recipe_id = recipe['recipe_id']
                        const name = recipe['recipe_name']
                        const create_user_upload_image = recipe['create_user_upload_image']
                        const recipe_image_id = recipe['recipe_image_id']
                        const createType = recipe['create_type']
                        const image = recipe['image']
                        return (

                            <div key={name + "_" + index} className="studio_recipe_tile_container">
                                {creationsView ?
                                    <RecipeTile key={name + "_" + index} recipe_id={recipe_id} name={name} imageSize="medium" width="100%" creation={true} create_user_upload_image={create_user_upload_image} image_id={recipe_image_id} createType={createType} />
                                    :
                                    <RecipeTile recipe_id={recipe_id} name={name} imageSize="medium" width="100%" creation={false} image={image} />
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Studio;