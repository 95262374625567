import Cookies from "js-cookie";

export function translate(section, text) {

    // NAVIGATION

    const language = Cookies.get("language") || "english";

    if (section === "navigation") {

        if (text === "LOG IN") {
            if (language === "english") { return "LOG IN"; }
            else if (language === "vietnamese") { return "ĐĂNG NHẬP"; }
            else if (language === "chinese") { return "登录"; }
            else if (language === "korean") { return "로그인"; }
            else if (language === "japanese") { return "ログイン"; }
            else if (language === "french") { return "SE CONNECTER"; }
            else if (language === "spanish") { return "INICIAR SESIÓN"; }
            else if (language === "german") { return "EINLOGGEN"; }
            else if (language === "italian") { return "ACCEDI"; }

        }

        if (text === "LOG OUT") {
            if (language === "english") { return "LOG OUT"; }
            else if (language === "vietnamese") { return "ĐĂNG XUẤT"; }
            else if (language === "chinese") { return "登出"; }
            else if (language === "korean") { return "로그 아웃"; }
            else if (language === "japanese") { return "ログアウト"; }
            else if (language === "french") { return "SE DÉCONNECTER"; }
            else if (language === "spanish") { return "CERRAR SESIÓN"; }
            else if (language === "german") { return "AUSLOGGEN"; }
            else if (language === "italian") { return "ESCI"; }
        }

        if (text === "SIGN UP") {
            if (language === "english") { return "SIGN UP"; }
            else if (language === "vietnamese") { return "ĐĂNG KÝ"; }
            else if (language === "chinese") { return "注册"; }
            else if (language === "korean") { return "가입하기"; }
            else if (language === "japanese") { return "サインアップ"; }
            else if (language === "french") { return "S'INSCRIRE"; }
            else if (language === "spanish") { return "REGÍSTRATE"; }
            else if (language === "german") { return "ANMELDEN"; }
            else if (language === "italian") { return "REGISTRATI"; }
        }

        if (text === "Welcome,") {
            if (language === "english") { return "Welcome,"; }
            else if (language === "vietnamese") { return "Chào mừng,"; }
            else if (language === "chinese") { return "欢迎,"; }
            else if (language === "korean") { return "환영,"; }
            else if (language === "japanese") { return "ようこそ,"; }
            else if (language === "french") { return "Bienvenue,"; }
            else if (language === "spanish") { return "Bienvenido,"; }
            else if (language === "german") { return "Willkommen,"; }
            else if (language === "italian") { return "Benvenuto,"; }
        }

        if (text === "EXPLORE") {
            if (language === "english") { return "EXPLORE"; }
            else if (language === "vietnamese") { return "KHÁM PHÁ"; }
            else if (language === "chinese") { return "探索"; }
            else if (language === "korean") { return "탐색"; }
            else if (language === "japanese") { return "探索"; }
            else if (language === "french") { return "EXPLORER"; }
            else if (language === "spanish") { return "EXPLORAR"; }
            else if (language === "german") { return "ERKUNDEN"; }
            else if (language === "italian") { return "ESPLORA"; }
        }

        if (text === "RECIPES") {
            if (language === "english") { return "RECIPES"; }
            else if (language === "vietnamese") { return "CÔNG THỨC"; }
            else if (language === "chinese") { return "食谱"; }
            else if (language === "korean") { return "레시피"; }
            else if (language === "japanese") { return "レシピ"; }
            else if (language === "french") { return "RECETTES"; }
            else if (language === "spanish") { return "RECETAS"; }
            else if (language === "german") { return "REZEPTE"; }
            else if (language === "italian") { return "RICETTE"; }
        }

        if (text === "BASKET") {
            if (language === "english") { return "BASKET"; }
            else if (language === "vietnamese") { return "GIỎ HÀNG"; }
            else if (language === "chinese") { return "篮子"; }
            else if (language === "korean") { return "바구니"; }
            else if (language === "japanese") { return "バスケット"; }
            else if (language === "french") { return "PANIER"; }
            else if (language === "spanish") { return "CARRITO"; }
            else if (language === "german") { return "WÄSCHEKORB"; }
            else if (language === "italian") { return "CESTINO"; }
        }

        if (text === "ABOUT US") {
            if (language === "english") { return "ABOUT US"; }
            else if (language === "vietnamese") { return "VỀ CHÚNG TÔI"; }
            else if (language === "chinese") { return "关于我们"; }
            else if (language === "korean") { return "회사 소개"; }
            else if (language === "japanese") { return "会社概要"; }
            else if (language === "french") { return "À PROPOS DE NOUS"; }
            else if (language === "spanish") { return "SOBRE NOSOTROS"; }
            else if (language === "german") { return "ÜBER UNS"; }
            else if (language === "italian") { return "CHI SIAMO"; }
        }

        if (text === "Explore") {
            if (language === "english") { return "Explore"; }
            else if (language === "vietnamese") { return "Khám Phá"; }
            else if (language === "chinese") { return "探索"; }
            else if (language === "korean") { return "탐색"; }
            else if (language === "japanese") { return "探索"; }
            else if (language === "french") { return "Explorer"; }
            else if (language === "spanish") { return "Explorar"; }
            else if (language === "german") { return "Erkunden"; }
            else if (language === "italian") { return "Esplora"; }
        }

        if (text === "Recipes") {
            if (language === "english") { return "Recipes"; }
            else if (language === "vietnamese") { return "Công Thức"; }
            else if (language === "chinese") { return "食谱"; }
            else if (language === "korean") { return "레시피"; }
            else if (language === "japanese") { return "レシピ"; }
            else if (language === "french") { return "Recettes"; }
            else if (language === "spanish") { return "Recetas"; }
            else if (language === "german") { return "Rezepte"; }
            else if (language === "italian") { return "Ricette"; }
        }

        if (text === "Basket") {
            if (language === "english") { return "Basket"; }
            else if (language === "vietnamese") { return "Giỏ Hàng"; }
            else if (language === "chinese") { return "篮子"; }
            else if (language === "korean") { return "바구니"; }
            else if (language === "japanese") { return "バスケット"; }
            else if (language === "french") { return "Panier"; }
            else if (language === "spanish") { return "Carrito"; }
            else if (language === "german") { return "Wäschekorb"; }
            else if (language === "italian") { return "Cestino"; }
        }

        if (text === "About Us") {
            if (language === "english") { return "About Us"; }
            else if (language === "vietnamese") { return "Về Chúng Tôi"; }
            else if (language === "chinese") { return "关于我们"; }
            else if (language === "korean") { return "회사 소개"; }
            else if (language === "japanese") { return "会社概要"; }
            else if (language === "french") { return "À Propos De Nous"; }
            else if (language === "spanish") { return "Sobre Nosotros"; }
            else if (language === "german") { return "Über Uns"; }
            else if (language === "italian") { return "Chi Siamo"; }
        }

        if (text === "Log In") {
            if (language === "english") { return "Log In"; }
            else if (language === "vietnamese") { return "Đăng Nhập"; }
            else if (language === "chinese") { return "登录"; }
            else if (language === "korean") { return "로그인"; }
            else if (language === "japanese") { return "ログイン"; }
            else if (language === "french") { return "Se Connecter"; }
            else if (language === "spanish") { return "Iniciar Sesión"; }
            else if (language === "german") { return "Einloggen"; }
            else if (language === "italian") { return "Accedi"; }
        }

        if (text === "Log Out") {
            if (language === "english") { return "Log Out"; }
            else if (language === "vietnamese") { return "Đăng Xuất"; }
            else if (language === "chinese") { return "登出"; }
            else if (language === "korean") { return "로그 아웃"; }
            else if (language === "japanese") { return "ログアウト"; }
            else if (language === "french") { return "Se Déconnecter"; }
            else if (language === "spanish") { return "Cerrar Sesión"; }
            else if (language === "german") { return "Ausloggen"; }
            else if (language === "italian") { return "Disconnettersi"; }
        }

        if (text === "Sign Up") {
            if (language === "english") { return "Sign Up"; }
            else if (language === "vietnamese") { return "Đăng Ký"; }
            else if (language === "chinese") { return "注册"; }
            else if (language === "korean") { return "가입하기"; }
            else if (language === "japanese") { return "サインアップ"; }
            else if (language === "french") { return "S'inscrire"; }
            else if (language === "spanish") { return "Regístrate"; }
            else if (language === "german") { return "Anmelden"; }
            else if (language === "italian") { return "Iscriviti"; }
        }

    }

    // EXPLORE

    if (section === "explore") {

        if (text === "welcome") {
            if (language === "english") { return "WELCOME"; }
            else if (language === "vietnamese") { return "CHÀO MỪNG"; }
            else if (language === "chinese") { return "欢迎"; }
            else if (language === "korean") { return "환영합니다"; }
            else if (language === "japanese") { return "ようこそ"; }
            else if (language === "french") { return "BIENVENUE"; }
            else if (language === "spanish") { return "BIENVENIDO"; }
            else if (language === "german") { return "HERZLICH WILLKOMMEN"; }
            else if (language === "italian") { return "BENVENUTO"; }
        }
        if (text === "to") {
            if (language === "english") { return "TO"; }
            else if (language === "vietnamese") { return "ĐẾN"; }
            else if (language === "chinese") { return "到"; }
            else if (language === "korean") { return "에"; }
            else if (language === "japanese") { return "へ"; }
            else if (language === "french") { return "À"; }
            else if (language === "spanish") { return "A"; }
            else if (language === "german") { return "ZU"; }
            else if (language === "italian") { return "A"; }
        }
        if (text === "trending") {
            if (language === "english") { return "Trending"; }
            else if (language === "vietnamese") { return "Xu hướng" }
            else if (language === "chinese") { return "趋势"; }
            else if (language === "korean") { return "인기"; }
            else if (language === "japanese") { return "トレンド"; }
            else if (language === "french") { return "Tendance"; }
            else if (language === "spanish") { return "Tendencia"; }
            else if (language === "german") { return "Trend"; }
            else if (language === "italian") { return "Tendenza"; }
        }
        if (text === "dinner") {
            if (language === "english") { return "Dinner"; }
            else if (language === "vietnamese") { return "Bữa tối"; }
            else if (language === "chinese") { return "晚餐"; }
            else if (language === "korean") { return "저녁 식사"; }
            else if (language === "japanese") { return "夕食"; }
            else if (language === "french") { return "Dîner"; }
            else if (language === "spanish") { return "Cena"; }
            else if (language === "german") { return "Abendessen"; }
            else if (language === "italian") { return "Cena"; }
        }
        if (text === "salad") {
            if (language === "english") { return "Salad"; }
            else if (language === "vietnamese") { return "Salad"; }
            else if (language === "chinese") { return "沙拉"; }
            else if (language === "korean") { return "샐러드"; }
            else if (language === "japanese") { return "サラダ"; }
            else if (language === "french") { return "Salade"; }
            else if (language === "spanish") { return "Ensalada"; }
            else if (language === "german") { return "Salat"; }
            else if (language === "italian") { return "Insalata"; }
        }
        if (text === "classic") {
            if (language === "english") { return "Classic"; }
            else if (language === "vietnamese") { return "Cổ điển"; }
            else if (language === "chinese") { return "经典"; }
            else if (language === "korean") { return "고전"; }
            else if (language === "japanese") { return "クラシック"; }
            else if (language === "french") { return "Classique"; }
            else if (language === "spanish") { return "Clásico"; }
            else if (language === "german") { return "Klassisch"; }
            else if (language === "italian") { return "Classico"; }
        }
        if (text === "lunch") {
            if (language === "english") { return "Lunch"; }
            else if (language === "vietnamese") { return "Bữa trưa"; }
            else if (language === "chinese") { return "午餐"; }
            else if (language === "korean") { return "점심 식사"; }
            else if (language === "japanese") { return "ランチ"; }
            else if (language === "french") { return "Déjeuner"; }
            else if (language === "spanish") { return "Almuerzo"; }
            else if (language === "german") { return "Mittagessen"; }
            else if (language === "italian") { return "Pranzo"; }
        }
        if (text === "crowd pleaser") {
            if (language === "english") { return "Crowd Pleaser"; }
            else if (language === "vietnamese") { return "Đám đông vui vẻ"; }
            else if (language === "chinese") { return "人群高兴者"; }
            else if (language === "korean") { return "관객 기쁨"; }
            else if (language === "japanese") { return "観客の喜び"; }
            else if (language === "french") { return "Plaisir de la foule"; }
            else if (language === "spanish") { return "Placer de la multitud"; }
            else if (language === "german") { return "Publikumsliebling"; }
            else if (language === "italian") { return "Piacere della folla"; }
        }
        if (text === "drinks") {
            if (language === "english") { return "Drinks"; }
            else if (language === "vietnamese") { return "Đồ uống"; }
            else if (language === "chinese") { return "饮料"; }
            else if (language === "korean") { return "음료"; }
            else if (language === "japanese") { return "飲み物"; }
            else if (language === "french") { return "Boissons"; }
            else if (language === "spanish") { return "Bebidas"; }
            else if (language === "german") { return "Getränke"; }
            else if (language === "italian") { return "Bevande"; }
        }
        if (text === "breakfast") {
            if (language === "english") { return "Breakfast"; }
            else if (language === "vietnamese") { return "Bữa sáng"; }
            else if (language === "chinese") { return "早餐"; }
            else if (language === "korean") { return "아침 식사"; }
            else if (language === "japanese") { return "朝食"; }
            else if (language === "french") { return "Petit déjeuner"; }
            else if (language === "spanish") { return "Desayuno"; }
            else if (language === "german") { return "Frühstück"; }
            else if (language === "italian") { return "Colazione"; }
        }
        if (text === "dessert") {
            if (language === "english") { return "Dessert"; }
            else if (language === "vietnamese") { return "Tráng miệng"; }
            else if (language === "chinese") { return "甜点"; }
            else if (language === "korean") { return "디저트"; }
            else if (language === "japanese") { return "デザート"; }
            else if (language === "french") { return "Dessert"; }
            else if (language === "spanish") { return "Postre"; }
            else if (language === "german") { return "Nachtisch"; }
            else if (language === "italian") { return "Dolce"; }
        }
    }

    // RECIPES SEARCH

    if (section === "recipes search") {

        if (text === "recipes search intro") {
            if (language === "english") { return (<>Discover your next home cooked meal.</>) }
            else if (language === "vietnamese") { return (<>Hãy cắn một miếng trong bộ <br></br>sưu tập công thức của chúng tôi.</>) }
            else if (language === "chinese") { return (<>咬一口我们的<br></br>食谱收藏。</>) }
            else if (language === "korean") { return (<>우리의 레시피 컬렉션에서 한 입을 물어보세요.</>) }
            else if (language === "japanese") { return (<>私たちのレシピコレクションを一口食べてください。</>) }
            else if (language === "french") { return (<>Prenez une bouchée de notre<br></br>Collection de recettes.</>) }
            else if (language === "spanish") { return (<>Dale un mordisco a nuestra<br></br>Colección de recetas.</>) }
            else if (language === "german") { return (<>Nehmen Sie einen Bissen aus unserer<br></br>Rezeptsammlung.</>) }
            else if (language === "italian") { return (<>Fai un boccone della nostra<br></br>Collezione di ricette.</>) }
        }
        if (text === "more recipes") {
            if (language === "english") { return "More recipes, please!"; }
            else if (language === "vietnamese") { return "Công thức khác!"; }
            else if (language === "chinese") { return "更多食谱，请！"; }
            else if (language === "korean") { return "다른 레시피!"; }
            else if (language === "japanese") { return "他のレシピ！"; }
            else if (language === "french") { return "Plus de recettes, s'il vous plaît!"; }
            else if (language === "spanish") { return "¡Más recetas, por favor!"; }
            else if (language === "german") { return "Mehr Rezepte, bitte!"; }
            else if (language === "italian") { return "Altre ricette, per favore!"; }
        }
        if (text === "search recipes") {
            if (language === "english") { return "Search for a recipe"; }
            else if (language === "vietnamese") { return "Tìm kiếm công thức"; }
            else if (language === "chinese") { return "搜索食谱"; }
            else if (language === "korean") { return "레시피 검색"; }
            else if (language === "japanese") { return "レシピを検索"; }
            else if (language === "french") { return "Rechercher une recette"; }
            else if (language === "spanish") { return "Buscar una receta"; }
            else if (language === "german") { return "Suche nach einem Rezept"; }
            else if (language === "italian") { return "Cerca una ricetta"; }
        }
        if (text === "views") {
            if (language === "english") { return "Views"; }
            else if (language === "vietnamese") { return "Lượt xem"; }
            else if (language === "chinese") { return "意见"; }
            else if (language === "korean") { return "견해"; }
            else if (language === "japanese") { return "見解"; }
            else if (language === "french") { return "Vues"; }
            else if (language === "spanish") { return "Puntos de vista"; }
            else if (language === "german") { return "Ansichten"; }
            else if (language === "italian") { return "Visualizzazioni"; }
        }
        if (text === "ratings") {
            if (language === "english") { return "Ratings"; }
            else if (language === "vietnamese") { return "Đánh giá"; }
            else if (language === "chinese") { return "评级"; }
            else if (language === "korean") { return "평가"; }
            else if (language === "japanese") { return "評価"; }
            else if (language === "french") { return "Évaluations"; }
            else if (language === "spanish") { return "Calificaciones"; }
            else if (language === "german") { return "Bewertungen"; }
            else if (language === "italian") { return "Valutazioni"; }
        }
        if (text === "A – Z") {
            if (language === "english") { return "A – Z"; }
            else if (language === "vietnamese") { return "A – Z"; }
            else if (language === "chinese") { return "A – Z"; }
            else if (language === "korean") { return "A – Z"; }
            else if (language === "japanese") { return "A – Z"; }
            else if (language === "french") { return "A – Z"; }
            else if (language === "spanish") { return "A – Z"; }
            else if (language === "german") { return "A – Z"; }
            else if (language === "italian") { return "A – Z"; }
        }
        if (text === "columns") {
            if (language === "english") { return "Columns"; }
            else if (language === "vietnamese") { return "Cột"; }
            else if (language === "chinese") { return "柱"; }
            else if (language === "korean") { return "기둥"; }
            else if (language === "japanese") { return "コラム"; }
            else if (language === "french") { return "Colonnes"; }
            else if (language === "spanish") { return "Columnas"; }
            else if (language === "german") { return "Spalten"; }
            else if (language === "italian") { return "Colonne"; }
        }
        if (text === "recipes") {
            if (language === "english") { return "Recipes"; }
            else if (language === "vietnamese") { return "Công thức"; }
            else if (language === "chinese") { return "食谱"; }
            else if (language === "korean") { return "레시피"; }
            else if (language === "japanese") { return "レシピ"; }
            else if (language === "french") { return "Recettes"; }
            else if (language === "spanish") { return "Recetas"; }
            else if (language === "german") { return "Rezepte"; }
            else if (language === "italian") { return "Ricette"; }
        }
        if (text === "favourites") {
            if (language === "english") { return "Favourites"; }
            else if (language === "vietnamese") { return "Yêu thích"; }
            else if (language === "chinese") { return "最爱"; }
            else if (language === "korean") { return "좋아요"; }
            else if (language === "japanese") { return "お気に入り"; }
            else if (language === "french") { return "Favoris"; }
            else if (language === "spanish") { return "Favoritos"; }
            else if (language === "german") { return "Favoriten"; }
            else if (language === "italian") { return "Preferiti"; }
        }
        if (text === "meal") {
            if (language === "english") { return "Meal"; }
            else if (language === "vietnamese") { return "Bữa ăn"; }
            else if (language === "chinese") { return "膳食"; }
            else if (language === "korean") { return "식사"; }
            else if (language === "japanese") { return "食事"; }
            else if (language === "french") { return "Repas"; }
            else if (language === "spanish") { return "Comida"; }
            else if (language === "german") { return "Mahlzeit"; }
            else if (language === "italian") { return "Pasto"; }
        }
        if (text === "cuisine") {
            if (language === "english") { return "Cuisine"; }
            else if (language === "vietnamese") { return "Ẩm thực"; }
            else if (language === "chinese") { return "烹饪"; }
            else if (language === "korean") { return "요리"; }
            else if (language === "japanese") { return "料理"; }
            else if (language === "french") { return "Cuisine"; }
            else if (language === "spanish") { return "Cocina"; }
            else if (language === "german") { return "Küche"; }
            else if (language === "italian") { return "Cucina"; }
        }
        if (text === "protein") {
            if (language === "english") { return "Protein"; }
            else if (language === "vietnamese") { return "Chất đạm"; }
            else if (language === "chinese") { return "蛋白质"; }
            else if (language === "korean") { return "단백질"; }
            else if (language === "japanese") { return "タンパク質"; }
            else if (language === "french") { return "Protéine"; }
            else if (language === "spanish") { return "Proteína"; }
            else if (language === "german") { return "Protein"; }
            else if (language === "italian") { return "Proteina"; }
        }
        if (text === "ingredient") {
            if (language === "english") { return "Ingredient"; }
            else if (language === "vietnamese") { return "Thành phần"; }
            else if (language === "chinese") { return "成分"; }
            else if (language === "korean") { return "성분"; }
            else if (language === "japanese") { return "成分"; }
            else if (language === "french") { return "Ingrédient"; }
            else if (language === "spanish") { return "Ingrediente"; }
            else if (language === "german") { return "Zutat"; }
            else if (language === "italian") { return "Ingrediente"; }
        }
        if (text === "method") {
            if (language === "english") { return "Method"; }
            else if (language === "vietnamese") { return "Phương pháp"; }
            else if (language === "chinese") { return "方法"; }
            else if (language === "korean") { return "방법"; }
            else if (language === "japanese") { return "方法"; }
            else if (language === "french") { return "Méthode"; }
            else if (language === "spanish") { return "Método"; }
            else if (language === "german") { return "Methode"; }
            else if (language === "italian") { return "Metodo"; }
        }
        if (text === "occasion") {
            if (language === "english") { return "Occasion"; }
            else if (language === "vietnamese") { return "Dịp"; }
            else if (language === "chinese") { return "场合"; }
            else if (language === "korean") { return "기회"; }
            else if (language === "japanese") { return "機会"; }
            else if (language === "french") { return "Occasion"; }
            else if (language === "spanish") { return "Ocasión"; }
            else if (language === "german") { return "Gelegenheit"; }
            else if (language === "italian") { return "Occasione"; }
        }
        if (text === "cooking time") {
            if (language === "english") { return "Cooking Time"; }
            else if (language === "vietnamese") { return "Thời gian nấu"; }
            else if (language === "chinese") { return "烹饪时间"; }
            else if (language === "korean") { return "요리 시간"; }
            else if (language === "japanese") { return "調理時間"; }
            else if (language === "french") { return "Temps"; }
            else if (language === "spanish") { return "Tiempo"; }
            else if (language === "german") { return "Kochzeit"; }
            else if (language === "italian") { return "Tempo"; }
        }
        if (text === "clear all") {
            if (language === "english") { return "Clear All"; }
            else if (language === "vietnamese") { return "Xóa tất cả"; }
            else if (language === "chinese") { return "全部清除"; }
            else if (language === "korean") { return "모두 지우기"; }
            else if (language === "japanese") { return "すべてクリア"; }
            else if (language === "french") { return "Tout effacer"; }
            else if (language === "spanish") { return "Borrar todo"; }
            else if (language === "german") { return "Alles löschen"; }
            else if (language === "italian") { return "Cancella tutto"; }
        }
        if (text === "no results title") {
            if (language === "english") { return (<>Well, butter my biscuit!<br />The recipe you want is nowhere to be found.</>); }
            else if (language === "vietnamese") { return (<>Ồ không, xin lỗi.<br />Công thức bạn muốn không có ở đâu.</>); }
            else if (language === "chinese") { return (<>哇，抹黄油的饼干！<br />您想要的食谱无处可寻。</>); }
            else if (language === "korean") { return (<>음, 내 비스킷에 버터를 발라!<br />당신이 원하는 레시피는 어디에도 없습니다.</>); }
            else if (language === "japanese") { return (<>まあ、私のビスケットにバターを塗ってください！<br />あなたが望むレシピはどこにもありません。</>); }
            else if (language === "french") { return (<>Eh bien, beurre mon biscuit!<br />La recette que vous voulez est introuvable.</>); }
            else if (language === "spanish") { return (<>Bueno, ¡mantequilla mi galleta!<br />La receta que desea no se encuentra en ninguna parte.</>); }
            else if (language === "german") { return (<>Nun, Butter mein Keks!<br />Das Rezept, das Sie möchten, ist nirgendwo zu finden.</>); }
            else if (language === "italian") { return (<>Beh, burro il mio biscotto!<br />La ricetta che desideri non si trova da nessuna parte.</>); }
        }
        if (text === "no results info") {
            if (language === "english") { return (<>We're constantly adding new recipes to Garlic Basil. {window.innerWidth < 768 ? null : <br />}Let us know if you want us to add the recipe below.</>); }
            else if (language === "vietnamese") { return (<>Chúng tôi đang liên tục thêm công thức mới vào Garlic Basil. {window.innerWidth < 768 ? null : <br />}Hãy cho chúng tôi biết nếu bạn muốn chúng tôi thêm công thức dưới đây.</>); }
            else if (language === "chinese") { return (<>我们正在不断地向Garlic Basil添加新食谱。 {window.innerWidth < 768 ? null : <br />}如果您想要我们添加下面的食谱，请告诉我们。</>); }
            else if (language === "korean") { return (<>우리는 계속해서 Garlic Basil에 새로운 레시피를 추가하고 있습니다. {window.innerWidth < 768 ? null : <br />}아래 레시피를 추가하길 원한다면 알려주세요.</>); }
            else if (language === "japanese") { return (<>私たちは常にGarlic Basilに新しいレシピを追加しています。 {window.innerWidth < 768 ? null : <br />}下のレシピを追加してほしい場合はお知らせください。</>); }
            else if (language === "french") { return (<>Nous ajoutons constamment de nouvelles recettes à Garlic Basil. {window.innerWidth < 768 ? null : <br />}Faites-nous savoir si vous souhaitez que nous ajoutions la recette ci-dessous.</>); }
            else if (language === "spanish") { return (<>Estamos constantemente agregando nuevas recetas a Garlic Basil. {window.innerWidth < 768 ? null : <br />}Háganos saber si desea que agreguemos la receta a continuación.</>); }
            else if (language === "german") { return (<>Wir fügen Garlic Basil ständig neue Rezepte hinzu. {window.innerWidth < 768 ? null : <br />}Lassen Sie uns wissen, ob Sie möchten, dass wir das Rezept unten hinzufügen.</>); }
            else if (language === "italian") { return (<>Stiamo costantemente aggiungendo nuove ricette a Garlic Basil. {window.innerWidth < 768 ? null : <br />}Fateci sapere se volete che aggiungiamo la ricetta qui sotto.</>); }
        }
        if (text === "send suggestion") {
            if (language === "english") { return (<>Send Suggestion</>); }
            else if (language === "vietnamese") { return (<>Gửi gợi ý</>); }
            else if (language === "chinese") { return (<>发送建议</>); }
            else if (language === "korean") { return (<>제안 보내기</>); }
            else if (language === "japanese") { return (<>提案を送信する</>); }
            else if (language === "french") { return (<>Envoyer une suggestion</>); }
            else if (language === "spanish") { return (<>Enviar sugerencia</>); }
            else if (language === "german") { return (<>Vorschlag senden</>); }
            else if (language === "italian") { return (<>Invia suggerimento</>); }
        }
        if (text === "thank you message") {
            if (language === "english") { return (<>Thank you for your suggestion! We will do our best to source this recipe and add it to our website.</>); }
            else if (language === "vietnamese") { return (<>Cảm ơn gợi ý của bạn! Chúng tôi sẽ cố gắng tìm công thức này và thêm nó vào trang web của chúng tôi.</>); }
            else if (language === "chinese") { return (<>感谢您的建议！ 我们将尽力寻找此食谱并将其添加到我们的网站中。</>); }
            else if (language === "korean") { return (<>제안 해 주셔서 감사합니다! 이 레시피를 찾아 웹 사이트에 추가 할 것입니다.</>); }
            else if (language === "japanese") { return (<>提案してくれてありがとう！ このレシピを見つけてウェブサイトに追加します。</>); }
            else if (language === "french") { return (<>Merci pour votre suggestion! Nous ferons de notre mieux pour trouver cette recette et l'ajouter à notre site Web.</>); }
            else if (language === "spanish") { return (<>¡Gracias por su sugerencia! Haremos todo lo posible para encontrar esta receta y agregarla a nuestro sitio web.</>); }
            else if (language === "german") { return (<>Vielen Dank für Ihren Vorschlag! Wir werden unser Bestes tun, um dieses Rezept zu finden und unserer Website hinzuzufügen.</>); }
            else if (language === "italian") { return (<>Grazie per il tuo suggerimento! Faremo del nostro meglio per trovare questa ricetta e aggiungerla al nostro sito web.</>); }
        }
        if (text === "filters") {
            if (language === "english") { return (<>Filters</>); }
            else if (language === "vietnamese") { return (<>Bộ lọc</>); }
            else if (language === "chinese") { return (<>过滤器</>); }
            else if (language === "korean") { return (<>필터</>); }
            else if (language === "japanese") { return (<>フィルター</>); }
            else if (language === "french") { return (<>Filtres</>); }
            else if (language === "spanish") { return (<>Filtros</>); }
            else if (language === "german") { return (<>Filter</>); }
            else if (language === "italian") { return (<>Filtri</>); }
        }
        if (text === "sort by") {
            if (language === "english") { return (<>Sort By</>); }
            else if (language === "vietnamese") { return (<>Sắp xếp theo</>); }
            else if (language === "chinese") { return (<>排序方式</>); }
            else if (language === "korean") { return (<>정렬 기준</>); }
            else if (language === "japanese") { return (<>並べ替え</>); }
            else if (language === "french") { return (<>Trier par</>); }
            else if (language === "spanish") { return (<>Ordenar por</>); }
            else if (language === "german") { return (<>Sortieren nach</>); }
            else if (language === "italian") { return (<>Ordina per</>); }
        }
        if (text === "recipe filters") {
            if (language === "english") { return (<>Recipe Filters</>); }
            else if (language === "vietnamese") { return (<>Bộ lọc công thức</>); }
            else if (language === "chinese") { return (<>食谱过滤器</>); }
            else if (language === "korean") { return (<>레시피 필터</>); }
            else if (language === "japanese") { return (<>レシピフィルター</>); }
            else if (language === "french") { return (<>Filtres de recettes</>); }
            else if (language === "spanish") { return (<>Filtros de recetas</>); }
            else if (language === "german") { return (<>Rezeptfilter</>); }
            else if (language === "italian") { return (<>Filtri di ricette</>); }
        }
        if (text === "set columns") {
            if (language === "english") { return (<>Set Columns</>); }
            else if (language === "vietnamese") { return (<>Đặt cột</>); }
            else if (language === "chinese") { return (<>设置列</>); }
            else if (language === "korean") { return (<>열 설정</>); }
            else if (language === "japanese") { return (<>列を設定する</>); }
            else if (language === "french") { return (<>Définir les colonnes</>); }
            else if (language === "spanish") { return (<>Establecer columnas</>); }
            else if (language === "german") { return (<>Spalten festlegen</>); }
            else if (language === "italian") { return (<>Imposta colonne</>); }
        }
    }

    // BASKET

    if (section === "basket") {

        if (text === "basket no items info") {
            if (language === "english") { return (<>An empty basket is like a blank canvas,<br />let our recipes be your inspiration!</>) }
            else if (language === "vietnamese") { return (<>Giỏ hàng trống giống như một bức tranh trống, hãy để công thức nấu ăn của chúng tôi làm nguồn cảm hứng cho bạn!</>) }
            else if (language === "chinese") { return (<>空篮子就像一块空画布，让我们的食谱成为您的灵感！</>) }
            else if (language === "korean") { return (<>빈 바구니는 빈 캔버스와 같습니다. 레시피를 참고하여 요리를 시작하세요!</>) }
            else if (language === "japanese") { return (<>空のバスケットは空のキャンバスのようなものです。レシピを参考に料理を始めましょう！</>) }
            else if (language === "french") { return (<>Un panier vide est comme une toile vierge, laissez nos recettes être votre inspiration !</>) }
            else if (language === "spanish") { return (<>Una cesta vacía es como un lienzo en blanco, ¡deja que nuestras recetas sean tu inspiración!</>) }
            else if (language === "german") { return (<>Ein leerer Korb ist wie eine leere Leinwand, lassen Sie sich von unseren Rezepten inspirieren!</>) }
            else if (language === "italian") { return (<>Un cestino vuoto è come una tela bianca, lascia che le nostre ricette siano la tua ispirazione!</>) }
        }
        if (text === "explore recipes") {
            if (language === "english") { return (<>Explore Recipes</>) }
            else if (language === "vietnamese") { return (<>Khám Phá</>) }
            else if (language === "chinese") { return (<>探索食谱</>) }
            else if (language === "korean") { return (<>레시피 탐색</>) }
            else if (language === "japanese") { return (<>レシピを探索する</>) }
            else if (language === "french") { return (<>Explorer les recettes</>) }
            else if (language === "spanish") { return (<>Explorar recetas</>) }
            else if (language === "german") { return (<>Rezepte erkunden</>) }
            else if (language === "italian") { return (<>Esplora ricette</>) }
        }
        if (text === "not complete description") {
            if (language === "english") { return (<>What I need to buy:</>) }
            else if (language === "vietnamese") { return (<>Những gì tôi cần mua:</>) }
            else if (language === "chinese") { return (<>我需要买什么：</>) }
            else if (language === "korean") { return (<>구입해야 할 것:</>) }
            else if (language === "japanese") { return (<>買う必要があるもの：</>) }
            else if (language === "french") { return (<>Ce que j'ai besoin d'acheter :</>) }
            else if (language === "spanish") { return (<>Lo que necesito comprar:</>) }
            else if (language === "german") { return (<>Was ich kaufen muss:</>) }
            else if (language === "italian") { return (<>Cosa devo comprare:</>) }
        }
        if (text === "complete description") {
            if (language === "english") { return (<>What I already have:</>) }
            else if (language === "vietnamese") { return (<>Những gì tôi đã có:</>) }
            else if (language === "chinese") { return (<>我已经有什么了：</>) }
            else if (language === "korean") { return (<>내가 이미 가지고 있는 것:</>) }
            else if (language === "japanese") { return (<>私がすでに持っているもの：</>) }
            else if (language === "french") { return (<>Ce que j'ai déjà :</>) }
            else if (language === "spanish") { return (<>Lo que ya tengo:</>) }
            else if (language === "german") { return (<>Was ich schon habe:</>) }
            else if (language === "italian") { return (<>Quello che ho già:</>) }
        }
        if (text === "single recipe count") {
            if (language === "english") { return (<>There is 1 recipe in your basket</>) }
            else if (language === "vietnamese") { return (<>Có 1 công thức trong giỏ hàng của bạn</>) }
            else if (language === "chinese") { return (<>您的购物篮中有1个食谱</>) }
            else if (language === "korean") { return (<>바구니에 1 개의 레시피가 있습니다.</>) }
            else if (language === "japanese") { return (<>あなたのバスケットには1つのレシピがあります</>) }
            else if (language === "french") { return (<>Il y a 1 recette dans votre panier</>) }
            else if (language === "spanish") { return (<>Hay 1 receta en tu cesta</>) }
            else if (language === "german") { return (<>Es gibt 1 Rezept in Ihrem Korb</>) }
            else if (language === "italian") { return (<>Ci sono 1 ricetta nel tuo cestino</>) }
        }
        if (text === "there is") {
            if (language === "english") { return (<>There is</>) }
            else if (language === "vietnamese") { return (<>Có</>) }
            else if (language === "chinese") { return (<>有</>) }
            else if (language === "korean") { return (<>있다</>) }
            else if (language === "japanese") { return (<>あります</>) }
            else if (language === "french") { return (<>Il y a</>) }
            else if (language === "spanish") { return (<>Hay</>) }
            else if (language === "german") { return (<>Es gibt</>) }
            else if (language === "italian") { return (<>Ci sono</>) }
        }
        if (text === "recipes in your basket") {
            if (language === "english") { return (<>recipes in your basket</>) }
            else if (language === "vietnamese") { return (<>công thức trong giỏ hàng của bạn</>) }
            else if (language === "chinese") { return (<>您的购物篮中的食谱</>) }
            else if (language === "korean") { return (<>바구니에있는 레시피</>) }
            else if (language === "japanese") { return (<>あなたのバスケットのレシピ</>) }
            else if (language === "french") { return (<>recettes dans votre panier</>) }
            else if (language === "spanish") { return (<>recetas en tu cesta</>) }
            else if (language === "german") { return (<>Rezepte in Ihrem Korb</>) }
            else if (language === "italian") { return (<>ricette nel tuo cestino</>) }
        }

    }


    // ABOUT US

    if (section === "about us") {
        if (text === "about us subtitle") {
            if (language === "english") { return (<>OUR PURPOSE</>) }
            else if (language === "vietnamese") { return (<>MỤC ĐÍCH CỦA CHÚNG TÔI</>) }
            else if (language === "chinese") { return (<>我们的目的</>) }
            else if (language === "korean") { return (<>우리의 목적</>) }
            else if (language === "japanese") { return (<>私たちの目的</>) }
            else if (language === "french") { return (<>NOTRE BUT</>) }
            else if (language === "spanish") { return (<>NUESTRO PROPÓSITO</>) }
            else if (language === "german") { return (<>UNSER ZWECK</>) }
            else if (language === "italian") { return (<>IL NOSTRO SCOPO</>) }
        }
    }
    if (section === "about us") {
        if (text === "about us title") {
            if (language === "english") { return (<><h1 className="purpose_title">Redefining Our Relationship</h1><h1 className="purpose_title">With Home Cooking</h1></>) }
            else if (language === "vietnamese") { return (<><h1 className="purpose_title">Tái Định Nghĩa Mối Quan Hệ</h1><h1 className="purpose_title">Với Nấu Ăn Tại Nhà</h1></>) }
            else if (language === "chinese") { return (<><h1 className="purpose_title">重新定义我们的关系</h1><h1 className="purpose_title">与家庭烹饪</h1></>) }
            else if (language === "korean") { return (<><h1 className="purpose_title">우리의 관계 재정의</h1><h1 className="purpose_title">홈 쿠킹과</h1></>) }
            else if (language === "japanese") { return (<><h1 className="purpose_title">私たちの関係を再定義する</h1><h1 className="purpose_title">家庭料理と</h1></>) }
            else if (language === "french") { return (<><h1 className="purpose_title">Redéfinir notre relation</h1><h1 className="purpose_title">Avec la cuisine maison</h1></>) }
            else if (language === "spanish") { return (<><h1 className="purpose_title">Redefinir nuestra relación</h1><h1 className="purpose_title">Con la cocina casera</h1></>) }
            else if (language === "german") { return (<><h1 className="purpose_title">Unsere Beziehung neu definieren</h1><h1 className="purpose_title">Mit dem Kochen zu Hause</h1></>) }
            else if (language === "italian") { return (<><h1 className="purpose_title">Ridefinire il nostro rapporto</h1><h1 className="purpose_title">Con la cucina casalinga</h1></>) }
        }
    }
    if (section === "about us") {
        if (text === "about us text") {
            if (language === "english") { return (<>Here at Garlic Basil we love food and we love cooking. Our purpose is to redefine our relationship with home cooking by providing a platform that inspires and empowers home chefs to explore new recipes, techniques, and ingredients, all while leveraging the power of Artificial Intelligence.<br /><br />Our recipes and images are sourced from the latest and most advanced Artificial Intelligence models, which enables us to offer a diverse range of recipes from across the globe.<br /><br />For enquiries, reach us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "vietnamese") { return (<>Ở Garlic Basil, chúng tôi yêu thích thức ăn và chúng tôi yêu thích nấu ăn. Mục đích của chúng tôi là tái định nghĩa mối quan hệ của chúng tôi với nấu ăn tại nhà bằng cách cung cấp một nền tảng truyền cảm hứng và trao quyền cho các đầu bếp tại nhà để khám phá các công thức, kỹ thuật và nguyên liệu mới, tất cả trong khi tận dụng sức mạnh của Trí tuệ nhân tạo.<br /><br />Các công thức và hình ảnh của chúng tôi được lấy từ các mô hình Trí tuệ nhân tạo tiên tiến và mới nhất, cho phép chúng tôi cung cấp một loạt các công thức đa dạng từ khắp nơi trên thế giới.<br /><br />Để biết thêm thông tin, vui lòng liên hệ với chúng tôi tại <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "chinese") { return (<>在 Garlic Basil, 我们热爱美食和烹饪。我们的目的是通过提供一个平台来重新定义我们与家庭烹饪的关系，该平台可以激发和赋予家庭厨师探索新食谱，技术和成分的能力，同时利用人工智能的力量。<br /><br />我们的食谱和图像来自最新和最先进的人工智能模型，这使我们能够提供来自全球各地的各种食谱。<br /><br />有关咨询，请联系我们 <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "korean") { return (<>Garlic Basil 에서는 음식과 요리를 좋아합니다. 우리의 목적은 인공 지능의 힘을 활용하면서 새로운 레시피, 기술 및 재료를 탐색하는 것을 영감을 주고 가정 셰프가 되도록 가정 요리와의 관계를 재정의하는 것입니다.<br /><br />우리의 레시피와 이미지는 최신 및 가장 진보 된 인공 지능 모델에서 가져온 것으로, 전 세계의 다양한 레시피를 제공 할 수 있습니다.<br /><br />문의 사항은 <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "japanese") { return (<>Garlic Basil では、食べ物と料理が大好きです。私たちの目的は、最新かつ最先端の人工知能モデルからレシピと画像を入手することにより、世界中のさまざまなレシピを提供できるようにすることです。<br /><br />私たちの目的は、最新かつ最先端の人工知能モデルからレシピと画像を入手することにより、世界中のさまざまなレシピを提供できるようにすることです。<br /><br />お問い合わせは <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "french") { return (<>Chez Garlic Basil, nous aimons la nourriture et nous aimons cuisiner. Notre objectif est de redéfinir notre relation avec la cuisine maison en fournissant une plate-forme qui inspire et responsabilise les chefs à domicile pour explorer de nouvelles recettes, techniques et ingrédients, tout en tirant parti de la puissance de l'intelligence artificielle.<br /><br />Nos recettes et images proviennent des modèles d'intelligence artificielle les plus récents et les plus avancés, ce qui nous permet d'offrir une gamme diversifiée de recettes du monde entier.<br /><br />Pour toute demande, contactez-nous à <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "spanish") { return (<>En Garlic Basil, nos encanta la comida y nos encanta cocinar. Nuestro objetivo es redefinir nuestra relación con la cocina casera al proporcionar una plataforma que inspire y empodere a los chefs caseros para explorar nuevas recetas, técnicas e ingredientes, todo mientras aprovecha el poder de la inteligencia artificial.<br /><br />Nuestras recetas e imágenes provienen de los modelos de inteligencia artificial más nuevos y avanzados, lo que nos permite ofrecer una variedad de recetas de todo el mundo.<br /><br />Para consultas, contáctenos en <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "german") { return (<>Bei Garlic Basil lieben wir Essen und wir lieben es zu kochen. Unser Ziel ist es, unsere Beziehung zum Kochen zu Hause neu zu definieren, indem wir eine Plattform bereitstellen, die Köche zu Hause inspiriert und befähigt, neue Rezepte, Techniken und Zutaten zu erkunden und gleichzeitig die Kraft der künstlichen Intelligenz zu nutzen.<br /><br />Unsere Rezepte und Bilder stammen aus den neuesten und fortschrittlichsten KI-Modellen, sodass wir eine Vielzahl von Rezepten aus der ganzen Welt anbieten können.<br /><br />Für Anfragen kontaktieren Sie uns unter <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
            else if (language === "italian") { return (<>Da Garlic Basil amiamo il cibo e amiamo cucinare. Il nostro obiettivo è ridefinire il nostro rapporto con la cucina casalinga fornendo una piattaforma che ispiri ed responsabilizzi i cuochi casalinghi ad esplorare nuove ricette, tecniche e ingredienti, sfruttando al contempo la potenza dell'intelligenza artificiale.<br /><br />Le nostre ricette e immagini provengono dai modelli di intelligenza artificiale più recenti e avanzati, il che ci consente di offrire una varietà di ricette provenienti da tutto il mondo.<br /><br />Per richieste, contattaci su <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a></>) }
        }
    }

    // LOG IN / SIGN UP

    if (section === "log in sign up") {

        // SIGN UP

        if (text === "sign up title") {
            if (language === "english") { return "Sign up to streamline your cooking" }
            else if (language === "vietnamese") { return "Đăng ký" }
            else if (language === "chinese") { return "注册" }
            else if (language === "korean") { return "가입" }
            else if (language === "japanese") { return "サインアップ" }
            else if (language === "french") { return "S'inscrire" }
            else if (language === "spanish") { return "Regístrate" }
            else if (language === "german") { return "Anmelden" }
            else if (language === "italian") { return "Iscriviti" }
        }
        if (text === "sign up intro") {
            if (language === "english") { return "Save your favourite recipes and easily access them on our website. Our basket feature lets you add ingredients as you plan your meals, making grocery shopping a breeze." }
            else if (language === "vietnamese") { return "Lưu công thức nấu ăn, thêm nguyên liệu vào giỏ của bạn và đánh giá công thức nấu ăn của chúng tôi!" }
            else if (language === "chinese") { return "保存您喜欢的食谱，轻松访问我们的网站。我们的篮子功能可让您在计划用餐时添加成分，从而轻松购物。" }
            else if (language === "korean") { return "좋아하는 레시피를 저장하고 웹 사이트에서 쉽게 액세스하세요. 바구니 기능을 사용하면 식사 계획을 세울 때 재료를 추가하여 쇼핑을 쉽게 할 수 있습니다." }
            else if (language === "japanese") { return "お気に入りのレシピを保存して、ウェブサイトで簡単にアクセスできます。バスケット機能を使用すると、食事の計画に合わせて材料を追加して、簡単に買い物をすることができます。" }
            else if (language === "french") { return "Enregistrez vos recettes préférées et accédez-y facilement sur notre site Web. Notre fonction de panier vous permet d'ajouter des ingrédients lorsque vous planifiez vos repas, ce qui rend les courses faciles." }
            else if (language === "spanish") { return "Guarde sus recetas favoritas y acceda fácilmente a ellas en nuestro sitio web. Nuestra función de cesta le permite agregar ingredientes a medida que planifica sus comidas, lo que facilita las compras." }
            else if (language === "german") { return "Speichern Sie Ihre Lieblingsrezepte und greifen Sie einfach auf sie auf unserer Website zu. Mit unserer Korb-Funktion können Sie Zutaten hinzufügen, während Sie Ihre Mahlzeiten planen, was den Einkauf zum Kinderspiel macht." }
            else if (language === "italian") { return "Salva le tue ricette preferite e accedile facilmente sul nostro sito web. La nostra funzione cestino ti consente di aggiungere ingredienti mentre pianifichi i tuoi pasti, rendendo la spesa un gioco da ragazzi." }
        }
        if (text === "email address") {
            if (language === "english") { return "Email Address" }
            else if (language === "vietnamese") { return "Địa chỉ email" }
            else if (language === "chinese") { return "电子邮件地址" }
            else if (language === "korean") { return "이메일 주소" }
            else if (language === "japanese") { return "電子メールアドレス" }
            else if (language === "french") { return "Adresse e-mail" }
            else if (language === "spanish") { return "Dirección de correo electrónico" }
            else if (language === "german") { return "E-Mail-Addresse" }
            else if (language === "italian") { return "Indirizzo email" }
        }
        if (text === "password") {
            if (language === "english") { return "Password (minimum 8 characters)" }
            else if (language === "vietnamese") { return "Mật khẩu (tối thiểu 8 ký tự)" }
            else if (language === "chinese") { return "密码（最少8个字符）" }
            else if (language === "korean") { return "비밀번호 (최소 8 자)" }
            else if (language === "japanese") { return "パスワード（最低8文字）" }
            else if (language === "french") { return "Mot de passe (minimum 8 caractères)" }
            else if (language === "spanish") { return "Contraseña (mínimo 8 caracteres)" }
            else if (language === "german") { return "Passwort (mindestens 8 Zeichen)" }
            else if (language === "italian") { return "Password (minimo 8 caratteri)" }
        }
        if (text === "confirm password") {
            if (language === "english") { return "Confirm Password" }
            else if (language === "vietnamese") { return "Xác nhận mật khẩu" }
            else if (language === "chinese") { return "确认密码" }
            else if (language === "korean") { return "비밀번호 확인" }
            else if (language === "japanese") { return "パスワードを認証する" }
            else if (language === "french") { return "Confirmez le mot de passe" }
            else if (language === "spanish") { return "Confirmar contraseña" }
            else if (language === "german") { return "Bestätige das Passwort" }
            else if (language === "italian") { return "Conferma la password" }
        }
        if (text === "first name") {
            if (language === "english") { return "First Name" }
            else if (language === "vietnamese") { return "Tên" }
            else if (language === "chinese") { return "名字" }
            else if (language === "korean") { return "이름" }
            else if (language === "japanese") { return "名" }
            else if (language === "french") { return "Prénom" }
            else if (language === "spanish") { return "Nombre de pila" }
            else if (language === "german") { return "Vorname" }
            else if (language === "italian") { return "Nome di battesimo" }
        }
        if (text === "email me") {
            if (language === "english") { return "Email me with news and updates" }
            else if (language === "vietnamese") { return "Gửi email cho tôi với tin tức và cập nhật" }
            else if (language === "chinese") { return "通过电子邮件向我发送新闻和更新" }
            else if (language === "korean") { return "뉴스 및 업데이트를 이메일로 보내주세요" }
            else if (language === "japanese") { return "ニュースと更新をメールで送信" }
            else if (language === "french") { return "Envoyez-moi un e-mail avec des nouvelles et des mises à jour" }
            else if (language === "spanish") { return "Envíeme un correo electrónico con noticias y actualizaciones" }
            else if (language === "german") { return "Senden Sie mir E-Mails mit Neuigkeiten und Updates" }
            else if (language === "italian") { return "Inviatemi e-mail con notizie e aggiornamenti" }
        }
        if (text === "sign up") {
            if (language === "english") { return "Sign Up" }
            else if (language === "vietnamese") { return "Đăng Ký" }
            else if (language === "chinese") { return "注册" }
            else if (language === "korean") { return "가입하기" }
            else if (language === "japanese") { return "サインアップ" }
            else if (language === "french") { return "S'inscrire" }
            else if (language === "spanish") { return "Regístrate" }
            else if (language === "german") { return "Anmelden" }
            else if (language === "italian") { return "Iscriviti" }
        }
        if (text === "privacy policy info") {
            if (language === "english") { return (<>Read our <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Privacy Policy</a> to understand how we collect, use, and protect personal information that we receive from users of our website.</>) }
            else if (language === "vietnamese") { return (<>Đọc <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Chính Sách Bảo Mật</a> của chúng tôi để hiểu cách chúng tôi thu thập, sử dụng và bảo vệ thông tin cá nhân mà chúng tôi nhận được từ người dùng trang web của chúng tôi.</>) }
            else if (language === "chinese") { return (<>阅读我们的<a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>隐私政策</a>，了解我们如何收集，使用和保护我们从网站用户那里收到的个人信息。</>) }
            else if (language === "korean") { return (<>사용자로부터 수신한 개인 정보를 수집, 사용 및 보호하는 방법을 이해하려면 <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>개인 정보 보호 정책</a>을 읽으십시오.</>) }
            else if (language === "japanese") { return (<>当社の<a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>プライバシーポリシー</a>を読んで、当社のウェブサイトのユーザーから受け取った個人情報をどのように収集、使用、保護するかを理解してください。</>) }
            else if (language === "french") { return (<>Lisez notre <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Politique de confidentialité</a> pour comprendre comment nous collectons, utilisons et protégeons les informations personnelles que nous recevons des utilisateurs de notre site Web.</>) }
            else if (language === "spanish") { return (<>Lea nuestra <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Política de privacidad</a> para comprender cómo recopilamos, usamos y protegemos la información personal que recibimos de los usuarios de nuestro sitio web.</>) }
            else if (language === "german") { return (<>Lesen Sie unsere <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Datenschutz-Bestimmungen</a>, um zu verstehen, wie wir personenbezogene Daten sammeln, verwenden und schützen, die wir von Benutzern unserer Website erhalten.</>) }
            else if (language === "italian") { return (<>Leggi la nostra <a className="privacy_policy_link" href="/privacy_policy" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(e.target.href, "_blank"); }}>Politica sulla riservatezza</a> per capire come raccogliamo, utilizziamo e proteggiamo le informazioni personali che riceviamo dagli utenti del nostro sito Web.</>) }
        }
        if (text === "Incorrect username or password") {
            if (language === "english") { return "Incorrect username or password" }
            else if (language === "vietnamese") { return "'Tên người dùng hoặc mật khẩu không chính xác'" }
            else if (language === "chinese") { return "'用户名或密码不正确'" }
            else if (language === "korean") { return "'사용자 이름 또는 비밀번호가 잘못되었습니다'" }
            else if (language === "japanese") { return "'ユーザー名またはパスワードが正しくありません'" }
            else if (language === "french") { return "'Nom d'utilisateur ou mot de passe incorrect'" }
            else if (language === "spanish") { return "'Nombre de usuario o contraseña incorrectos'" }
            else if (language === "german") { return "'Falscher Benutzername oder falsches Passwort'" }
            else if (language === "italian") { return "'Nome utente o password errati'" }
        }

        // LOG IN

        if (text === "log in title") {
            if (language === "english") { return "Curate your recipe collection" }
            else if (language === "vietnamese") { return "Đăng Nhập" }
            else if (language === "chinese") { return "登录" }
            else if (language === "korean") { return "로그인" }
            else if (language === "japanese") { return "ログイン" }
            else if (language === "french") { return "Se connecter" }
            else if (language === "spanish") { return "Iniciar sesión" }
            else if (language === "german") { return "Einloggen" }
            else if (language === "italian") { return "Accesso" }
        }
        if (text === "log in intro") {
            if (language === "english") { return "Log in to access all of the amazing features we have to offer. We hope you enjoy your time on our website and look forward to helping you find and create delicious meals." }
            else if (language === "vietnamese") { return "Đăng nhập để truy cập tất cả các tính năng tuyệt vời mà chúng tôi có để cung cấp. Chúng tôi hy vọng bạn sẽ thích thú với thời gian của mình trên trang web của chúng tôi và mong muốn giúp bạn tìm thấy và tạo ra những bữa ăn ngon miệng." }
            else if (language === "chinese") { return "登录以访问我们提供的所有精彩功能。我们希望您在我们的网站上度过愉快的时光，并期待帮助您找到并制作美味的餐点。" }
            else if (language === "korean") { return "모든 멋진 기능에 액세스하려면 로그인하십시오. 우리는 당신이 당신의 시간을 즐기고 당신이 맛있는 식사를 찾고 만드는 데 도움이되기를 바랍니다." }
            else if (language === "japanese") { return "すべての素晴らしい機能にアクセスするには、ログインしてください。私たちはあなたがあなたの時間を楽しむことを願っています、そしてあなたがおいしい食事を見つけて作るのを助けることを楽しみにしています。" }
            else if (language === "french") { return "Connectez-vous pour accéder à toutes les fonctionnalités incroyables que nous avons à offrir. Nous espérons que vous apprécierez votre temps sur notre site Web et nous nous réjouissons de vous aider à trouver et à créer des repas délicieux." }
            else if (language === "spanish") { return "Inicie sesión para acceder a todas las increíbles funciones que tenemos para ofrecer. Esperamos que disfrute su tiempo en nuestro sitio web y esperamos ayudarlo a encontrar y crear comidas deliciosas." }
            else if (language === "german") { return "Melden Sie sich an, um auf alle erstaunlichen Funktionen zuzugreifen, die wir anbieten. Wir hoffen, Sie genießen Ihre Zeit auf unserer Website und freuen uns darauf, Ihnen bei der Suche und Erstellung köstlicher Mahlzeiten zu helfen." }
            else if (language === "italian") { return "Accedi per accedere a tutte le incredibili funzionalità che abbiamo da offrire. Speriamo che ti diverta il ​​tuo tempo sul nostro sito Web e non vediamo l'ora di aiutarti a trovare e creare deliziose pietanze." }
        }
        if (text === "forgot password") {
            if (language === "english") { return "Forgot Password?" }
            else if (language === "vietnamese") { return "Quên mật khẩu?" }
            else if (language === "chinese") { return "忘记密码？" }
            else if (language === "korean") { return "비밀번호를 잊으 셨나요?" }
            else if (language === "japanese") { return "パスワードをお忘れですか？" }
            else if (language === "french") { return "Mot de passe oublié?" }
            else if (language === "spanish") { return "¿Olvidó su contraseña?" }
            else if (language === "german") { return "Passwort vergessen?" }
            else if (language === "italian") { return "Password dimenticata?" }
        }
        if (text === "log in") {
            if (language === "english") { return "Log In" }
            else if (language === "vietnamese") { return "Đăng Nhập" }
            else if (language === "chinese") { return "登录" }
            else if (language === "korean") { return "로그인" }
            else if (language === "japanese") { return "ログイン" }
            else if (language === "french") { return "Se connecter" }
            else if (language === "spanish") { return "Iniciar sesión" }
            else if (language === "german") { return "Einloggen" }
            else if (language === "italian") { return "Accesso" }
        }
        if (text === "sign up instead") {
            if (language === "english") { return (<>New to Garlic Basil?</>) }
            else if (language === "vietnamese") { return (<>Mới vào Garlic Basil?</>) }
            else if (language === "chinese") { return (<>新来的？</>) }
            else if (language === "korean") { return (<>신규 가입자?</>) }
            else if (language === "japanese") { return (<>新規参入者？</>) }
            else if (language === "french") { return (<>Nouveau venu?</>) }
            else if (language === "spanish") { return (<>¿Nuevo en Garlic Basil?</>) }
            else if (language === "german") { return (<>Neu bei Garlic Basil?</>) }
            else if (language === "italian") { return (<>Nuovo in Garlic Basil?</>) }
        }

        // FORGOT PASSWORD

        if (text === "forgot password title") {
            if (language === "english") { return "Reset your password" }
            else if (language === "vietnamese") { return "Đặt lại mật khẩu" }
            else if (language === "chinese") { return "重设密码" }
            else if (language === "korean") { return "비밀번호 재설정" }
            else if (language === "japanese") { return "パスワードを再設定する" }
            else if (language === "french") { return "Réinitialiser votre mot de passe" }
            else if (language === "spanish") { return "Restablecer su contraseña" }
            else if (language === "german") { return "Setzen Sie Ihr Passwort zurück" }
            else if (language === "italian") { return "Reimposta la tua password" }
        }
        if (text === "forgot password intro") {
            if (language === "english") { return "Don't worry, we've all forgotten the recipe for a perfect password at some point! Enter your email below and we will send instructions to reset your password." }
            else if (language === "vietnamese") { return "Nhập địa chỉ email của bạn bên dưới và chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn." }
            else if (language === "chinese") { return "别担心，我们都曾经忘记过完美密码的配方！在下面输入您的电子邮件，我们将发送说明以重置密码。" }
            else if (language === "korean") { return "걱정 마라, 우리 모두가 어느 순간 완벽한 비밀번호의 비밀을 잊어 버렸습니다! 아래에 이메일을 입력하면 비밀번호를 재설정하는 방법에 대한 지침을 보내 드립니다." }
            else if (language === "japanese") { return "心配しないでください、私たちは皆、完璧なパスワードの秘密を忘れたことがあります！ 以下にメールアドレスを入力すると、パスワードをリセットする手順が送信されます。" }
            else if (language === "french") { return "Ne vous inquiétez pas, nous avons tous oublié la recette d'un mot de passe parfait à un moment donné! Entrez votre e-mail ci-dessous et nous vous enverrons des instructions pour réinitialiser votre mot de passe." }
            else if (language === "spanish") { return "¡No te preocupes, todos hemos olvidado la receta de una contraseña perfecta en algún momento! Ingrese su correo electrónico a continuación y le enviaremos instrucciones para restablecer su contraseña." }
            else if (language === "german") { return "Keine Sorge, wir haben alle irgendwann das Rezept für ein perfektes Passwort vergessen! Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts." }
            else if (language === "italian") { return "Non preoccuparti, tutti abbiamo dimenticato la ricetta di una password perfetta in un momento! Inserisci la tua email qui sotto e ti invieremo le istruzioni per reimpostare la password." }
        }
        if (text === "reset password") {
            if (language === "english") { return "Reset Password" }
            else if (language === "vietnamese") { return "Đặt Lại Mật Khẩu" }
            else if (language === "chinese") { return "重设密码" }
            else if (language === "korean") { return "비밀번호 재설정" }
            else if (language === "japanese") { return "パスワードを再設定する" }
            else if (language === "french") { return "Réinitialiser le mot de passe" }
            else if (language === "spanish") { return "Restablecer la contraseña" }
            else if (language === "german") { return "Passwort zurücksetzen" }
            else if (language === "italian") { return "Reimposta la password" }
        }
        if (text === "back to log in") {
            if (language === "english") { return "Remember your password?" }
            else if (language === "vietnamese") { return "Nhớ mật khẩu của bạn?" }
            else if (language === "chinese") { return "记得你的密码吗？" }
            else if (language === "korean") { return "비밀번호를 기억하세요?" }
            else if (language === "japanese") { return "パスワードを覚えていますか？" }
            else if (language === "french") { return "Vous souvenez-vous de votre mot de passe?" }
            else if (language === "spanish") { return "¿Recuerdas tu contraseña?" }
            else if (language === "german") { return "Erinnern Sie sich an Ihr Passwort?" }
            else if (language === "italian") { return "Ricordi la tua password?" }
        }
    }

    // RECIPE

    if (section === "recipe") {

        if (text === "No ratings yet") {
            if (language === "english") { return "No ratings yet"; }
            else if (language === "vietnamese") { return "Chưa có đánh giá"; }
            else if (language === "chinese") { return "还没有评分"; }
            else if (language === "korean") { return "아직 평가 없음"; }
            else if (language === "japanese") { return "まだ評価はありません"; }
            else if (language === "french") { return "Pas encore de notes"; }
            else if (language === "spanish") { return "Sin calificaciones todavía"; }
            else if (language === "german") { return "Noch keine Bewertungen"; }
            else if (language === "italian") { return "Ancora nessuna valutazione"; }
        }

        if (text === "Views") {
            if (language === "english") { return "Views"; }
            else if (language === "vietnamese") { return "Lượt xem"; }
            else if (language === "chinese") { return "观看次数"; }
            else if (language === "korean") { return "견해"; }
            else if (language === "japanese") { return "見た目"; }
            else if (language === "french") { return "Vues"; }
            else if (language === "spanish") { return "Puntos de vista"; }
            else if (language === "german") { return "Ansichten"; }
            else if (language === "italian") { return "Visualizzazioni"; }
        }

        if (text === "Share") {
            if (language === "english") { return "Share"; }
            else if (language === "vietnamese") { return "Chia sẻ"; }
            else if (language === "chinese") { return "分享"; }
            else if (language === "korean") { return "몫"; }
            else if (language === "japanese") { return "シェア"; }
            else if (language === "french") { return "Partager"; }
            else if (language === "spanish") { return "Compartir"; }
            else if (language === "german") { return "Aktie"; }
            else if (language === "italian") { return "Condividere"; }
        }

        if (text === "Share this recipe in") {
            if (language === "english") { return "Share this recipe in"; }
            else if (language === "vietnamese") { return "Chia sẻ công thức này trong"; }
            else if (language === "chinese") { return "在这个食谱中分享"; }
            else if (language === "korean") { return "이 레시피를 공유하십시오"; }
            else if (language === "japanese") { return "このレシピを共有する"; }
            else if (language === "french") { return "Partagez cette recette dans"; }
            else if (language === "spanish") { return "Comparte esta receta en"; }
            else if (language === "german") { return "Teilen Sie dieses Rezept in"; }
            else if (language === "italian") { return "Condividi questa ricetta in"; }
        }

        if (text === "Share in a different language") {
            if (language === "english") { return "Share in a different language"; }
            else if (language === "vietnamese") { return "Chia sẻ bằng một ngôn ngữ khác"; }
            else if (language === "chinese") { return "用另一种语言分享"; }
            else if (language === "korean") { return "다른 언어로 공유"; }
            else if (language === "japanese") { return "別の言語で共有する"; }
            else if (language === "french") { return "Partager dans une autre langue"; }
            else if (language === "spanish") { return "Compartir en un idioma diferente"; }
            else if (language === "german") { return "In einer anderen Sprache teilen"; }
            else if (language === "italian") { return "Condividi in un'altra lingua"; }
        }

        if (text === "link copied") {
            if (language === "english") { return "Link copied"; }
            else if (language === "vietnamese") { return "Đã sao chép liên kết"; }
            else if (language === "chinese") { return "链接已复制"; }
            else if (language === "korean") { return "링크 복사"; }
            else if (language === "japanese") { return "リンクがコピーされました"; }
            else if (language === "french") { return "Lien copié"; }
            else if (language === "spanish") { return "Enlace copiado"; }
            else if (language === "german") { return "Link kopiert"; }
            else if (language === "italian") { return "Link copiato"; }
        }

        if (text === "Report") {
            if (language === "english") { return "Report"; }
            else if (language === "vietnamese") { return "Báo cáo"; }
            else if (language === "chinese") { return "报告"; }
            else if (language === "korean") { return "보고서"; }
            else if (language === "japanese") { return "報告書"; }
            else if (language === "french") { return "Rapport"; }
            else if (language === "spanish") { return "Informe"; }
            else if (language === "german") { return "Bericht"; }
            else if (language === "italian") { return "Rapporto"; }
        }

        if (text === 'report title') {
            if (language === "english") { return "Did we make a mess in the kitchen?"; }
            else if (language === "vietnamese") { return "Chúng tôi có làm một mớ hỗn độn trong nhà bếp không?"; }
            else if (language === "chinese") { return "我们在厨房里搞砸了吗？"; }
            else if (language === "korean") { return "우리는 부엌에서 엉망이 되었습니까?"; }
            else if (language === "japanese") { return "台所でめちゃくちゃにしましたか？"; }
            else if (language === "french") { return "Avons-nous fait un gâchis dans la cuisine?"; }
            else if (language === "spanish") { return "¿Hicimos un desastre en la cocina?"; }
            else if (language === "german") { return "Haben wir in der Küche ein Durcheinander gemacht?"; }
            else if (language === "italian") { return "Abbiamo fatto un pasticcio in cucina?"; }
        }

        if (text === 'report subtitle') {
            if (language === "english") { return "Let us know and we're look into it!"; }
            else if (language === "vietnamese") { return "Hãy cho chúng tôi biết và chúng tôi sẽ tìm hiểu về nó!"; }
            else if (language === "chinese") { return "让我们知道，我们会研究一下！"; }
            else if (language === "korean") { return "알려 주시면 살펴 보겠습니다!"; }
            else if (language === "japanese") { return "私たちに知らせてください、そして私たちはそれを調べます！"; }
            else if (language === "french") { return "Faites-le nous savoir et nous y regarderons!"; }
            else if (language === "spanish") { return "¡Háganos saber y lo miraremos!"; }
            else if (language === "german") { return "Lassen Sie es uns wissen und wir werden es uns ansehen!"; }
            else if (language === "italian") { return "Fatecelo sapere e ci guarderemo dentro!"; }
        }

        if (text === 'report section') {
            if (language === "english") { return "Which section is the issue in?"; }
            else if (language === "vietnamese") { return "Vấn đề ở phần nào?"; }
            else if (language === "chinese") { return "问题在哪个部分？"; }
            else if (language === "korean") { return "어느 부분에 문제가 있습니까?"; }
            else if (language === "japanese") { return "どのセクションに問題がありますか？"; }
            else if (language === "french") { return "Dans quelle section se trouve le problème?"; }
            else if (language === "spanish") { return "¿En qué sección está el problema?"; }
            else if (language === "german") { return "In welchem ​​Abschnitt liegt das Problem?"; }
            else if (language === "italian") { return "In quale sezione si trova il problema?"; }
        }

        if (text === 'report details title') {
            if (language === "english") { return "Spill us the details:"; }
            else if (language === "vietnamese") { return "Đổ chi tiết cho chúng tôi:"; }
            else if (language === "chinese") { return "告诉我们细节："; }
            else if (language === "korean") { return "세부 사항을 알려주세요 :"; }
            else if (language === "japanese") { return "詳細を教えてください："; }
            else if (language === "french") { return "Renversez-nous les détails:"; }
            else if (language === "spanish") { return "Derrámenos los detalles:"; }
            else if (language === "german") { return "Verschütten Sie uns die Details:"; }
            else if (language === "italian") { return "Versaci i dettagli:"; }
        }

        if (text === 'report details placeholder') {
            if (language === "english") { return "Tell us what's wrong with this recipe.."; }
            else if (language === "vietnamese") { return "Hãy cho chúng tôi biết điều gì không ổn với công thức này.."; }
            else if (language === "chinese") { return "告诉我们这个食谱有什么问题.."; }
            else if (language === "korean") { return "이 레시피에 문제가 있음을 알려주세요.."; }
            else if (language === "japanese") { return "このレシピに問題があることを教えてください.."; }
            else if (language === "french") { return "Dites-nous ce qui ne va pas avec cette recette.."; }
            else if (language === "spanish") { return "Dinos qué hay de malo en esta receta.."; }
            else if (language === "german") { return "Sagen Sie uns, was mit diesem Rezept nicht stimmt.."; }
            else if (language === "italian") { return "Dicci cosa non va in questa ricetta.."; }
        }

        if (text === 'Recipe Name') {
            if (language === "english") { return "Recipe Name"; }
            else if (language === "vietnamese") { return "Tên công thức"; }
            else if (language === "chinese") { return "食谱名称"; }
            else if (language === "korean") { return "레시피 이름"; }
            else if (language === "japanese") { return "レシピ名"; }
            else if (language === "french") { return "Nom de la recette"; }
            else if (language === "spanish") { return "Nombre de la receta"; }
            else if (language === "german") { return "Rezeptname"; }
            else if (language === "italian") { return "Nome della ricetta"; }
        }

        if (text === 'Introduction') {
            if (language === "english") { return "Introduction"; }
            else if (language === "vietnamese") { return "Giới thiệu"; }
            else if (language === "chinese") { return "介绍"; }
            else if (language === "korean") { return "소개"; }
            else if (language === "japanese") { return "紹介"; }
            else if (language === "french") { return "Introduction"; }
            else if (language === "spanish") { return "Introducción"; }
            else if (language === "german") { return "Einführung"; }
            else if (language === "italian") { return "Introduzione"; }
        }

        if (text === 'see more') {
            if (language === "english") { return "see more" }
            else if (language === "vietnamese") { return "xem thêm" }
            else if (language === "chinese") { return "查看更多" }
            else if (language === "korean") { return "더 보기" }
            else if (language === "japanese") { return "もっと見る" }
            else if (language === "french") { return "voir plus" }
            else if (language === "spanish") { return "ver más" }
            else if (language === "german") { return "mehr sehen" }
            else if (language === "italian") { return "vedi di più" }
        }

        if (text === 'see less') {
            if (language === "english") { return "see less" }
            else if (language === "vietnamese") { return "thấy ít" }
            else if (language === "chinese") { return "看得少" }
            else if (language === "korean") { return "덜 보기" }
            else if (language === "japanese") { return "もっと見る" }
            else if (language === "french") { return "voir moins" }
            else if (language === "spanish") { return "ver menos" }
            else if (language === "german") { return "weniger sehen" }
            else if (language === "italian") { return "vedi meno" }
        }

        if (text === 'Image') {
            if (language === "english") { return "Image"; }
            else if (language === "vietnamese") { return "Hình ảnh"; }
            else if (language === "chinese") { return "图片"; }
            else if (language === "korean") { return "영상"; }
            else if (language === "japanese") { return "画像"; }
            else if (language === "french") { return "Image"; }
            else if (language === "spanish") { return "Imagen"; }
            else if (language === "german") { return "Bild"; }
            else if (language === "italian") { return "Immagine"; }
        }

        if (text === 'Ingredients') {
            if (language === "english") { return "Ingredients"; }
            else if (language === "vietnamese") { return "Thành phần"; }
            else if (language === "chinese") { return "成分"; }
            else if (language === "korean") { return "성분"; }
            else if (language === "japanese") { return "材料"; }
            else if (language === "french") { return "Ingrédients"; }
            else if (language === "spanish") { return "Ingredientes"; }
            else if (language === "german") { return "Zutaten"; }
            else if (language === "italian") { return "Ingredienti"; }
        }

        if (text === 'Method') {
            if (language === "english") { return "Method"; }
            else if (language === "vietnamese") { return "Phương pháp"; }
            else if (language === "chinese") { return "方法"; }
            else if (language === "korean") { return "방법"; }
            else if (language === "japanese") { return "方法"; }
            else if (language === "french") { return "Méthode"; }
            else if (language === "spanish") { return "Método"; }
            else if (language === "german") { return "Methode"; }
            else if (language === "italian") { return "Metodo"; }
        }

        if (text === 'Cooking Time') {
            if (language === "english") { return "Cooking Time"; }
            else if (language === "vietnamese") { return "Thời gian nấu"; }
            else if (language === "chinese") { return "烹饪时间"; }
            else if (language === "korean") { return "요리 시간"; }
            else if (language === "japanese") { return "調理時間"; }
            else if (language === "french") { return "Temps de cuisson"; }
            else if (language === "spanish") { return "Tiempo de cocción"; }
            else if (language === "german") { return "Kochzeit"; }
            else if (language === "italian") { return "Tempo di cottura"; }
        }

        if (text === 'Nutritional Information') {
            if (language === "english") { return "Nutritional Information"; }
            else if (language === "vietnamese") { return "Thông tin dinh dưỡng"; }
            else if (language === "chinese") { return "营养信息"; }
            else if (language === "korean") { return "영양 정보"; }
            else if (language === "japanese") { return "栄養情報"; }
            else if (language === "french") { return "Informations nutritionnelles"; }
            else if (language === "spanish") { return "Información nutricional"; }
            else if (language === "german") { return "Nährwertinformationen"; }
            else if (language === "italian") { return "Informazioni nutrizionali"; }
        }

        if (text === 'Other') {
            if (language === "english") { return "Other"; }
            else if (language === "vietnamese") { return "Khác"; }
            else if (language === "chinese") { return "其他"; }
            else if (language === "korean") { return "다른"; }
            else if (language === "japanese") { return "その他"; }
            else if (language === "french") { return "Autre"; }
            else if (language === "spanish") { return "Otro"; }
            else if (language === "german") { return "Andere"; }
            else if (language === "italian") { return "Altro"; }
        }

        if (text === 'report submit') {
            if (language === "english") { return "Submit"; }
            else if (language === "vietnamese") { return "Gửi"; }
            else if (language === "chinese") { return "提交"; }
            else if (language === "korean") { return "제출"; }
            else if (language === "japanese") { return "提出する"; }
            else if (language === "french") { return "Soumettre"; }
            else if (language === "spanish") { return "Enviar"; }
            else if (language === "german") { return "einreichen"; }
            else if (language === "italian") { return "Sottoscrivi"; }
        }

        if (text === "recipe image disclaimer") {
            if (language === "english") { return "This image was brewed using artificial intelligence, but don't get steamed if it's not quite your cup of broth. It might not be the cream of the crop and could be a bit nutty. So take it with a pinch of salt and don't get too cheesed off about it!"; }
            else if (language === "vietnamese") { return "Hình ảnh này được pha chế bằng trí tuệ nhân tạo, nhưng đừng bị hấp hối nếu nó không phải là cốc nước dùng của bạn. Nó có thể không phải là kem của vụ và có thể hơi ngu ngốc. Vì vậy, hãy nhận nó với một chút muối và đừng quá bực tức về nó!"; }
            else if (language === "chinese") { return "这张图片是用人工智能酿造的，但如果它不是你的汤杯，不要生气。它可能不是最好的，可能有点傻。所以带着一点盐，不要对此感到太生气！"; }
            else if (language === "korean") { return "이 이미지는 인공 지능을 사용하여 양조되었지만, 당신의 수프 컵이 아니라면 화가 나지 마십시오. 그것은 최고의 크림이 아닐 수 있으며 약간 미친 것일 수 있습니다. 그래서 소금 한 줌을 가지고 그것에 대해 너무 화가 나지 마십시오!"; }
            else if (language === "japanese") { return "この画像は人工知能を使用して醸造されましたが、あなたのスープカップがそれほどではない場合は蒸気を上げないでください。それは最高のクリームである可能性があり、少しナッツかもしれません。だからそれを少し塩と一緒に取り、それについてあまりにもチーズを取らないでください！"; }
            else if (language === "french") { return "Cette image a été brassée à l'aide d'une intelligence artificielle, mais ne vous énervez pas si ce n'est pas tout à fait votre tasse de bouillon. Ce n'est peut-être pas la crème de la crème et pourrait être un peu fou. Alors prenez-le avec une pincée de sel et ne vous fâchez pas trop à ce sujet !"; }
            else if (language === "spanish") { return "Esta imagen fue elaborada con inteligencia artificial, pero no se enoje si no es exactamente su taza de caldo. Puede que no sea la crema de la cosecha y podría ser un poco loco. Así que tómalo con una pizca de sal y no te enojes demasiado por eso!"; }
            else if (language === "german") { return "Dieses Bild wurde mit künstlicher Intelligenz gebraut, aber ärgern Sie sich nicht, wenn es nicht ganz Ihre Tasse Brühe ist. Es könnte nicht die Sahne der Ernte sein und könnte ein bisschen verrückt sein. Also nehmen Sie es mit einer Prise Salz und ärgern Sie sich nicht zu sehr darüber!"; }
            else if (language === "italian") { return "Questa immagine è stata preparata con l'intelligenza artificiale, ma non arrabbiarti se non è esattamente la tua tazza di brodo. Potrebbe non essere la crema del raccolto e potrebbe essere un po' pazzo. Quindi prendilo con un pizzico di sale e non arrabbiarti troppo per questo!"; }
        }

        if (text === "Ingredients") {
            if (language === "english") { return "Ingredients"; }
            else if (language === "vietnamese") { return "Nguyên liệu"; }
            else if (language === "chinese") { return "成分"; }
            else if (language === "korean") { return "성분"; }
            else if (language === "japanese") { return "材料"; }
            else if (language === "french") { return "Ingrédients"; }
            else if (language === "spanish") { return "Ingredientes"; }
            else if (language === "german") { return "Zutaten"; }
            else if (language === "italian") { return "Ingredienti"; }
        }

        if (text === "Servings") {
            if (language === "english") { return "Servings"; }
            else if (language === "vietnamese") { return "Phần"; }
            else if (language === "chinese") { return "份"; }
            else if (language === "korean") { return "서빙"; }
            else if (language === "japanese") { return "サービング"; }
            else if (language === "french") { return "Portions"; }
            else if (language === "spanish") { return "Porciones"; }
            else if (language === "german") { return "Portionen"; }
            else if (language === "italian") { return "Porzioni"; }
        }

        if (text === "Add to Basket") {
            if (language === "english") { return "Add to Basket"; }
            else if (language === "vietnamese") { return "Thêm vào giỏ hàng"; }
            else if (language === "chinese") { return "添加到购物篮"; }
            else if (language === "korean") { return "바구니에 추가"; }
            else if (language === "japanese") { return "バスケットに追加"; }
            else if (language === "french") { return "Ajouter au panier"; }
            else if (language === "spanish") { return "Añadir a la cesta"; }
            else if (language === "german") { return "In den Warenkorb legen"; }
            else if (language === "italian") { return "Aggiungi al carrello"; }
        }

        if (text === "Ingredients hint") {
            if (language === "english") { return "Helpful suggestion: Confirm you have all ingredients for this recipe by clicking each one."; }
            else if (language === "vietnamese") { return "Gợi ý hữu ích: Xác nhận bạn có tất cả các nguyên liệu cho công thức này bằng cách nhấp vào mỗi nguyên liệu."; }
            else if (language === "chinese") { return "有用的建议：通过单击每个原料来确认您是否拥有该食谱的所有原料。"; }
            else if (language === "korean") { return "도움이되는 제안 : 각 재료를 클릭하여이 레시피에 필요한 모든 재료가 있는지 확인하십시오."; }
            else if (language === "japanese") { return "役立つ提案：各材料をクリックして、このレシピに必要なすべての材料を確認してください。"; }
            else if (language === "french") { return "Suggestion utile : Confirmez que vous avez tous les ingrédients pour cette recette en cliquant sur chacun d'entre eux."; }
            else if (language === "spanish") { return "Sugerencia útil: Confirme que tiene todos los ingredientes para esta receta haciendo clic en cada uno de ellos."; }
            else if (language === "german") { return "Hilfreicher Vorschlag: Bestätigen Sie, dass Sie alle Zutaten für dieses Rezept haben, indem Sie auf jede Zutat klicken."; }
            else if (language === "italian") { return "Suggerimento utile: conferma di avere tutti gli ingredienti per questa ricetta facendo clic su ciascuno di essi."; }
        }

        if (text === "Instructions") {
            if (language === "english") { return "Method"; }
            else if (language === "vietnamese") { return "Cách làm"; }
            else if (language === "chinese") { return "方法"; }
            else if (language === "korean") { return "방법"; }
            else if (language === "japanese") { return "方法"; }
            else if (language === "french") { return "Méthode"; }
            else if (language === "spanish") { return "Método"; }
            else if (language === "german") { return "Methode"; }
            else if (language === "italian") { return "Metodo"; }
        }

        if (text === "Minutes") {
            if (language === "english") { return "Minutes"; }
            else if (language === "vietnamese") { return "Phút"; }
            else if (language === "chinese") { return "分钟"; }
            else if (language === "korean") { return "분"; }
            else if (language === "japanese") { return "分"; }
            else if (language === "french") { return "Minutes"; }
            else if (language === "spanish") { return "Minutos"; }
            else if (language === "german") { return "Protokoll"; }
            else if (language === "italian") { return "Minuti"; }
        }

        if (text === "Step") {
            if (language === "english") { return "Step"; }
            else if (language === "vietnamese") { return "Bước"; }
            else if (language === "chinese") { return "步骤"; }
            else if (language === "korean") { return "단계"; }
            else if (language === "japanese") { return "ステップ"; }
            else if (language === "french") { return "Étape"; }
            else if (language === "spanish") { return "Paso"; }
            else if (language === "german") { return "Schritt"; }
            else if (language === "italian") { return "Passo"; }
        }

        if (text === "Instructions hint") {
            if (language === "english") { return "Helpful suggestion: While you're cooking, mark each step as done by tapping it."; }
            else if (language === "vietnamese") { return "Gợi ý hữu ích: Trong khi bạn đang nấu ăn, đánh dấu mỗi bước làm xong bằng cách nhấp vào nó."; }
            else if (language === "chinese") { return "有用的建议：在烹饪时，通过点击每个步骤来标记每个步骤。"; }
            else if (language === "korean") { return "도움이되는 제안 : 요리하는 동안 각 단계를 탭하여 완료로 표시하십시오."; }
            else if (language === "japanese") { return "役立つ提案：調理中に、それぞれのステップをタップして完了としてマークします。"; }
            else if (language === "french") { return "Suggestion utile : Pendant que vous cuisinez, marquez chaque étape comme terminée en la touchant."; }
            else if (language === "spanish") { return "Sugerencia útil: mientras cocina, marque cada paso como hecho tocándolo."; }
            else if (language === "german") { return "Hilfreicher Vorschlag: Markieren Sie während des Kochens jeden Schritt als erledigt, indem Sie ihn antippen."; }
        }

        if (text === "Nutritional Information") {
            if (language === "english") { return "Nutritional Information"; }
            else if (language === "vietnamese") { return "Thông tin dinh dưỡng"; }
            else if (language === "chinese") { return "营养信息"; }
            else if (language === "korean") { return "영양 정보"; }
            else if (language === "japanese") { return "栄養情報"; }
            else if (language === "french") { return "Informations nutritionnelles"; }
            else if (language === "spanish") { return "Información nutricional"; }
            else if (language === "german") { return "Nährwertangaben"; }
            else if (language === "italian") { return "Informazioni nutrizionali"; }
        }

        if (text === "Per Serving") {
            if (language === "english") { return "Per Serving"; }
            else if (language === "vietnamese") { return "Mỗi phần"; }
            else if (language === "chinese") { return "每份"; }
            else if (language === "korean") { return "서빙 당"; }
            else if (language === "japanese") { return "サービングあたり"; }
            else if (language === "french") { return "Par portion"; }
            else if (language === "spanish") { return "Por porción"; }
            else if (language === "german") { return "Pro Portion"; }
            else if (language === "italian") { return "Per porzione"; }
        }

        if (text === "Calories") {
            if (language === "english") { return "Calories"; }
            else if (language === "vietnamese") { return "Calo"; }
            else if (language === "chinese") { return "卡路里"; }
            else if (language === "korean") { return "열량"; }
            else if (language === "japanese") { return "カロリー"; }
            else if (language === "french") { return "Calories"; }
            else if (language === "spanish") { return "Calorías"; }
            else if (language === "german") { return "Kalorien"; }
            else if (language === "italian") { return "Calorie"; }
        }

        if (text === "Carbohydrates") {
            if (language === "english") { return "Carbs"; }
            else if (language === "vietnamese") { return "Tinh bột"; }
            else if (language === "chinese") { return "碳水化合物"; }
            else if (language === "korean") { return "탄수화물"; }
            else if (language === "japanese") { return "炭水化物"; }
            else if (language === "french") { return "Glucides"; }
            else if (language === "spanish") { return "Carbohidratos"; }
            else if (language === "german") { return "Kohlenhydrate"; }
            else if (language === "italian") { return "Carboidrati"; }
        }

        if (text === "Protein") {
            if (language === "english") { return "Protein"; }
            else if (language === "vietnamese") { return "Chất đạm"; }
            else if (language === "chinese") { return "蛋白质"; }
            else if (language === "korean") { return "단백질"; }
            else if (language === "japanese") { return "タンパク質"; }
            else if (language === "french") { return "Protéine"; }
            else if (language === "spanish") { return "Proteína"; }
            else if (language === "german") { return "Protein"; }
            else if (language === "italian") { return "Proteina"; }
        }

        if (text === "Fats") {
            if (language === "english") { return "Fats"; }
            else if (language === "vietnamese") { return "Chất béo"; }
            else if (language === "chinese") { return "脂肪"; }
            else if (language === "korean") { return "지방"; }
            else if (language === "japanese") { return "脂肪"; }
            else if (language === "french") { return "Graisses"; }
            else if (language === "spanish") { return "Grasas"; }
            else if (language === "german") { return "Fette"; }
            else if (language === "italian") { return "Grassi"; }
        }

        if (text === "Sugars") {
            if (language === "english") { return "Sugars"; }
            else if (language === "vietnamese") { return "Đường"; }
            else if (language === "chinese") { return "糖"; }
            else if (language === "korean") { return "설탕"; }
            else if (language === "japanese") { return "砂糖"; }
            else if (language === "french") { return "Sucres"; }
            else if (language === "spanish") { return "Azúcares"; }
            else if (language === "german") { return "Zucker"; }
            else if (language === "italian") { return "Zuccheri"; }
        }

        if (text === "Fibre") {
            if (language === "english") { return "Fibre"; }
            else if (language === "vietnamese") { return "Chất xơ"; }
            else if (language === "chinese") { return "纤维"; }
            else if (language === "korean") { return "섬유질"; }
            else if (language === "japanese") { return "繊維"; }
            else if (language === "french") { return "Fibres"; }
            else if (language === "spanish") { return "Fibra"; }
            else if (language === "german") { return "Faser"; }
            else if (language === "italian") { return "Fibre"; }
        }

        if (text === "Total Fat") {
            if (language === "english") { return "Total Fat"; }
            else if (language === "vietnamese") { return "Tổng chất béo"; }
            else if (language === "chinese") { return "总脂肪"; }
            else if (language === "korean") { return "총 지방"; }
            else if (language === "japanese") { return "脂肪総量"; }
            else if (language === "french") { return "Graisses totales"; }
            else if (language === "spanish") { return "Grasas totales"; }
            else if (language === "german") { return "Gesamtfett"; }
            else if (language === "italian") { return "Grassi totali"; }
        }

        if (text === "Saturated Fat") {
            if (language === "english") { return "Saturated Fat"; }
            else if (language === "vietnamese") { return "Chất béo bão hòa"; }
            else if (language === "chinese") { return "饱和脂肪"; }
            else if (language === "korean") { return "포화 지방"; }
            else if (language === "japanese") { return "飽和脂肪酸"; }
            else if (language === "french") { return "Graisses saturées"; }
            else if (language === "spanish") { return "Grasas saturadas"; }
            else if (language === "german") { return "Gesättigte"; }
            else if (language === "italian") { return "Grassi saturi"; }
        }

        if (text === "Trans Fat") {
            if (language === "english") { return "Trans Fat"; }
            else if (language === "vietnamese") { return "Chất béo chuyển hóa"; }
            else if (language === "chinese") { return "反式脂肪"; }
            else if (language === "korean") { return "트랜스 지방"; }
            else if (language === "japanese") { return "トランス脂肪酸"; }
            else if (language === "french") { return "Trans Gras"; }
            else if (language === "spanish") { return "Trans Grasa"; }
            else if (language === "german") { return "Trans Fett"; }
            else if (language === "italian") { return "Trans Grasso"; }
        }

        if (text === "Monounsaturated Fat") {
            if (language === "english") { return "Monounsaturated Fat"; }
            else if (language === "vietnamese") { return "Chất béo không bão hòa đơn"; }
            else if (language === "chinese") { return "单不饱和脂肪"; }
            else if (language === "korean") { return "단일 불포화 지방"; }
            else if (language === "japanese") { return "一価不飽和脂肪酸"; }
            else if (language === "french") { return "Graisses mono-insaturées"; }
            else if (language === "spanish") { return "Grasas monoinsaturadas"; }
            else if (language === "german") { return "Einfach ungesättigte"; }
            else if (language === "italian") { return "Grassi monoinsaturi"; }
        }

        if (text === "Polyunsaturated Fat") {
            if (language === "english") { return "Polyunsaturated Fat"; }
            else if (language === "vietnamese") { return "Chất béo không bão hòa đa"; }
            else if (language === "chinese") { return "多不饱和脂肪"; }
            else if (language === "korean") { return "다중 불포화 지방"; }
            else if (language === "japanese") { return "多価不飽和脂肪酸"; }
            else if (language === "french") { return "Graisses polyinsaturées"; }
            else if (language === "spanish") { return "Grasas poliinsaturadas"; }
            else if (language === "german") { return "Mehrfach ungesättigte"; }
            else if (language === "italian") { return "Grassi polinsaturi"; }
        }

        if (text === "Sodium") {
            if (language === "english") { return "Sodium"; }
            else if (language === "vietnamese") { return "Muối"; }
            else if (language === "chinese") { return "钠"; }
            else if (language === "korean") { return "나트륨"; }
            else if (language === "japanese") { return "ナトリウム"; }
            else if (language === "french") { return "Sodium"; }
            else if (language === "spanish") { return "Sodio"; }
            else if (language === "german") { return "Natrium"; }
            else if (language === "italian") { return "Sodio"; }
        }

        if (text === "Cholesterol") {
            if (language === "english") { return "Cholesterol"; }
            else if (language === "vietnamese") { return "Cholesterol"; }
            else if (language === "chinese") { return "胆固醇"; }
            else if (language === "korean") { return "콜레스테롤"; }
            else if (language === "japanese") { return "コレステロール"; }
            else if (language === "french") { return "Cholestérol"; }
            else if (language === "spanish") { return "Colesterol"; }
            else if (language === "german") { return "Cholesterin"; }
            else if (language === "italian") { return "Colesterolo"; }
        }

        if (text === "Alcohol") {
            if (language === "english") { return "Alcohol"; }
            else if (language === "vietnamese") { return "Cồn"; }
            else if (language === "chinese") { return "酒精"; }
            else if (language === "korean") { return "알코올"; }
            else if (language === "japanese") { return "アルコール"; }
            else if (language === "french") { return "Alcool"; }
            else if (language === "spanish") { return "Alcohol"; }
            else if (language === "german") { return "Alkohol"; }
            else if (language === "italian") { return "Alcol"; }
        }

        if (text === "Minerals") {
            if (language === "english") { return "Minerals"; }
            else if (language === "vietnamese") { return "Khoáng chất"; }
            else if (language === "chinese") { return "矿物"; }
            else if (language === "korean") { return "미네랄"; }
            else if (language === "japanese") { return "ミネラル"; }
            else if (language === "french") { return "Minéraux"; }
            else if (language === "spanish") { return "Minerales"; }
            else if (language === "german") { return "Mineralien"; }
            else if (language === "italian") { return "Minerali"; }
        }

        if (text === "Calcium") {
            if (language === "english") { return "Calcium"; }
            else if (language === "vietnamese") { return "Canxi"; }
            else if (language === "chinese") { return "钙"; }
            else if (language === "korean") { return "칼슘"; }
            else if (language === "japanese") { return "カルシウム"; }
            else if (language === "french") { return "Calcium"; }
            else if (language === "spanish") { return "Calcio"; }
            else if (language === "german") { return "Kalzium"; }
            else if (language === "italian") { return "Calcio"; }
        }

        if (text === "Iron") {
            if (language === "english") { return "Iron"; }
            else if (language === "vietnamese") { return "Sắt"; }
            else if (language === "chinese") { return "铁"; }
            else if (language === "korean") { return "철"; }
            else if (language === "japanese") { return "鉄"; }
            else if (language === "french") { return "Fer"; }
            else if (language === "spanish") { return "Hierro"; }
            else if (language === "german") { return "Eisen"; }
            else if (language === "italian") { return "Ferro"; }
        }

        if (text === "Magnesium") {
            if (language === "english") { return "Magnesium"; }
            else if (language === "vietnamese") { return "Magiê"; }
            else if (language === "chinese") { return "镁"; }
            else if (language === "korean") { return "마그네슘"; }
            else if (language === "japanese") { return "マグネシウム"; }
            else if (language === "french") { return "Magnésium"; }
            else if (language === "spanish") { return "Magnesio"; }
            else if (language === "german") { return "Magnesium"; }
            else if (language === "italian") { return "Magnesio"; }
        }

        if (text === "Zinc") {
            if (language === "english") { return "Zinc"; }
            else if (language === "vietnamese") { return "Kẽm"; }
            else if (language === "chinese") { return "锌"; }
            else if (language === "korean") { return "아연"; }
            else if (language === "japanese") { return "亜鉛"; }
            else if (language === "french") { return "Zinc"; }
            else if (language === "spanish") { return "Zinc"; }
            else if (language === "german") { return "Zink"; }
            else if (language === "italian") { return "Zinco"; }
        }

        if (text === "Potassium") {
            if (language === "english") { return "Potassium"; }
            else if (language === "vietnamese") { return "Kali"; }
            else if (language === "chinese") { return "钾"; }
            else if (language === "korean") { return "칼륨"; }
            else if (language === "japanese") { return "カリウム"; }
            else if (language === "french") { return "Potassium"; }
            else if (language === "spanish") { return "Potasio"; }
            else if (language === "german") { return "Kalium"; }
            else if (language === "italian") { return "Potassio"; }
        }

        if (text === "Show full nutrition information") {
            if (language === "english") { return "Show full nutrition information"; }
            else if (language === "vietnamese") { return "Hiển thị thông tin dinh dưỡng đầy đủ"; }
            else if (language === "chinese") { return "显示完整的营养信息"; }
            else if (language === "korean") { return "전체 영양 정보 표시"; }
            else if (language === "japanese") { return "完全な栄養情報を表示"; }
            else if (language === "french") { return "Afficher les informations nutritionnelles complètes"; }
            else if (language === "spanish") { return "Mostrar información nutricional completa"; }
            else if (language === "german") { return "Vollständige Nährwertangaben anzeigen"; }
            else if (language === "italian") { return "Mostra informazioni nutrizionali complete"; }
        }

        if (text === "Hide full nutrition information") {
            if (language === "english") { return "Hide full nutrition information"; }
            else if (language === "vietnamese") { return "Ẩn thông tin dinh dưỡng đầy đủ"; }
            else if (language === "chinese") { return "隐藏完整的营养信息"; }
            else if (language === "korean") { return "전체 영양 정보 숨기기"; }
            else if (language === "japanese") { return "完全な栄養情報を非表示にする"; }
            else if (language === "french") { return "Masquer les informations nutritionnelles complètes"; }
            else if (language === "spanish") { return "Ocultar información nutricional completa"; }
            else if (language === "german") { return "Vollständige Nährwertangaben ausblenden"; }
            else if (language === "italian") { return "Nascondi informazioni nutrizionali complete"; }
        }

        if (text === "Nutrient") {
            if (language === "english") { return "Nutrient"; }
            else if (language === "vietnamese") { return "Chất dinh dưỡng"; }
            else if (language === "chinese") { return "营养素"; }
            else if (language === "korean") { return "영양소"; }
            else if (language === "japanese") { return "栄養素"; }
            else if (language === "french") { return "Nutriment"; }
            else if (language === "spanish") { return "Nutriente"; }
            else if (language === "german") { return "Nährstoff"; }
            else if (language === "italian") { return "Nutriente"; }
        }

        if (text === "Value") {
            if (language === "english") { return "Value"; }
            else if (language === "vietnamese") { return "Giá trị"; }
            else if (language === "chinese") { return "值"; }
            else if (language === "korean") { return "값"; }
            else if (language === "japanese") { return "値"; }
            else if (language === "french") { return "Valeur"; }
            else if (language === "spanish") { return "Valor"; }
            else if (language === "german") { return "Wert"; }
            else if (language === "italian") { return "Valore"; }
        }

        if (text === "nutrient disclaimer") {
            if (language === "english") { return (<><span style={{ fontWeight: '500' }}>Please read: </span>The nutritional information provided for this recipe has been calculated by aggregating the nutrition values of each ingredient. The nutrition values for each ingredient are sourced from the U.S. Department of Agriculture, Agricultural Research Service FoodData Central, 2019. <a href="https://fdc.nal.usda.gov/" tagert="_blank">fdc.nal.usda.gov</a>. Please note that the actual nutrition value may vary depending on the variations of the ingredients, the specific types or brands and the cooking methods applied to the ingredients. Therefore, please use this information as an approximation only. For any dietary requirements, please consult your healthcare professionals.</>) }
            else if (language === "vietnamese") { return (<><span style={{ fontWeight: '500' }}>Vui lòng đọc: </span>Thông tin dinh dưỡng được cung cấp cho công thức này đã được tính toán bằng cách tổng hợp các giá trị dinh dưỡng của từng thành phần. Các giá trị dinh dưỡng cho từng thành phần được lấy từ Bộ Nông nghiệp Hoa Kỳ, Dịch vụ Nghiên cứu Nông nghiệp FoodData Central, 2019. Xin lưu ý rằng giá trị dinh dưỡng thực tế có thể khác nhau tùy thuộc vào các biến thể của thành phần, loại hoặc nhãn hiệu cụ thể và phương pháp nấu ăn được áp dụng đến các thành phần. Do đó, vui lòng chỉ sử dụng thông tin này dưới dạng gần đúng. Đối với bất kỳ yêu cầu chế độ ăn uống, xin vui lòng tham khảo ý kiến ​​các chuyên gia chăm sóc sức khỏe của bạn.</>) }
            else if (language === "chinese") { return (<><span style={{ fontWeight: '500' }}>请阅读： </span>为此食谱提供的营养信息是通过汇总每种成分的营养价值来计算的。每种成分的营养价值均来自美国农业部，农业研究服务部FoodData Central，2019年。请注意，实际的营养价值可能会因成分的变化，特定类型或品牌以及应用于成分的烹饪方法而有所不同。因此，请仅将此信息用作近似值。对于任何饮食要求，请咨询您的医疗保健专业人员。</>) }
            else if (language === "korean") { return (<><span style={{ fontWeight: '500' }}>읽어주세요: </span>이 레시피에 대한 영양 정보는 각 재료의 영양 가치를 집계하여 계산되었습니다. 각 재료의 영양 가치는 미국 농무부 농업 연구 서비스 FoodData Central, 2019에서 가져온 것입니다. 실제 영양 가치는 재료의 변형, 특정 유형 또는 브랜드 및 재료에 적용 된 조리 방법에 따라 다를 수 있습니다. 따라서이 정보는 근사치로만 사용하십시오. 모든 식이 요구 사항에 대해서는 건강 관리 전문가와 상담하십시오.</>) }
            else if (language === "japanese") { return (<><span style={{ fontWeight: '500' }}>お読みください： </span>このレシピの栄養情報は、各成分の栄養価を集計して計算されています。各成分の栄養価は、米国農務省、農業研究局FoodData Central、2019から取得されています。実際の栄養価は、成分の変化、特定のタイプまたはブランド、および成分に適用される調理方法によって異なる場合があります。したがって、この情報は概算としてのみ使用してください。すべての食事要件については、医療専門家に相談してください。</>) }
            else if (language === "french") { return (<><span style={{ fontWeight: '500' }}>Veuillez lire: </span>Les informations nutritionnelles fournies pour cette recette ont été calculées en agrégeant les valeurs nutritionnelles de chaque ingrédient. Les valeurs nutritionnelles de chaque ingrédient proviennent du FoodData Central du Service de recherche agricole du Département de l'agriculture des États-Unis, 2019. Veuillez noter que la valeur nutritionnelle réelle peut varier en fonction des variations des ingrédients, des types ou marques spécifiques et des méthodes de cuisson appliquées aux ingrédients. Par conséquent, veuillez utiliser ces informations à titre indicatif uniquement. Pour tout régime alimentaire, veuillez consulter vos professionnels de la santé.</>) }
            else if (language === "spanish") { return (<><span style={{ fontWeight: '500' }}>Por favor lea: </span>La información nutricional proporcionada para esta receta se calculó agregando los valores nutricionales de cada ingrediente. Los valores nutricionales de cada ingrediente provienen del FoodData Central del Servicio de Investigación Agrícola del Departamento de Agricultura de los Estados Unidos, 2019. Tenga en cuenta que el valor nutricional real puede variar según las variaciones de los ingredientes, los tipos o marcas específicos y los métodos de cocción aplicados a los ingredientes. Por lo tanto, utilice esta información solo como guía. Para cualquier requisito dietético, consulte a sus profesionales de la salud.</>) }
            else if (language === "german") { return (<><span style={{ fontWeight: '500' }}>Bitte lesen: </span>Die für dieses Rezept bereitgestellten Nährwertangaben wurden berechnet, indem die Nährwerte jeder Zutat zusammengefasst wurden. Die Nährwerte jeder Zutat stammen aus dem FoodData Central des Agricultural Research Service des US-Landwirtschaftsministeriums, 2019. Bitte beachten Sie, dass der tatsächliche Nährwert je nach Variationen der Zutaten, bestimmten Arten oder Marken und den auf die Zutaten angewendeten Kochmethoden variieren kann. Verwenden Sie diese Informationen daher nur als Richtlinie. Für alle Ernährungsbedürfnisse wenden Sie sich bitte an Ihre Gesundheitsfachkräfte.</>) }
            else if (language === "italian") { return (<><span style={{ fontWeight: '500' }}>Si prega di leggere: </span>Le informazioni nutrizionali fornite per questa ricetta sono state calcolate aggregando i valori nutrizionali di ogni ingrediente. I valori nutrizionali di ogni ingrediente provengono dal FoodData Central del Servizio di ricerca agricola del Dipartimento dell'Agricoltura degli Stati Uniti, 2019. Si prega di notare che il valore nutrizionale effettivo può variare in base alle variazioni degli ingredienti, ai tipi o marchi specifici e ai metodi di cottura applicati agli ingredienti. Pertanto, utilizzare queste informazioni solo come guida. Per qualsiasi requisito dietetico, consultare i propri professionisti sanitari.</>) }
        }

        if (text === "nutrient disclaimer rdi") {
            if (language === "english") { return (<>The <span style={{ fontWeight: '500' }}>recommended daily intake (RDI)</span> measurements provided are based on an adult requiring approximately 2000 calories per day. These are general guidelines and should not be considered personalised medical or nutritional advice. Individual needs for daily intake may vary depending on factors such as age, sex, weight, height, activity level, and overall health. The suggested intake amounts are estimates and should be used as a starting point rather than definitive targets. It is important to consult with a qualified healthcare professional or registered dietitian for personalised advice based on your specific needs and health conditions.</>) }
            else if (language === "vietnamese") { return (<>Các phép đo lượng khuyến nghị hàng ngày (RDI) được cung cấp dựa trên một người trưởng thành cần khoảng 2000 calo mỗi ngày. Đây là những hướng dẫn chung và không nên được coi là lời khuyên y tế hoặc dinh dưỡng cá nhân. Nhu cầu cá nhân về lượng tiêu thụ hàng ngày có thể khác nhau tùy thuộc vào các yếu tố như tuổi tác, giới tính, cân nặng, chiều cao, mức độ hoạt động và sức khỏe tổng thể. Lượng tiêu thụ được đề xuất là ước tính và nên được sử dụng làm điểm bắt đầu thay vì các mục tiêu cuối cùng. Điều quan trọng là phải tham khảo ý kiến ​​của chuyên gia chăm sóc sức khỏe có trình độ hoặc chuyên gia dinh dưỡng đã đăng ký để được tư vấn cá nhân hóa dựa trên nhu cầu cụ thể và tình trạng sức khỏe của bạn.</>) }
            else if (language === "chinese") { return (<>提供的推荐每日摄入量（RDI）测量是基于成年人每天需要约2000卡路里。这些是一般准则，不应被视为个性化的医疗或营养建议。每日摄入量的个人需求可能会因年龄，性别，体重，身高，活动水平和整体健康等因素而有所不同。建议的摄入量是估计值，应该用作起点而不是明确的目标。重要的是要咨询合格的医疗保健专业人员或注册营养师，以根据您的特定需求和健康状况获得个性化建议。</>) }
            else if (language === "korean") { return (<>제공되는 권장 일일 섭취량 (RDI) 측정은 성인이 하루에 약 2000 칼로리가 필요한 것을 기준으로합니다. 이것들은 일반적인 지침이며 맞춤형 의료 또는 영양 조언으로 간주되어서는 안됩니다. 일일 섭취량에 대한 개별 요구 사항은 연령, 성별, 체중, 신장, 활동 수준 및 전반적인 건강과 같은 요소에 따라 다를 수 있습니다. 제안된 섭취량은 추정치이며 명확한 목표가 아닌 시작점으로 사용해야합니다. 특정 요구 사항과 건강 상태에 따라 맞춤형 조언을 받으려면 자격을 갖춘 의료 전문가 또는 등록 영양사와 상담하는 것이 중요합니다.</>) }
            else if (language === "japanese") { return (<>提供される1日当たりの推奨摂取量（RDI）の測定値は、1日に約2000カロリーの成人を基準としています。これらは一般的なガイドラインであり、個人の医療または栄養アドバイスと見なすべきではありません。1日当たりの摂取量の個人のニーズは、年齢、性別、体重、身長、活動レベル、および全体的な健康状態などの要因によって異なる場合があります。推奨される摂取量は推定値であり、決定的な目標ではなく、出発点として使用する必要があります。特定のニーズと健康状態に基づいて個別化されたアドバイスを受けるには、資格のある医療専門家または登録栄養士に相談することが重要です。</>) }
            else if (language === "french") { return (<>Les mesures recommandées de l'apport quotidien recommandé (RDI) sont basées sur un adulte ayant besoin d'environ 2000 calories par jour. Ceux-ci sont des directives générales et ne doivent pas être considérés comme des conseils médicaux ou nutritionnels personnalisés. Les besoins individuels en apport quotidien peuvent varier en fonction de facteurs tels que l'âge, le sexe, le poids, la taille, le niveau d'activité et l'état de santé général. L'apport recommandé est une estimation et doit être utilisé comme point de départ plutôt que comme objectif précis. Il est important de consulter un professionnel de la santé qualifié ou un diététiste agréé pour obtenir des conseils personnalisés en fonction de vos besoins spécifiques et de votre état de santé.</>) }
            else if (language === "spanish") { return (<>Las medidas recomendadas de la ingesta diaria recomendada (RDI) se basan en un adulto que necesita aproximadamente 2000 calorías por día. Estas son pautas generales y no deben considerarse como consejos médicos o nutricionales personalizados. Las necesidades individuales de ingesta diaria pueden variar según factores como la edad, el sexo, el peso, la altura, el nivel de actividad y el estado de salud general. La ingesta recomendada es una estimación y debe usarse como punto de partida en lugar de como objetivo específico. Es importante consultar a un profesional de la salud calificado o un dietista registrado para obtener consejos personalizados según sus necesidades específicas y su estado de salud.</>) }
            else if (language === "german") { return (<>Die empfohlenen Maßeinheiten für die empfohlene Tagesdosis (RDI) basieren auf einem Erwachsenen, der etwa 2000 Kalorien pro Tag benötigt. Dies sind allgemeine Richtlinien und sollten nicht als individuelle medizinische oder ernährungsphysiologische Beratung angesehen werden. Der individuelle Bedarf an täglicher Zufuhr kann je nach Faktoren wie Alter, Geschlecht, Gewicht, Größe, Aktivitätsniveau und allgemeinem Gesundheitszustand variieren. Die empfohlene Zufuhr ist eine Schätzung und sollte als Ausgangspunkt und nicht als genaues Ziel verwendet werden. Es ist wichtig, einen qualifizierten Gesundheitsdienstleister oder einen registrierten Ernährungsberater zu konsultieren, um individuelle Beratung entsprechend Ihrer spezifischen Bedürfnisse und Ihres Gesundheitszustands zu erhalten.</>) }
            else if (language === "italian") { return (<>Le misure raccomandate dell'apporto giornaliero raccomandato (RDI) si basano su un adulto che ha bisogno di circa 2000 calorie al giorno. Queste sono linee guida generali e non devono essere considerate come consigli medici o nutrizionali personalizzati. I bisogni individuali di apporto giornaliero possono variare a seconda di fattori come età, sesso, peso, altezza, livello di attività e stato di salute generale. L'apporto raccomandato è una stima e deve essere utilizzato come punto di partenza anziché come obiettivo specifico. È importante consultare un professionista sanitario qualificato o un dietista registrato per ottenere consigli personalizzati in base alle proprie esigenze specifiche e al proprio stato di salute.</>) }
        }
        if (text === "nutrition disclaimer ingredients matched") {
            if (language === "english") { return (<>The ingredients in this recipe are matched to the USDA FoodData SR Legacy Database's description (FDC ID)</>) }
            else if (language === "vietnamese") { return (<>Các thành phần trong công thức này phù hợp với mô tả của Cơ sở dữ liệu USDA FoodData SR (ID FDC)</>) }
            else if (language === "chinese") { return (<>此食谱中的成分与美国农业部FoodData SR遗留数据库的描述（FDC ID）匹配</>) }
            else if (language === "korean") { return (<>이 레시피의 재료는 미국 농무부 FoodData SR 레거시 데이터베이스의 설명 (FDC ID)에 맞춰져 있습니다.</>) }
            else if (language === "japanese") { return (<>このレシピの材料は、米国農務省FoodData SRレガシーデータベースの説明（FDC ID）に一致しています。</>) }
            else if (language === "french") { return (<>Les ingrédients de cette recette correspondent à la description de la base de données héritée USDA FoodData SR (ID FDC)</>) }
            else if (language === "spanish") { return (<>Los ingredientes de esta receta coinciden con la descripción de la base de datos heredada de USDA FoodData SR (ID FDC)</>) }
            else if (language === "german") { return (<>Die Zutaten in diesem Rezept entsprechen der Beschreibung der USDA FoodData SR Legacy-Datenbank (FDC ID)</>) }
            else if (language === "italian") { return (<>Gli ingredienti di questa ricetta corrispondono alla descrizione del database ereditato USDA FoodData SR (ID FDC)</>) }
        }
        if (text === "Rate this recipe") {
            if (language === "english") { return "Rate this recipe"; }
            else if (language === "vietnamese") { return "Đánh giá công thức này"; }
            else if (language === "chinese") { return "评价这个食谱"; }
            else if (language === "korean") { return "이 레시피 평가"; }
            else if (language === "japanese") { return "このレシピを評価する"; }
            else if (language === "french") { return "Évaluez cette recette"; }
            else if (language === "spanish") { return "Califica esta receta"; }
            else if (language === "german") { return "Bewerten Sie dieses Rezept"; }
            else if (language === "italian") { return "Valuta questa ricetta"; }
        }

        if (text === "Similar Recipes") {
            if (language === "english") { return "Similar Recipes"; }
            else if (language === "vietnamese") { return "Công thức tương tự"; }
            else if (language === "chinese") { return "类似的食谱"; }
            else if (language === "korean") { return "비슷한 레시피"; }
            else if (language === "japanese") { return "似たようなレシピ"; }
            else if (language === "french") { return "Recettes similaires"; }
            else if (language === "spanish") { return "Recetas similares"; }
            else if (language === "german") { return "Ähnliche Rezepte"; }
            else if (language === "italian") { return "Ricette simili"; }
        }
    }

    // FOOTER

    if (section === 'footer') {
        if (text === "share feedback") {
            if (language === "english") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>Share your feedback!</span></a> We would love to hear from you. {window.innerWidth < 768 ? <br /> : null} Please send us your feedback, suggestions and comments.</p></>) }
            else if (language === "vietnamese") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>Chia sẻ phản hồi của bạn!</span></a> Chúng tôi rất mong nhận được phản hồi từ bạn. Vui lòng gửi cho chúng tôi phản hồi, đề xuất và nhận xét của bạn.</p></>) }
            else if (language === "chinese") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>分享您的反馈！</span></a> 我们很乐意收到您的来信。 请向我们发送您的反馈，建议和评论。</p></>) }
            else if (language === "korean") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>피드백을 공유하십시오!</span></a> 우리는 당신의 소식을 듣고 싶어합니다. 피드백, 제안 및 의견을 보내주십시오.</p></>) }
            else if (language === "japanese") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>フィードバックを共有する！</span></a> あなたからのご意見をお聞かせください。 フィードバック、提案、コメントをお送りください。</p></>) }
            else if (language === "french") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>Partagez vos commentaires !</span></a> Nous aimerions avoir de vos nouvelles. Veuillez nous envoyer vos commentaires, suggestions et commentaires.</p></>) }
            else if (language === "spanish") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>¡Comparte tus comentarios!</span></a> Nos encantaría saber de ti. Envíenos sus comentarios, sugerencias y comentarios.</p></>) }
            else if (language === "german") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>Teilen Sie Ihr Feedback!</span></a> Wir würden gerne von Ihnen hören. Bitte senden Sie uns Ihr Feedback, Ihre Vorschläge und Kommentare.</p></>) }
            else if (language === "italian") { return (<><p className="footer_feedback_text"><a className="footer_feedback_link" href="/feedback"><span>Condividi il tuo feedback!</span></a> Ci piacerebbe sentirti. Invia i tuoi feedback, suggerimenti e commenti.</p></>) }
        }
        if (text === "language") {
            if (language === "english") { return "Language"; }
            else if (language === "vietnamese") { return "Ngôn ngữ"; }
            else if (language === "chinese") { return "语言"; }
            else if (language === "korean") { return "언어"; }
            else if (language === "japanese") { return "言語"; }
            else if (language === "french") { return "Langue"; }
            else if (language === "spanish") { return "Idioma"; }
            else if (language === "german") { return "Sprache"; }
            else if (language === "italian") { return "Linguaggio"; }
        }
        if (text === "cookies policy") {
            if (language === "english") { return "Cookies Policy"; }
            else if (language === "vietnamese") { return "Chính sách cookie"; }
            else if (language === "chinese") { return "Cookie 政策"; }
            else if (language === "korean") { return "쿠키 정책"; }
            else if (language === "japanese") { return "クッキーポリシー"; }
            else if (language === "french") { return "Politique relative aux cookies"; }
            else if (language === "spanish") { return "Política de cookies"; }
            else if (language === "german") { return "Cookie-Richtlinie"; }
            else if (language === "italian") { return "Politica sui cookie"; }
        }
        if (text === "privacy policy") {
            if (language === "english") { return "Privacy Policy"; }
            else if (language === "vietnamese") { return "Chính sách bảo mật"; }
            else if (language === "chinese") { return "隐私政策"; }
            else if (language === "korean") { return "개인 정보 보호 정책"; }
            else if (language === "japanese") { return "プライバシーポリシー"; }
            else if (language === "french") { return "Politique de confidentialité"; }
            else if (language === "spanish") { return "Política de privacidad"; }
            else if (language === "german") { return "Datenschutz-Bestimmungen"; }
            else if (language === "italian") { return "Informativa sulla privacy"; }
        }
    }

    // ALERT MESSSAGE

    if (section === 'alert') {
        if (text === "Sign up") {
            if (language === "english") { return "Sign up"; }
            else if (language === "vietnamese") { return "Đăng ký"; }
            else if (language === "chinese") { return "注册"; }
            else if (language === "korean") { return "가입하기"; }
            else if (language === "japanese") { return "サインアップ"; }
            else if (language === "french") { return "S'inscrire"; }
            else if (language === "spanish") { return "Regístrate"; }
            else if (language === "german") { return "Anmelden"; }
            else if (language === "italian") { return "Iscriviti"; }
        }
        if (text === "or") {
            if (language === "english") { return "or"; }
            else if (language === "vietnamese") { return "hoặc"; }
            else if (language === "chinese") { return "要么"; }
            else if (language === "korean") { return "또는"; }
            else if (language === "japanese") { return "または"; }
            else if (language === "french") { return "ou"; }
            else if (language === "spanish") { return "o"; }
            else if (language === "german") { return "oder"; }
            else if (language === "italian") { return "o"; }
        }
        if (text === "log in") {
            if (language === "english") { return "log in"; }
            else if (language === "vietnamese") { return "đăng nhập"; }
            else if (language === "chinese") { return "登录"; }
            else if (language === "korean") { return "로그인"; }
            else if (language === "japanese") { return "ログイン"; }
            else if (language === "french") { return "se connecter"; }
            else if (language === "spanish") { return "iniciar sesión"; }
            else if (language === "german") { return "einloggen"; }
            else if (language === "italian") { return "accesso"; }
        }
        if (text === "to save your favourite recipes.") {
            if (language === "english") { return "to save your favourite recipes."; }
            else if (language === "vietnamese") { return "để lưu công thức ưa thích của bạn."; }
            else if (language === "chinese") { return "保存您喜欢的食谱。"; }
            else if (language === "korean") { return "좋아하는 레시피를 저장하십시오."; }
            else if (language === "japanese") { return "お気に入りのレシピを保存します。"; }
            else if (language === "french") { return "pour enregistrer vos recettes préférées."; }
            else if (language === "spanish") { return "para guardar tus recetas favoritas."; }
            else if (language === "german") { return "um Ihre Lieblingsrezepte zu speichern."; }
            else if (language === "italian") { return "per salvare le tue ricette preferite."; }
        }
        if (text === "recipe link") {
            if (language === "english") { return "'Recipe link copied to clipboard!'"; }
            else if (language === "vietnamese") { return "'Đã sao chép liên kết công thức vào bộ nhớ tạm!'"; }
            else if (language === "chinese") { return "'食谱链接已复制到剪贴板！'"; }
            else if (language === "korean") { return "'레시피 링크가 클립 보드에 복사되었습니다!'"; }
            else if (language === "japanese") { return "'レシピリンクがクリップボードにコピーされました！'"; }
            else if (language === "french") { return "'Lien de recette copié dans le presse-papiers!'"; }
            else if (language === "spanish") { return "'¡Enlace de receta copiado al portapapeles!'"; }
            else if (language === "german") { return "'Rezeptlink in die Zwischenablage kopiert!'"; }
            else if (language === "italian") { return "'Link della ricetta copiato negli appunti!'"; }
        }
        if (text === "recipe link error") {
            if (language === "english") { return "Your clipboard is not available"; }
            else if (language === "vietnamese") { return "Bộ nhớ tạm của bạn không khả dụng"; }
            else if (language === "chinese") { return "您的剪贴板不可用"; }
            else if (language === "korean") { return "클립 보드를 사용할 수 없습니다"; }
            else if (language === "japanese") { return "クリップボードを使用できません"; }
            else if (language === "french") { return "Votre presse-papiers n'est pas disponible"; }
            else if (language === "spanish") { return "Su portapapeles no está disponible"; }
            else if (language === "german") { return "Ihre Zwischenablage ist nicht verfügbar"; }
            else if (language === "italian") { return "La tua clipboard non è disponibile"; }
        }
        if (text === "report issue") {
            if (language === "english") { return "Thank you for reporting this issue!"; }
            else if (language === "vietnamese") { return "Cảm ơn bạn đã báo cáo vấn đề này!"; }
            else if (language === "chinese") { return "感谢您报告此问题！"; }
            else if (language === "korean") { return "이 문제를 보고 해 주셔서 감사합니다!"; }
            else if (language === "japanese") { return "この問題を報告していただきありがとうございます！"; }
            else if (language === "french") { return "Merci d'avoir signalé ce problème!"; }
            else if (language === "spanish") { return "¡Gracias por informar este problema!"; }
            else if (language === "german") { return "Vielen Dank, dass Sie dieses Problem gemeldet haben!"; }
            else if (language === "italian") { return "Grazie per aver segnalato questo problema!"; }
        }
        if (text === "try again later") {
            if (language === "english") { return "'Something went wrong, please try again later')"; }
            else if (language === "vietnamese") { return "'Đã xảy ra lỗi, vui lòng thử lại sau'"; }
            else if (language === "chinese") { return "'出了些问题，请稍后再试'"; }
            else if (language === "korean") { return "'문제가 발생했습니다. 나중에 다시 시도하십시오.'"; }
            else if (language === "japanese") { return "'問題が発生しました。後でもう一度お試しください'"; }
            else if (language === "french") { return "'Quelque chose s'est mal passé, veuillez réessayer plus tard'"; }
            else if (language === "spanish") { return "'Algo salió mal, inténtalo de nuevo más tarde'"; }
            else if (language === "german") { return "'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut'"; }
            else if (language === "italian") { return "'Qualcosa è andato storto, riprova più tardi'"; }
        }
        if (text === "recipe exist") {
            if (language === "english") { return "Recipe already added to basket."; }
            else if (language === "vietnamese") { return "Công thức đã được thêm vào giỏ hàng."; }
            else if (language === "chinese") { return "食谱已添加到购物篮。"; }
            else if (language === "korean") { return "레시피가 이미 바구니에 추가되었습니다."; }
            else if (language === "japanese") { return "レシピはすでにバスケットに追加されています。"; }
            else if (language === "french") { return "Recette déjà ajoutée au panier."; }
            else if (language === "spanish") { return "Receta ya agregada a la cesta."; }
            else if (language === "german") { return "Rezept bereits zum Warenkorb hinzugefügt."; }
            else if (language === "italian") { return "Ricetta già aggiunta al carrello."; }
        }
        if (text === 'to add ingredients to your basket.') {
            if (language === "english") { return "to add ingredients to your basket."; }
            else if (language === "vietnamese") { return "để thêm nguyên liệu vào giỏ hàng của bạn."; }
            else if (language === "chinese") { return "将食材添加到购物篮中。"; }
            else if (language === "korean") { return "바구니에 재료를 추가하려면"; }
            else if (language === "japanese") { return "材料をバスケットに追加するには"; }
            else if (language === "french") { return "pour ajouter des ingrédients à votre panier."; }
            else if (language === "spanish") { return "para agregar ingredientes a su canasta."; }
            else if (language === "german") { return "um Zutaten zu Ihrem Warenkorb hinzuzufügen."; }
            else if (language === "italian") { return "per aggiungere ingredienti al tuo carrello."; }
        }
        if (text === 'Ingredients have been added to your basket.') {
            if (language === "english") { return "Ingredients have been added to your basket."; }
            else if (language === "vietnamese") { return "Nguyên liệu đã được thêm vào giỏ hàng của bạn."; }
            else if (language === "chinese") { return "食材已添加到您的购物篮中。"; }
            else if (language === "korean") { return "재료가 바구니에 추가되었습니다."; }
            else if (language === "japanese") { return "材料がバスケットに追加されました。"; }
            else if (language === "french") { return "Les ingrédients ont été ajoutés à votre panier."; }
            else if (language === "spanish") { return "Los ingredientes se han agregado a su canasta."; }
            else if (language === "german") { return "Zutaten wurden zu Ihrem Warenkorb hinzugefügt."; }
            else if (language === "italian") { return "Gli ingredienti sono stati aggiunti al tuo carrello."; }
        }
        if (text === 'to rate this recipe.') {
            if (language === "english") { return "to rate this recipe."; }
            else if (language === "vietnamese") { return "để đánh giá công thức này."; }
            else if (language === "chinese") { return "评价这个食谱。"; }
            else if (language === "korean") { return "이 레시피를 평가하려면"; }
            else if (language === "japanese") { return "このレシピを評価するには"; }
            else if (language === "french") { return "pour évaluer cette recette."; }
            else if (language === "spanish") { return "para calificar esta receta."; }
            else if (language === "german") { return "um dieses Rezept zu bewerten."; }
            else if (language === "italian") { return "per valutare questa ricetta."; }
        }
        if (text === 'to see your favourite recipes.') {
            if (language === "english") { return "to see your favourite recipes."; }
            else if (language === "vietnamese") { return "để xem công thức ưa thích của bạn."; }
            else if (language === "chinese") { return "查看您喜欢的食谱。"; }
            else if (language === "korean") { return "좋아하는 레시피를 보려면"; }
            else if (language === "japanese") { return "お気に入りのレシピを見るには"; }
            else if (language === "french") { return "pour voir vos recettes préférées."; }
            else if (language === "spanish") { return "para ver tus recetas favoritas."; }
            else if (language === "german") { return "um Ihre Lieblingsrezepte zu sehen."; }
            else if (language === "italian") { return "per vedere le tue ricette preferite."; }
        }
        if (text === "Welcome, ") {
            if (language === "english") { return "Welcome, "; }
            else if (language === "vietnamese") { return "Chào mừng, "; }
            else if (language === "chinese") { return "欢迎, "; }
            else if (language === "korean") { return "환영합니다, "; }
            else if (language === "japanese") { return "ようこそ、 "; }
            else if (language === "french") { return "Bienvenue, "; }
            else if (language === "spanish") { return "Bienvenido, "; }
            else if (language === "german") { return "Willkommen, "; }
            else if (language === "italian") { return "Benvenuto, "; }
        }
        if (text === "recipe added") {
            if (language === "english") { return "Recipe added to basket."; }
            else if (language === "vietnamese") { return "Công thức đã được thêm vào giỏ hàng."; }
            else if (language === "chinese") { return "食谱已添加到购物篮。"; }
            else if (language === "korean") { return "레시피가 바구니에 추가되었습니다."; }
            else if (language === "japanese") { return "レシピがバスケットに追加されました。"; }
            else if (language === "french") { return "Recette ajoutée au panier."; }
            else if (language === "spanish") { return "Receta agregada a la canasta."; }
            else if (language === "german") { return "Rezept zum Warenkorb hinzugefügt."; }
            else if (language === "italian") { return "Ricetta aggiunta al carrello."; }
        }
    }

    // FEEDBACK

    if (section ==='feedback') {
        if (text === "feedback title") {
            if (language === "english") { return "Your feedback is the spice of life!" }
            else if (language === "vietnamese") { return "Chia sẻ ý kiến của bạn!" }
            else if (language === "chinese") { return "分享您的反馈！" }
            else if (language === "korean") { return "피드백을 공유하십시오!" }
            else if (language === "japanese") { return "フィードバックを共有する！" }
            else if (language === "french") { return "Partagez vos commentaires!" }
            else if (language === "spanish") { return "¡Comparte tus comentarios!" }
            else if (language === "german") { return "Teilen Sie Ihr Feedback!" }
            else if (language === "italian") { return "Condividi il tuo feedback!" }
        }
        if (text ===  "subtitle") {
            if (language === "english") { return "So add some flavor to our day and send us your peppery suggestions and zesty comments." }
            else if (language === "vietnamese") { return "Chúng tôi rất muốn nghe từ bạn!" }
            else if (language === "chinese") { return "我们很想听到您的声音！" }
            else if (language === "korean") { return "당신의 목소리를 듣고 싶습니다!" }
            else if (language === "japanese") { return "あなたの声を聞きたいです！" }
            else if (language === "french") { return "Nous aimerions avoir de vos nouvelles!" }
            else if (language === "spanish") { return "¡Nos encantaría saber de ti!" }
            else if (language === "german") { return "Wir würden gerne von Ihnen hören!" }
            else if (language === "italian") { return "Ci piacerebbe sentirti!" }
        }
        if (text === "name") {
            if (language === "english") { return "Name" }
            else if (language === "vietnamese") { return "Tên" }
            else if (language === "chinese") { return "名称" }
            else if (language === "korean") { return "이름" }
            else if (language === "japanese") { return "名" }
            else if (language === "french") { return "Nom" }
            else if (language === "spanish") { return "Nombre" }
            else if (language === "german") { return "Name" }
            else if (language === "italian") { return "Nome" }
        }
        if (text === "email") {
            if (language === "english") { return "Email" }
            else if (language === "vietnamese") { return "Email" }
            else if (language === "chinese") { return "电子邮件" }
            else if (language === "korean") { return "이메일" }
            else if (language === "japanese") { return "メール" }
            else if (language === "french") { return "Email" }
            else if (language === "spanish") { return "Email" }
            else if (language === "german") { return "Email" }
            else if (language === "italian") { return "Email" }
        }
        if (text === "feedback") {
            if (language === "english") { return "Feedback" }
            else if (language === "vietnamese") { return "Phản hồi" }
            else if (language === "chinese") { return "反馈" }
            else if (language === "korean") { return "피드백" }
            else if (language === "japanese") { return "フィードバック" }
            else if (language === "french") { return "Retour d'information" }
            else if (language === "spanish") { return "Realimentación" }
            else if (language === "german") { return "Feedback" }
            else if (language === "italian") { return "Risposta" }
        }
        if (text === "Share feedback") {
            if (language === "english") { return "Share feedback" }
            else if (language === "vietnamese") { return "Chia sẻ phản hồi" }
            else if (language === "chinese") { return "分享反馈" }
            else if (language === "korean") { return "피드백 공유" }
            else if (language === "japanese") { return "フィードバックを共有する" }
            else if (language === "french") { return "Partager des commentaires" }
            else if (language === "spanish") { return "Compartir comentarios" }
            else if (language === "german") { return "Feedback teilen" }
            else if (language === "italian") { return "Condividi feedback" }
        }
    }

    // MISC

    if (section === 'misc') {
        if (text === "done") {
            if (language === "english") { return "Done"; }
            else if (language === "vietnamese") { return "Xong"; }
            else if (language === "chinese") { return "完成"; }
            else if (language === "korean") { return "끝난"; }
            else if (language === "japanese") { return "完了"; }
            else if (language === "french") { return "Terminé"; }
            else if (language === "spanish") { return "Hecho"; }
            else if (language === "german") { return "Erledigt"; }
            else if (language === "italian") { return "Fatto"; }
        }
    }
}

export function translateTags(tags) {

    const language = Cookies.get("language") || "english";

    if (tags === 'parent_filters') {
        if (language === "english") { return [['Meal', 'Meal'], ['Cuisine', 'Cuisine'], ['Protein', 'Protein'], ['Ingredients', 'Ingredients'], ['Method', 'Method'], ['Occasion', 'Occasion'], ['Cooking Time', 'Cooking Time']] }
        else if (language === "vietnamese") { return [['Meal', 'Bữa ăn'], ['Cuisine', 'Ẩm thực'], ['Protein', 'Thịt'], ['Ingredients', 'Nguyên liệu'], ['Method', 'Phương pháp'], ['Occasion', 'Dịp'], ['Cooking Time', 'Thời gian nấu']] }
        else if (language === "chinese") { return [['Meal', '餐'], ['Cuisine', '烹饪'], ['Protein', '蛋白质'], ['Ingredients', '成分'], ['Method', '方法'], ['Occasion', '场合'], ['Cooking Time', '烹饪时间']] }
        else if (language === "korean") { return [['Meal', '식사'], ['Cuisine', '요리'], ['Protein', '단백질'], ['Ingredients', '성분'], ['Method', '방법'], ['Occasion', '기회'], ['Cooking Time', '요리 시간']] }
        else if (language === "japanese") { return [['Meal', '食事'], ['Cuisine', '料理'], ['Protein', 'タンパク質'], ['Ingredients', '材料'], ['Method', '方法'], ['Occasion', '機会'], ['Cooking Time', '調理時間']] }
        else if (language === "french") { return [['Meal', 'Repas'], ['Cuisine', 'Cuisine'], ['Protein', 'Protéine'], ['Ingredients', 'Ingrédients'], ['Method', 'Méthode'], ['Occasion', 'Occasion'], ['Cooking Time', 'Temps de cuisson']] }
        else if (language === "spanish") { return [['Meal', 'Comida'], ['Cuisine', 'Cocina'], ['Protein', 'Proteína'], ['Ingredients', 'Ingredientes'], ['Method', 'Método'], ['Occasion', 'Ocasión'], ['Cooking Time', 'Tiempo de cocción']] }
        else if (language === "german") { return [['Meal', 'Mahlzeit'], ['Cuisine', 'Küche'], ['Protein', 'Protein'], ['Ingredients', 'Zutaten'], ['Method', 'Methode'], ['Occasion', 'Gelegenheit'], ['Cooking Time', 'Kochzeit']] }
        else if (language === "italian") { return [['Meal', 'Pasto'], ['Cuisine', 'Cucina'], ['Protein', 'Proteina'], ['Ingredients', 'Ingredienti'], ['Method', 'Metodo'], ['Occasion', 'Occasione'], ['Cooking Time', 'Tempo di cottura']] }
    }

    if (tags === 'meal_filters') {
        if (language === "english") { return [['Breakfast', 'Breakfast'], ['Brunch', 'Brunch'], ['Lunch', 'Lunch'], ['Dinner', 'Dinner'], ['Dessert', 'Dessert'], ['Snack', 'Snack'], ['Drinks', 'Drinks']] }
        else if (language === "vietnamese") { return [['Breakfast', 'Bữa sáng'], ['Brunch', 'Bữa nửa buổi'], ['Lunch', 'Bữa trưa'], ['Dinner', 'Bữa tối'], ['Dessert', 'Món tráng miệng'], ['Snack', 'Ăn vặt'], ['Drinks', 'Đồ uống']] }
        else if (language === "chinese") { return [['Breakfast', '早餐'], ['Brunch', '早午餐'], ['Lunch', '午餐'], ['Dinner', '晚餐'], ['Dessert', '甜点'], ['Snack', '小吃'], ['Drinks', '饮料']] }
        else if (language === "korean") { return [['Breakfast', '아침 식사'], ['Brunch', '브런치'], ['Lunch', '점심 식사'], ['Dinner', '저녁 식사'], ['Dessert', '디저트'], ['Snack', '간식'], ['Drinks', '음료수']] }
        else if (language === "japanese") { return [['Breakfast', '朝食'], ['Brunch', 'ブランチ'], ['Lunch', 'ランチ'], ['Dinner', '夕食'], ['Dessert', 'デザート'], ['Snack', 'おやつ'], ['Drinks', '飲み物']] }
        else if (language === "french") { return [['Breakfast', 'Petit déjeuner'], ['Brunch', 'Brunch'], ['Lunch', 'Déjeuner'], ['Dinner', 'Dîner'], ['Dessert', 'Dessert'], ['Snack', 'Collation'], ['Drinks', 'Boissons']] }
        else if (language === "spanish") { return [['Breakfast', 'Desayuno'], ['Brunch', 'Brunch'], ['Lunch', 'Almuerzo'], ['Dinner', 'Cena'], ['Dessert', 'Postre'], ['Snack', 'Bocadillo'], ['Drinks', 'Bebidas']] }
        else if (language === "german") { return [['Breakfast', 'Frühstück'], ['Brunch', 'Brunch'], ['Lunch', 'Mittagessen'], ['Dinner', 'Abendessen'], ['Dessert', 'Nachtisch'], ['Snack', 'Snack'], ['Drinks', 'Getränke']] }
        else if (language === "italian") { return [['Breakfast', 'Colazione'], ['Brunch', 'Brunch'], ['Lunch', 'Pranzo'], ['Dinner', 'Cena'], ['Dessert', 'Dolce'], ['Snack', 'Spuntino'], ['Drinks', 'Bevande']] }
    }

    if (tags === 'occasion_filters') {
        if (language === "english") { return [['Appetisers', 'Appetisers'], ['Celebration', 'Celebration'], ['Cocktail Party', 'Cocktail Party'], ['Crowd Pleaser', 'Crowd Pleaser'], ['Picnic', 'Picnic'], ['Potluck', 'Potluck']] }
        else if (language === "vietnamese") { return [['Appretisers', 'Món khai vị'], ['Celebration', 'Lễ kỷ niệm'], ['Cocktail Party', 'Tiệc cocktail'], ['Crowd Pleaser', 'Món hấp dẫn cho đám đông'], ['Picnic', 'Chuyến dã ngoại'], ['Potluck', 'Tiệc tự mang món']] }
        else if (language === "chinese") { return [['Appetisers', '开胃菜'], ['Celebration', '庆祝'], ['Cocktail Party', '鸡尾酒会'], ['Crowd Pleaser', '人群'], ['Picnic', '野餐'], ['Potluck', '便餐']] }
        else if (language === "korean") { return [['Appetisers', '애피타이저'], ['Celebration', '축하'], ['Cocktail Party', '칵테일 파티'], ['Crowd Pleaser', '관중'], ['Picnic', '소풍'], ['Potluck', '자기 소개']] }
        else if (language === "japanese") { return [['Appetisers', '前菜'], ['Celebration', '祝賀'], ['Cocktail Party', 'カクテルパーティー'], ['Crowd Pleaser', '群衆'], ['Picnic', 'ピクニック'], ['Potluck', '持ち寄り']] }
        else if (language === "french") { return [['Appetisers', 'Apéritifs'], ['Celebration', 'Célébration'], ['Cocktail Party', 'Cocktail Party'], ['Crowd Pleaser', 'Plaisir de la foule'], ['Picnic', 'Pique-nique'], ['Potluck', 'Repas-partage']] }
        else if (language === "spanish") { return [['Appetisers', 'Aperitivos'], ['Celebration', 'Celebración'], ['Cocktail Party', 'Cocktail Party'], ['Crowd Pleaser', 'Placer de la multitud'], ['Picnic', 'Picnic'], ['Potluck', 'Comida compartida']] }
        else if (language === "german") { return [['Appetisers', 'Vorspeisen'], ['Celebration', 'Feier'], ['Cocktail Party', 'Cocktail Party'], ['Crowd Pleaser', 'Publikumsliebling'], ['Picnic', 'Picknick'], ['Potluck', 'Mitbringparty']] }
        else if (language === "italian") { return [['Appetisers', 'Antipasti'], ['Celebration', 'Celebrazione'], ['Cocktail Party', 'Cocktail Party'], ['Crowd Pleaser', 'Folla'], ['Picnic', 'Picnic'], ['Potluck', 'Potluck']] }
    }

    if (tags === 'cuisine_filters') {
        if (language === "english") { return [['Chinese', 'Chinese'], ['Ethiopian', 'Ethiopian'], ['French', 'French'], ['Greek', 'Greek'], ['Indian', 'Indian'], ['Italian', 'Italian'], ['Japanese', 'Japanese'], ['Lebanese', 'Lebanese'], ['Korean', 'Korean'], ['Malaysian', 'Malaysian'], ['Mexican', 'Mexican'], ['Middle Eastern', 'Middle Eastern'], ['Spanish', 'Spanish'], ['Thai', 'Thai'], ['Vietnamese', 'Vietnamese']] }
        else if (language === "vietnamese") { return [['Chinese', 'Trung Quốc'], ['Ethiopian', 'Ethiopian'], ['French', 'Pháp'], ['Greek', 'Hy Lạp'], ['Indian', 'Ấn Độ'], ['Italian', 'Ý'], ['Japanese', 'Nhật Bản'], ['Lebanese', 'Li-băng'], ['Korean', 'Hàn Quốc'], ['Malaysian', 'Mã Lai Xinh'], ['Mexican', 'Mê híc'], ['Middle Eastern', 'Trung Đông'], ['Spanish', 'Tây Ban Nha'], ['Thai', 'Thái Lan'], ['Vietnamese', 'Việt Nam']] }
        else if (language === "chinese") { return [['Chinese', '中国'], ['Ethiopian', '埃塞俄比亚'], ['French', '法国'], ['Greek', '希腊'], ['Indian', '印度'], ['Italian', '意大利'], ['Japanese', '日本'], ['Lebanese', '黎巴嫩'], ['Korean', '韩国'], ['Malaysian', '马来西亚'], ['Mexican', '墨西哥'], ['Middle Eastern', '中东'], ['Spanish', '西班牙'], ['Thai', '泰国'], ['Vietnamese', '越南']] }
        else if (language === "korean") { return [['Chinese', '중국어'], ['Ethiopian', '에티오피아'], ['French', '프랑스 국민'], ['Greek', '그리스어'], ['Indian', '인도'], ['Italian', '이탈리아'], ['Japanese', '일본어'], ['Lebanese', '레바논'], ['Korean', '한국어'], ['Malaysian', '말레이시아'], ['Mexican', '멕시코'], ['Middle Eastern', '중동'], ['Spanish', '스페인어'], ['Thai', '태국어'], ['Vietnamese', '베트남어']] }
        else if (language === "japanese") { return [['Chinese', '中国語'], ['Ethiopian', 'エチオピア語'], ['French', 'フランス語'], ['Greek', 'ギリシャ語'], ['Indian', 'インド'], ['Italian', 'イタリア語'], ['Japanese', '日本語'], ['Lebanese', 'レバノン語'], ['Korean', '韓国語'], ['Malaysian', 'マレーシア語'], ['Mexican', 'メキシコ'], ['Middle Eastern', '中東'], ['Spanish', 'スペイン語'], ['Thai', 'タイ語'], ['Vietnamese', 'ベトナム語']] }
        else if (language === "french") { return [['Chinese', 'Chinois'], ['Ethiopian', 'Éthiopien'], ['French', 'Français'], ['Greek', 'Grec'], ['Indian', 'Indien'], ['Italian', 'Italien'], ['Japanese', 'Japonais'], ['Lebanese', 'Libanais'], ['Korean', 'Coréen'], ['Malaysian', 'Malaisien'], ['Mexican', 'Mexicain'], ['Middle Eastern', 'Moyen-Orient'], ['Spanish', 'Espagnol'], ['Thai', 'Thaï'], ['Vietnamese', 'Vietnamien']] }
        else if (language === "spanish") { return [['Chinese', 'Chino'], ['Ethiopian', 'Etíope'], ['French', 'Francés'], ['Greek', 'Griego'], ['Indian', 'Indio'], ['Italian', 'Italiano'], ['Japanese', 'Japonés'], ['Lebanese', 'Libanés'], ['Korean', 'Coreano'], ['Malaysian', 'Malayo'], ['Mexican', 'Mexicano'], ['Middle Eastern', 'Medio Oriente'], ['Spanish', 'Español'], ['Thai', 'Tailandés'], ['Vietnamese', 'Vietnamita']] }
        else if (language === "german") { return [['Chinese', 'Chinesisch'], ['Ethiopian', 'Äthiopisch'], ['French', 'Französisch'], ['Greek', 'Griechisch'], ['Indian', 'Indisch'], ['Italian', 'Italienisch'], ['Japanese', 'Japanisch'], ['Lebanese', 'Libanesisch'], ['Korean', 'Koreanisch'], ['Malaysian', 'Malaysisch'], ['Mexican', 'Mexikanisch'], ['Middle Eastern', 'Mittlerer Osten'], ['Spanish', 'Spanisch'], ['Thai', 'Thailändisch'], ['Vietnamese', 'Vietnamesisch']] }
        else if (language === "italian") { return [['Chinese', 'Cinese'], ['Ethiopian', 'Etiope'], ['French', 'Francese'], ['Greek', 'Greco'], ['Indian', 'Indiano'], ['Italian', 'Italiano'], ['Japanese', 'Giapponese'], ['Lebanese', 'Libanese'], ['Korean', 'Coreano'], ['Malaysian', 'Malese'], ['Mexican', 'Messicano'], ['Middle Eastern', 'Medio Oriente'], ['Spanish', 'Spagnolo'], ['Thai', 'Tailandese'], ['Vietnamese', 'Vietnamita']] }
    }

    if (tags === 'ingredient_filters') {
        if (language === "english") { return [['Avocado', 'Avocado'], ['Broccoli', 'Broccoli'], ['Cauliflower', 'Cauliflower'], ['Carrot', 'Carrot'], ['Garlic', 'Garlic'], ['Mushroom', 'Mushroom'], ['Onion', 'Onion'], ['Pasta', 'Pasta'], ['Potatoes', 'Potatoes'], ['Quinoa', 'Quinoa'], ['Rice', 'Rice'], ['Spinach', 'Spinach'], ['Sweet Potatoes', 'Sweet Potatoes'], ['Tomatoes', 'Tomatoes'], ['Tofu', 'Tofu']] }
        else if (language === "vietnamese") { return [['Avocado', 'Bơ'], ['Broccoli', 'Cải bắp'], ['Cauliflower', 'Cải cứng'], ['Carrot', 'Cà rốt'], ['Garlic', 'Tỏi'], ['Mushroom', 'Nấm'], ['Onion', 'Hành lá'], ['Pasta', 'Mỳ'], ['Potatoes', 'Khoai tây'], ['Quinoa', 'Cân khô'], ['Rice', 'Gạo'], ['Spinach', 'Cải thố'], ['Sweet Potatoes', 'Khoai lang'], ['Tomatoes', 'Cà chua'], ['Tofu', 'Đậu hủ']] }
        else if (language === "chinese") { return [['Avocado', '鳄梨'], ['Broccoli', '西兰花'], ['Cauliflower', '菜花'], ['Carrot', '胡萝卜'], ['Garlic', '大蒜'], ['Mushroom', '蘑菇'], ['Onion', '洋葱'], ['Pasta', '意大利面'], ['Potatoes', '土豆'], ['Quinoa', '藜麦'], ['Rice', '大米'], ['Spinach', '菠菜'], ['Sweet Potatoes', '红薯'], ['Tomatoes', '西红柿'], ['Tofu', '豆腐']] }
        else if (language === "korean") { return [['Avocado', '아보카도'], ['Broccoli', '브로콜리'], ['Cauliflower', '콜리플라워'], ['Carrot', '당근'], ['Garlic', '마늘'], ['Mushroom', '버섯'], ['Onion', '양파'], ['Pasta', '파스타'], ['Potatoes', '감자'], ['Quinoa', '퀴노아'], ['Rice', '쌀'], ['Spinach', '시금치'], ['Sweet Potatoes', '고구마'], ['Tomatoes', '토마토'], ['Tofu', '두부']] }
        else if (language === "japanese") { return [['Avocado', 'アボカド'], ['Broccoli', 'ブロッコリー'], ['Cauliflower', 'カリフラワー'], ['Carrot', '人参'], ['Garlic', 'ニンニク'], ['Mushroom', 'キノコ'], ['Onion', '玉ねぎ'], ['Pasta', 'パスタ'], ['Potatoes', 'ジャガイモ'], ['Quinoa', 'キヌア'], ['Rice', '米'], ['Spinach', 'ほうれん草'], ['Sweet Potatoes', 'サツマイモ'], ['Tomatoes', 'トマト'], ['Tofu', '豆腐']] }
        else if (language === "french") { return [['Avocado', 'Avocat'], ['Broccoli', 'Brocoli'], ['Cauliflower', 'Chou-fleur'], ['Carrot', 'Carotte'], ['Garlic', 'Ail'], ['Mushroom', 'Champignon'], ['Onion', 'Oignon'], ['Pasta', 'Pâtes'], ['Potatoes', 'Pommes de terre'], ['Quinoa', 'Quinoa'], ['Rice', 'Riz'], ['Spinach', 'Épinard'], ['Sweet Potatoes', 'Patates douces'], ['Tomatoes', 'Tomates'], ['Tofu', 'Tofu']] }
        else if (language === "spanish") { return [['Avocado', 'Aguacate'], ['Broccoli', 'Brócoli'], ['Cauliflower', 'Coliflor'], ['Carrot', 'Zanahoria'], ['Garlic', 'Ajo'], ['Mushroom', 'Seta'], ['Onion', 'Cebolla'], ['Pasta', 'Pasta'], ['Potatoes', 'Patatas'], ['Quinoa', 'Quinoa'], ['Rice', 'Arroz'], ['Spinach', 'Espinaca'], ['Sweet Potatoes', 'Batatas'], ['Tomatoes', 'Tomates'], ['Tofu', 'Tofu']] }
        else if (language === "german") { return [['Avocado', 'Avocado'], ['Broccoli', 'Brokkoli'], ['Cauliflower', 'Blumenkohl'], ['Carrot', 'Karotte'], ['Garlic', 'Knoblauch'], ['Mushroom', 'Pilz'], ['Onion', 'Zwiebel'], ['Pasta', 'Nudeln'], ['Potatoes', 'Kartoffeln'], ['Quinoa', 'Quinoa'], ['Rice', 'Reis'], ['Spinach', 'Spinat'], ['Sweet Potatoes', 'Süßkartoffeln'], ['Tomatoes', 'Tomaten'], ['Tofu', 'Tofu']] }
        else if (language === "italian") { return [['Avocado', 'Avocado'], ['Broccoli', 'Broccoli'], ['Cauliflower', 'Cavolfiore'], ['Carrot', 'Carota'], ['Garlic', 'Aglio'], ['Mushroom', 'Fungo'], ['Onion', 'Cipolla'], ['Pasta', 'Pasta'], ['Potatoes', 'Patate'], ['Quinoa', 'Quinoa'], ['Rice', 'Riso'], ['Spinach', 'Spinaci'], ['Sweet Potatoes', 'Patate dolci'], ['Tomatoes', 'Pomodori'], ['Tofu', 'Tofu']] }
    }

    if (tags === 'method_filters') {
        if (language === "english") { return [['Baking', 'Baking'], ['Barbecue', 'Barbecue'], ['Frying', 'Frying'], ['Roasting', 'Roasting'], ['Sauteing', 'Sauteing'], ['Slow cooking', 'Slow cooking'], ['Steaming', 'Steaming'], ['Stir frying', 'Stir frying']] }
        else if (language === "vietnamese") { return [['Baking', 'Làm Bánh'], ['Barbecue', 'Thịt Nướng'], ['Frying', 'Xào'], ['Roasting', 'Nướng'], ['Sauteing', 'Xào lốc'], ['Slow cooking', 'Nấu chậm'], ['Steaming', 'Hấp'], ['Stir frying', 'Xào rán']] }
        else if (language === "chinese") { return [['Baking', '烘烤'], ['Barbecue', '烧烤'], ['Frying', '炒'], ['Roasting', '烤'], ['Sauteing', '炒'], ['Slow cooking', '慢煮'], ['Steaming', '蒸'], ['Stir frying', '炒']] }
        else if (language === "korean") { return [['Baking', '베이킹'], ['Barbecue', '바베큐'], ['Frying', '볶음'], ['Roasting', '구이'], ['Sauteing', '볶음'], ['Slow cooking', '슬로우 쿠킹'], ['Steaming', '찌기'], ['Stir frying', '볶음']] }
        else if (language === "japanese") { return [['Baking', 'ベーキング'], ['Barbecue', 'バーベキュー'], ['Frying', '炒める'], ['Roasting', '焼く'], ['Sauteing', '炒める'], ['Slow cooking', 'スロークッキング'], ['Steaming', '蒸し'], ['Stir frying', '炒める']] }
        else if (language === "french") { return [['Baking', 'Cuisson au four'], ['Barbecue', 'Barbecue'], ['Frying', 'Friture'], ['Roasting', 'Rôtissage'], ['Sauteing', 'Sauter'], ['Slow cooking', 'Cuisson lente'], ['Steaming', 'Cuisson à la vapeur'], ['Stir frying', 'Sauter']] }
        else if (language === "spanish") { return [['Baking', 'Horneando'], ['Barbecue', 'Barbacoa'], ['Frying', 'Fritura'], ['Roasting', 'Asado'], ['Sauteing', 'Salteado'], ['Slow cooking', 'Cocción lenta'], ['Steaming', 'Cocción al vapor'], ['Stir frying', 'Salteado']] }
        else if (language === "german") { return [['Baking', 'Backen'], ['Barbecue', 'Grillen'], ['Frying', 'Braten'], ['Roasting', 'Rösten'], ['Sauteing', 'Anbraten'], ['Slow cooking', 'Langsam kochen'], ['Steaming', 'Dämpfen'], ['Stir frying', 'Braten']] }
        else if (language === "italian") { return [['Baking', 'Cottura al forno'], ['Barbecue', 'Barbecue'], ['Frying', 'Frittura'], ['Roasting', 'Arrosto'], ['Sauteing', 'Saltare'], ['Slow cooking', 'Cottura lenta'], ['Steaming', 'Cottura a vapore'], ['Stir frying', 'Saltare']] }
    }

    if (tags === 'protein_filters') {
        if (language === "english") { return [['Bean', 'Bean'], ['Beef', 'Beef'], ['Cheese', 'Cheese'], ['Chicken', 'Chicken'], ['Egg', 'Egg'], ['Fish', 'Fish'], ['Lamb', 'Lamb'], ['Lentil', 'Lentil'], ['Nut', 'Nut'], ['Pork', 'Pork'], ['Quinoa', 'Quinoa'], ['Seafood', 'Seafood'], ['Tofu', 'Tofu'], ['Turkey', 'Turkey'], ['Venison', 'Venison']] }
        else if (language === "vietnamese") { return [['Bean', 'Hạt điều'], ['Beef', 'Thịt bò'], ['Cheese', 'Phô mai'], ['Chicken', 'Gà'], ['Egg', 'Trứng'], ['Fish', 'Cá'], ['Lamb', 'Thịt cừu (Dê)'], ['Lentil', 'Hạt đậu'], ['Nut', 'Hạt ép'], ['Pork', 'Thịt lợn'], ['Quinoa', 'Cốm kiển'], ['Seafood', 'Hải sản'], ['Tofu', 'Đậu phụ'], ['Turkey', 'Gà Trứng'], ['Venison', 'Thịt nai']] }
        else if (language === "chinese") { return [['Bean', '豆'], ['Beef', '牛肉'], ['Cheese', '奶酪'], ['Chicken', '鸡肉'], ['Egg', '鸡蛋'], ['Fish', '鱼'], ['Lamb', '羊肉'], ['Lentil', '扁豆'], ['Nut', '坚果'], ['Pork', '猪肉'], ['Quinoa', '藜麦'], ['Seafood', '海鲜'], ['Tofu', '豆腐'], ['Turkey', '火鸡'], ['Venison', '鹿肉']] }
        else if (language === "korean") { return [['Bean', '콩'], ['Beef', '소고기'], ['Cheese', '치즈'], ['Chicken', '닭고기'], ['Egg', '계란'], ['Fish', '생선'], ['Lamb', '양고기'], ['Lentil', '렌틸콩'], ['Nut', '견과류'], ['Pork', '돼지고기'], ['Quinoa', '퀴노아'], ['Seafood', '해산물'], ['Tofu', '두부'], ['Turkey', '칠면조'], ['Venison', '사슴고기']] }
        else if (language === "japanese") { return [['Bean', '豆'], ['Beef', '牛肉'], ['Cheese', 'チーズ'], ['Chicken', '鶏肉'], ['Egg', '卵'], ['Fish', '魚'], ['Lamb', '羊肉'], ['Lentil', 'レンズ豆'], ['Nut', 'ナッツ'], ['Pork', '豚肉'], ['Quinoa', 'キヌア'], ['Seafood', 'シーフード'], ['Tofu', '豆腐'], ['Turkey', '七面鳥'], ['Venison', '鹿肉']] }
        else if (language === "french") { return [['Bean', 'Haricot'], ['Beef', 'Boeuf'], ['Cheese', 'Fromage'], ['Chicken', 'Poulet'], ['Egg', 'Oeuf'], ['Fish', 'Poisson'], ['Lamb', 'Agneau'], ['Lentil', 'Lentille'], ['Nut', 'Noix'], ['Pork', 'Porc'], ['Quinoa', 'Quinoa'], ['Seafood', 'Fruits de mer'], ['Tofu', 'Tofu'], ['Turkey', 'Dinde'], ['Venison', 'Venaison']] }
        else if (language === "spanish") { return [['Bean', 'Frijol'], ['Beef', 'Carne de res'], ['Cheese', 'Queso'], ['Chicken', 'Pollo'], ['Egg', 'Huevo'], ['Fish', 'Pescado'], ['Lamb', 'Cordero'], ['Lentil', 'Lenteja'], ['Nut', 'Nuez'], ['Pork', 'Cerdo'], ['Quinoa', 'Quinua'], ['Seafood', 'Mariscos'], ['Tofu', 'Tofu'], ['Turkey', 'Pavo'], ['Venison', 'Venado']] }
        else if (language === "german") { return [['Bean', 'Bohne'], ['Beef', 'Rindfleisch'], ['Cheese', 'Käse'], ['Chicken', 'Hähnchen'], ['Egg', 'Ei'], ['Fish', 'Fisch'], ['Lamb', 'Lamm'], ['Lentil', 'Linsen'], ['Nut', 'Nuss'], ['Pork', 'Schweinefleisch'], ['Quinoa', 'Quinoa'], ['Seafood', 'Meeresfrüchte'], ['Tofu', 'Tofu'], ['Turkey', 'Truthahn'], ['Venison', 'Hirschfleisch']] }
        else if (language === "italian") { return [['Bean', 'Fagiolo'], ['Beef', 'Manzo'], ['Cheese', 'Formaggio'], ['Chicken', 'Pollo'], ['Egg', 'Uovo'], ['Fish', 'Pesce'], ['Lamb', 'Agnello'], ['Lentil', 'Lenticchia'], ['Nut', 'Noce'], ['Pork', 'Maiale'], ['Quinoa', 'Quinoa'], ['Seafood', 'Frutti di mare'], ['Tofu', 'Tofu'], ['Turkey', 'Tacchino'], ['Venison', 'Cervo']] }
    }

    if (tags === 'cooking_time_filters') {
        if (language === "english") { return [['< 10 mins', '< 10 mins'], ['< 20 mins', '< 20 mins'], ['< 30 mins', '< 30 mins'], ['< 45 mins', '< 45 mins'], ['< 60 mins', '< 60 mins']] }
        else if (language === "vietnamese") { return [['< 10 mins', '< 10 phút'], ['< 20 mins', '< 20 phút'], ['< 30 mins', '< 30 phút'], ['< 45 mins', '< 45 phút'], ['< 60 mins', '< 60 phút']] }
        else if (language === "chinese") { return [['< 10 mins', '< 10 分钟'], ['< 20 mins', '< 20 分钟'], ['< 30 mins', '< 30 分钟'], ['< 45 mins', '< 45 分钟'], ['< 60 mins', '< 60 分钟']] }
        else if (language === "korean") { return [['< 10 mins', '< 10 분'], ['< 20 mins', '< 20 분'], ['< 30 mins', '< 30 분'], ['< 45 mins', '< 45 분'], ['< 60 mins', '< 60 분']] }
        else if (language === "japanese") { return [['< 10 mins', '< 10 分'], ['< 20 mins', '< 20 分'], ['< 30 mins', '< 30 分'], ['< 45 mins', '< 45 分'], ['< 60 mins', '< 60 分']] }
        else if (language === "french") { return [['< 10 mins', '< 10 mins'], ['< 20 mins', '< 20 mins'], ['< 30 mins', '< 30 mins'], ['< 45 mins', '< 45 mins'], ['< 60 mins', '< 60 mins']] }
        else if (language === "spanish") { return [['< 10 mins', '< 10 mins'], ['< 20 mins', '< 20 mins'], ['< 30 mins', '< 30 mins'], ['< 45 mins', '< 45 mins'], ['< 60 mins', '< 60 mins']] }
        else if (language === "german") { return [['< 10 mins', '< 10 mins'], ['< 20 mins', '< 20 mins'], ['< 30 mins', '< 30 mins'], ['< 45 mins', '< 45 mins'], ['< 60 mins', '< 60 mins']] }
        else if (language === "italian") { return [['< 10 mins', '< 10 mins'], ['< 20 mins', '< 20 mins'], ['< 30 mins', '< 30 mins'], ['< 45 mins', '< 45 mins'], ['< 60 mins', '< 60 mins']] }
    }

}

export function translateActiveFilter(filter) {

    const language = Cookies.get("language") || "english";

    let filtersDict;

    if (language === "english") { return filter; }
    else if (language === "vietnamese") {
        filtersDict = {
            'Breakfast': 'Bữa sáng',
            'Brunch': 'Bữa nửa buổi',
            'Lunch': 'Bữa trưa',
            'Dinner': 'Bữa tối',
            'Dessert': 'Món tráng miệng',
            'Snack': 'Ăn vặt',
            'Drinks': 'Đồ uống',
            'Appretisers': 'Món khai vị',
            'Celebration': 'Lễ kỷ niệm',
            'Cocktail Party': 'Tiệc cocktail',
            'Crowd Pleaser': 'Món hấp dẫn cho đám đông',
            'Picnic': 'Chuyến dã ngoại',
            'Potluck': 'Tiệc tự mang món',
            'Chinese': 'Trung Quốc',
            'Ethiopian': 'Ethiopian',
            'French': 'Pháp',
            'Greek': 'Hy Lạp',
            'Indian': 'Ấn Độ',
            'Italian': 'Ý',
            'Japanese': 'Nhật Bản',
            'Lebanese': 'Li-băng',
            'Korean': 'Hàn Quốc',
            'Malaysian': 'Mã Lai Xinh',
            'Mexican': 'Mê híc',
            'Middle Eastern': 'Trung Đông',
            'Spanish': 'Tây Ban Nha',
            'Thai': 'Thái Lan',
            'Vietnamese': 'Việt Nam',
            'Avocado': 'Bơ',
            'Broccoli': 'Cải bắp',
            'Cauliflower': 'Cải cứng',
            'Carrot': 'Cà rốt',
            'Garlic': 'Tỏi',
            'Mushroom': 'Nấm',
            'Onion': 'Hành lá',
            'Pasta': 'Mỳ',
            'Potatoes': 'Khoai tây',
            'Quinoa': 'Cân khô',
            'Rice': 'Gạo',
            'Spinach': 'Cải thố',
            'Sweet Potatoes': 'Khoai lang',
            'Tomatoes': 'Cà chua',
            'Tofu': 'Đậu hủ',
            'Baking': 'Làm Bánh',
            'Barbecue': 'Thịt Nướng',
            'Frying': 'Xào',
            'Roasting': 'Nướng',
            'Sauteing': 'Xào lốc',
            'Slow cooking': 'Nấu chậm',
            'Steaming': 'Hấp',
            'Stir frying': 'Xào rán',
            'Bean': 'Hạt điều',
            'Beef': 'Thịt bò',
            'Cheese': 'Phô mai',
            'Chicken': 'Gà',
            'Egg': 'Trứng',
            'Fish': 'Cá',
            'Lamb': 'Thịt cừu (Dê)',
            'Lentil': 'Hạt đậu',
            'Nut': 'Hạt ép',
            'Pork': 'Thịt lợn',
            'Seafood': 'Hải sản',
            'Turkey': 'Gà Trứng',
            'Venison': 'Thịt nai',
            '< 10 mins': '< 10 phút',
            '< 20 mins': '< 20 phút',
            '< 30 mins': '< 30 phút',
            '< 45 mins': '< 45 phút',
            '< 60 mins': '< 60 phút'
        }
    }
    else if (language === "chinese") {
        filtersDict = {
            'Breakfast': '早餐',
            'Brunch': '早午餐',
            'Lunch': '午餐',
            'Dinner': '晚餐',
            'Dessert': '甜点',
            'Snack': '小吃',
            'Drinks': '饮料',
            'Appetisers': '开胃菜',
            'Celebration': '庆祝',
            'Cocktail Party': '鸡尾酒会',
            'Crowd Pleaser': '人群高潮',
            'Picnic': '野餐',
            'Potluck': '聚餐',
            'Chinese': '中国',
            'Ethiopian': '埃塞俄比亚',
            'French': '法国',
            'Greek': '希腊',
            'Indian': '印度',
            'Italian': '意大利',
            'Japanese': '日本',
            'Lebanese': '黎巴嫩',
            'Korean': '韩国',
            'Malaysian': '马来西亚',
            'Mexican': '墨西哥',
            'Middle Eastern': '中东',
            'Spanish': '西班牙',
            'Thai': '泰国',
            'Vietnamese': '越南',
            'Avocado': '鳄梨',
            'Broccoli': '西兰花',
            'Cauliflower': '花椰菜',
            'Carrot': '胡萝卜',
            'Garlic': '大蒜',
            'Mushroom': '蘑菇',
            'Onion': '洋葱',
            'Pasta': '意大利面',
            'Potatoes': '土豆',
            'Quinoa': '藜麦',
            'Rice': '米饭',
            'Spinach': '菠菜',
            'Sweet Potatoes': '红薯',
            'Tomatoes': '番茄',
            'Tofu': '豆腐',
            'Baking': '烘焙',
            'Barbecue': '烧烤',
            'Frying': '油炸',
            'Roasting': '烘烤',
            'Sauteing': '炒',
            'Slow cooking': '慢煮',
            'Steaming': '蒸',
            'Stir frying': '炒',
            'Bean': '豆',
            'Beef': '牛肉',
            'Cheese': '奶酪',
            'Chicken': '鸡',
            'Egg': '鸡蛋',
            'Fish': '鱼',
            'Lamb': '羊肉',
            'Lentil': '扁豆',
            'Nut': '坚果',
            'Pork': '猪肉',
            'Seafood': '海鲜',
            'Turkey': '火鸡',
            'Venison': '鹿肉',
            '< 10 mins': '< 10 分钟',
            '< 20 mins': '< 20 分钟',
            '< 30 mins': '< 30 分钟',
            '< 45 mins': '< 45 分钟',
            '< 60 mins': '< 60 分钟'
        }
    }
    else if (language === "korean") {
        filtersDict = {
            'Breakfast': '아침',
            'Brunch': '브런치',
            'Lunch': '점심',
            'Dinner': '저녁',
            'Dessert': '디저트',
            'Snack': '간식',
            'Drinks': '음료',
            'Appetisers': '애피타이저',
            'Celebration': '축하',
            'Cocktail Party': '칵테일 파티',
            'Crowd Pleaser': '관객 환호',
            'Picnic': '소풍',
            'Potluck': '자선',
            'Chinese': '중국어',
            'Ethiopian': '에티오피아',
            'French': '프랑스 국민',
            'Greek': '그리스인',
            'Indian': '인도인',
            'Italian': '이탈리아인',
            'Japanese': '일본어',
            'Lebanese': '레바논',
            'Korean': '한국어',
            'Malaysian': '말레이시아',
            'Mexican': '멕시코',
            'Middle Eastern': '중동',
            'Spanish': '스페인어',
            'Thai': '태국어',
            'Vietnamese': '베트남어',
            'Avocado': '아보카도',
            'Broccoli': '브로콜리',
            'Cauliflower': '콜리플라워',
            'Carrot': '당근',
            'Garlic': '마늘',
            'Mushroom': '버섯',
            'Onion': '양파',
            'Pasta': '파스타',
            'Potatoes': '감자',
            'Quinoa': '퀴노아',
            'Rice': '쌀',
            'Spinach': '시금치',
            'Sweet Potatoes': '고구마',
            'Tomatoes': '토마토',
            'Tofu': '두부',
            'Baking': '베이킹',
            'Barbecue': '바베큐',
            'Frying': '튀김',
            'Roasting': '구운',
            'Sauteing': '볶음',
            'Slow cooking': '느린 요리',
            'Steaming': '증기',
            'Stir frying': '볶음',
            'Bean': '콩',
            'Beef': '소고기',
            'Cheese': '치즈',
            'Chicken': '닭고기',
            'Egg': '계란',
            'Fish': '물고기',
            'Lamb': '양고기',
            'Lentil': '렌틸',
            'Nut': '견과류',
            'Pork': '돼지고기',
            'Seafood': '해산물',
            'Turkey': '칠면조',
            'Venison': '사슴고기',
            '< 10 mins': '< 10 분',
            '< 20 mins': '< 20 분',
            '< 30 mins': '< 30 분',
            '< 45 mins': '< 45 분',
            '< 60 mins': '< 60 분'
        }
    }
    else if (language === "japanese") {
        filtersDict = {
            'Breakfast': '朝食',
            'Brunch': 'ブランチ',
            'Lunch': '昼食',
            'Dinner': '夕食',
            'Dessert': 'デザート',
            'Snack': 'おやつ',
            'Drinks': '飲み物',
            'Appetisers': '前菜',
            'Celebration': '祝賀',
            'Cocktail Party': 'カクテルパーティー',
            'Crowd Pleaser': '群衆の喜び',
            'Picnic': 'ピクニック',
            'Potluck': 'ポットラック',
            'Chinese': '中国語',
            'Ethiopian': 'エチオピア',
            'French': 'フランス語',
            'Greek': 'ギリシャ語',
            'Indian': 'インド',
            'Italian': 'イタリア語',
            'Japanese': '日本語',
            'Lebanese': 'レバノン',
            'Korean': '韓国語',
            'Malaysian': 'マレーシア',
            'Mexican': 'メキシコ',
            'Middle Eastern': '中東',
            'Spanish': 'スペイン語',
            'Thai': 'タイ語',
            'Vietnamese': 'ベトナム語',
            'Avocado': 'アボカド',
            'Broccoli': 'ブロッコリー',
            'Cauliflower': 'カリフラワー',
            'Carrot': '人参',
            'Garlic': 'にんにく',
            'Mushroom': 'キノコ',
            'Onion': 'タマネギ',
            'Pasta': 'パスタ',
            'Potatoes': 'じゃがいも',
            'Quinoa': 'キヌア',
            'Rice': '米',
            'Spinach': 'ほうれん草',
            'Sweet Potatoes': 'サツマイモ',
            'Tomatoes': 'トマト',
            'Tofu': '豆腐',
            'Baking': 'ベーキング',
            'Barbecue': 'バーベキュー',
            'Frying': 'フライ',
            'Roasting': '焼く',
            'Sauteing': '炒める',
            'Slow cooking': 'スロークッキング',
            'Steaming': '蒸気',
            'Stir frying': '炒める',
            'Bean': '豆',
            'Beef': '牛肉',
            'Cheese': 'チーズ',
            'Chicken': '鶏肉',
            'Egg': '卵',
            'Fish': '魚',
            'Lamb': '羊肉',
            'Lentil': 'レンズ豆',
            'Nut': 'ナッツ',
            'Pork': '豚肉',
            'Seafood': 'シーフード',
            'Turkey': '七面鳥',
            'Venison': '鹿肉',
            '< 10 mins': '< 10 分',
            '< 20 mins': '< 20 分',
            '< 30 mins': '< 30 分',
            '< 45 mins': '< 45 分',
            '< 60 mins': '< 60 分'
        }
    }
    else if (language === "french") {
        filtersDict = {
            'Breakfast': 'Petit déjeuner',
            'Brunch': 'Brunch',
            'Lunch': 'Déjeuner',
            'Dinner': 'Dîner',
            'Dessert': 'Dessert',
            'Snack': 'Collation',
            'Drinks': 'Boissons',
            'Appetisers': 'Apéritifs',
            'Celebration': 'Célébration',
            'Cocktail Party': 'Cocktail Party',
            'Crowd Pleaser': 'Plaisir de la foule',
            'Picnic': 'Pique-nique',
            'Potluck': 'Potluck',
            'Chinese': 'Chinois',
            'Ethiopian': 'Éthiopien',
            'French': 'Français',
            'Greek': 'Grec',
            'Indian': 'Indien',
            'Italian': 'Italien',
            'Japanese': 'Japonais',
            'Lebanese': 'Libanais',
            'Korean': 'Coréen',
            'Malaysian': 'Malaisien',
            'Mexican': 'Mexicain',
            'Middle Eastern': 'Moyen-Orient',
            'Spanish': 'Espagnol',
            'Thai': 'Thaïlandais',
            'Vietnamese': 'Vietnamien',
            'Avocado': 'Avocat',
            'Broccoli': 'Brocoli',
            'Cauliflower': 'Chou-fleur',
            'Carrot': 'Carotte',
            'Garlic': 'Ail',
            'Mushroom': 'Champignon',
            'Onion': 'Oignon',
            'Pasta': 'Pâtes',
            'Potatoes': 'Pommes de terre',
            'Quinoa': 'Quinoa',
            'Rice': 'Riz',
            'Spinach': 'Épinards',
            'Sweet Potatoes': 'Patates douces',
            'Tomatoes': 'Tomates',
            'Tofu': 'Tofu',
            'Baking': 'Cuisson',
            'Barbecue': 'Barbecue',
            'Frying': 'Friture',
            'Roasting': 'Rôtissage',
            'Sauteing': 'Sauter',
            'Slow cooking': 'Cuisson lente',
            'Steaming': 'Vapeur',
            'Stir frying': 'Sauter',
            'Bean': 'Haricot',
            'Beef': 'Bœuf',
            'Cheese': 'Fromage',
            'Chicken': 'Poulet',
            'Egg': 'Œuf',
            'Fish': 'Poisson',
            'Lamb': 'Agneau',
            'Lentil': 'Lentille',
            'Nut': 'Noix',
            'Pork': 'Porc',
            'Seafood': 'Fruits de mer',
            'Turkey': 'Dinde',
            'Venison': 'Venaison',
            '< 10 mins': '< 10 min',
            '< 20 mins': '< 20 min',
            '< 30 mins': '< 30 min',
            '< 45 mins': '< 45 min',
            '< 60 mins': '< 60 min'
        }
    }
    else if (language === "spanish") {
        filtersDict = {
            'Breakfast': 'Desayuno',
            'Brunch': 'Brunch',
            'Lunch': 'Almuerzo',
            'Dinner': 'Cena',
            'Dessert': 'Postre',
            'Snack': 'Bocadillo',
            'Drinks': 'Bebidas',
            'Appetisers': 'Aperitivos',
            'Celebration': 'Celebración',
            'Cocktail Party': 'Cóctel',
            'Crowd Pleaser': 'Placer de la multitud',
            'Picnic': 'Picnic',
            'Potluck': 'Potluck',
            'Chinese': 'Chino',
            'Ethiopian': 'Etíope',
            'French': 'Francés',
            'Greek': 'Griego',
            'Indian': 'Indio',
            'Italian': 'Italiano',
            'Japanese': 'Japonés',
            'Lebanese': 'Libanés',
            'Korean': 'Coreano',
            'Malaysian': 'Malayo',
            'Mexican': 'Mexicano',
            'Middle Eastern': 'Medio Oriente',
            'Spanish': 'Español',
            'Thai': 'Tailandés',
            'Vietnamese': 'Vietnamita',
            'Avocado': 'Aguacate',
            'Broccoli': 'Brócoli',
            'Cauliflower': 'Coliflor',
            'Carrot': 'Zanahoria',
            'Garlic': 'Ajo',
            'Mushroom': 'Seta',
            'Onion': 'Cebolla',
            'Pasta': 'Pasta',
            'Potatoes': 'Patatas',
            'Quinoa': 'Quinua',
            'Rice': 'Arroz',
            'Spinach': 'Espinacas',
            'Sweet Potatoes': 'Batatas',
            'Tomatoes': 'Tomates',
            'Tofu': 'Tofu',
            'Baking': 'Horneado',
            'Barbecue': 'Barbacoa',
            'Frying': 'Fritura',
            'Roasting': 'Asado',
            'Sauteing': 'Salteado',
            'Slow cooking': 'Cocción lenta',
            'Steaming': 'Vapor',
            'Stir frying': 'Salteado',
            'Bean': 'Frijol',
            'Beef': 'Carne de res',
            'Cheese': 'Queso',
            'Chicken': 'Pollo',
            'Egg': 'Huevo',
            'Fish': 'Pescado',
            'Lamb': 'Cordero',
            'Lentil': 'Lenteja',
            'Nut': 'Nuez',
            'Pork': 'Cerdo',
            'Seafood': 'Mariscos',
            'Turkey': 'Pavo',
            'Venison': 'Venado',
            '< 10 mins': '< 10 min',
            '< 20 mins': '< 20 min',
            '< 30 mins': '< 30 min',
            '< 45 mins': '< 45 min',
            '< 60 mins': '< 60 min'
        }
    }
    else if (language === "german") {
        filtersDict = {
            'Breakfast': 'Frühstück',
            'Brunch': 'Brunch',
            'Lunch': 'Mittagessen',
            'Dinner': 'Abendessen',
            'Dessert': 'Nachspeise',
            'Snack': 'Snack',
            'Drinks': 'Getränke',
            'Appetisers': 'Vorspeisen',
            'Celebration': 'Feier',
            'Cocktail Party': 'Cocktailparty',
            'Crowd Pleaser': 'Publikumsliebling',
            'Picnic': 'Picknick',
            'Potluck': 'Potluck',
            'Chinese': 'Chinesisch',
            'Ethiopian': 'Äthiopisch',
            'French': 'Französisch',
            'Greek': 'Griechisch',
            'Indian': 'Indisch',
            'Italian': 'Italienisch',
            'Japanese': 'Japanisch',
            'Lebanese': 'Libanesisch',
            'Korean': 'Koreanisch',
            'Malaysian': 'Malaysisch',
            'Mexican': 'Mexikanisch',
            'Middle Eastern': 'Mittlerer Osten',
            'Spanish': 'Spanisch',
            'Thai': 'Thailändisch',
            'Vietnamese': 'Vietnamesisch',
            'Avocado': 'Avocado',
            'Broccoli': 'Brokkoli',
            'Cauliflower': 'Blumenkohl',
            'Carrot': 'Karotte',
            'Garlic': 'Knoblauch',
            'Mushroom': 'Pilz',
            'Onion': 'Zwiebel',
            'Pasta': 'Nudeln',
            'Potatoes': 'Kartoffeln',
            'Quinoa': 'Quinoa',
            'Rice': 'Reis',
            'Spinach': 'Spinat',
            'Sweet Potatoes': 'Süßkartoffeln',
            'Tomatoes': 'Tomaten',
            'Tofu': 'Tofu',
            'Baking': 'Backen',
            'Barbecue': 'Grillen',
            'Frying': 'Braten',
            'Roasting': 'Rösten',
            'Sauteing': 'Anbraten',
            'Slow cooking': 'Langsam kochen',
            'Steaming': 'Dämpfen',
            'Stir frying': 'Rühren braten',
            'Bean': 'Bohne',
            'Beef': 'Rindfleisch',
            'Cheese': 'Käse',
            'Chicken': 'Huhn',
            'Egg': 'Ei',
            'Fish': 'Fisch',
            'Lamb': 'Lamm',
            'Lentil': 'Linse',
            'Nut': 'Nuss',
            'Pork': 'Schweinefleisch',
            'Seafood': 'Meeresfrüchte',
            'Turkey': 'Truthahn',
            'Venison': 'Hirschfleisch',
            '< 10 mins': '< 10 min',
            '< 20 mins': '< 20 min',
            '< 30 mins': '< 30 min',
            '< 45 mins': '< 45 min',
            '< 60 mins': '< 60 min'
        }
    }
    else if (language === "italian") {
        filtersDict = {
            'Breakfast': 'Colazione',
            'Brunch': 'Brunch',
            'Lunch': 'Pranzo',
            'Dinner': 'Cena',
            'Dessert': 'Dolce',
            'Snack': 'Spuntino',
            'Drinks': 'Bevande',
            'Appetisers': 'Antipasti',
            'Celebration': 'Celebrazione',
            'Cocktail Party': 'Festa del cocktail',
            'Crowd Pleaser': 'Piacere della folla',
            'Picnic': 'Picnic',
            'Potluck': 'Potluck',
            'Chinese': 'Cinese',
            'Ethiopian': 'Etiopico',
            'French': 'Francese',
            'Greek': 'Greco',
            'Indian': 'Indiano',
            'Italian': 'Italiano',
            'Japanese': 'Giapponese',
            'Lebanese': 'Libanese',
            'Korean': 'Coreano',
            'Malaysian': 'Malese',
            'Mexican': 'Messicano',
            'Middle Eastern': 'Medio orientale',
            'Spanish': 'Spagnolo',
            'Thai': 'Tailandese',
            'Vietnamese': 'Vietnamita',
            'Avocado': 'Avocado',
            'Broccoli': 'Broccoli',
            'Cauliflower': 'Cavolfiore',
            'Carrot': 'Carota',
            'Garlic': 'Aglio',
            'Mushroom': 'Fungo',
            'Onion': 'Cipolla',
            'Pasta': 'Pasta',
            'Potatoes': 'Patate',
            'Quinoa': 'Quinoa',
            'Rice': 'Riso',
            'Spinach': 'Spinaci',
            'Sweet Potatoes': 'Patate dolci',
            'Tomatoes': 'Pomodori',
            'Tofu': 'Tofu',
            'Baking': 'Cottura',
            'Barbecue': 'Barbecue',
            'Frying': 'Frittura',
            'Roasting': 'Arrosto',
            'Sauteing': 'Saltare',
            'Slow cooking': 'Cottura lenta',
            'Steaming': 'Vapore',
            'Stir Frying': 'Saltare in padella',
            'Bean': 'Fagiolo',
            'Beef': 'Manzo',
            'Cheese': 'Formaggio',
            'Chicken': 'Pollo',
            'Egg': 'Uovo',
            'Fish': 'Pesce',
            'Lamb': 'Agnello',
            'Lentil': 'Lenticchia',
            'Nut': 'Noce',
            'Pork': 'Maiale',
            'Seafood': 'Frutti di mare',
            'Turkey': 'Tacchino',
            'Venison': 'Cervo',
            '< 10 mins': '< 10 min',
            '< 20 mins': '< 20 min',
            '< 30 mins': '< 30 min',
            '< 45 mins': '< 45 min',
            '< 60 mins': '< 60 min'
        }
    }

    return filtersDict[filter]

}

export function translateShareOptions() {

    const language = Cookies.get("language") || "english";

    let languages;
    if (language === "english") { languages = { 'chinese': 'Chinese', 'english': 'English', 'french': 'French', 'german': 'German', 'italian': 'Italian', 'japanese': 'Japanese', 'korean': 'Korean', 'spanish': 'Spanish', 'vietnamese': 'Vietnamese'}}
    else if (language === "vietnamese") { languages = { 'chinese': 'Tiếng Trung', 'english': 'Tiếng Anh', 'french': 'Tiếng Pháp', 'german': 'Tiếng Đức', 'italian': 'Tiếng Ý', 'japanese': 'Tiếng Nhật', 'korean': 'Tiếng Hàn', 'spanish': 'Tiếng Tây Ban Nha', 'vietnamese': 'Tiếng Việt' } }
    else if (language === "chinese") { languages = { 'chinese': '中文', 'english': '英语', 'french': '法语', 'german': '德语', 'italian': '意大利语', 'japanese': '日语', 'korean': '韩语', 'spanish': '西班牙语', 'vietnamese': '越南语' } }
    else if (language === "korean") { languages = { 'chinese': '중국어', 'english': '영어', 'french': '프랑스어', 'german': '독일어', 'italian': '이탈리아어', 'japanese': '일본어', 'korean': '한국어', 'spanish': '스페인어', 'vietnamese': '베트남어' } }
    else if (language === "japanese") { languages = { 'chinese': '中国語', 'english': '英語', 'french': 'フランス語', 'german': 'ドイツ語', 'italian': 'イタリア語', 'japanese': '日本語', 'korean': '韓国語', 'spanish': 'スペイン語', 'vietnamese': 'ベトナム語' } }
    else if (language === "french") { languages = { 'chinese': 'Chinois', 'english': 'Anglais', 'french': 'Français', 'german': 'Allemand', 'italian': 'Italien', 'japanese': 'Japonais', 'korean': 'Coréen', 'spanish': 'Espagnol', 'vietnamese': 'Vietnamien' } }
    else if (language === "spanish") { languages = { 'chinese': 'Chino', 'english': 'Inglés', 'french': 'Francés', 'german': 'Alemán', 'italian': 'Italiano', 'japanese': 'Japonés', 'korean': 'Coreano', 'spanish': 'Español', 'vietnamese': 'Vietnamita' } }
    else if (language === "german") { languages = { 'chinese': 'Chinesisch', 'english': 'Englisch', 'french': 'Französisch', 'german': 'Deutsch', 'italian': 'Italienisch', 'japanese': 'Japanisch', 'korean': 'Koreanisch', 'spanish': 'Spanisch', 'vietnamese': 'Vietnamesisch' } }
    else if (language === "italian") { languages = { 'chinese': 'Cinese', 'english': 'Inglese', 'french': 'Francese', 'german': 'Tedesco', 'italian': 'Italiano', 'japanese': 'Giapponese', 'korean': 'Coreano', 'spanish': 'Spagnolo', 'vietnamese': 'Vietnamita' } }

    return languages

}


// else if (language === "chinese") { languages = { 'english': '英语', 'vietnamese': '越南语', 'chinese': '中文', 'korean': '韩语', 'japanese': '日语', 'french': '法语', 'spanish': '西班牙语', 'german': '德语', 'italian': '意大利语' } }
//     else if (language === "korean") { languages = { 'english': '영어', 'vietnamese': '베트남어', 'chinese': '중국어', 'korean': '한국어', 'japanese': '일본어', 'french': '프랑스어', 'spanish': '스페인어', 'german': '독일어', 'italian': '이탈리아어' } }
//     else if (language === "japanese") { languages = { 'english': '英語', 'vietnamese': 'ベトナム語', 'chinese': '中国語', 'korean': '韓国語', 'japanese': '日本語', 'french': 'フランス語', 'spanish': 'スペイン語', 'german': 'ドイツ語', 'italian': 'イタリア語' } }
//     else if (language === "french") { languages = { 'english': 'Anglais', 'vietnamese': 'Vietnamien', 'chinese': 'Chinois', 'korean': 'Coréen', 'japanese': 'Japonais', 'french': 'Français', 'spanish': 'Espagnol', 'german': 'Allemand', 'italian': 'Italien' } }
//     else if (language === "spanish") { languages = { 'english': 'Inglés', 'vietnamese': 'Vietnamita', 'chinese': 'Chino', 'korean': 'Coreano', 'japanese': 'Japonés', 'french': 'Francés', 'spanish': 'Español', 'german': 'Alemán', 'italian': 'Italiano' } }
//     else if (language === "german") { languages = { 'english': 'Englisch', 'vietnamese': 'Vietnamesisch', 'chinese': 'Chinesisch', 'korean': 'Koreanisch', 'japanese': 'Japanisch', 'french': 'Französisch', 'spanish': 'Spanisch', 'german': 'Deutsch', 'italian': 'Italienisch' } }
//     else if (language === "italian") { languages = { 'english': 'Inglese', 'vietnamese': 'Vietnamita', 'chinese': 'Cinese', 'korean': 'Coreano', 'japanese': 'Giapponese', 'french': 'Francese', 'spanish': 'Spagnolo', 'german': 'Tedesco', 'italian': 'Italiano' } }
