import './edit_email_marketing.css'
import React, { useEffect, useState } from 'react'
import ButtonPrimary from '../../components/button/button_primary.js'
import ButtonSecondary from '../../components/button/button_secondary.js'
import Cookies from 'js-cookie'
import ButtonCancel from '../../components/button/button_cancel';

function EditEmailMarketingPanel(props) {

    const [emailMarketing, setEmailMarketing] = useState(); 
    const session_id = Cookies.get('session_id');

    useEffect(() => {
        setEmailMarketing(null);
    }, [props.email_marketing]) 

    function updateEmailMarketing() {

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ session_id: session_id, email_marketing: emailMarketing })
        }

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserEmailMarketing", options)
            .then(response => response.json())
            .then(data => { 
                if (emailMarketing === 1) {
                    props.setEmailMarketing("Yes");
                } else {
                    props.setEmailMarketing("No");
                }
                
                props.setShowEmailMarketingPanel(false);
            })
    }


    return (
        <div className="edit_email_marketing_panel_container" onClick={() => props.setShowEmailMarketingPanel(false)}>
            <div className="edit_email_marketing_panel_content" onClick={(e) => e.stopPropagation()}>
                <div className="edit_email_marketing_panel_header">
                    <h1 className="edit_email_marketing_panel_header_title">Edit Marketing Preference</h1>
                    <p>Choose whether you want to receive marketing emails.</p>
                </div>
                <div className="edit_email_marketing_panel_body">
                    <div className="edit_email_marketing_panel_body_item"> 
                        {emailMarketing === null ? 
                            <>
                                <ButtonSecondary name="Yes, send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(1)} /> 
                                <ButtonSecondary name="No, don't send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(0)} />
                            </>
                        : null}
                        {emailMarketing === 1 ? 
                            <>
                                <ButtonPrimary name="Yes, send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(1)} /> 
                                <ButtonSecondary name="No, don't send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(0)} />
                            </>
                        : null}
                        
                        {emailMarketing === 0 ? 
                            <>
                                <ButtonSecondary name="Yes, send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(1)} /> 
                                <ButtonPrimary name="No, don't send me marketing emails" margin="bottom" width="100%" action={() => setEmailMarketing(0)} />
                            </>
                        : null}
                    </div>
                </div>
                <div className="edit_email_marketing_panel_footer">
                    <ButtonPrimary name="Update Preference" margin="right" action={() => updateEmailMarketing()} icon="save" />
                    <ButtonCancel name="Nevermind" icon="undo" action={() => props.setShowEmailMarketingPanel(false)} />
                </div>
            </div>
        </div>
    )

}

export default EditEmailMarketingPanel;