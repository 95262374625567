import './introduction_mobile.css';
import React from 'react';
import Cookies from 'js-cookie';
import ButtonPrimary from '../../components/button/button_primary.js';  

function IntroductionMobile() {

    const session_id = Cookies.get('session_id');

    function createNewRecipe() {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ session_id: session_id }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createNewRecipe', options)
            .then(response => response.json())
            .then(data => {
                window.location.href = '/create/' + data.create_recipe_id + '/new-recipe';
            });
    }

    return (
        <div className="introduction_mobile_container">
            <div className="introduction_mobile_section_container">
                <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1192x600_my_collection_header_background.webp" alt="Introduction Background" className="introduction_mobile_header_background" />
                <div className="introduction_mobile_section_background"></div>
                <div className="introduction_mobile_header_content_container" >
                    <h1 className="introduction_mobile_header_title">Curate your personalised<br />recipe collection.</h1>
                    <p className="introduction_mobile_header_info">Measured to perfection.</p>
                    <div>
                        <img className="introduction_mobile_header_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/900x716_introduction_my_collection.webp" alt="Recipe Collection" />
                    </div>
                </div>
            </div>
            <div className="introduction_mobile_section_container">
                <div className="introduction_mobile_section_background"></div>
                <div className="introduction_mobile_header_content_container" >
                    <h1 className="introduction_mobile_secion_header">Create and publish your recipes.</h1>
                    <p className="introduction_mobile_section_text">Create personalised recipes with our recipe studio. When you're ready, publish and share it with the world!</p>
                    <div className="introduction_desktop_section_button">
                        <ButtonPrimary name="Create your first recipe" icon="new_window" action={createNewRecipe}/>
                    </div>
                    <div className="introduction_mobile_section_image_container">
                        <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/create_recipe.gif" alt="Create Recipe" className="introduction_mobile_section_curate_image" />
                    </div>
                </div>
            </div> 
            <div className="introduction_mobile_section_container">
                <div className="introduction_mobile_section_background"></div>
                <div className="introduction_mobile_header_content_container" >
                    <h1 className="introduction_mobile_secion_header">Discover and curate your collection.</h1>
                    <p className="introduction_mobile_section_text">Explore recipes from our community and simply click <span style={{fontWeight:'500', color:'var(--primary-color)'}}>Add to My Collection</span> to add a recipe to your collection.</p>
                    <div className="introduction_mobile_section_image_container">
                        <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/add_to_collection.gif" alt="Create Recipe" className="introduction_mobile_section_curate_image" />
                    </div>
                </div>
            </div> 
            <div className="introduction_mobile_section_container">
                <div className="introduction_mobile_section_background"></div>
                <div className="introduction_mobile_header_content_container" >
                <h1 className="introduction_mobile_secion_header">Tweak recipes to your taste.</h1>
                    <p className="introduction_mobile_section_text">Too sweet? Not enough acidity? Tweak an existing recipe to make it personalised for you. Just click <span style={{fontWeight:'500', color:'var(--primary-color)'}}>Create Variation</span> in a recipe page to start tweaking.</p>
                    <div className="introduction_mobile_section_image_container">
                        <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesmisc/create_variation.gif" alt="Create Recipe" className="introduction_mobile_section_curate_image" />
                    </div>
                </div>
            </div>  
        </div>
    )

}

export default IntroductionMobile;