import './feedback.css'
import React, { useState, useEffect, useRef } from "react";
import { translate } from '../../translations/translations.js';

function Feedback() {

    useEffect(()=> {
        document.title = "Garlic Basil - Feedback Form"
    },[])

    const [feedbackCaptured, setFeedbackCaptured] = useState(false);
    const emailAddress = useRef();
    const name = useRef();
    const feedback = useRef();

    function sendFeedback(event) {
        event.preventDefault()

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: emailAddress.current.value,
                name: name.current.value,
                feedback: feedback.current.value
            })
        }

        fetch(process.env.REACT_APP_API_URL + "/api/sendFeedback", options)
        .then(response => response.json())
        .then(json => {

            if (json['status'] === 'true') {
                setFeedbackCaptured(true);
            }   
        })
    }

    return (
        <div className="feedback_container">
            <div className="feedback_content">
                <span className="feedback_content_title">{translate('feedback', 'feedback title')}</span>
                <p className="feedback_text">{translate('feedback', 'subtitle')}</p>
                <form onSubmit={sendFeedback}>
                    <div className="feedback_form">
                        <div className="feedback_form_row">
                            <div className="feedback_email_and_name_input_container">
                                <div className="feedback_email_input_container">
                                    <label>{translate('feedback', 'email')}</label><br/>
                                    <input className="feedback_form_input_email" type="text" name="email" ref={emailAddress} />
                                </div>
                                <div className="feedback_name_input_container">
                                    <label>{translate('feedback', 'name')}</label><br/>
                                    <input className="feedback_form_input_name" type="text" name="name" ref={name} />
                                </div>
                            </div>

                            <label>{translate('feedback', 'feedback')}</label>
                            <textarea className="feedback_form_input_feedback" name="message"  ref={feedback} required></textarea>
                            <button className="feedback_form_submit" type="submit">{translate('feedback', 'Share feedback')}</button>
                            
                        </div>
                        {feedbackCaptured ? <p className="feedback_form_success">Thank you for sharing your feedback!</p>: null}
                        {/* <p className="feedback_form_success">Thank you for sharing your feedback!</p> */}
                    </div>
                </form>
            </div>
            <img className="feedback_background_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/feedback.jpg" alt="feedback background" />
        </div>
    );
}

export default Feedback;

