import "./recipe_tile.css"
import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';

import RecipeHeader from '../recipe_header/recipe_header.js' 

function RecipeTile(props) {
    
    const [imageError, setImageError] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

        let imageSizePrefix = ''
        if (props.imageSize === 'small') {
            imageSizePrefix = '400x400_'
        } else if (props.imageSize === 'medium') {
            imageSizePrefix = '800x800_'
        }

        if (props.creation === true) {
            if (props.create_user_upload_image === 1) {
                setImageURL("https://thesiliconkitchenimages.blob.core.windows.net/imagescreateupload/" + imageSizePrefix + props.recipe_id + ".webp?key=" + uniqueKey())
            } else {
                setImageURL("https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/" + imageSizePrefix +  props.image_id + ".webp")
            }
        } else {
            if (props.image === 1) {
                setImageURL("https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/" + imageSizePrefix + props.recipe_id + ".webp")
            } else {
                setImageURL("https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/" + imageSizePrefix + "0.webp")
            }
        } 


    }, [props.image_id, props.recipe_id, props.creation, props.imageSize, props.create_user_upload_image, props.image]);

    const uniqueKey = () => {
        return Date.now();
    }

    const handleImageError = () => { 
        setImageError(true);
    };

    function handleClick(event, recipe_id) {
        
        if (props.recipe_id !== 0) {
            if (props.creation === true) {
                if (event.metaKey || event.ctrlKey) {
                    window.open("/create/" + recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase(), "_blank")
                } else {
                    navigate("/create/" + recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase());
                }
            } else {
                if (event.metaKey || event.ctrlKey) {
                    window.open("/recipe/" + recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase(), "_blank")
                } else {
                    navigate("/recipe/" + recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase());
                }
            }
        }
    }

    function handleAuxClick(event) {
        if (event.button === 1) {
            event.preventDefault();
            window.open("recipe/" + props.recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase(), "_blank")
        }
    } 

    return (
        <div className="recipe_tile_container place_holder_container" onClick={(event) => handleClick(event, props.recipe_id)} style={{width: props.width}} >
            {props.recipe_id !== 0 ?
                <div className="recipe_tile_container_for_snap" key={props.name + '_a_click'} onAuxClick={handleAuxClick}>
                    {imageError ? <img loading="lazy" className="recipe_tile_img" src={"https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/400x400_0.webp"} alt={props.name} /> :
                    <picture>
                        <img loading="lazy" onError={handleImageError} className="recipe_tile_img" srcSet={imageURL} type="image/webp" alt="" />
                    </picture>
                    }   
                    {/* <div className="recipe_tile_favourite_button"> */}
                        {/* <FavouriteButton recipe_id={props.recipe_id} favourite={props.favourite} iconSize={'24px'} showAlertMessage={props.showAlertMessage}/> */}
                    {/* </div> */}
                    <RecipeHeader name={props.name} fontSize="font_size_18" createType={props.createType}/>
                </div>
                :
                null
            }
        </div>
    )
}

export default RecipeTile;
