import React, { useState } from "react"
import Cookies from "js-cookie";
import "./recipes_item.css"
import { translate } from "../../../translations/translations.js"

function RecipesItem(props) {

    const session_id = Cookies.get("session_id")
    const [servings, setServings] = useState(props.servings);

    let status = []
    const barWidth = ((880 - (props.ingredient_count * 3)) / props.ingredient_count)

    for (var i=0; i < props.ingredient_count; i++) {
        if (i < props.completed) {
            status.push(true)
        } else {
            status.push(false)
        }
    } 

    function removeRecipe() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({session_id: session_id, recipe_id: props.recipe_id}),
        };  

        fetch(process.env.REACT_APP_API_URL + "/api/removeRecipeFromUserShoppingList", options)
        .then(() => (props.updateShoppingList()))

    }

    function decreaseServing() {
        if (servings > 1) {
            setServings(servings - 1);
            updateServingsInDatabase(servings - 1)
        }   
    }

    function increaseServing() {
        if (servings < 20) {
            setServings(servings + 1);
            updateServingsInDatabase(servings + 1)
        }
    }

    function updateServingsInDatabase(servings_count) {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({session_id: session_id, recipe_id: props.recipe_id, servings: servings_count}),
        };  

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserRecipeServings", options)

        props.updateIngredientsQuantity(props.recipe_id, servings_count)

    }

    function goToRecipe() {
        window.location.href = "/recipe/" + props.recipe_id + "/" + props.name.replace(/ /g, "-").toLowerCase()
    }

    return (
        <div className="recipes_item_container">
            <div className="recipes_item_info_container">

                <div className="recipes_item_info_left_side">
                    <span className="recipes_item_remove">
                        {/* <ButtonClose action={removeRecipe} /> */}
                        <button className="recipes_item_remove_button" onClick={removeRecipe} ><span className="material-symbols-outlined recipes_item_remove_icon">close</span></button>
                        {/* <button className="recipes_item_remove_button" onClick={removeRecipe} >X</button> */}
                    </span>  
                    <p className="recipes_item_name" onClick={goToRecipe} >{props.name}</p>
                </div>
                  
                <div className="recipes_item_info_right_side">
                    <span className="recipes_item_servings">
                        <button className="recipe_item_serving_adjust_button_minus" onClick={decreaseServing} ><span className="material-symbols-outlined serving_adjust_button_icon">remove</span></button>
                            <p className="recipes_item_serving_text">{window.innerWidth < 768 ? servings: null}{window.innerWidth < 768 ? <span className="material-symbols-outlined servings_person_icon">person</span>: translate('recipe', 'Servings') + ": " + servings}</p>
                        <button className="recipe_item_serving_adjust_button_add" onClick={increaseServing}><span className="material-symbols-outlined serving_adjust_button_icon">add</span></button>
                    </span>                
                </div>
            </div>
            <div className="recipes_item_status_container">
                {status.map((item, index) => (
                    <span key={index} className="recipes_item_status_bar" style={{width: barWidth, backgroundColor: item ? "var(--primary-color)": "lightgrey"}} ></span>
                ))}
            </div>
        </div>
    )
}

export default RecipesItem;