import './publish_recipe.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'
import ButtonPrimary from '../../components/button/button_primary.js'; 
import PopUp from '../../components/pop_up/pop_up.js';

function PublishRecipe(props) {

    const [showPublishRecipe, setShowPublishRecipe] = useState(false);
    const [showPublishSuccess, setShowPublishSuccess] = useState(false);
    const [newRecipeID, setNewRecipeID] = useState(null);
    const [recipeName, setRecipeName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [publishType, setPublishType] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [publishedCount, setPublishedCount] = useState(0);
    const [showPublishRemaining, setShowPublishRemaining] = useState(false);
    const [requiredMessage, setRequiredMessage] = useState([]);
    const [showRequiredMessage, setShowRequiredMessage] = useState(false);

    const session_id = Cookies.get('session_id');
    const navigate = useNavigate();

    useEffect(() => {
        if (props.createType === 'new') {
            setPublishType('recipe');
        } else if (props.createType === 'edit') {
            setPublishType('edit');
        } else if (props.createType === 'variation') {
            setPublishType('variation');
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createGetUserDailyPublishedCount', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setPublishedCount(data.daily_published_count);
                    if (data.daily_published_count >= 10) {
                        setErrorMessage(<>You have reached your daily limit of 10 publications. <br /> Please publish tomorrow.</>);
                    } else {
                        setShowPublishRemaining(true)
                    }
                }
            })
    }, [props.createType, session_id]);


    function publishRecipe() {
        setIsLoading(true);
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID, create_type: props.createType })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createPublishRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setNewRecipeID(data.recipe_id);
                    setRecipeName(data.recipe_name);
                    setShowPublishRecipe(false);
                    setShowPublishSuccess(true);
                    setIsLoading(false);
                } else {
                    if (data.message === 'daily limit reached') {
                        setErrorMessage(<>You have reached your daily limit of 10 publications. <br /> Please try again tomorrow.</>);
                    } else {
                        setErrorMessage(data.message);
                    }
                    setIsLoading(false);
                }
            });
    }

    function goToPublishedRecipe() {
        navigate('/recipe/' + newRecipeID + '/' + recipeName.replace(/ /g, '-').toLowerCase());
    }

    function checkPublishReady(e) {
        e.stopPropagation();
        const nameValue = document.getElementById('create_content_name_value_element').value;
        const ingredientItems = document.getElementById('create_content_ingredient_list_element');
        const instructionItems = document.getElementById('create_instructions_step_text_area_id1');
        const servingsValue = document.getElementById('create_content_servings_value_element').value;
        const cookingTimeValue = document.getElementById('create_content_cooking_time_value_element').value;

        let requiredMessageTmp = []; 
        if (nameValue === '' || nameValue === null) {
            requiredMessageTmp.push('Give this recipe a name');
        } 
        if (ingredientItems.children.length === 0 || ingredientItems.children.length === null) {
            requiredMessageTmp.push('Add at least 1 ingredient to the recipe');
        } 
        if (instructionItems.value === '' || instructionItems.value === null) {
            requiredMessageTmp.push('Add at least 1 instruction to the recipe');
        } 
        if (servingsValue === "0" || servingsValue === 0 || servingsValue === '' || servingsValue === null) {
            requiredMessageTmp.push('Specify the servings for this recipe');
        } 
        if (cookingTimeValue === "0" || cookingTimeValue === 0 || cookingTimeValue === '' || cookingTimeValue === null) {
            requiredMessageTmp.push('Provide an estimate for this recipe\'s cooking time');
        }

        setRequiredMessage(requiredMessageTmp);

        if (requiredMessageTmp.length === 0) {
            setShowPublishRecipe(true)

        } else {
            setShowRequiredMessage(true);

        }

    }

    function requiredMessageBody() {

        return (
            <>
                <p className="publish_recipe_required_message">Execute these steps before serving your masterpiece!</p>
                <ul className="publish_recipe_required_message_list">
                    {requiredMessage.map((message, index) => (
                        <li className="publish_recipe_required_message_item" key={index}>{message}</li>
                    ))}
                </ul>
                <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Yes Chef!" action={() => setShowRequiredMessage(false)} icon="check" />
            </>
        )

    }

    function publishRecipeBody() {

        return (
            <>
                <p>Once you publish your recipe, it will be available for everyone to see. You agree to allow members of this website to add this recipe to their recipe collection, and also create variations to adapt this recipe to their preference. For more information, please refer to the full <span style={{ fontWeight: '500' }}><a href="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a></span>.
                    <br /><br />
                    You will not be able to edit the name of your recipe once it's published. However, you will still be able to update the content within the recipe.</p>
                {showPublishRemaining ?

                    <div className="publish_recipe_content_header_buttons_container">
                        <p className="publish_recipe_remaining_count">You have <span style={{ fontWeight: '500' }}>{10 - publishedCount} publications</span> remaining today.</p>
                        <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Share the love" margin={window.innerWidth < 768 ? "bottom" : "right"} action={() => publishRecipe()} icon="send" isLoading={isLoading} />
                        {/* <ButtonCancel width={window.innerWidth < 768 ? '100%' : null} name="Let me think about it" margin="right" action={() => setShowPublishRecipe(false)} icon="undo" isLoading={isLoading} /> */}
                    </div>
                    : null}
                {errorMessage !== '' ?
                    <div className="publish_recipe_error_message_container">
                        <p className="publish_recipe_error_message">{errorMessage}</p>
                    </div>
                    : null}
            </>
        )

    }

    function publishSuccessBody() {

        return (
            <>
                <p><span className="publish_recipe_success_recipe_name">{recipeName}</span> has been published and added to your collection! Spread the love and share your creation with family and friends using the link below.</p>
                <input className="publish_recipe_success_url_link" value={"https://www.garlicbasil.com/recipe/" + newRecipeID + "/" + recipeName.replace(/ /g, '-').toLowerCase()} readOnly></input>
                <div className="publish_recipe_content_header_buttons_container">
                    <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="See my creation!" margin="right" action={() => goToPublishedRecipe()} icon="tab_move" />
                </div>
            </>
        )

    }

    return (
        <div className="publish_recipe_parent_container" onClick={() => setShowPublishRecipe(false)}>
            <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name={props.publishButtonName} margin={window.innerWidth < 768 ? null : 'left'} action={(e) => checkPublishReady(e)} icon="publish" isDisabled={props.createRecipeID === "0" ? true : false} />
            <PopUp showPopUp={showRequiredMessage} hidePopUp={() => setShowRequiredMessage(false)} title={"Missing Ingredients"} body={requiredMessageBody()} />
            <PopUp showPopUp={showPublishRecipe} hidePopUp={() => setShowPublishRecipe(false)} title={"Ready to publish your " + publishType + "?"} body={publishRecipeBody()} />
            <PopUp showPopUp={showPublishSuccess} hidePopUp={() => setShowPublishRecipe(false)} title={"Deliciously wonderful."} body={publishSuccessBody()} />
        </div>
    )

}

export default PublishRecipe;