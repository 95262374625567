import './alert_message.css';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { translate } from '../../translations/translations.js'

function AlertMessage(props) {

    function closeAlert() {
        document.getElementsByClassName('alert_message_container')[0].style.bottom = "-50px";
        document.getElementsByClassName('alert_message_container')[0].style.opacity = "0";
        props.hideShowAlert()
    }

    const navigate = useNavigate()

    function goToLogInPage() {
        navigate("/log-in")
    }

    function goToSignUpPage() {
        navigate("/sign-up")
    }

    function goToBasket() {
        navigate("/shopping-list")
    }

    function returnMessage(message, type) {
        if (type === "sign_up_log_in") {
            return (
                <p className="alert_message_text"><span className="alert_message_link" onClick={goToSignUpPage}>{translate('alert', 'Sign up')}</span> {translate('alert', 'or')} <span className="alert_message_link" onClick={goToLogInPage}>{translate('alert', 'log in')}</span> {message}</p>
            )
        } else if (type === "basket") {
            return (
                <p className="alert_message_text"><span className="alert_message_link" onClick={goToBasket}>{message}</span></p>
            )
        } else {
            return (
                <p className="alert_message_text">{message}</p>
            )
        }

    }

    return (
        <div className="alert_message_container" style={props.display ? {bottom:"1em", opacity:"100"} : {bottom:"-100px", opacity:"0"}} role="alert">
            {returnMessage(props.message, props.type)} 
            <button className="alert_message_close_button" onClick={closeAlert}><span className="material-symbols-outlined alert_message_close">close</span></button>
        </div>
    )
}

export default AlertMessage;