import React from "react"
import './sort_by.css'
import { translate } from '../../../../../translations/translations.js';

function SortByOptions(props) {
    
    function sortRecipes(event, sortType, displayName) {
      event.stopPropagation();
      props.sortRecipes(sortType, displayName);
    }
  
    function hideFilters() {
        props.hideFilters()
    }
    
    return (
        <div className="sort_by_options_container">
            <ul className="sort_by_options_list">
                <p className="sort_by_options_header">{translate('recipes search', 'sort by')}</p>
                {/* <li className="sort_by_options_list_item" onClick={(event) => sortRecipes(event, "Relevance")} >
                    Relevance
                </li> */}
                <li className="sort_by_options_list_item" onClick={(event) => sortRecipes(event, "A – Z", translate('recipes search', "A – Z"))} >
                    {translate('recipes search', "A – Z")}
                </li>
                <li className="sort_by_options_list_item" onClick={(event) => sortRecipes(event, "Ratings", translate('recipes search', "ratings"))} >
                    {translate('recipes search', "ratings")}
                </li>
                <li className="sort_by_options_list_item" onClick={(event) => sortRecipes(event, "Views", translate('recipes search', "views"))} >
                    {translate('recipes search', "views")}
                </li>
            </ul>
            <button className="results_columns_done" onClick={hideFilters}>{translate("misc", "done")}</button>
        </div>
    );
}

export default SortByOptions;