import React, { useState, useEffect, useRef } from "react"
import ResultsColumnsOptions from './results_columns_options.js'
import { translate } from '../../../../../translations/translations.js';
import './results_columns.css'

function ResultsColumns(props) {

    const [focalResultsColumns, setFocalResultsColumns] =  useState('3 ' + translate('recipes search', 'columns'))

    function toggleShowResultsColumns() {
        props.showColumnsCountFilters()
    }

    function setColumns(columns) {
        setFocalResultsColumns(columns + ' ' + translate('recipes search', 'columns'))
        props.setColumns(columns)
    }

    function hideFilters() {
        props.hideFilters()
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                hideFilters('')
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className="results_columns_container">
            <button className="results_columns_button" onClick={toggleShowResultsColumns}>{focalResultsColumns} <span className="material-symbols-outlined results_columns_expand_more">expand_more</span></button>
            {props.showColumnsCountDisplay ? <div ref={wrapperRef}><ResultsColumnsOptions setColumns={setColumns} hideFilters={hideFilters}/></div>: null}
        </div>
    )
}

export default ResultsColumns;