import React from "react"
import './recipe_header.css'

function RecipeHeader(props) {

    const name = props.name
    let title_name = ''
    
    name.length > 35 ? title_name = props.name: title_name="" 
        
    const convertToTitleCase = (str) => {
        return str.replace(/(^|\s)\w/g, (match) => match.toUpperCase());
    };

    return (
        <div className="header_container" title={title_name}>
            {props.createType && <p className="recipe_header_create_type">{convertToTitleCase(props.createType)}</p>}
            <p className="carousel_wide_content_recipe_name">{convertToTitleCase(props.name)}</p>
        </div>
    )
}

export default RecipeHeader;