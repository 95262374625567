import './ingredient_tile.css';
import React from 'react';
import { getUOMOptions } from '../../formatting/ingredients_options.js';
import { toTitleCase } from '../../formatting/string_functions.js';
import { formatQuantity, getDisplayUOM} from '../../formatting/ingredient_formatting.js';

function IngredientTile(props) {
 
    const image_id = props.hash_id; 

    const rawQuantity = props.quantity;  
    const quantityDisplay = formatQuantity(rawQuantity);
    const ingredientNameDisplay = rawQuantity > 1 ? toTitleCase(props.name_plural) : toTitleCase(props.name);
    // const ingredientNameDisplay =  toTitleCase(props.name);

    const uomPluralDict = getUOMOptions(); 
    const uom = getDisplayUOM(props.uom); 
    const uomDisplay = uom === 'blank' ? '' : rawQuantity > 1 ? uomPluralDict[uom] : uom;

    function getAcronym(inputText) { 
        const words = inputText.split(' '); 
        const acronym = words.map((word) => word[0]).join(''); 
        return acronym;
    };

    return (
        <div id={"ingredient_tile_container" + image_id} className="ingredient_tile_container" style={{width:props.width}}>
            <div className="ingredient_tile_image_container" style={{height:(props.height)}}>
                {image_id !== null ? 
                    <img className="ingredient_tile_image" src={"https://thesiliconkitchenimages.blob.core.windows.net/imageingredient/" + image_id + ".webp"} alt=""/>
                    :
                    <div className="ingredient_tile_initials">{getAcronym(ingredientNameDisplay)}</div>
                }
                <div className="ingredient_tile_image_complete_icon"></div>
            </div>
            <div className="ingredient_tile_text">
                {rawQuantity === 0 ? (
                    <span>{ingredientNameDisplay} {uomDisplay}</span>
                ) : (
                    <span><span style={{fontWeight:"500"}}>{quantityDisplay} {uomDisplay}</span> {ingredientNameDisplay}</span>
                )}
            </div>
        </div>
    );
}

export default IngredientTile;