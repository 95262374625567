import { useNavigate } from 'react-router-dom';
import './recipe_variations.css'
import React, { useState, useEffect } from 'react'
import PopUp from '../../../components/pop_up/pop_up.js'

function RecipeVariations(props) {

    const [variationRecipeData, setVariationRecipeData] = useState(null);
    const [showVariations, setShowVariations] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ recipe_id: props.recipe_id })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/getRecipeVariations', options)
            .then(response => response.json())
            .then(data => { 
                setVariationRecipeData(data)
                if (data.length > 0) {
                    setShowVariations(true)
                }
            });
    }, [props.recipe_id]);

    function variationsPopUpBody() {
        return (
            <div className="recipe_variations_content" >
                {variationRecipeData && variationRecipeData.map((recipe, index) => {
                    const recipeID = recipe.recipe_id;
                    const recipeName = recipe.recipe_name;
                    const recipeRating = recipe.recipe_ratings;
                    const recipeViews = recipe.recipe_views;
                    const recipePublisheDate = recipe.recipe_date_published; 
                    return (
                        <div key={recipeName + index} className="recipe_variations_recipe_container" onClick={() => { navigate('/recipe/' + recipeID + '/' + recipeName.replace(/ /g, '-').toLowerCase()); props.hideVariations() }}>
                            <div className="recipe_variations_recipe_content_container">
                                <div className="recipe_variations_recipe_name" >{recipeName}</div>
                                <div className="recipe_variation_item_info">
                                    <div className="recipe_variations_recipe_views">Views: {recipeViews}</div>
                                    <div className="recipe_variations_recipe_rating">&nbsp;&nbsp;&#8226;&nbsp;&nbsp; Ratings: {recipeRating === null ? "No ratings yet" : parseFloat(recipeRating).toFixed(1)}</div>
                                    <div className="recipe_variations_recipe_date_published">&nbsp;&nbsp;&#8226;&nbsp;&nbsp; Published: {recipePublisheDate}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="recipe_variations_container" onClick={() => props.hideVariations()}> 
            <PopUp showPopUp={showVariations} hidePopUp={() => setShowVariations(false)} title="Recipe Variations" body={variationsPopUpBody()} /> 
        </div>

    )

}

export default RecipeVariations;