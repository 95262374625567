import './navigation.css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './logo/logo.js'
import PageLinks from './page_links/desktop/page_links.js'
import MobilePageLinks from './page_links/mobile/mobile_page_links.js'
import LogInSignUp from './account_information/log_in_sign_up.js'
import UserInfo from './account_information/user_info.js'
import MobileSearch from './search/mobile_search/mobile_search.js'

function NavigationBar(props) {

    const [createMode, setCreateMode] = useState(false); 
    const location = useLocation();

    useEffect(() => { 
        const path = location.pathname;
        const firstItem = path.split('/')[1]; 
        if (firstItem === 'recipe-studio' || firstItem === 'create') {
            const nav_container = document.getElementById('nav_parent_container');
            nav_container.style.backgroundColor = 'var(--primary-color)';
            setCreateMode(true);
        } else {
            const nav_container = document.getElementById('nav_parent_container');
            nav_container.style.backgroundColor = 'rgba(243, 243, 243, 0.8)';
            setCreateMode(false);
        }
    }, [location]);

    return (
        // <div id="nav_parent_container" className={createMode ? "nav_container_create" : "nav_container" }>
        <div id="nav_parent_container" className="nav_container">
            <nav className="nav_content">
                <span className="nav_logo_container">
                    <Logo createMode={createMode}/>
                </span>
                <span id="nav_links_container_id" className="nav_links_container">
                    <PageLinks createMode={createMode}/>
                </span>
                <span className="nav_account_information_container">
                    {props.loggedIn ? <UserInfo userLoggedOut={props.userLoggedOut} createMode={createMode}/>: <LogInSignUp createMode={createMode}/>}
                </span>
                <span className="nav_mobile_search_container">
                    <MobileSearch createMode={createMode}/>
                </span>
                <span className="nav_mobile_links_container">
                    <MobilePageLinks createMode={createMode} loggedIn={props.loggedIn} userLoggedOut={props.userLoggedOut}/>
                </span>
            </nav>
        </div>
    );
}

export default NavigationBar;