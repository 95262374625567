import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from "js-cookie";

import NavigationBar from './navigation/navigation.js'

import Home from './home/home.js'
import RecipesSearch from './recipes_search/recipes_search.js'
import ShoppingList from './shopping_list/shopping_list.js'
import About from './about/about.js'
import SignUpLogIn from './sign_up_log_in/sign_up_log_in.js'

import Recipe from './recipe/recipe.js'
import EmailConfirmation from './email_links/email_confirmation/email_confirmation.js'
import ResetPassword from './email_links/reset_password/reset_password.js'

import Footer from './footer/footer.js'
import CookiePopUp from './cookie/cookie_popup/cookie_popup.js'

import TermsOfUse from './info_pages/terms_of_use/terms_of_use.js'
import PrivacyPolicy from './info_pages/privacy_policy/privacy_policy.js'
import CookiePolicy from './info_pages/cookie_policy/cookie_policy.js'
import Feedback from './info_pages/feedback/feedback.js'

import AlertMessage from './components/alert_message/alert_message.js';
import ScrollToTopPath from './components/scroll_to_top/scrollToTopPath';

import MyCollection from './my_collection/my_collection.js'
import Collection from './collection/collection.js'
import RecipeStudio from './studio/studio.js'
import Create from './create/create.js'

import Settings from './settings/settings.js'
import Introduction from './introduction/introduction.js'
import DemoVideo from './demo_video/demo_video.js'


function App() {

    const session_id = Cookies.get('session_id')
    const firstname = Cookies.get('firstname')
    const [loggedIn, setLoggedIn] = useState(false)

    useEffect(() => {

        if (firstname !== undefined) {
            setLoggedIn(true)
        }

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ session_id: session_id }),
        };        
        
        fetch(process.env.REACT_APP_API_URL + "/api/checkUserLoggedIn", options)
        .then((response) => response.json())
        .then((json) => setLoggedInStatus(json));
        
    }, [session_id, firstname])

    function setLoggedInStatus(json) {
        json['status'] === true ? setLoggedIn(true): setLoggedIn(false)
    }

    function userLoggedIn() {
        setLoggedIn(true)
    }

    function userLoggedOut() {
        Cookies.remove('session_id');
        Cookies.remove('firstname');
        setLoggedIn(false)
    }

    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('')
    const timerRef = useRef(null);

    function showAlertMessage(message, type = '', autoHide = true, length = 5000) {
        setAlertType(type)
        setAlertMessage(message)
        setShowAlert(true)
        resetTimer(autoHide, length)
    }

    function resetTimer(autoHide, length) {
        if (autoHide) {

            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
                setShowAlert(false);
            }, length);

            return () => clearTimeout(timerRef.current);
        }
    }

    function hideShowAlert() {
        setShowAlert(false)
    }

    let logInStartPosition = ''
    let signUpStartPosition = ''

    if (window.innerWidth < 768) {
        logInStartPosition = '-100vw'
        signUpStartPosition = '-200vw'
    } else {
        logInStartPosition = '-500px'
        signUpStartPosition = '-1000px'
    }

    return (
        <Router>
            <ScrollToTopPath />
            <NavigationBar loggedIn={loggedIn} userLoggedOut={userLoggedOut}/>
            <Routes>
                <Route path="/" element={<Home showAlertMessage={showAlertMessage}/>}></Route>
                <Route path="/recipes-search" element={<RecipesSearch showAlertMessage={showAlertMessage} />}></Route>
                <Route path="/shopping-list" element={<ShoppingList />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/log-in" element={<SignUpLogIn startPosition={logInStartPosition} userLoggedIn={userLoggedIn}/>}></Route>
                <Route path="/sign-up" element={<SignUpLogIn startPosition={signUpStartPosition} userLoggedIn={userLoggedIn} showAlertMessage={showAlertMessage}/>}></Route>
                <Route path="/recipe" element={<Recipe showAlertMessage={showAlertMessage}/>}></Route>
                <Route path="/recipe/:recipeId/:recipeName" element={<Recipe showAlertMessage={showAlertMessage} />} />
                <Route path="/email_confirmation" element={<EmailConfirmation />}></Route>
                <Route path="/password_reset" element={<ResetPassword />}></Route>
                <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
                <Route path="/feedback" element={<Feedback />}></Route>
                <Route path="/my-collection" element={<MyCollection showAlertMessage={showAlertMessage} />}></Route>
                <Route path="/collection/:userHandle" element={<Collection/>}></Route>
                <Route path="/recipe-studio" element={<RecipeStudio showAlertMessage={showAlertMessage}/>}></Route>
                <Route path="/create/:recipeId/:recipeName" element={<Create showAlertMessage={showAlertMessage}/>}></Route>
                <Route path="/create/:recipeId/" element={<Create showAlertMessage={showAlertMessage} />}></Route>
                <Route path="/create" element={<Create />}></Route>
                <Route path="/settings" element={<Settings />}></Route>
                <Route path="/introduction" element={<Introduction />}></Route>
                <Route path="/demo" element={<DemoVideo />}></Route>
            </Routes>
            <Footer />
            <AlertMessage display={showAlert} message={alertMessage} type={alertType} hideShowAlert={hideShowAlert} />
            <CookiePopUp />
            {/* <div className="testing_notification">Alpha Testing</div> */}
        </Router>    
    );
}

export default App;

