import './recipes_filter_mobile.css';
import React, { useState, useEffect } from 'react';
import SearchUI from '../../../components/search/search.js';
import MobileFilters from './mobile_filters/mobile_filters.js';
import ActiveFilterItem from '../recipes_filter_desktop/active_filter_item.js'
import MobileSortBy from './mobile_sort_by/mobile_sort_by.js';
import Cookies from 'js-cookie'
import { translate } from '../../../translations/translations.js'
import { useLocation } from 'react-router-dom';

function RecipesFilterMobile(props) {

    const [activeFavourites, setActiveFavourites] = useState(false)
    const [showFilters, setShowFilters] = useState(false);
    const [showSortBy, setShowSortBy] = useState(false);
    const [showMask, setShowMask] = useState(false);
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {

        const urlParams = new URLSearchParams(location.search);
        const search = urlParams.get('search');
        if (search) {
            setSearchValue(search)
        }
    }, [location]);

    function toggleFilters() {
        setShowSortBy(false)
        if (showFilters) {
            setShowFilters(false)
            setShowMask(false)
        } else {
            setShowFilters(true)
            setShowMask(true)
        }
    }

    function toggleSortBy() {
        setShowFilters(false)
        if (showSortBy) {
            setShowSortBy(false)
            setShowMask(false)
        } else {
            setShowSortBy(true)
            setShowMask(true)
        }
    }

    function toggleFavourites() {

        const session_id = Cookies.get('session_id')

        if (typeof session_id !== "undefined") {
            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({ session_id: session_id }),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/checkUserLoggedIn', options)
            .then(response => response.json())
            .then(json => { 

                if (json['status']) {
                    props.updateRecipes('favourite')
                    setActiveFavourites(current => !current)                
                } else {
                    props.showAlertMessage(translate('alert', 'to see your favourite recipes.'), 'sign_up_log_in')
                }
            })
        } else {
            props.showAlertMessage(translate('alert', 'to see your favourite recipes.'), 'sign_up_log_in')
        }

    }

    function addFilter(filterType, filter) {
        props.updateRecipes(filterType, filter)
    }

    function removeFilter(filterType, filter) {
        props.updateRecipes(filterType, filter)
    }

    function mobileSortBy(type) {
        props.sortRecipes(type)
        setShowSortBy(false)
        setShowMask(false)
    }

    return (
        <div className="recipe_filter_mobile_container">
            <span className={"recipe_filter_mask"} style={showMask ? {opacity:"0.3", zIndex:"10"}: {opacity:"0", zIndex:"-2"}} onClick={() => {setShowFilters(false);  setShowSortBy(false); setShowMask(false)}}></span>
            <div className="recipe_filter_mobile_panel_container">
                <div className="recipe_filter_mobile_search_container">
                    <SearchUI updateRecipes={addFilter} currentValue={searchValue}/>
                </div>

                <div className="recipe_filter_mobile_functions_container">
                    <span className="recipe_filter_mobile_functions_item" onClick={toggleFilters}>{translate('recipes search', 'filters')}</span>
                    <span className="recipe_filter_mobile_functions_item" onClick={toggleSortBy}>{translate('recipes search', 'sort by')}</span>
                    <span className={activeFavourites ? "recipe_filter_mobile_functions_item_selected":"recipe_filter_mobile_functions_item"} onClick={toggleFavourites}>{translate('recipes search', 'favourites')}</span>
                </div>

                {props.activeFilters.length === 0 || (
                        props.activeFilters[0][1].length === 0 && 
                        props.activeFilters[1][1].length === 0 && 
                        props.activeFilters[2][1].length === 0 &&
                        props.activeFilters[3][1].length === 0 &&
                        props.activeFilters[4][1].length === 0 &&
                        props.activeFilters[5][1].length === 0 &&
                        props.activeFilters[6][1].length === 0
                        )
                    ? null:

                    <div className='recipe_filter_third_row'>
                        {props.activeFilters ? props.activeFilters.map(filterType => (
                            <span key={filterType}>
                                {filterType[1] ? filterType[1].map((filter)=> (
                                    <ActiveFilterItem key={filter} filterType={filterType[0]} activeFilter={filter} removeFilter={removeFilter}/>
                                )): null }
                            </span>
                        )): null }
                        <button className='filters_clear_all' onClick={props.clearAllFilters}>{translate('recipes search', 'Clear all')}</button>
                    </div>
                }

            </div>

            <div className={showFilters ? "recipe_filter_mobile_filters recipe_filter_mobile_filters_show":"recipe_filter_mobile_filters recipe_filter_mobile_filters_hide"}>
                <MobileFilters updateRecipes={(filterType, filter) => props.updateRecipes(filterType, filter)} closeMobileFilters={() => {setShowMask(false); setShowFilters(false)}}/>
            </div>
            <div className={showSortBy ? "recipe_sort_by_mobile_sort_by recipe_sort_by_mobile_filters_show":"recipe_sort_by_mobile_sort_by recipe_sort_by_mobile_filters_hide"}>
                <MobileSortBy closeMobileSortBy={() => {setShowMask(false); setShowSortBy(false)}}  sortRecipes={mobileSortBy}/>
            </div>
            <div className="recipe_filter_loading_container">
                <div className='recipe_filter_mobile_break_line_filters'></div>
                {props.searching ? <div className="loader_line"><div className="bar" style={props.searchingFinish ? {opacity: "0%"} : null }></div></div> : null}
            </div>
        </div>
    )
}

export default RecipesFilterMobile;