import './recipe_ingredients.css'
import React, { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import RecipeIngredientsItem from './recipe_ingredients_item';
import { translate } from '../../../translations/translations.js';
import PopUp from '../../../components/pop_up/pop_up.js';

function RecipeIngredients(props) {

    const session_id = Cookies.get('session_id');
    const ingredients = props['text'];
    const [servings, setServings] = useState(4);
    const savedServings = Cookies.get('servings');
    const savedWeightIsMetric = Cookies.get('weightIsMetric');
    const savedVolumeIsMetric = Cookies.get('volumeIsMetric');
    const isMobile = window.innerWidth < 768;
    const [weightIsMetric, setWeightIsMetric] = useState(true);
    const [volumeIsMetric, setVolumeIsMetric] = useState(true);
    const [showMeasurementPanel, setShowMeasurementPanel] = useState(false);

    useEffect(() => {
        if (savedServings !== undefined) {
            setServings(parseInt(savedServings, 10));
        } else {
            setServings(4);
        }
        if (savedWeightIsMetric !== undefined) {
            setWeightIsMetric(JSON.parse(savedWeightIsMetric));
        } else {
            setWeightIsMetric(true);
        }
        if (savedVolumeIsMetric !== undefined) {
            setVolumeIsMetric(JSON.parse(savedVolumeIsMetric));
        } else {
            setVolumeIsMetric(true);
        }
    }, [savedServings, savedWeightIsMetric, savedVolumeIsMetric])

    if (!ingredients || ingredients.length === 0) {
        return <div></div>;
    }

    const addToShoppingList = () => {

        if (typeof session_id !== "undefined") {

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id, servings: servings }),
            };

            fetch(process.env.REACT_APP_API_URL + "/api/addToShoppingList", options)
                .then((response) => response.json())
                .then(json => {
                    if (json['status'] === false) {
                        if (json['error'] === 'Recipe already added') {
                            props.showAlertMessage(translate('alert', 'recipe exist'))
                        } else {
                            props.showAlertMessage(translate('alert', 'Ingredients have been added to your basket.'), 'sign_up_log_in')
                        }
                    } else {
                        props.showAlertMessage(translate('alert', 'Ingredients have been added to your basket.'), 'basket')
                    }
                })
        } else {
            props.showAlertMessage(translate('alert', 'to add ingredients to your basket.'), 'sign_up_log_in')
        }
    }

    function decreaseServing() {
        if (servings > 1) {
            Cookies.set('servings', servings - 1);
            setServings(servings - 1);
        }
    }

    function increaseServing() {
        if (servings < 999) {
            Cookies.set('servings', servings + 1);
            setServings(servings + 1);
        }
    }

    function convertWeightMeasurement(quantity, sourceMeasurement, targetMeasurement) {
        if (sourceMeasurement === 'gram' && targetMeasurement === 'ounce') {
            return quantity * 0.035274;
        } else if (sourceMeasurement === 'ounce' && targetMeasurement === 'gram') {
            return quantity * 28.3495;
        } else {
            return quantity;
        }
    }

    function convertVolumeMeasurement(quantity, sourceMeasurement, targetMeasurement) {
        if (sourceMeasurement === 'cup' && targetMeasurement === 'millilitre') {
            return quantity * 236.588;
        } else if (sourceMeasurement === 'millilitre' && targetMeasurement === 'cup') {
            return quantity * 0.00422675;
        } else {
            return quantity;
        }
    }

    function measurementSettingsBody() {
        return (
            <div className="measurement_settings_body">
                <div className="measurement_settings_body_item">
                    <h3 className="measurement_settings_item_title">Weight</h3>
                    <div className="toggle_container">
                        <button className={weightIsMetric ? "toggle_button_left toggle_button_active" : "toggle_button_left"} onClick={() => {setWeightIsMetric(true); Cookies.set('weightIsMetric', true);}}>Grams</button>
                        <button className={!weightIsMetric ? "toggle_button_right toggle_button_active" : "toggle_button_right"} onClick={() => {setWeightIsMetric(false); Cookies.set('weightIsMetric', false)}}>Ounces</button>
                    </div>
                </div>
                <div className="measurement_settings_body_item">
                    <h3 className="measurement_settings_item_title">Volume</h3>
                    <div className="toggle_container">
                        <button className={volumeIsMetric ? "toggle_button_left toggle_button_active" : "toggle_button_left"} onClick={() => {setVolumeIsMetric(true); Cookies.set('volumeIsMetric', true);}}>Millilitres</button>
                        <button className={!volumeIsMetric ? "toggle_button_right toggle_button_active" : "toggle_button_right"} onClick={() => {setVolumeIsMetric(false); Cookies.set('volumeIsMetric', false);}}>Cups</button>
                    </div>
                </div>
            </div>

        )
    }


    function returnDesktopView() {

        return (
            <div className="recipe_ingredients_container">
                <div className="recipe_ingredients_info_container">
                    <h2 className="recipe_ingredients_title">{translate('recipe', 'Ingredients')}</h2>
                    <div className="recipe_ingredients_action_button_container">

                        <div className="recipe_ingredients_action_button_item">
                            <button className="add_shopping_list_button" onClick={addToShoppingList}>
                                <span className="material-symbols-outlined add_to_basket_add_icon">add</span> {translate('recipe', 'Add to Basket')}
                            </button>
                        </div>
                        <div className="recipe_ingredients_action_button_item">
                            <div className="recipe_ingredients_action_button_servings">
                                <span className="serving_adjust_button_container">
                                    <button className="serving_adjust_button_minus" onClick={decreaseServing} ><span className="material-symbols-outlined serving_adjust_button_icon">remove</span></button>
                                </span>
                                <span className="recipe_ingredients_action_button_servings_info">
                                    <p className="recipe_ingredients_action_button_servings_info_text">{translate('recipe', 'Servings')}: {servings}</p>
                                </span>
                                <span className="serving_adjust_button_container">
                                    <button className="serving_adjust_button_add" onClick={increaseServing}><span className="material-symbols-outlined serving_adjust_button_icon">add</span></button>
                                </span>
                            </div>
                        </div>

                        <div className="recipe_ingredients_action_button_item_measurements" onClick={() => setShowMeasurementPanel(true)}>
                            <span className="material-symbols-outlined recipe_ingredients_action_button_item_measurements_icon">tune</span>
                        </div>
                    </div>
                </div> 

                <div className="recipe_ingredients_all_ingredients_container">
                    {ingredients.map((item, index) => {
                        const name = item[1]
                        const name_plural = item[2]
                        const recipe_quantity = item[3]
                        // const quantity = recipe_quantity * servings / props.recipeServings
                        // const uom = item[3]
                        const hash_id = item[5]

                        const sourceQuantity = recipe_quantity * servings / props.recipeServings;
                        const sourceMeasurement = item[4];

                        let quantity;
                        let uom;
                        if (sourceMeasurement === 'gram' || sourceMeasurement === 'ounce') {
                            if (sourceMeasurement === 'gram' && weightIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement;
                            } else if (sourceMeasurement === 'ounce' && !weightIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement;
                            } else {
                                const targetMeasurement = weightIsMetric ? 'gram' : 'ounce';
                                const target = convertWeightMeasurement(sourceQuantity, sourceMeasurement, targetMeasurement);
                                quantity = target;
                                uom = targetMeasurement;
                            }
                        // } else if (sourceMeasurement === 'cup' || sourceMeasurement === 'millilitre') {
                        } else if (sourceMeasurement === 'millilitre') {
                            if (sourceMeasurement === 'millilitre' && volumeIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement; 
                            } else {
                                const targetMeasurement = volumeIsMetric ? 'millilitre' : 'cup';
                                const target = convertVolumeMeasurement(sourceQuantity, sourceMeasurement, targetMeasurement);
                                quantity = target;
                                uom = targetMeasurement;
                            }
                        } else {
                            quantity = sourceQuantity;
                            uom = sourceMeasurement;
                        }

                        return (
                            <RecipeIngredientsItem key={item + index} width={152} height={132} name={name} name_plural={name_plural} quantity={quantity} uom={uom} hash_id={hash_id} servings={servings} recipeServings={props.recipeServings} />
                        )
                    })}

                </div>
                <p className="recipe_ingredients_tip">{translate('recipe', 'Ingredients hint')}</p>



            </div>
        )
    }

    function returnMobileView() {

        return (
            <div className="recipe_ingredients_container">
                <div className="recipe_ingredients_info_container">
                    <h2 className="recipe_ingredients_title">{translate('recipe', 'Ingredients')}</h2>
                </div>

                <div className="recipe_ingredients_all_ingredients_container">
                    {ingredients.map((item, index) => {
                        const name = item[1]
                        const name_plural = item[2]
                        const recipe_quantity = item[3]
                        // const quantity = recipe_quantity * servings / props.recipeServings
                        // const uom = item[3]
                        const hash_id = item[5]

                        const sourceQuantity = recipe_quantity * servings / props.recipeServings;
                        const sourceMeasurement = item[4];

                        let quantity;
                        let uom;
                        if (sourceMeasurement === 'gram' || sourceMeasurement === 'ounce') {
                            if (sourceMeasurement === 'gram' && weightIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement;
                            } else if (sourceMeasurement === 'ounce' && !weightIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement;
                            } else {
                                const targetMeasurement = weightIsMetric ? 'gram' : 'ounce';
                                const target = convertWeightMeasurement(sourceQuantity, sourceMeasurement, targetMeasurement);
                                quantity = target;
                                uom = targetMeasurement;
                            }
                        } else if (sourceMeasurement === 'millilitre') {
                            if (sourceMeasurement === 'millilitre' && volumeIsMetric) {
                                quantity = sourceQuantity;
                                uom = sourceMeasurement; 
                            } else {
                                const targetMeasurement = volumeIsMetric ? 'millilitre' : 'cup';
                                const target = convertVolumeMeasurement(sourceQuantity, sourceMeasurement, targetMeasurement);
                                quantity = target;
                                uom = targetMeasurement;
                            }
                        } else {
                            quantity = sourceQuantity;
                            uom = sourceMeasurement;
                        }

                        return (
                            <RecipeIngredientsItem key={item + index} width={"calc((100vw - 2em - 20px) / 3)"} height={"calc(((100vw - 2em - 20px) / 3) - 20px)"} name={name} name_plural={name_plural} quantity={quantity} uom={uom} hash_id={hash_id} servings={servings} recipeServings={props.recipeServings} />
                        )
                    })}
                </div>
                <p className="recipe_ingredients_tip">{translate('recipe', 'Ingredients hint')}</p>
                <div className="recipe_ingredients_settings_container">
                    <div className="recipe_ingredients_serving_info_parent_container">
                        <div className="recipe_ingredients_serving_info_container">
                            <span className="recipe_ingredients_serving_info_item"><button className="serving_adjust_button_minus" onClick={decreaseServing} ><span className="material-symbols-outlined serving_adjust_button_icon">remove</span></button></span>
                            <span className="recipe_ingredients_serving_info_item">{translate('recipe', 'Servings')}: {servings}</span>
                            <span className="recipe_ingredients_serving_info_item"><button className="serving_adjust_button_add" onClick={increaseServing}><span className="material-symbols-outlined serving_adjust_button_icon">add</span></button></span>
                        </div>
                    </div>
                    <div className="recipe_ingredients_action_button_item_measurements" onClick={() => setShowMeasurementPanel(true)}>
                        <span className="material-symbols-outlined recipe_ingredients_action_button_item_measurements_icon">tune</span>
                    </div>
                </div>
                <div className="add_shopping_list_container">
                    <button className="add_shopping_list_button" onClick={addToShoppingList}>
                        <span className="material-symbols-outlined add_to_basket_add_icon">add</span> {translate('recipe', 'Add to Basket')}
                    </button>
                </div>

            </div>
        )
    }

    return (
        <div>
            {isMobile ? returnMobileView() : returnDesktopView()}
            <PopUp showPopUp={showMeasurementPanel} hidePopUp={() => setShowMeasurementPanel(false)} title={"Measurement Settings"} body={measurementSettingsBody()} width='300px' />
        </div>
    );

}

export default RecipeIngredients;