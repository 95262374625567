import './delete_recipe.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../components/button/button_primary';
import ButtonCancel from '../../components/button/button_cancel';
import Cookies from 'js-cookie'
import PopUp from '../../components/pop_up/pop_up.js'

function DeleteRecipe(props) {

    const session_id = Cookies.get('session_id')
    const [confirmDeletePanel, setConfirmDeletePanel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [displayError, setDisplayError] = useState(false)

    const navigate = useNavigate()

    function deleteUserRecipe() {
        setIsLoading(true)
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createDeleteRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    navigate('/recipe-studio')
                    props.showAlertMessage('Your recipe has been deleted.')
                }
            })
            .catch(() => {
                setDisplayError(true);
                setIsLoading(false);
            })
    }

    function deletePopUpBody() {

        return (
            <>
                <p>This action cannot be undone.</p>
                <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Yes, delete it" margin={window.innerWidth < 768 ? "bottom" : "right"} action={() => deleteUserRecipe()} icon="delete" isLoading={isLoading} />
                <ButtonCancel width={window.innerWidth < 768 ? '100%' : null} name="No, keep it" margin="right" action={() => { setConfirmDeletePanel(false); setDisplayError(false) }} icon="undo" isLoading={isLoading} />
                {displayError ? <p className="create_delete_recipe_panel_text_error">Sorry, something went wrong. Please try again later.</p> : null}
            </>
        )
    }

    return (
        <div style={{ display: 'relative' }}>
            <ButtonCancel name="Delete Recipe" width='100%' icon='delete' action={() => setConfirmDeletePanel(true)} theme='grey' /> 
            <PopUp showPopUp={confirmDeletePanel} hidePopUp={() => setConfirmDeletePanel(false)} title="Wait! Are you sure you want to delete this recipe?" body={deletePopUpBody()} width="450px"/>
        </div>
    )
}

export default DeleteRecipe;