export function getDisplayUOM(rawUOM) {

    if (rawUOM === "tablespoon") {
        return "tb";
    } else if (rawUOM === "teaspoon") {
        return "tsp";
    } else if (rawUOM === "kilogram") {
        return "kg";
    } else if (rawUOM === "millilitre") {
        return "ml";
    } else return rawUOM ;

}

export function getAdjustedQuantity(recipe_quantity, recipe_servings = 4, user_servings) {

    const adjust_servings = user_servings / recipe_servings;
    const adjusted_quantity = recipe_quantity * adjust_servings
    return adjusted_quantity

}

export function formatQuantity(quantity) {

    const quantityMapping = {
        0.125: "⅛",
        0.25: "¼",
        0.5: "½",
        0.75: "¾",
        1: "1",
        1.25: "1¼",
        1.5: "1½",
        1.75: "1¾",
        2: "2",
        2.25: "2¼",
        2.5: "2½",
        2.75: "2¾",
        3: "3",
        3.25: "3¼",
        3.5: "3½",
        3.75: "3¾",
        4: "4",
        4.25: "4¼",
        4.5: "4½",
        4.75: "4¾",
        5: "5",
        5.25: "5¼",
        5.5: "5½",
        5.75: "5¾",
        6: "6",
        6.25: "6¼",
        6.5: "6½",
        6.75: "6¾",
        7: "7",
        7.25: "7¼",
        7.5: "7½",
        7.75: "7¾",
        8: "8",
        8.25: "8¼",
        8.5: "8½",
        8.75: "8¾",
        9: "9",
        9.25: "9¼",
        9.5: "9½",
        9.75: "9¾",
        10: "10",
        10.25: "10¼",
        10.5: "10½",
        10.75: "10¾"
    }; 
    const quantity_string = quantityMapping[quantity] || (Math.abs(quantity - Math.floor(quantity)) > 0.001 ? quantity.toFixed(1) : quantity);

    return quantity_string

}