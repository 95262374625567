import React from "react"
import './filter_display.css'
import FilterItem from "./filter_item.js"
import { translate } from '../../../translations/translations.js';

function FilterDisplay(props) {

    function preventHiding(event) {
        event.stopPropagation()
    }

    function addFilter(filter, displayName) {
        props.addFilter(props.filterType, filter, displayName)
    }

    function hideFilters() {
        props.hideFilters()
    }

    return (
        <div className="filter_display_container" onClick={preventHiding}>
            <div className="filter_display_child_container">
                <p className="filter_title">Filters</p>
                <span className="material-symbols-outlined filter_display_close" onClick={hideFilters}>close</span>
                <ul className="filter_list">
                    {props.filters.map((filter) => (
                        <FilterItem key={filter} filter={filter[0]} displayName={filter[1]} addFilter={addFilter} />
                    ))}
                </ul>
                <button className="filter_display_done" onClick={hideFilters}>{translate('misc', 'done')}</button>
            </div>
        </div>
    )
}

export default FilterDisplay;


