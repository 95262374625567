import './ingredients.css';
import React, { useState, useEffect, useCallback } from 'react';
import IngredientAdd from './ingredient_add/ingredient_add.js';
import IngredientsList from './ingredients_list/ingredients_list.js';
import Cookies from 'js-cookie'

import { toTitleCase } from '../../formatting/string_functions.js';

function Ingredients(props) {

    const session_id = Cookies.get('session_id')
    const [ingredients, setIngredients] = useState([])
    const [servings, setServings] = useState()
    const refreshIngredientsListMemoize = useCallback(refreshIngredientsList, [session_id, props.createRecipeID])

    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [selectedQuantity, setSelectedQuantity] = useState(null);
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);

    const [ingredientOptions, setIngredientOptions] = useState([]);

    useEffect(() => {
        refreshIngredientsListMemoize();
    }, [refreshIngredientsListMemoize, props.createRecipeID]);

    useEffect(() => {

        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(process.env.REACT_APP_API_URL + '/api/getIngredientMetaData', options)
            .then(response => response.json())
            .then(data => {
                const tmpIngredientOptions = data.map((item) => ({
                    value: item[0],
                    label: toTitleCase(item[1])
                }));
                setIngredientOptions(tmpIngredientOptions);
            });
    } , []);

    function refreshIngredientsList() {

        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ session_id: session_id, create_recipe_id: props.createRecipeID }),
            };

            fetch(process.env.REACT_APP_API_URL + "/api/createGetIngredients", options)
                .then((response) => response.json())
                .then((json) => {
                    setIngredients(json)
                });

            fetch(process.env.REACT_APP_API_URL + "/api/createGetServings", options)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status) {
                        setServings(data.servings)
                    }
                });
        } else {
            const rawCreateIngredients = Cookies.get('create_ingredients')
            let createIngredients = []
            if (rawCreateIngredients !== undefined) {
                createIngredients = JSON.parse(rawCreateIngredients)
                console.log(rawCreateIngredients)
            }
            if (createIngredients.length !== 0) {
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({ ingredientsArray: createIngredients }),
                };

                fetch(process.env.REACT_APP_API_URL + "/api/createGetIngredientsNonMember", options)
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        setIngredients(json)
                    });
            } else {
                setIngredients([])
            }

            const rawCreateServings = Cookies.get('create_servings')
            if (rawCreateServings !== undefined) {
                setServings(rawCreateServings)
            } else {
                setServings(0)
            }

        }
    }

    function updateIngredient(create_ingredient_id, create_name) {
        setSelectedIngredient({ value: create_ingredient_id, label: create_name })
    }
    function updateQuantity(create_quantity) {
        setSelectedQuantity({ value: create_quantity, label: create_quantity })
    }
    function updateMeasurement(create_uom, create_uom_label) {
        setSelectedMeasurement({ value: create_uom, label: create_uom_label })
    }

    return (
        <div className="create_content_ingredients">
            <IngredientAdd createRecipeID={props.createRecipeID} refreshIngredientsList={refreshIngredientsList} updateIngredient={updateIngredient} updateQuantity={updateQuantity} updateMeasurement={updateMeasurement} selectedIngredient={selectedIngredient} selectedQuantity={selectedQuantity} selectedMeasurement={selectedMeasurement} servings={servings} setServings={setServings} ingredientOptions={ingredientOptions}/>
            <IngredientsList createRecipeID={props.createRecipeID} refreshIngredientsList={refreshIngredientsList} updateIngredient={updateIngredient} updateQuantity={updateQuantity} updateMeasurement={updateMeasurement} ingredients={ingredients} ingredientOptions={ingredientOptions}/>
        </div>
    );

}

export default Ingredients;