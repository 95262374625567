import './home_title.css';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ButtonPrimary from '../../components/button/button_primary.js';
import LoadingBar from '../../components/loading_bar/loading_bar';

function HomeTitle() {

    const session_id = Cookies.get('session_id');
    const recipeTextRef = useRef();
    const [completedSentence, setCompletedSentence] = useState("Generate a recipe for ");
    const [generateDisabled, setGenerateDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleInputChange = () => {
        const userInput = recipeTextRef.current.value;
        if (userInput === "Generate a recipe for ") {
            setGenerateDisabled(true);
            setCompletedSentence(userInput);
        } else {
            if (userInput.startsWith("Generate a recipe for ")) {
                setGenerateDisabled(false);
                setCompletedSentence(userInput);
            }
        }
    };

    function generateRecipe() {

        setLoading(true);

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, create_recipe_id: 0, recipe_text: recipeTextRef.current.value })
        }

        // fetch(process.env.REACT_APP_API_URL + '/api/createGenerateRecipe', options)
        fetch('https://thesiliconkitchenfunctions.azurewebsites.net/api/generateRecipeHome', options)
            .then(response => response.json())
            .then(data => {

                if (data.recipe_response.status === true) {
                    if (data.recipe_response.createRecipeID !== 0) {
                        navigate('/create/' + data.recipe_response.createRecipeID);
                    } else {
                        const recipeData = data.recipe_response.recipe_data
                        const ingredientsData = data.recipe_response.ingredientsArrayNew
                        const instructionsData = data.recipe_response.instructionsArrayNew
                        const imageHash = data.recipe_response.imageHash

                        Cookies.set('create_name', recipeData['name'], { expires: 7 });
                        Cookies.set('create_introduction', recipeData['introduction'], { expires: 7 });
                        Cookies.set('create_cooking_time', recipeData['cooking_time'], { expires: 7 });
                        Cookies.set('create_servings', recipeData['serving_size'], { expires: 7 });

                        let ingredientsArray = []
                        for (let i = 0; i < ingredientsData.length; i++) {
                            ingredientsArray.push([0, ingredientsData[i].ingredientID, ingredientsData[i].quantity, ingredientsData[i].uom]);
                        }
                        Cookies.set('create_ingredients', JSON.stringify(ingredientsArray), { expires: 7 });
                        Cookies.set('create_instructions', JSON.stringify(instructionsData), { expires: 7 });
                        Cookies.set('create_image_hash', imageHash, { expires: 7 });
                        navigate('/create/0/' + recipeData['name'].replace(/ /g, '-'));
                    }

                } else {
                    setLoading(false);
                    setErrorMessage("Oh no, there was an error generating this recipe. Please try agian later. Error: " + data.id);
                }
            });

    }

    return (
        <div className="home_hero_introduction_parent_container">
            <div className="home_hero_introduction_container">
                {/* <p className="home_hero_sub_title">WELCOME TO</p> */}
                {/* <h1 className="home_hero_title">GARLIC BASIL</h1> */}
                <h1 className="home_hero_title">GENERATE {window.innerWidth < 768 ? <br/>: null}RECIPES WITH AI</h1>
                <p className="home_hero_introduction_information">Describe the recipe and click generate!</p>
                
                <div className="home_hero_introduction_prompt">
                    <div className="home_hero_introduction_prompt_text" >
                        <textarea className="home_hero_introduction_prompt_text_area" value={completedSentence} onChange={handleInputChange} ref={recipeTextRef}></textarea>
                    </div>
                    <ButtonPrimary name="Generate Recipe" width="100%" action={() => generateRecipe()} icon="ambient_screen" isDisabled={generateDisabled || loading} />
                    {loading && <div className="home_hero_introduction_loading_bar"><LoadingBar isLoading={true} /> </div>}
                    {errorMessage !== "" && <p className="home_hero_introduction_error_message">{errorMessage}</p>}
                </div>
                <p className="home_hero_introduction_tip">Tip: You can use words like authentic, quick or vegan to personalise your recipe. Or paste in a recipe to import it to our platform.</p>
            </div>
        </div>
    );
}

export default HomeTitle;
