import './carousel.css'
import React, { useState, useEffect, useCallback, useMemo } from "react"
import RecipeTile from "../recipe_tile/recipe_tile.js"
import Cookies from "js-cookie";
import { getFavouriteRecipes, getSimilarRecipes, getFilteredRecipes, getTrendingRecipes } from '../../functions/functions.js'
let carouselCount = 2

function Carousel(props) {

    const session_id = Cookies.get('session_id')
    const [isMobile, setIsMobile] = useState(false)
    const [favourite, setFavourite] = useState([]);
    const [allRecipes, setAllRecipes] = useState([])
    const [recipesGroup, setRecipes] = useState([])
    const [count, setCount] = useState(0)
    const handleDataMemoize = useCallback(handleData, [props.numberOfGroups])
    const placeHolder = useMemo(() => {
        return [{"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}, {"recipe_id": 0, "recipe_name": "placeholder", recipe_views: 0}];
      }, []);
    let carousel = []

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        }

        handleDataMemoize(placeHolder)

        getFavouriteRecipes(session_id)
            .then(data => setFavourite(data))

        if (props.similar_recipes === true) {
            if (props.recipe_id) {
                getSimilarRecipes(props.recipe_id)
                    .then(data => handleDataMemoize(data))
            }
        } else if (props.trending === true) {
            getTrendingRecipes(props.shuffleResults)
                .then(data => handleDataMemoize(data))
        } else {
            getFilteredRecipes(props.tags, props.shuffleResults)
                .then(data => handleDataMemoize(data))
        }

    }, [props.trending, props.similar_recipes, props.recipe_id, props.tags, props.shuffleResults, session_id, placeHolder, handleDataMemoize])

    function updateRecipe(recipe_id) {
        props.updateRecipe(recipe_id)
    }

    function handleData(data) {

        const recipes = data
        setAllRecipes(recipes)
        let recipesGroupRaw = []
        let recipesTemp = []

        for (let i = 0; i < recipes.length; i++) {
            
            recipesTemp = recipesTemp.concat([recipes[i]])
            if ((i + 1) % props.numberOfGroups  === 0) {
                recipesGroupRaw = recipesGroupRaw.concat([recipesTemp])
                recipesTemp = []
            }
        }
        setRecipes(recipesGroupRaw)
    }

    function moveRight() {
        carousel = document.querySelectorAll('[data-key="' + props.name  + '_carousel"]'); 
        if (count > 1 - carousel.length) {
            for (let i = 0; i < carousel.length; i++) {
                carousel[i].style.transform = 'translateX(' + (count - 1) * 110 + '%)';
            }
            setCount(count - 1)
        } 
        carouselCount = carousel.length
    }

    function moveLeft() {
        carousel = document.querySelectorAll('[data-key="' + props.name  + '_carousel"]'); 
        if (count < 0) {
            for (let i = 0; i < carousel.length; i++) {
                carousel[i].style.transform = 'translateX(' + (count + 1) * 110 + '%)';
            }
            setCount(count + 1)
        } 
        carouselCount = carousel.length
    }

    let heightValue =''
    let contentWidthValue =''
    let minHeightValue = ''
    let imageSize = ''

    switch (props.numberOfGroups) {
        case 1:
            if (window.innerWidth > 768) {
                heightValue = '28vw'
                contentWidthValue = '100%'
                minHeightValue = '250px'
                imageSize= 'medium'
            } else {
                heightValue = '28vw'
                contentWidthValue = '100%'
                minHeightValue = '500px'
                imageSize= 'medium'
            }
            break;
        case 2:
            if (window.innerWidth > 768) {
                heightValue = '22vw'
                contentWidthValue = '49.3%'
                minHeightValue = '250px'
                imageSize= 'medium'
            } else {
                heightValue = '400px'
                contentWidthValue = '260px'
                imageSize= 'medium'
            }
            break;
        case 3:
            if (window.innerWidth > 768) {
                heightValue = '15vw'
                contentWidthValue = '32.5%'
                minHeightValue = '170px'
                imageSize= 'medium'
            } else {
                heightValue = '370px'
                contentWidthValue = '235px'
                imageSize= 'medium'
            }
            break;
        case 4:
            if (window.innerWidth > 768) {
                heightValue = '12vw'
                contentWidthValue = '24%'
                imageSize= 'small'
            } else {
                heightValue = '22vw'
                contentWidthValue = '49.3%'
                minHeightValue = '250px'
                imageSize= 'small'
            }
            break;
        case 5:
            if (window.innerWidth > 768) {
                heightValue = '10vw'
                contentWidthValue = '19%'
                minHeightValue = '113px'
                imageSize= 'small'
            } else {
                heightValue = '340px'
                contentWidthValue = '210px'
                imageSize= 'medium'
            }
            break;
        case 6:
            if (window.innerWidth > 768) {
            heightValue = '9vw'
            contentWidthValue = '16%'
            imageSize= 'small'
            } else {
                heightValue = '22vw'
                contentWidthValue = '49.3%'
                minHeightValue = '250px'
                imageSize= 'small'
            }
            break;
        default:
            break;
    }
    
    function returnDecktopView() {
        return(
           
            recipesGroup.map((group, index) => ( 
                <div key={"group_" + index} className="carousel_single_group_container" data-key={props.name + '_carousel'}>
                    {group.map((recipe, recipe_index) => (
                        <RecipeTile recipe_id={recipe["recipe_id"]} name={recipe["recipe_name"]} image={recipe["recipe_image"]} favourite={favourite.includes(recipe["recipe_id"]) ? true: false} width={contentWidthValue} imageSize={imageSize} key={props.title + "_recipe_index" + recipe_index} updateRecipe={updateRecipe} showAlertMessage={props.showAlertMessage}/>
                    ))}
                </div>
            ))
        )
    }

    function returnButtons() {
        return (
            <div className="carousel_button_container">
                <button className="carousel_small_left_button" style={count < 0 ? { display: ''} : { display: 'none'}} onClick={moveLeft} data-key={props.name + '_left_button'}><span className="material-symbols-outlined carousel_arrow_icon">arrow_back_ios</span></button>
                <button className="carousel_small_right_button" style={count > 1 - carouselCount ? { display: ''} : { display: 'none'}}  onClick={moveRight} data-key={props.name + '_right_button'}><span className="material-symbols-outlined carousel_arrow_icon">arrow_forward_ios</span></button>
            </div>
        )
    }

    function returnMobileView() {
        return (
            <div key={"group_"} className="carousel_single_group_container" data-key={props.name + '_carousel'}>
                {allRecipes.map((recipe, recipe_index) => (
                    <RecipeTile recipe_id={recipe["recipe_id"]} name={recipe["recipe_name"]} image={recipe["recipe_image"]} favourite={favourite.includes(recipe["recipe_id"]) ? true: false} width={contentWidthValue} imageSize={imageSize} key={props.title + "_recipe_index" + recipe_index} updateRecipe={updateRecipe} showAlertMessage={props.showAlertMessage}/>
                ))}
            </div>
        )
    }

    return (
        <div className="carousel_container">
            <div className={"carousel_content_container"} style={{height: heightValue, minHeight: minHeightValue}}  >
                <h2 className="carousel_title">{props.title}</h2>
                {returnButtons()}
                <div className="carousel_multi_group_container" >
                    {isMobile ? returnMobileView() : returnDecktopView()}
                </div>
            </div>
        </div>
    )
}

export default Carousel;