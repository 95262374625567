import './image.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'
import ImageUploader from '../../components/image_uploader/image_uploader.js'; 
import ButtonSecondary from '../../components/button/button_secondary.js';
import { useNavigate } from "react-router-dom";

function Image(props) {

    const session_id = Cookies.get('session_id')
    const [imgUrl, setImgUrl] = useState(null)
    const [imgKey, setImgKey] = useState(0)
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {

        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID})
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createGetRecipeImageId', options)
                .then(response => response.json())
                .then(data => {
                    if (data.create_user_upload_image === 1) {
                        setImgUrl("https://thesiliconkitchenimages.blob.core.windows.net/imagescreateupload/800x800_" + props.createRecipeID)
                        setImageError(false)
                    } else {
                        setImgUrl("https://thesiliconkitchenimages.blob.core.windows.net/imagesrecipetmp/800x800_" + data.create_recipe_image_id)
                        setImageError(false)
                    }

                })
        } else {
            const imageHash = Cookies.get('create_image_hash')
            setImgUrl("https://thesiliconkitchenimages.blob.core.windows.net/imagescreatetmp/800x800_" + imageHash + ".webp")
        }

    }, [session_id, props.createRecipeID])

    function refreshUploadImage() {
        setImageError(false)
        setImgUrl("https://thesiliconkitchenimages.blob.core.windows.net/imagescreateupload/800x800_" + props.createRecipeID)
        setImgKey(prevKey => prevKey + 1)
    }

    const uniqueKey = () => {
        return Date.now();
    }

    function isLoading(value) {
        setLoading(value)
    }

    function handleImageError() {
        setImageError(true)
    }

    return (
        <div className="create_content_image">
            {props.createRecipeID !== "0" ? 
                <>
                    <div className="create_content_upload_image_container" style={loading ? {opacity: 1} : null}>
                        <div className="upload_image_button_container">
                            <ImageUploader createRecipeID={props.createRecipeID} refreshUploadImage={refreshUploadImage} isLoading={isLoading}/>
                        </div>
                    </div>
                    {imageError ? 
                        null
                    :
                        <picture >
                            <img key={imgKey} onError={() => handleImageError()} className="create_content_recipe_image" srcSet={imgUrl + ".webp?key=" + uniqueKey()} alt="recipe" type="image/webp" />
                            {/* <img key={imgKey + "_backup"} className="create_content_recipe_image" srcSet={imgUrl + ".jpg?key=" + uniqueKey()} alt="recipe" type="image/webp" /> */}
                        </picture>
                    }
                </>
                :
                <>
                    <div className="create_content_upload_image_container" style={loading ? {opacity: 1} : null}>
                        <div className="upload_image_button_container">
                            <ButtonSecondary name="Sign up or log in to upload an image" icon="login" action={() => navigate('/sign-up')}/>
                        </div>
                    </div>
                    <img key={imgKey} onError={() => handleImageError()} className="create_content_recipe_image" srcSet={imgUrl} alt="recipe" type="image/webp" />
                    {/* <img key={imgKey} onError={() => handleImageError()} className="create_content_recipe_image" srcSet={"https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/reset_password.jpg"} alt="recipe" type="image/webp" /> */}
                </>
            }
        </div>
    )

}

export default Image;