import './cookie_policy.css'
import React, { useEffect } from "react";

function CookiePolicy() {

    useEffect(()=> {
        document.title = "Garlic Basil - Cookie Policy"
    },[])

    return (
        <div className="cookie_policy_container">
            <div className="cookie_policy_content">
                <span style={{fontWeight:"500"}}>Cookies Policy</span>
                <br/><br/>
                <span style={{fontWeight:"500"}}>Last updated: 5 April 2023</span>
                <br/><br/>
                Our website uses cookies to enhance your user experience. By continuing to use our website, you consent to our use of cookies in accordance with the terms of this policy.
                <br/><br/>
                <span style={{fontWeight:"500"}}>What are cookies?</span>
                <br/>
                Cookies are small data files that are placed on your device when you visit a website. They are commonly used to remember your preferences, enhance your user experience, and provide anonymous tracking data to website owners.
                <br/><br/>
                <span style={{fontWeight:"500"}}>What cookies do we use?</span>
                <br/>
                Our website uses three categories of cookies: session, preference and trial cookies.
                <br/><br/>
                Session cookies are temporary cookies that are stored on your device during your visit to our website. They are used to remember your login information, display preferences, and other settings that are necessary for the website to function properly. We store a user's session ID in a session cookie.
                <br/><br/>
                Preference cookies are used to remember your preferences for future visits to our website. We store a variable in a preference cookie to hold your current serving portion preference.
                <br/><br/>
                Trial cookies enable non-logged-in users to experiment with the recipe creation functionality. These trial cookies store relevant data, such as recipe name, ingredient selections, measurements, and other user inputs, allowing users to trial the recipe creation feature seamlessly.
                <br/><br/>
                <span style={{fontWeight:"500"}}>How to control cookies</span>
                <br/>
                You can control and manage cookies through your browser settings. Please note that disabling cookies may impact your user experience and some features of our website may not work as intended.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Changes to this policy</span>
                <br/>
                We may update this cookies policy from time to time. We encourage you to check this page regularly for updates.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Contact us</span>
                <br/>
                If you have any questions or concerns about our cookies policy, please contact us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a>.
            </div>
        </div>
    );
}

export default CookiePolicy;

