import React, { useState, useEffect, useRef } from "react"
import SortByOptions from './sort_by_options.js'
import { translate } from '../../../../../translations/translations.js';
import './sort_by.css'

function SortByFilter(props) {

    const [focalSort, setFocalSort] =  useState(translate('recipes search', 'views'))

    function sortRecipes(type, diplayName) {
        setFocalSort(diplayName)
        props.sortRecipes(type)
    }

    function hideFilters() {
        props.hideFilters()
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                hideFilters('')
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className="sort_by_container">
            <button className="sort_by_button" onClick={props.showSortByFilters}>{focalSort} <span className="material-symbols-outlined sort_by_expand_more">expand_more</span></button>
            {props.showSortByDisplay ? <div ref={wrapperRef}><SortByOptions sortRecipes={sortRecipes}  hideFilters={hideFilters}/></div> : null}
        </div>
    )
}

export default SortByFilter;