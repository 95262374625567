import React from "react"
import './filter_item.css'

function FilterItem(props) {

    function addFilter(event) {
        event.stopPropagation()
        props.addFilter(props.filter, props.displayName)
    }

    return (
        <div className="filter_item" onClick={addFilter}>
            <li>{props.displayName}</li>
        </div>
    )
}

export default FilterItem;