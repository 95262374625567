import './similar_recipes.css'
import React from 'react'
import Carousel from '../../../components/carousel/carousel.js'
import { translate } from '../../../translations/translations.js'

function SimilarRecipes(props) {

    

    function returnCarousel() {
        if (props.recipe_id) {
            return (
                <Carousel name="similar_recipes" title={translate('recipe', "Similar Recipes")} numberOfGroups={5} tags={''} similar_recipes={true} recipe_id={props.recipe_id} updateRecipe={props.updateRecipe} showAlertMessage={props.showAlertMessage}/>
            )
        }
    }

    return (
        <div className="recipe_similar_recipes_container">
            {returnCarousel()}
        </div>
    )
}

export default SimilarRecipes;