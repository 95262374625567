import './mobile_filters.css';
import React, { useState } from 'react';
import { translate, translateTags } from '../../../../translations/translations.js'

function MobileFilters(props) {

    // const parentFilters = ["Meal", "Cuisine", "Protein", "Ingredients", "Method", "Occasion", "Cooking Time"]
    // const meal_filters = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Dessert', 'Snack', 'Drinks']
    // const cuisine_filters = ['American', 'Asian', 'British', 'Caribbean', 'Chinese', 'German', 'Greek', 'Hawaiian', 'Indian', 'Italian', 'Japanese', 'Korean', 'Malaysian', 'Mediterranean', 'Mexican', 'Middle Eastern', 'Spanish', 'Thai', 'Vietnamese']
    // const ingredient_filters = ['Almond', 'Avocado', 'Balsamic Vinegar', 'Basil', 'Bay Leave', 'Bread', 'Brown Sugar', 'Butter', 'Carrot', 'Cayenne Pepper', 'Celery', 'Cheese', 'Chili Powder', 'Corriander', 'Cinnamon', 'Coconut Milk', 'Cornstarch', 'Cream', 'Cumin', 'Dijon Mustard', 'Egg', 'Flour', 'Garlic', 'Ginger', 'Honey', 'Lemon', 'Lime', 'Maple Syrup', 'Mayonnaise', 'Milk', 'Mustard', 'Olive Oil', 'Onion', 'Oregano', 'Paprika', 'Parsley', 'Pasta', 'Potatoes', 'Rice', 'Soy Sauce', 'Thyme', 'Tomatoes', 'Vinegar']
    // const method_filters = ['Baking', 'Barbecue', 'Frying', 'Roasting', 'Sauteing', 'Slow cooking', 'Steaming', 'Stir frying']
    // const protein_filters = ['Bean', 'Beef', 'Chicken', 'Chickpeas', 'Clams', 'Crab', 'Duck', 'Egg', 'Fish', 'Kangaroo', 'Lamb', 'Lentils', 'Lobster', 'Milk', 'Mussels', 'Oysters', 'Pork', 'Prawns', 'Scallops', 'Tofu', 'Turkey', 'Veal', 'Yoghurt']
    // const occasion_filters = ['Appetisers', 'Banquet', 'Celebration', 'Cocktail Party', 'Crowd Pleaser', 'Picnic', 'Potluck']
    // const cooking_time_filters = ['< 10 mins', '< 15 mins', '< 20 mins', '< 30 mins', '< 45 mins', '< 60 mins']

    const [showChild, setShowChild] = useState([]);

    function addFilter(event, filterType, filter) {

        event.stopPropagation()

        let filterTypeValue = ''
        
        switch (filterType) {
            case "Meal":    
                filterTypeValue = "mealType"
                break;
            case "Cuisine":
                filterTypeValue = "cuisine"
                break;
            case "Ingredients":
                filterTypeValue = "ingredient"
                break;
            case "Method":
                filterTypeValue = "method"
                break;
            case "Protein":
                filterTypeValue = "protein"
                break;
            case "Occasion":
                filterTypeValue = "occasion"
                break;
            case "Cooking Time":
                filterTypeValue = "cooking_time"
                break;
            default:
                break;
        }

        props.updateRecipes(filterTypeValue, filter)
    }

    function showChildItems(parentFilter) {
        if (showChild.includes(parentFilter)) {
            const tmpArray = showChild.filter(item => item !== parentFilter);
            setShowChild(tmpArray)
            switch (parentFilter) {
                case "Meal":
                    document.getElementById("mobile_filters_Meal").style.height = "0";
                    break;
                case "Protein":
                    document.getElementById("mobile_filters_Protein").style.height = "0";
                    break;
                case "Cuisine":
                    document.getElementById("mobile_filters_Cuisine").style.height = "0";
                    break;
                case "Ingredients":
                    document.getElementById("mobile_filters_Ingredients").style.height = "0";
                    break;
                case "Method":
                    document.getElementById("mobile_filters_Method").style.height = "0";
                    break;
                case "Occasion":
                    document.getElementById("mobile_filters_Occasion").style.height = "0";
                    break;
                case "Cooking Time":
                    document.getElementById("mobile_filters_Cooking Time").style.height = "0";
                    break;
                default:

            }
        } else {
            setShowChild([...showChild, parentFilter])
            const parentElement = document.getElementById('mobile_filters_' + parentFilter); // Replace 'parent' with the ID of your parent element
            const childElements = parentElement.getElementsByClassName('mobile_filters_child_item'); // Replace 'class-name' with the name of the class you want to count
            const childCount = childElements.length;
            const numberOfRows = childCount / 2;
            const height = (numberOfRows * 35) + 40;
            switch (parentFilter) {
                case "Meal":
                    document.getElementById("mobile_filters_Meal").style.height = height + "px";
                    break;
                case "Protein":
                    document.getElementById("mobile_filters_Protein").style.height = height + "px";
                    break;
                case "Cuisine":
                    document.getElementById("mobile_filters_Cuisine").style.height = height + "px";
                    break;
                case "Ingredients":
                    document.getElementById("mobile_filters_Ingredients").style.height = height + "px";
                    break;
                case "Method":
                    document.getElementById("mobile_filters_Method").style.height = height + "px";
                    break;
                case "Occasion":
                    document.getElementById("mobile_filters_Occasion").style.height = height + "px";
                    break;
                case "Cooking Time":
                    document.getElementById("mobile_filters_Cooking Time").style.height = height + "px";
                    break;
                default:

            }
        }

        
    }

    function returnChildItems(parentFilter) {

            let parentFilterArray = [];

            switch (parentFilter) {
                case "Meal":
                    parentFilterArray = translateTags('meal_filters');
                    break;
                case "Protein":
                    parentFilterArray = translateTags('protein_filters');
                    break;
                case "Cuisine":
                    parentFilterArray = translateTags('cuisine_filters');
                    break;
                case "Ingredients":
                    parentFilterArray = translateTags('ingredient_filters');
                    break;
                case "Method":
                    parentFilterArray = translateTags('method_filters');
                    break;
                case "Occasion":
                    parentFilterArray = translateTags('occasion_filters');
                    break;
                case "Cooking Time":
                    parentFilterArray = translateTags('cooking_time_filters');
                    break;
                default:
                    parentFilterArray = [];
            }

             

            return (
                <div className="mobile_filters_child_background_container" id={"mobile_filters_background_" + parentFilter} >
                    <div className="mobile_filters_child_container" id={"mobile_filters_" + parentFilter} >
                        <div className="mobile_filters_child_padding"  >
                            {parentFilterArray.map((filter, index) => {
                                return (
                                    <span key={filter + index} className="mobile_filters_child_item"><button className="mobile_filters_child_item_button" onClick={(event) => addFilter(event, parentFilter, filter[0])}>{filter[1]}</button></span>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )

        // }
    }

    return (
        <div className="mobile_filters_container">
            
            <div className="mobile_filters_header_container">
                <div className="mobile_filters_title">
                    {translate('recipes search', 'recipe filters')}
                </div>
                <div className="mobile_filters_close_container" onClick={props.closeMobileFilters}>
                    {translate('misc', 'done')}
                </div>
            </div>
            <div className="mobile_filters_parent_container">
                {translateTags('parent_filters').map((filter, index) => {
                    return (
                        <div key={filter[0] + index}>
                            <span className="mobile_filters_parent_item" onClick={() => showChildItems(filter[0])}>
                                {filter[1]} 
                                <div className="mobile_filter_expand_more_container">
                                    <span className='material-symbols-outlined mobile_filter_expand_more' style={showChild.includes(filter) ? {transform: "rotate(180deg)"} : null }  >
                                        expand_more
                                    </span>
                                </div>
                            </span>
                            {returnChildItems(filter[0])}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MobileFilters;