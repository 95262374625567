import React, { useState, useRef } from 'react'
import '../sign_up_log_in.css'
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { translate } from '../../translations/translations';
import LoadingSpinner from '../../components/loading_spinner/loading_spinner';

function LogIn(props) {

    
    const navigate = useNavigate();
    const emailAddressRef = useRef();
    const passwordRef = useRef();
    const [logInMessage, setLogInMessage] = useState('')
    const cookie_username   = Cookies.get("username")
    const formRef = useRef(null);
    const logInRef = useRef(null);
  
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRegex = /^[\w!@#$%^&*()\-+=[\]{};':"\\|,.<>/?]{8,}$/;
    const [submitActive, setSubmitActive] = useState(false)
    const [submitting, setSubmitting] = useState(false);

    const handleChange = () => {
        const isValidEmail = emailRegex.test(emailAddressRef.current.value);
        const isValidPassword = passwordRegex.test(passwordRef.current.value);

        if (isValidEmail && isValidPassword) {
            setSubmitActive(true)
        } else {
            setSubmitActive(false)
        }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        logInRef.current.click();
      }
    };

    function handleSubmit(event) {
        event.preventDefault();

        setSubmitting(true);

        const emailAddressValue = emailAddressRef.current.value;
        const passwordValue = passwordRef.current.value;
        Cookies.set("username", emailAddressValue);

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ email_address: emailAddressValue, password: passwordValue}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/logUserIn", options)
        .then((response) => response.json())
        .then((json) => handleResponse(json))
    }

    function handleResponse(data) {
        if (data['loggedIn'] === "true") {
            Cookies.set("session_id", data['session_id'], { expires: 7 });
            props.userLoggedIn()
            navigate("/my-collection?loggedin=true");
        } else {
            setLogInMessage(translate('log in sign up', 'Incorrect username or password'))
        }
        setSubmitting(false);
    }

    return (

        <div className="option_content_container">
            <div className="option_content_container_absolute">

                <h1 className="sign_up_title">{translate('log in sign up', 'log in title')}</h1>

                <p className="sign_up_info">
                    {translate('log in sign up', 'log in intro')}
                </p>
                <div className="sign_up_form_container">
                    <form className="sign_up_form" onSubmit={handleSubmit} ref={formRef} onKeyDown={handleKeyDown}>

                        {/* <label> */}
                            <input className="sign_up" type="text" name="name" placeholder={translate('log in sign up', 'email address')} ref={emailAddressRef} defaultValue={cookie_username} onChange={handleChange} autoComplete="off"/>
                        {/* </label> */}
                        <br></br>

                        <label>
                            <input className="sign_up" type="password" name="name" placeholder={translate('log in sign up', 'password')} ref={passwordRef} onChange={handleChange} autoComplete="off" />
                        </label><br/><br/>

                        <button className="forgot_password" onClick={props.goToForgotPassword}>{translate('log in sign up', 'forgot password')}</button>
                        
                        <button type="submit" className={submitActive && !submitting ? "sign_up_submit":"sign_up_submit_deactivate"} disabled={submitActive && !submitting ? false: true } ref={logInRef}>{submitting ? <span style={{ display: "inline-block", textAlign: "center", top:"2px" }}> <LoadingSpinner /> </span>: translate('log in sign up', 'log in')}</button>
                        
                    </form>
                </div>
                <p className="sign_up_message">{logInMessage}</p>
                <p className="go_to_log_in_message">{translate('log in sign up', 'sign up instead')}<button className="log_in_here_button" onClick={props.goToSignIn}>{translate('log in sign up', 'sign up')}</button> </p>
                
            </div>
        </div>
    )
}

export default LogIn;