import './recipe_new_version.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../../components/button/button_primary.js';
import ButtonSecondary from '../../../components/button/button_secondary.js'
import ButtonCancel from '../../../components/button/button_cancel.js';
import ButtonCancelSecondary from '../../../components/button/button_cancel_secondary.js';
import Cookies from 'js-cookie';
import PopUp from '../../../components/pop_up/pop_up.js';

function RecipeNewVersion(props) {

    const navigate = useNavigate();
    const session_id = Cookies.get('session_id');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id })
        }
        fetch(process.env.REACT_APP_API_URL + '/api/checkUserLoggedIn', options)
            .then(response => response.json())
            .then(data => {
                setIsLoggedIn(data.status);
            });
    }, [session_id]);

    function updateToLatest() { 
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id })
        }
        fetch(process.env.REACT_APP_API_URL + '/api/updateRecipeToLatest', options)
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    navigate('/recipe/' + props.versions[0][0] + '/' + props.versions[0][1].replace(/ /g, '-').toLowerCase());
                    props.hideVersions();
                    props.showAlertMessage('This recipe has been updated to the latest version in your collection.');
                }
            }
            );
    }

    function newVersionPopUpBody() {

        return (
            <div className="recipe_versions_content">
                {isLoggedIn ?
                    <p>The owner of this recipe has published a new version of this recipe. This version is no longer discoverable by the community.<br /><br />You can choose to update this recipe to the latest version for your collection, or keep this current version.</p>
                    :
                    <p>The owner of this recipe has published a new version of this recipe. This version is no longer discoverable by the community.</p>
                }
                {isLoggedIn ?
                    <>
                        <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Update Recipe" margin={window.innerWidth < 768 ? "bottom" : "right"} icon="update" action={() => updateToLatest()} />
                        <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name="See Latest" margin={window.innerWidth < 768 ? "bottom" : "right"} icon="feature_search" action={() => { navigate('/recipe/' + props.versions[0][0] + '/' + props.versions[0][1].replace(/ /g, '-').toLowerCase()); props.hideVersions() }} />
                        <ButtonCancelSecondary width={window.innerWidth < 768 ? '100%' : null} name="No thanks" margin="right" icon="undo" action={() => props.hideVersions()} />
                    </>
                    :
                    <>
                        <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="See Latest" margin={window.innerWidth < 768 ? "bottom" : "right"} icon="feature_search" action={() => { navigate('/recipe/' + props.versions[0][0] + '/' + props.versions[0][1].replace(/ /g, '-').toLowerCase()); props.hideVersions() }} />
                        <ButtonCancel width={window.innerWidth < 768 ? '100%' : null} name="No thanks" margin="right" icon="undo" action={() => props.hideVersions()} />
                    </>
                }
            </div>
        )
    }
    return (
        <div className="recipe_versions_container" onClick={() => props.hideVersions()}>
            <PopUp showPopUp={true} hidePopUp={props.hideVersions} title="New Version Available" body={newVersionPopUpBody()} />
        </div>
    )

}

export default RecipeNewVersion;