import './page_links.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { translate } from '../../../translations/translations';
import DesktopSearch from '../../search/desktop_search/desktop_search.js';
import Cookies from 'js-cookie';

function PageLinks(props) {

    const language = Cookies.get('language') || 'english';
    const [navFontSize, setNavFontSize] = useState();

    useEffect(() => {
        if (language === 'chinese' || language === "korean" || language === "japanese") {
            setNavFontSize('16px');
        }
    }, [language]);

    return (
        <div className="page_links_container" style={{fontSize:navFontSize}}>
            <ul className="page_links_list">
                {/* <li>
                    <Link to="/" className="page_links_item">{translate('navigation', 'EXPLORE')}</Link>
                </li> */}
                <li>
                    <Link to="/my-collection" className={props.createMode ? "page_links_item_create" : "page_links_item"}>MY COLLECTION</Link>
                </li>
                <li>
                    <Link to="/recipes-search" className={props.createMode ? "page_links_item_create" : "page_links_item"}>{translate('navigation', 'RECIPES')}</Link>
                </li>
                {/* <li >
                    <Link to="/about" className="page_links_item" onClick={hideLogInUI}>INGREDIENTS</Link>
                </li> */}
                <li >
                    <Link to="/shopping-list" className={props.createMode ? "page_links_item_create" : "page_links_item"}>{translate('navigation', 'BASKET')}</Link>
                </li>
                <li>
                    <Link to="/about" className={props.createMode ? "page_links_item_create" : "page_links_item"}>{translate('navigation', 'ABOUT US')}</Link>
                </li>
                <li>
                    <span className="page_links_item ">
                        <DesktopSearch createMode={props.createMode} />
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default PageLinks;