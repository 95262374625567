import './introduction.css';
import React, { useEffect, useRef, useCallback } from 'react';
import Cookies from "js-cookie";

function Introduction(props) {

    const inputRef = useRef();
    const session_id = Cookies.get('session_id')
    const getRecipeIntroductionMemo = useCallback(getRecipeIntroduction, [props.createRecipeID, session_id])

    useEffect(() => {
        
        getRecipeIntroductionMemo()
        
        
    }, [getRecipeIntroductionMemo]);

    function getRecipeIntroduction() {
        
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID}),
            };

            fetch(process.env.REACT_APP_API_URL + '/api/createGetIntroduction', options)

                .then(response => response.json())
                .then(data => {
                    inputRef.current.value = data['create_introduction']
                    autoResize()
                });
        } else {
            let introduction = Cookies.get('create_introduction')
            if (introduction === undefined) {
                inputRef.current.value = ''
            } else {
                inputRef.current.value = Cookies.get('create_introduction')
            }
            autoResize();
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        updateIntroduction()
        const createIntroductionInput = document.getElementById('create_introduction_input')
        createIntroductionInput.blur()
        
    };

    const updateIntroduction = () => {
        
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, introduction: inputRef.current.value }),
            };

            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateIntroduction', options)
                .then(response => response.json())
                .then(data => {});
        } else {
            Cookies.set('create_introduction', inputRef.current.value, { expires: 7 });
        }
    };

    const autoResize = () => {
        const createIntroductionInput = document.getElementById('create_introduction_input')
        createIntroductionInput.style.height = 'auto';
        createIntroductionInput.style.height = createIntroductionInput.scrollHeight + 10 + 'px';
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <textarea id="create_introduction_input" className="create_content_introduction" placeholder="Introduce your recipe (Optional)" ref={inputRef} onChange={() => autoResize()} onBlur={handleSubmit}></textarea>
            </form>
        </>
    )

}

export default Introduction;