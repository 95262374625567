export function toTitleCase(str) {  
    if (str === undefined) {
        return;
    } else if (str === null) {
        return;
    } else if (str === "") {
        return;
    } else {
      return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    }
}