import './privacy_policy.css'
import React, { useEffect } from "react";

function PrivacyPolicy() {

    useEffect(()=> {
        document.title = "Garlic Basil - Privacy Policy"
    },[])

    return (
        <div className="privacy_policy_container">


            <div className="privacy_policy_content">
            <span style={{fontWeight:"500"}}>Privacy Policy</span>
            <br/><br/>
            <span style={{fontWeight:"500"}}>Effective Date: 5 April 2023</span>
            <br/><br/>
            At Garlic Basil, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and protect personal information that we receive from users of our website.
            <br/><br/>
            <span style={{fontWeight:"500"}}>What personal information do we collect?</span><br/>
            When you register on our website, we collect your username, encrypted password, and first name. We also collect usage data to help improve the website user experience.
            <br/><br/>
            <span style={{fontWeight:"500"}}>How do we use your personal information?</span><br/>
            We use your personal information to:
            <br/>
            <ul>
            <li>Provide you with access to our website and its features</li>
            <li>Communicate with you about our website and any updates or changes</li>
            <li>Improve our website and user experience</li>
            <li>Enforce our terms and conditions of use</li>
            </ul>
            We will not disclose your personal information to third parties without your consent, except where required by law or as necessary to protect our legal rights.
            <br/><br/>
            <span style={{fontWeight:"500"}}>How do we protect your personal information?</span><br/>
            We use industry-standard measures to protect your personal information from unauthorized access, use, or disclosure. We also limit access to your personal information to employees who need it to perform their duties.
            <br/><br/>
            <span style={{fontWeight:"500"}}>Your Rights</span><br/>
            You have the right to access, correct, or delete any personal information that we hold about you. You can do this by contacting us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a>.
            <br/><br/>
            <span style={{fontWeight:"500"}}>Changes to Our Privacy Policy</span><br/>
            We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on our website.
            <br/><br/>
            <span style={{fontWeight:"500"}}>Contact Us</span><br/>
            If you have any questions or concerns about our Privacy Policy, please contact us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a>.
            </div>
        </div>
    );
}

export default PrivacyPolicy;

