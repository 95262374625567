import './recipe_instructions.css'
import React, { useState, useEffect } from 'react'
import { translate } from '../../../translations/translations.js'

function RecipeInstructionItem(props) {

    const [completed, setCompleted] = useState(false);
    

    useEffect(() => {
        // set all steps completed to false
        setCompleted(false);
    }, [props.recipe_id]);

    function toggleComplete() {
        const element = document.getElementById('step_' + props.item[2]);
        const heightString = window.getComputedStyle(element).height;
        const height = parseInt(heightString, 10);
        if (completed) {
            window.scrollTo({
                top: window.pageYOffset - height - 20,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: window.pageYOffset + height + 20,
                behavior: 'smooth'
            });
        }
        setCompleted(!completed);
    }
    
    return (
        <li id={'step_' + props.item[2]} className="recipe_instructions_item" onClick={() => toggleComplete()}>
            <span className="recipe_steps_text" style={completed ? {'color':'rgb(180,180,180)'} : {'color':'black'}}>{translate('recipe', 'Step')} {props.item[2]}</span>
            <span className="recipe_instruction_text" style={completed ? {'color':'rgb(180,180,180)'} : {'color':'black'}}>{props.item[3]}</span>
        </li>
    )

}

export default RecipeInstructionItem;