import './pop_up.css';
import React, { useEffect, useState } from 'react';

function MobilePopUp(props) {

    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        setShowPopUp(props.showPopUp);
    }, [props.showPopUp]);

    function hidePopUp() { 
        if (props.preventHide === undefined || props.preventHide === false) {
            props.hidePopUp()
        }
    }

    return (
        window.innerWidth < 768 ?
            <>
                {showPopUp ? <div className="mobile_pop_up_mask" onClick={() => hidePopUp()}></div> : null}
                <div className="mobile_pop_up_content_container" style={showPopUp ? { bottom: '0px' } : { bottom: '-100vh' }} onClick={(e) => e.stopPropagation()}>
                    <div className="mobile_pop_up_close_button_container">
                        {props.preventHide === undefined || props.preventHide === false ? <div className="mobile_pop_up_close_button" onClick={() => hidePopUp()}></div> : null}
                    </div>
                    <div>
                        <h1 className="mobile_pop_up_title">{props.title}</h1>
                    </div>
                    <div className="mobile_pop_up_body">
                        {props.body}
                    </div>
                </div>

            </>
            :
            showPopUp ?
                <>
                    <div className="desktop_pop_up_mask" onClick={() => hidePopUp()}></div>
                    <div className="desktop_pop_up_content_container" style={showPopUp ? { display: '0px', width: props.width } : { bottom: '-100vh', width: props.width }} onClick={(e) => e.stopPropagation()}>
                        <div className="desktop_pop_up_content_header">
                            <div className="desktop_pop_up_header_title">
                                <h1 className="desktop_pop_up_title">{props.title}</h1>
                            </div>
                            <div className="desktop_pop_up_close_button_container" onClick={() => hidePopUp()}>
                                <span className="material-symbols-outlined desktop_pop_up_close_button" >close</span>
                            </div>
                        </div>
                        <div className="desktop_pop_up_body">
                            {props.body}
                        </div>
                    </div>

                </>
                : null

    )
}

export default MobilePopUp;