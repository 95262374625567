import './edit_handle_panel.css'
import React, { useEffect, useState, useRef } from 'react'
import ButtonPrimary from '../../components/button/button_primary.js'
import Cookies from 'js-cookie'
import ButtonCancel from '../../components/button/button_cancel';

function EditHandlePanel(props) {

    const [handle, setHandle] = useState('');
    const [handleAvailable, setHandleAvailable] = useState(0);
    const handleRef = useRef();
    const session_id = Cookies.get('session_id');

    useEffect(() => {
        setHandle(props.handle);
    }, [props.handle])

    function checkIfHandleIsAvailable() {

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ handle: handle })
        }

        fetch(process.env.REACT_APP_API_URL + "/api/checkIfHandleAvailable", options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    setHandleAvailable(2);
                } else {    
                    setHandleAvailable(1);
                }
            })
    }

    function saveHandle() {

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ session_id: session_id, handle: handleRef.current.value })
        }

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserHandle", options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    props.setHandle(handle);
                    props.setShowHandlePanel(false); 
                }
            })
    }


    return (
        <div className="edit_handle_panel_container" onClick={() => props.setShowHandlePanel(false)}>
            <div className="edit_handle_panel_content" onClick={(e) => e.stopPropagation()}>
                <div className="edit_handle_panel_header">
                    <h1 className="edit_handle_panel_header_title">Edit Handle</h1>
                </div>
                <div className="edit_handle_panel_body">
                    <div className="edit_handle_panel_body_item"> 
                        <div className="edit_handle_panel_body_item_content">
                            <input ref={handleRef} className="edit_handle_panel_item_input" type="text" value={handle} onChange={(e) => setHandle(e.target.value)} placeholder="Handle" onInput={() => setHandleAvailable(0)}/>
                            <div className="edit_handle_panel_item_check_status">
                                {handleAvailable === 2 &&  <p className="edit_handle_panel_handle_status_text" style={{color:'var(--primary-color)'}} >Available!</p> }
                                {handleAvailable === 1 && <p className="edit_handle_panel_handle_status_text" style={{color:'var(--cancel-color)'}}>Not available!</p>}
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="edit_handle_panel_footer">
                {handleAvailable === 2 && <ButtonPrimary name={"Update Handle"} margin="right" action={() => saveHandle(handle)} icon="save" />}
                {(handleAvailable === 0 || handleAvailable === 1) && <ButtonPrimary margin="right" name="Check Availability" action={() => checkIfHandleIsAvailable()} icon="how_to_reg" /> }
                <ButtonCancel name="Nevermind" icon="undo" action={() => props.setShowHandlePanel(false)} />
                </div>
            </div>
        </div>
    )

}

export default EditHandlePanel;