import './instructions_item.css';
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ButtonCancel from '../../components/button/button_cancel.js';
import ButtonPrimary from '../../components/button/button_primary.js';

function InstructionItem(props) {

    const session_id = Cookies.get('session_id');
    const stepNumber = props.data[2];
    const instructionRef = useRef();
    const [handleBlurEnabled, setHandleBlurEnabled] = useState(true);

    useEffect(() => {
        instructionRef.current.value = props.data[3];
        const element = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
        element.style.height = 'auto';
        let newHeight = parseInt(element.scrollHeight) + 'px';
        element.style.height = newHeight
    }, [props.data, stepNumber]);

    const autoResize = (event) => {
        event.target.style.height = 'auto';
        let newHeight = parseInt(event.target.scrollHeight) + 'px';
        event.target.style.height = newHeight
    };

    function updateInstruction() {

        if (props.createRecipeID !== "0") {
            if (handleBlurEnabled) {
                if (window.innerWidth > 768) {
                    const element = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
                    const actionButtons = document.getElementById('create_instruction_remove_id' + stepNumber);
                    element.style.backgroundColor = 'transparent';
                    actionButtons.style.display = 'none';
                }
            }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, create_step_number: stepNumber, create_instruction: instructionRef.current.value}),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateInstruction', options)
                .then(response => response.json())
                .then((json) => {});
        } else { 
            if (handleBlurEnabled) {
                if (window.innerWidth > 768) {
                    const element = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
                    const actionButtons = document.getElementById('create_instruction_remove_id' + stepNumber);
                    element.style.backgroundColor = 'transparent';
                    actionButtons.style.display = 'none';
                }
            }
            props.instructionsList[stepNumber - 1][3] = instructionRef.current.value;
            Cookies.set('create_instructions', JSON.stringify(props.instructionsList));
        }
    }

    function removeInstruction() {

        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, create_step_number: stepNumber}),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createRemoveInstruction', options)
                .then(response => response.json())
                .then(() => {
                    props.refreshInstructionsList();
                });
        } else {
            const updatedArray = props.instructionsList.filter((_, i) => i !== (stepNumber-1));
            for (let i = 0; i < updatedArray.length; i++) {
                if (updatedArray[i][2] > stepNumber) {
                    updatedArray[i][2] = updatedArray[i][2] - 1;
                }
            }
            props.setInstructionsList(updatedArray);
            Cookies.set('create_instructions', JSON.stringify(updatedArray));
            if (updatedArray.length === 1) {
                props.setHideDeleteButton(true);
            } else {
                props.setHideDeleteButton(false);
            }

            
        }
    }

    function textBoxFocus() {
        if (window.innerWidth > 768) {
            const element = document.getElementById('create_instructions_step_text_area_id' + stepNumber);
            const actionButtons = document.getElementById('create_instruction_remove_id' + stepNumber);
            
            element.style.backgroundColor = 'rgb(240,240,240)';
            actionButtons.style.display = 'inline-block';
        }
    }

    function cancelBlur() {
        if (window.innerWidth > 768) {
            setHandleBlurEnabled(false);
            setTimeout(() => {
            setHandleBlurEnabled(true);
            }, 100);
        }
    }

    function updateAndAddStep() {
        updateInstruction();
        props.addNewStep(stepNumber)
    }

    return (
        <div className="create_instructions_step_parent_container">
            <div className="create_instructions_step_container">
                {/* <div id={"create_instructions_outline_id" + stepNumber} className="create_instructions_step_outline"></div> */}
                <div className="create_instructions_step_text_container">
                    <textarea id={'create_instructions_step_text_area_id' + stepNumber} className="create_instructions_step_textarea" placeholder="Write your instructions here..." onInput={autoResize} onFocus={() => textBoxFocus()} onBlur={updateInstruction} ref={instructionRef}></textarea>
                    <div className="create_instructions_step_number_container">
                        <h3 className="create_instructions_step_number">Step {stepNumber}</h3>
                    </div>
                    <div id={"create_instruction_remove_id" + stepNumber} className="create_instructions_step_action_buttons" onMouseDown={() => cancelBlur()}>
                        {window.innerWidth < 768 ?
                        <> 
                            <div style={{marginBottom:'5px'}}><ButtonPrimary width={'50px'} margin={'left'} icon={'add'} action={() => updateAndAddStep()}/></div>
                            <div>{props.hideDelete ? null : <ButtonCancel width={'50px'} margin={'left'} icon={'delete'} action={() => removeInstruction()}/> }</div>
                        </>
                        :
                        <>
                            {props.hideDelete ? null : <ButtonCancel width={'50px'} margin={'left'} icon={'delete'} action={() => removeInstruction()}/> }
                            <ButtonPrimary width={'50px'} margin={'left'} icon={'add'} action={() => updateAndAddStep()}/>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )


}

export default InstructionItem;