import './cookie_popup.css'
import React, { useEffect } from 'react'
import ButtonPrimary from '../../components/button/button_primary.js'
import ButtonSecondary from '../../components/button/button_secondary.js'
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom'; 

function CookiePopUp() {

    const navigate = useNavigate()

    useEffect(() => {
        const cookie_consent = Cookies.get('cookie_consent')
        if (cookie_consent === undefined) {
            document.getElementById("cookie_popup_container").style.display = "block"
        }
    }, [])

    function setCookie() {
        Cookies.set('cookie_consent', 'true', { expires: 30 })
        document.getElementById("cookie_popup_container").style.display = "none"
    }

    return (
        <div id="cookie_popup_container" className="cookie_popup_container">
            <div className="cookie_popup_content">
                <h3 className="cookie_popup_title">Cookie Consent</h3>
                <p className="cookie_popup_text">
                    We use cookies to improve your experience on our website. By browsing
                    this website, you agree to our use of cookies.
                </p>
                <div className="cookie_popup_buttons">
                <ButtonPrimary name="Got it!" icon="cookie" margin={window.innerWidth < 768 ? null : "right"} action={() => setCookie()}/>
                <ButtonSecondary name="Cookie Policy" icon="help" action={() => navigate('/cookie-policy')} />
                </div>
            </div>
        </div>
    )
}

export default CookiePopUp;