import './recipe_info.css'
import React, { useState, useRef, useEffect } from 'react'
// import FavouriteButton from '../../../components/favourite_button/favourite_button.js'
// import { useLocation } from 'react-router-dom'
import PlaceHolder from '../../../components/placeholder/placeholder.js'
import RecipeInfoReportItem from './recipe_info_report_item.js'
import RecipeShare from './recipe_share/recipe_share.js'
import { translate } from '../../../translations/translations.js'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import ButtonPrimary from '../../../components/button/button_primary'
import ButtonSecondary from '../../../components/button/button_secondary'
import PopUp from '../../../components/pop_up/pop_up.js'

function RecipeInfo(props) {

    const session_id = Cookies.get('session_id')
    const [showShareOptions, setShowShareOptions] = useState(false)
    const [showReportPanel, setShowReportPanel] = useState(false);
    const [reportItems, setReportItems] = useState([]);
    const [archived, setArchived] = useState(0)
    const navigate = useNavigate();

    const reportDetailsRef = useRef(null);

    useEffect(() => {
        setArchived(props.archived)
    }, [props.archived])

    function addItemToReportItems(item) {

        if (reportItems.includes(item)) {
            const index = reportItems.indexOf(item)
            const newReportItems = [...reportItems]
            newReportItems.splice(index, 1)
            setReportItems(newReportItems)
        } else {
            setReportItems([...reportItems, item])
        }

    }

    function sendReport() {
        if (reportItems.length > 0) {
            for (let i = 0; i < reportItems.length; i++) {
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ recipe_id: props.recipe_id, section: reportItems[i], detail: reportDetailsRef.current.value })
                }
                fetch(process.env.REACT_APP_API_URL + '/api/reportRecipe', options)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 'success') {
                            props.showAlertMessage(translate('alert', 'report issue'))
                            setShowReportPanel(false)
                        } else {
                            props.showAlertMessage(translate('alert', 'try again later'))
                            setShowReportPanel(false)
                        }
                    })
            }
        }
    }

    function addToMyCollection(recipe_id) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: recipe_id })
        }
        fetch(process.env.REACT_APP_API_URL + "/api/addUserFavouriteRecipes", options)
            .then(response => response.json())
            .then(json => {
                if (json['status'] === false) {
                    props.showAlertMessage('to add to your collection', 'sign_up_log_in')
                } else {
                    props.showAlertMessage(json.message)
                    props.setInUserCollection(true)
                }
            })
    }

    function removeFromMyCollection(recipe_id) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: recipe_id })
        }
        fetch(process.env.REACT_APP_API_URL + "/api/removeUserFavouriteRecipes", options)
            .then(response => response.json())
            .then(json => {
                if (json['status'] === false) {
                    props.showAlertMessage(translate('alert', 'to save your favourite recipes.'), 'sign_up_log_in')
                } else {
                    props.showAlertMessage(json.message)
                    props.setInUserCollection(false)
                }

            })
    }

    function switchToCreateMode(recipe_id, create_type) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: recipe_id, create_type: create_type })
        }
        fetch(process.env.REACT_APP_API_URL + '/api/createDuplicateRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    if (create_type === 'edit') {
                        props.showAlertMessage('Created new version')
                    } else {
                        props.showAlertMessage('Created new variation')
                    }
                    navigate('/create/' + data.create_recipe_id + '/' + props.name.replace(/ /g, '_').toLowerCase())
                } else {
                    if (create_type === 'edit') {
                        props.showAlertMessage('to create a new version', 'sign_up_log_in')
                    } else {
                        props.showAlertMessage('to create a new variation', 'sign_up_log_in')
                    }

                }
            })
    }

    function archiveRecipe() {

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/archiveRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    props.showAlertMessage('Recipe archived')
                    setArchived(1)
                }
            })
    }

    function unarchiveRecipe() {

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id })
        }

        fetch(process.env.REACT_APP_API_URL + '/api/unarchiveRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    props.showAlertMessage('Recipe unarchived')
                    setArchived(0)
                }
            })
    }

    function showBrowserShareOptions() { 

        if (navigator.share) {
            try {
                navigator.share({
                    title: 'Garlic Basil',
                    text: 'Hey, check out this recipe on Garlic Basil!',
                    url: window.location.href
                });
                console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            setShowShareOptions(true)
        }
    }

    function showContent() {

        return (
            <div className="recipe_info_button_containers">
                {/* {!props.inUserCollection &&
                    <ButtonPrimary name="Add to My Collection" margin="right" action={() => {addToMyCollection(props.recipe_id)}} icon="library_add"/>
                }
                {props.isOwner ? <ButtonSecondary name="Edit Recipe" margin="right" action={() => {switchToCreateMode(props.recipe_id, 'edit')}} icon="edit"/> : null}
                <ButtonSecondary name="Create Variation" margin="right" action={() => {switchToCreateMode(props.recipe_id, 'variation')}} icon="edit_square"/>
                <ButtonSecondary name="Share" margin="right" action={() => {setShowShareOptions(true)}} icon="ios_share"/>
                <ButtonSecondary name="Report" action={() => {setShowReportPanel(true)}} icon="flag"/>
                {props.inUserCollection && <ButtonSecondary name="Remove from Collection" margin="left" action={() => {removeFromMyCollection(props.recipe_id)}} icon="delete"/>}
                {props.isOwner ? <ButtonSecondary name="Archive Recipe" margin="right" action={() => {switchToCreateMode(props.recipe_id, 'edit')}} icon="edit"/> : null} */}
                {props.isOwner ?
                    <>
                        <ButtonPrimary name="Share" margin="right" action={() => showBrowserShareOptions()} icon="ios_share" />
                        <ButtonSecondary name="Edit Recipe" margin="right" action={() => { switchToCreateMode(props.recipe_id, 'edit') }} icon="edit" />
                        <ButtonSecondary name="Create Variation" margin="right" action={() => { switchToCreateMode(props.recipe_id, 'variation') }} icon="edit_square" />
                        {!props.inUserCollection && <ButtonSecondary name="Add to My Collection" margin="right" action={() => { addToMyCollection(props.recipe_id) }} icon="library_add" />}
                        {props.inUserCollection && <ButtonSecondary name="Remove from Collection" margin="right" action={() => { removeFromMyCollection(props.recipe_id) }} icon="delete" />}
                        <ButtonSecondary name={archived === 1 ? "Unarchive Recipe" : "Archive Recipe"} margin="right" action={() => { archived === 1 ? unarchiveRecipe() : archiveRecipe() }} icon={archived === 1 ? "unarchive" : "archive"} />
                    </>
                    :
                    <>
                        {!props.inUserCollection && <ButtonPrimary name="Add to My Collection" margin="right" action={() => { addToMyCollection(props.recipe_id) }} icon="library_add" />}
                        <ButtonSecondary name="Create Variation" margin="right" action={() => { switchToCreateMode(props.recipe_id, 'variation') }} icon="edit_square" />
                        <ButtonSecondary name="Share" margin="right" action={() => showBrowserShareOptions()} icon="ios_share" />
                        <ButtonSecondary name="Report" action={() => { setShowReportPanel(true) }} icon="flag" />
                        {props.inUserCollection && <ButtonSecondary name="Remove from Collection" margin="left" action={() => { removeFromMyCollection(props.recipe_id) }} icon="delete" />}
                    </>
                }
            </div>
        )
    }

    function reportPopUpBody() {
        return (
            <div className="recipe_info_report_body">
                {/* // <div className="report_recipe_input_container">
            //     <div className="report_recipe_user_input">
            //         <button className="report_recipe_close"><span className="material-symbols-outlined report_recipe_close_icon" onClick={() => {setShowReportPanel(false); setReportItems([])}}>close</span></button>
            //         <h2 className="report_recipe_title">{translate('recipe','report title')}</h2>  */}
                <p>{translate('recipe', 'reprot subtitle')}</p>
                <p>{translate('recipe', 'report section')}</p>
                <RecipeInfoReportItem name={translate("recipe", "Recipe Name")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Introduction")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Image")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Ingredients")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Method")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Cooking Time")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Nutritional Information")} addItemToReportItems={addItemToReportItems} />
                <RecipeInfoReportItem name={translate("recipe", "Other")} addItemToReportItems={addItemToReportItems} />
                <p>{translate('recipe', 'report details title')}</p>
                <textarea className="report_recipe_textarea" placeholder={translate('recipe', 'report details placeholder')} ref={reportDetailsRef} ></textarea>
                <button className={reportItems.length > 0 ? "report_recipe_submit_button_active" : "report_recipe_submit_button"} onClick={() => sendReport()}>{translate('recipe', 'report submit')}</button>
                {/* //     </div>
            // </div> */}
            </div>
        )
    }

    return (
        <div id="recipe_info" className="recipe_info_container">
            {props.infoLoaded ? showContent() : <div className="recipe_info_placeholder"><PlaceHolder backgroundColour={"white"} /></div>}
            {/* <div className="recipe_info_placeholder"><PlaceHolder backgroundColour={"white"}/></div> */}
            {showShareOptions ? <RecipeShare hide={() => setShowShareOptions(false)} /> : null}
            {/* {showReportPanel ? reportPanel() : null} */}
            <div className="recipe_info_report_panel">
                <PopUp showPopUp={showReportPanel} hidePopUp={() => setShowReportPanel(false)} title="Report an issue" body={reportPopUpBody()} />
            </div>
        </div>
    )

}

export default RecipeInfo;