import './header.css'
import React, { useState, useEffect } from 'react';
import UploadRecipe from '../upload_recipe/upload_recipe.js';
import PublishRecipe from '../publish_recipe/publish_recipe.js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Placeholder from '../../components/placeholder/placeholder.js';

function Header(props) {

    const [dataLoading, setDataLoading] = useState(true);
    const [createType, setCreateType] = useState('');
    const [title, setTitle] = useState('');
    const [howToMesssage, setHowToMessage] = useState('');
    const [isVariation, setIsVariation] = useState(false);
    const [handle, setHandle] = useState('');
    const [baseRecipeID, setBaseRecipeID] = useState('');
    const [baseRecipeName, setBaseRecipeName] = useState('');
    const session_id = Cookies.get('session_id')
    const [newUser, setNewUser] = useState(false);
    const [publishButtonName, setPublishButtonName] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID})
            }
            fetch(process.env.REACT_APP_API_URL + '/api/createGetRecipeMetaData', options)
            .then(response => response.json())
            .then(data => { 

                if (data.create_type === "new") {
                    setCreateType("new");
                    setIsVariation(false);
                    setTitle('Creating a new recipe');
                    setHowToMessage('Let\'s get creative! Begin crafting your recipe by simply clicking in each area. Your edits are seamlessly saved as you leave each input box. When you\'re ready, publish your recipe and share it with the world!');
                    setPublishButtonName('Publish Recipe');
                } else if (data.create_type === "variation") {
                    setCreateType("variation");
                    setIsVariation(true);
                    setTitle('Creating a variation');
                    setHowToMessage('Begin crafting your variation by simply clicking in each area. Your edits are seamlessly saved as you leave each input box. When you\'re ready, publish your variation and share it with the world!');
                    setBaseRecipeName(data.recipe_name);
                    setBaseRecipeID(data.recipe_id_link);
                    setHandle(data.handle);
                    setPublishButtonName('Publish Variation');
                } else if (data.create_type === "edit") {
                    setCreateType("edit");
                    setTitle('Editing an existing recipe');
                    setHowToMessage(<>You are editing your recipe, <span style={{fontWeight:'500'}}>{data.recipe_name}</span>. When you publish this edit, it will create a new version of this recipe. It will also hide the previous version from the public. However, users who have added the previous version to their collection will still have access to it. If they view an older version, there will a prompt notifying them that a new version is available.</>);
                    setPublishButtonName('Publish Edit');
                }
             
                setDataLoading(false);
            })
        } else {
            setIsVariation(false);
            setTitle('Create your recipe');
            setHowToMessage('Begin crafting your recipe by simply clicking in each area. Your edits are seamlessly saved as you leave each input box. When you\'re ready, publish your recipe and share it with the world!');
            setNewUser(true);
            setPublishButtonName('Publish Recipe');
            setDataLoading(false);
        }
    }, [props.createRecipeID, session_id])

    function goToBaseRecipe() {
        navigate('/recipe/' + baseRecipeID + '/' + baseRecipeName.replace(/ /g, '_').toLowerCase())
    }

    return (
        <>  
            {newUser && <p className="create_upload_new_user_message" onClick={() => navigate('/sign-up')} >This recipe is available for this session only. Sign up or log in to save and publish this recipe to your account.</p>}
            
            {
            dataLoading ? 
            
                <div className="create_upload_recipe_title_placeholder_container">
                    <div className="create_upload_recipe_title_placeholder">
                        <Placeholder />
                    </div>
                    <div className="create_upload_recipe_body_placeholder">
                        <Placeholder />
                    </div>
                </div>
                :
                window.innerWidth < 768 ?
                <>
                    <h1 className="create_title">{title}</h1>
                    <div className="create_upload_recipe_container">
                        
                    </div>
                    <p className="create_upload_how_to">{howToMesssage} </p>
                    {isVariation ? <p className="create_upload_how_to">This recipe is a variation of <span style={{fontWeight:'500', cursor:'pointer'}} onClick={() => goToBaseRecipe()} >{baseRecipeName}</span> by <span style={{fontWeight:'500'}}>{handle}</span>.</p> : null}
                    <div className="create_upload_recipe_container">
                        <UploadRecipe createRecipeID={props.createRecipeID} createType={createType}/>
                        <PublishRecipe createRecipeID={props.createRecipeID} publishButtonName={publishButtonName} createType={createType} recipeName={props.recipeName} ingredients={props.ingredients} instructionsList={props.instructionsList}/>
                    </div>
                </>
                :
                <>
                    <h1 className="create_title">{title}</h1>
                    <div className="create_upload_recipe_container">
                        <UploadRecipe createRecipeID={props.createRecipeID} createType={null}/>
                        <PublishRecipe createRecipeID={props.createRecipeID} publishButtonName={publishButtonName} createType={createType} recipeName={props.recipeName} ingredients={props.ingredients} instructionsList={props.instructionsList}/>
                    </div>
                    <p className="create_upload_how_to">{howToMesssage}</p>
                    {isVariation ? <p>This recipe is a variation of <span style={{fontWeight:'500', cursor:'pointer'}} onClick={() => goToBaseRecipe()} >{baseRecipeName}</span> by <span style={{fontWeight:'500'}}>{handle}</span>.</p> : null}
                </>
            }
        </>
    )

}

export default Header;