import './button_primary.css';
import React from "react"
import LoadingSpinner from '../loading_spinner/loading_spinner.js'

function ButtonPrimary(props) {

    function getMargin() {
        if (props.margin === "left") {
            return {marginLeft: '5px', width: props.width}
        } else if (props.margin === "right") {
            return {marginRight: '5px', width: props.width}
        } else if (props.margin === "bottom") {
            return {marginBottom: '5px', width: props.width}
        } else {
            return {margin: '0px', width: props.width}
        }
    }

    return (
        <div className="button_primary_container" style={getMargin()} >
            <button className={props.isLoading || props.isDisabled ? "button_primary_disabled": "button_primary_class"} onClick={props.isLoading || props.isDisabled  ? null : props.action} >
                <span className="material-symbols-outlined button_primary_icon">
                    {props.icon}
                </span>
                {props.name}
                {props.isLoading ? <LoadingSpinner /> : null}
            </button>
        </div>
    )

}

export default ButtonPrimary;