import './placeholder.css';
import React from 'react';

function PlacehHolder() {

    return (
        <div className="placeholder-container">
        <div className="loading-bar"></div>
        </div>
    )
}

export default PlacehHolder;