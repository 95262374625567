import './image_uploader.css'
import React, { useState } from 'react';
import ButtonSecondary from '../button/button_secondary.js';
import Cookies from 'js-cookie'

const ImageUploader = (props) => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const session_id = Cookies.get('session_id')
    const [loading, setLoading] = useState(false);

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file !== undefined) {
    //         setSelectedImage(file);
    //         setSelectedName(file.name);
    //     }
    // }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file !== undefined) { 
            const formData = new FormData();
            formData.append('image', file);
            formData.append('session_id', session_id);
            formData.append('create_recipe_id', props.createRecipeID);

            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Determine the correct dimensions of the canvas based on the image orientation
                    let width = img.width;
                    let height = img.height;
                    const orientation = getOrientationFromExif(file);
                    if (orientation === 6 || orientation === 8) {
                        // Swap width and height for 90° or 270° rotations
                        [width, height] = [height, width];
                    }

                    // Set the correct canvas dimensions
                    canvas.width = width;
                    canvas.height = height;

                    // Rotate and draw the image on the canvas
                    switch (orientation) {
                        case 2:
                            ctx.transform(-1, 0, 0, 1, width, 0); // Flip horizontally
                            break;
                        case 3:
                            ctx.transform(-1, 0, 0, -1, width, height); // Rotate 180°
                            break;
                        case 4:
                            ctx.transform(1, 0, 0, -1, 0, height); // Flip vertically
                            break;
                        case 6:
                            ctx.transform(0, 1, 1, 0, 0, 0); // Rotate 90° clockwise
                            break;
                        case 8:
                            ctx.transform(0, -1, -1, 0, width, height); // Rotate 90° counterclockwise
                            break;
                        default:
                            break; // No rotation necessary
                    }

                    // Draw the image on the canvas
                    ctx.drawImage(img, 0, 0, width, height);

                    // Get the rotated image data from the canvas
                    canvas.toBlob(function (blob) {
                        const rotatedImageFile = new File([blob], file.name, { type: file.type });

                        // Update the selected image state with the rotated image file
                        setSelectedImage(rotatedImageFile);
                        setSelectedName(rotatedImageFile.name);

 
                    }, file.type);
                };

                img.src = e.target.result;
            };

            reader.readAsDataURL(file);
        }
    }


    function getOrientationFromExif(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                const view = new DataView(e.target.result);
                if (view.getUint16(0, false) !== 0xffd8) {
                    resolve(-2); // Not a valid JPEG
                    return;
                }

                const length = view.byteLength;
                let offset = 2;

                while (offset < length) {
                    if (view.getUint16(offset, false) === 0xffe1) {
                        offset += 2;
                        if (view.getUint32(offset, false) !== 0x45786966) {
                            resolve(-1); // Invalid EXIF data
                            return;
                        }
                        const littleEndian = view.getUint16(offset + 4, false) === 0x4949;
                        offset += view.getUint32(offset + 8, littleEndian);
                        const tags = view.getUint16(offset, littleEndian);
                        offset += 2;
                        for (let i = 0; i < tags; i++) {
                            if (view.getUint16(offset + i * 12, littleEndian) === 0x0112) {
                                resolve(view.getUint16(offset + i * 12 + 8, littleEndian));
                                return;
                            }
                        }
                    } else if ((view.getUint16(offset, false) & 0xff00) !== 0xff00) {
                        break;
                    } else {
                        offset += view.getUint16(offset, false);
                    }
                }

                resolve(-1); // No orientation tag found
            };

            reader.readAsArrayBuffer(file);
        });
    }


    function uploadImage() {
        if (selectedImage) {
            setLoading(true);
            props.isLoading(true)
            const formData = new FormData();
            formData.append('image', selectedImage);
            formData.append('session_id', session_id);
            formData.append('create_recipe_id', props.createRecipeID);

            fetch(process.env.REACT_APP_API_URL + '/api/createUploadImage', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(() => {
                    props.refreshUploadImage()
                    props.isLoading(false)
                    setLoading(false);
                    setSelectedName(null);
                })
        }
    }

    return (
        <div className="image_uploader_container">
            <div className="image_uploading_file_input_container">
                <label htmlFor="file-input" className={loading ? "file-input-label-disabled" : "file-input-label"}>
                    Select Image For Recipe (Optional)
                </label>
            </div>
            {loading ? null : <input id="file-input" type="file" accept=".jpg, .jpeg, .png, .webp, .heic" onChange={handleFileChange} />}
            {selectedName && <><p className="image_uploader_iamge_caption">Image selected: <span className="image_uploading_file_name">{selectedName} </span></p></>}
            {selectedName && <ButtonSecondary name="Upload Image" action={() => uploadImage()} icon="upload" isLoading={loading} />}
        </div>
    );

};

export default ImageUploader;
