// import GoogleAds from '../google_ads/google_ads.js';

import './recipe.css';
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

import RecipeInfo from './recipe_components/recipe_info/recipe_info.js'
import RecipeIntroduction from './recipe_components/recipe_introduction/recipe_introduction';
import RecipeIntroductionAdmin from './recipe_components/recipe_introduction/recipe_introduction_admin.js';
import RecipeImage from './recipe_components/recipe_image/recipe_image';
import RecipeIngredients from './recipe_components/recipe_ingredients/recipe_ingredients';
import RecipeIngredientsAdmin from './recipe_components/recipe_ingredients/recipe_ingredients_admin.js';
import RecipeInstructions from './recipe_components/recipe_instructions/recipe_instructions.js';
// import RecipeNutrition from './recipe_components/recipe_nutrition/recipe_nutrition.js';
import Cookies from 'js-cookie'
import StarRating from './recipe_components/recipe_ratings/star_rating.js'
import SimilarRecipes from './recipe_components/similar_recipes/similar_recipes.js'
import ScrollToTopOnMount from '../components/scroll_to_top/scrollToTop.js';
import AdminPanel from '../components/admin_panel/admin_panel.js'

function Recipe(props) {
    
    const session_id = Cookies.get('session_id')
    
    const [isAdmin, setIsAdmin] = useState(false)
    const [isOwner, setIsOwner] = useState(false)
    const [isArchived, setIsArchived] = useState(false)
    const [inUserCollection, setInUserCollection] = useState(false)
    const [collectionCount, setCollectionCount] = useState(null)
    const [variationCount, setVariationCount] = useState(null)
    const [showAdminPanel, setShowAdminPanel] = useState(true)

    const [recipeVersions, setRecipeVersions] = useState(null)
    const [ratings, setRatings] = useState(null);
    const [ratingsCount, setRatingsCount] = useState(null);
    const [views, setViews] = useState(null);
    const [recipeServings, setRecipeServings] = useState(null);
    const [cookingTime, setCookingTime] = useState(null);
    const [favourite, setFavourite] = useState(false)

    const [metaDescription, setMetaDescription] = useState(null);
    const [name, setName] = useState(null);
    const [handle, setHandle] = useState(null);
    const [introduction, setIntroduction] = useState(null);
    const [ingredients, setIngredients] = useState(null);
    const [instructions, setInstructions] = useState(null);
    // const [nutrition, setNutrition] = useState({'num_focal_ingredients': 0, 'matched_ingredients':[]});

    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    // let recipe_id = searchParams.get('recipe_id')
    const { recipeId } = useParams();
    let recipe_id = recipeId
    
    const [infoLoaded, setInfoLoaded] = useState(false)
    const [introductionLoaded, setIntroductionLoaded] = useState(false)

    const getRecipeStatisticsMemoize = useCallback(getRecipeStatistics, [recipe_id, session_id])
    const getRecipeInformationMemoize = useCallback(getRecipeInformation, [recipe_id, session_id, getRecipeStatisticsMemoize])
    const updateRecipeMemoize = useCallback(updateRecipe, [getRecipeInformationMemoize])

    useEffect(() => {
        // Check if link includes a specific language
        const targetLanguage = searchParams.get('lng')
        if (targetLanguage !== null) {
            const currentLanguage = Cookies.get('language')
            if (currentLanguage !== targetLanguage) {
                Cookies.set('language', targetLanguage)
                window.location.reload()
            }
        }
        
    },[searchParams])

    useEffect(() => {
        updateRecipeMemoize()
    }, [updateRecipeMemoize]);

    useEffect(() => {
        setInfoLoaded(false)
        setIntroductionLoaded(false)
    }, [recipe_id]);

    function updateRecipe() {
        getRecipeInformationMemoize()
    }
    
    function getRecipeInformation() {
        
        const language = Cookies.get('language')

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({session_id: session_id, recipe_id: recipe_id, language: language}),
        };

        getRecipeStatisticsMemoize()

        fetch(process.env.REACT_APP_API_URL + "/api/addViewToRecipe", options)

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeInfo", options)
        .then((response) => response.json())
        .then((json) => fillInfo(json));

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeIngredients", options)
        .then((response) => response.json())
        .then((json) => fillIngredients(json));

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeInstructions", options)
        .then((response) => response.json())
        .then((json) => fillInstructions(json));

        fetch(process.env.REACT_APP_API_URL + "/api/isRecipeUserFavourite", options)
        .then((response) => response.json())
        .then((json) => setFavourite(json));

        // fetch(process.env.REACT_APP_API_URL + "/api/getRecipeNutrition", options)
        // .then((response) => response.json())
        // .then((json) => fillNutrition(json));

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeVersions", options)
        .then((response) => response.json())
        .then((json) => fillVersions(json));

        fetch(process.env.REACT_APP_API_URL + "/api/isRecipeOwner", options)
        .then((response) => response.json())
        .then((json) => {setIsOwner(json[0])});

        fetch(process.env.REACT_APP_API_URL + "/api/isUserAdmin", options)
        .then((response) => response.json())
        .then((json) => {setIsAdmin(json)});

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserFavouriteLastViewed", options)

    }

    function getRecipeStatistics() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({session_id: session_id, recipe_id: recipe_id}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeStatistics", options)
        .then((response) => response.json())
        .then((json) => fillStatistics(json[0]));
    }

    function fillInfo(json) {   
        const recipe_info = json['info']
        document.title = recipe_info[0][1] + " Recipe"
        setName(recipe_info[0][1])
        setHandle(recipe_info[0][7])
        setIsArchived(recipe_info[0][8])
        setIntroduction(recipe_info[0][2])
        setMetaDescription(recipe_info[0][3])
        setCookingTime(recipe_info[0][4])
        setRecipeServings(recipe_info[0][5])
        setIntroductionLoaded(true)

        setCollectionCount(json['collection_count'])
        setVariationCount(json['base_recipe_id_count'])
        setInUserCollection(json['is_user_collection'])
    }

    function fillVersions(data) {  
        setRecipeVersions(data)
    }

    function fillStatistics(statistics) {

        if (statistics['views'] === 0) {
            setViews(1)
        } else {
            setViews(statistics['views'])
        }
        if (statistics['rating_count']) {
            setRatings(statistics['rating'])
            setRatingsCount(statistics['rating_count'])            
        } else {
            setRatings(0)
            setRatingsCount(0)   
        }
        setInfoLoaded(true)
    }

    function fillIngredients(json) {
        setIngredients(json)
    }

    function fillInstructions(json) {
        setInstructions(json)
    }

    // function fillNutrition(json) {
    //     setNutrition(json)
    // }

    function showAdminPanelToggle() {
        if (showAdminPanel) {
            setShowAdminPanel(false)
        } else {
            setShowAdminPanel(true)
        }
        
    }

    return (
        <>
            <Helmet>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <div className="recipe_page_container">

                <div id="recipe_page_top" className="recipe_page_top_container"></div>
                {isAdmin && <button className="show_admin_panel_button" onClick={() => showAdminPanelToggle()}>Show Admin Panel</button>}
                {isAdmin && showAdminPanel ? <div className="recipe_admin_panel_container"><AdminPanel /></div>: null}
                
                <ScrollToTopOnMount />
                <div className="recipe_container">

                    <RecipeInfo isOwner={isOwner} recipe_id={recipe_id} inUserCollection={inUserCollection} setInUserCollection={setInUserCollection} name={name} views={views} ratings={ratings} ratingsCount={ratingsCount} cooking_time={cookingTime} favourite={favourite} showAlertMessage={props.showAlertMessage} infoLoaded={infoLoaded} archived={isArchived}/>
                    
                    <div className="recipe_page_content_container">
                        {isAdmin ? <RecipeIntroductionAdmin recipe_id={recipe_id} name={name} text={introduction} introductionLoaded={introductionLoaded} /> : <RecipeIntroduction recipe_id={recipe_id} name={name} handle={handle} text={introduction} introductionLoaded={introductionLoaded} views={views} ratings={ratings} collectionCount={collectionCount} variationCount={variationCount} recipeVersions={recipeVersions} showAlertMessage={props.showAlertMessage}/>}
                        <RecipeImage name={name} recipe_id={recipe_id} introductionLoaded={introductionLoaded} showAlertMessage={props.showAlertMessage}/> 
                        {/* <GoogleAds /> */}

                        <div className="recipe_info">
                            {isAdmin ? <RecipeIngredientsAdmin text={ingredients} recipe_id={recipe_id} showAlertMessage={props.showAlertMessage}/> : <RecipeIngredients text={ingredients} recipe_id={recipe_id} showAlertMessage={props.showAlertMessage} recipeServings={recipeServings}/>}
                            <div className="recipe_page_mobile_break"></div>
                            <RecipeInstructions recipe_id={recipe_id} text={instructions} cooking_time={cookingTime} />
                            {/* <RecipeNutrition nutrition={nutrition} isAdmin={isAdmin}/> */}
                            <div className="recipe_page_mobile_break"></div>
                            <StarRating recipe_id={recipe_id} showAlertMessage={props.showAlertMessage} getRecipeStatistics={getRecipeStatistics} />            
                        </div>
                    </div>
                </div>

                <SimilarRecipes recipe_id={recipe_id} updateRecipe={updateRecipe} showAlertMessage={props.showAlertMessage} />

            </div>
        </>
    );
  }
  
export default Recipe;
  