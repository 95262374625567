import './recipe_instructions.css'
import React from 'react'
import RecipeIngredientItem from './recipe_instruction_item.js'
import { translate } from '../../../translations/translations.js'

function RecipeInstructions(props) {

    const instructions = props['text'];

    const formatCookingTime = (cookingTime) => {
        const minutes = cookingTime
        const hours = minutes / 60
        const remainder = minutes % 60
        if (hours >= 1) {
            return Math.floor(hours) + "h " + remainder + "m"
        } else {
            return minutes + " " + translate('recipe', 'Minutes')
        }
    }
    
    if (!instructions || instructions.length === 0) {
      return <div></div>;
    }

    return (
        <div className="recipe_instructions_container">
            <span><h2 className="recipe_instructions_title">{translate('recipe', 'Instructions')}</h2></span><span className="recipe_instructions_cooking_time"><span className="material-symbols-outlined hourglasss_icon">schedule</span>{formatCookingTime(props.cooking_time)} </span>
            {instructions.map((item) => (
                <RecipeIngredientItem recipe_id={props.recipe_id} key={item[2]} item={item} />
            ))}
            <p className="recipe_instruction_tip">{translate('recipe', 'Instructions hint')}</p>
        </div>
    )

}

export default RecipeInstructions;