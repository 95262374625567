import '../sign_up_log_in.css'
import React, { useState, useRef, useEffect } from 'react'
import Cookies from "js-cookie";
import { translate } from '../../translations/translations.js'

function ForgotPassword(props) {

    
    const emailAddressRef = useRef();
    const [logInMessage, setLogInMessage] = useState('')
    const cookie_username   = Cookies.get("username")
    const [submitActive, setSubmitActive] = useState(false)

    useEffect(() => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = emailRegex.test(emailAddressRef.current.value);
        if (isValidEmail) {
            setSubmitActive(true)
        } else {
            setSubmitActive(false)
        }
    },[])

    const handleChange = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = emailRegex.test(emailAddressRef.current.value);

        if (isValidEmail) {
            setSubmitActive(true)
        } else {
            setSubmitActive(false)
        }
    }

    function handleSubmit(event) {

        event.preventDefault();

        const emailAddressValue = emailAddressRef.current.value;
        Cookies.set("username", emailAddressValue);

        setLogInMessage('If your email is registered with us, we will send a link to reset your password within a few minutes.')

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({email_address: emailAddressValue}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/sendResetPasswordEmail", options)

    }

    return (

        <div className="option_content_container">
            <div className="option_content_container_absolute">
                <h1 className="sign_up_title">{translate('log in sign up', 'forgot password title')}</h1>
                <p className="sign_up_info">
                    {translate('log in sign up', 'forgot password intro')}
                </p>
                <div className="sign_up_form_container">
                <form className="sign_up_form" onSubmit={handleSubmit}>
                    <label>
                        <input className="sign_up" type="text" name="name" placeholder={translate('log in sign up', 'email address')} ref={emailAddressRef} defaultValue={cookie_username} onChange={handleChange}  autoComplete="off"/>
                    </label><br></br>
                    <button className={submitActive ? "sign_up_submit":"sign_up_submit_deactivate"} type="submit" disabled={submitActive ? false: true } >{translate('log in sign up', 'reset password')}</button>
                </form>
                </div>
                <p className="sign_up_message" style={{color: "var(--primary-color"}}>{logInMessage}</p>
                <p className="go_to_log_in_message">{translate('log in sign up', 'back to log in')}<button className="log_in_here_button" onClick={props.goToLogIn}>{translate('log in sign up', 'log in')}</button> </p>
                
            </div>
        </div>
    )
}

export default ForgotPassword;