import React from "react"
import { translate } from '../../../../../translations/translations.js';
import './results_columns.css'

function ResultsColumnsOptions(props) {
    
    function updateShowColumns(event, columns) {
      event.stopPropagation();
      props.setColumns(columns);
    }

    function hideFilters() {
        props.hideFilters()
    }
  
    return (
        <div className="results_columns_options_container">
            
            <ul className="results_columns_options_list">
                <p className="results_columns_options_header">{translate('recipes search', 'set columns')}</p>
                <li className="results_columns_options_list_item" onClick={(event) => updateShowColumns(event, 2)} >
                    2 {translate('recipes search', 'recipes')}
                </li>
                <li className="results_columns_options_list_item" onClick={(event) => updateShowColumns(event, 3)} >
                    3 {translate('recipes search', 'recipes')}
                </li>
                <li className="results_columns_options_list_item" onClick={(event) => updateShowColumns(event, 4)} >
                    4 {translate('recipes search', 'recipes')}
                </li>
                <li className="results_columns_options_list_item" onClick={(event) => updateShowColumns(event, 5)} >
                    5 {translate('recipes search', 'recipes')}
                </li>
            </ul>
            <button className="results_columns_done" onClick={hideFilters}>{translate("misc", "done")}</button>
        </div>
    );
}

export default ResultsColumnsOptions;