import './ingredients_list.css';
import React from 'react';
import Cookies from 'js-cookie';
import IngredientTile from '../../../components/ingredient_tile/ingredient_tile.js';
import ButtonPrimary from '../../../components/button/button_primary.js';
import ButtonCancel from '../../../components/button/button_cancel';
import { toTitleCase } from '../../.././formatting/string_functions.js';

function IngredientsList(props) {

    const session_id = Cookies.get('session_id')

    function removeIngredient(create_ingredient_id) {

        if (props.createRecipeID !== "0") {

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, create_ingredient_id: create_ingredient_id})
            }

            fetch(process.env.REACT_APP_API_URL + '/api/createRemoveIngredient', options)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        props.refreshIngredientsList()
                    }
                })
        } else {
            let ingredientsArray = JSON.parse(Cookies.get('create_ingredients'))
            if (ingredientsArray.length === 1) {
                ingredientsArray = []
            } else {
                for (let i = 0; i < ingredientsArray.length; i++) { 
                    if (parseInt(ingredientsArray[i][1], 10) === parseInt(create_ingredient_id, 10)) {
                        ingredientsArray.splice(i, 1)
                    }
                }
            }
            
            Cookies.set('create_ingredients', JSON.stringify(ingredientsArray))
            props.refreshIngredientsList()
        }
    }

    function fillValues(create_ingredient_id, create_name, create_quantity, create_uom) {
        const section = document.getElementById("ingredients_add_panel");
        if (section) {
          section.scrollIntoView({ behavior: 'smooth'}); 
        }
        const ingredientNameDisplay = toTitleCase(create_name);
        props.updateIngredient(create_ingredient_id, ingredientNameDisplay)
        props.updateQuantity(create_quantity)
        props.updateMeasurement(create_uom, convertToTitleCase(create_uom))
    }

    const convertToTitleCase = (str) => { 
        return str.toLowerCase().replace(/^(.)|\s(.)/g, function (char) {
          return char.toUpperCase();
        });
    };

    return (

        <div id="create_content_ingredient_list_element" className="create_content_ingredients_list_container">
                
            {props.ingredients.map((item, index) => { 
                const create_name = item[1]
                const create_name_plural = item[2]
                const create_quantity = item[3]
                const create_uom = item[4]
                const hash_id = item[5]
                const create_ingredient_id = item[6]
                return (
                    <div key={'create_ingredient_' + index} className="create_ingredient_tile_container">
                        {window.innerWidth < 768 ?
                            <IngredientTile width={"calc((100vw - 2em - 30px) / 3)"} height={"calc(((100vw - 2em - 30px) / 3) - 20px)"} name={create_name} name_plural={create_name_plural} quantity={create_quantity} uom={create_uom} hash_id={hash_id} servings={4} />
                        :
                            <IngredientTile width={152} height={132} name={create_name} name_plural={create_name_plural} quantity={create_quantity} uom={create_uom} hash_id={hash_id} servings={4} />
                        }
                        <div className="create_ingredient_tile_edit_container">
                            <div className="create_ingredient_tile_edit_content">
                                <ButtonPrimary name={'Edit'} width={'100%'}  icon={window.innerWidth < 768 ? null: 'edit'} margin={'bottom'} action={() => fillValues(create_ingredient_id, create_name, create_quantity, create_uom)}/>
                                <ButtonCancel name={'Remove'} width={'100%'} icon={window.innerWidth < 768 ? null: 'delete'} margin={'bottom'} action={() => removeIngredient(create_ingredient_id)}/>    
                            </div>
                        </div>
                    </div>
                )
            })}

                
        </div>

    );


}

export default IngredientsList;