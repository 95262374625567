import React, { useState } from 'react'

function RecipeInfoReportItem(props) {

    const [selected, setSelected] = useState(false)

    function toggleSelected() {
        if (selected) {
            setSelected(false)
        } else {
            setSelected(true)
        }
        props.addItemToReportItems(props.name)
    }

    return (
        <div className={selected ? "report_recipe_section_item_selected" : "report_recipe_section_item"} onClick={() => toggleSelected()}>
            {props.name}
        </div>
    )
}

export default RecipeInfoReportItem;