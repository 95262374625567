import './name.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Cookies from "js-cookie";

function Name(props) {

    const inputRef = useRef();
    const [recipeName, setRecipeName] = useState('');
    const session_id = Cookies.get('session_id')
    const getRecipeNameMemo = useCallback(getRecipeName, [props.createRecipeID, session_id])
    const [nameRows, setNameRows] = useState(1);
    const [createType, setCreateType] = useState('');

    useEffect(() => {
        getRecipeNameMemo()
    }, [getRecipeNameMemo]);

    useEffect(() => {
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID})
            }
            fetch(process.env.REACT_APP_API_URL + '/api/createGetRecipeMetaData', options)
            .then(response => response.json())
            .then(data => { 
 
                if (data.create_type === "new") {
                    setCreateType("new"); 
                } else if (data.create_type === "variation") {
                    setCreateType("variation"); 
                } else if (data.create_type === "edit") {
                    setCreateType("edit"); 
                }
            })
        } else {
            setCreateType("new"); 
        }
    }, [props.createRecipeID, session_id])

    function getRecipeName() {
        
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID}),
            };

            fetch(process.env.REACT_APP_API_URL + '/api/createGetName', options)

                .then(response => response.json())
                .then(data => {     
                    inputRef.current.value = data['create_name']
                    setRecipeName(data['create_name'])
                    autoResize()        

                    // To help check when user publish that value is not empty.
                    
                });
        } else {
            let name = Cookies.get('create_name')
            if (name === undefined) {
                inputRef.current.value = ''
            } else {
                inputRef.current.value = Cookies.get('create_name')
                autoResize()
            }
            
        }
            
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        updateName()
        const createNameInput = document.getElementById('create_content_name_value_element')
        createNameInput.blur()
    };

    const updateName = () => {
        
        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, name: inputRef.current.value }),
            };

            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateName', options)
                .then(response => response.json())
                .then(data => {
            
            });
        } else {
            Cookies.set('create_name', inputRef.current.value, { expires: 7 });
        }
    };

    const autoResize = () => { 
        const createNameInput = document.getElementById('create_content_name_value_element')
        createNameInput.style.height = 'auto'; 
        let rows;
        window.innerWidth < 768 ?
            rows = Math.floor(createNameInput.scrollHeight / 42) 
            :
            rows = Math.floor((createNameInput.scrollHeight - 40.5) / 58)  
        setNameRows(rows)
        // creatNameInput.style.height = creatNameInput.scrollHeight + 10 + 'px';
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="create_content_name_child_container">
                    {createType === "edit" ?
                        <p id="create_content_name_value_element" className="create_content_name_edit" title="You can't edit the name of a recipe when creating a new version. If you want to change the name of a recipe, consider creating a new variation."> {recipeName}</p>
                        :
                        <textarea id="create_content_name_value_element" rows={nameRows} autoComplete="off" autoCorrect="off" autoCapitalize="off" className="create_content_name" type="text" placeholder="Recipe name" ref={inputRef} onBlur={handleSubmit} onInput={autoResize}/>
                    }
                </div>
            </form>
        </>
    )

}

export default Name;