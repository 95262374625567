import React, { useState, useEffect, useRef } from 'react'
import './recipe_introduction.css'
import PlaceHolder from '../../../components/placeholder/placeholder.js'
import RecipeVariations from '../recipe_variations/recipe_variations.js'
import RecipeNewVersion from '../recipe_new_version/recipe_new_version.js'
import { useNavigate } from 'react-router-dom'
import { translate } from '../../../translations/translations.js'

function RecipeIntroduction(props) {

    const isMobile = window.innerWidth < 768 ? true : false
    const [showIntroduction, setShowIntroduction] = useState(true);
    const [shortenText, setShortenText] = useState(false);
    const [showVariations,  setShowVariations] = useState(false); 
    const [isLatestVersion, setIsLatestVersion] = useState(true); 
    const [showNewVersion, setShowNewVersion] = useState(false);
    const [versionsData, setVersionsData] = useState(null);
    const pRef = useRef(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        countRenderedLines();
    }, [props.text]);

    useEffect(() => { 
        if (props.recipeVersions !== null && props.recipeVersions !== undefined) { 
            setIsLatestVersion(props.recipeVersions.is_latest_version);
            setVersionsData(props.recipeVersions.versions);
        }
    }, [props.recipeVersions]);

    function countRenderedLines() {
        const pElement = pRef.current;
        if (pElement) {
            const lineHeight = parseFloat(getComputedStyle(pElement).lineHeight);
            const elementHeight = pElement.offsetHeight;

            const renderedLines = Math.round(elementHeight / lineHeight); 
            if (renderedLines > 3) {
                setShowIntroduction(false);
                setShortenText(true);
            }
        }
    }


    function toggleShowIntroduciton() {
        setShowIntroduction(preState => !preState);
    }

    const convertToTitleCase = (str) => {
        return str.replace(/(^|\s)\w/g, (match) => match.toUpperCase());
    }; 

    function showNewVersionPanel() {
        setShowNewVersion(true);
    }

    function goToAuthorCollection() {
        navigate(`/collection/${props.handle}`);
    }

    function returnMobileView() {
        return (
            props.introductionLoaded ?
                <div>
                    <p ref={pRef} className={showIntroduction ? "recipe_introduction_text_show" : "recipe_introduction_text_hide"}>
                        {props.text}
                    </p>
                    {shortenText ?
                    <span className="recipe_introduction_show_more" onClick={toggleShowIntroduciton} >
                        {showIntroduction ? translate("recipe", "see less") : translate("recipe", "see more")}
                    </span>
                    : null}
                </div>
                :
                <div className="recipe_introduction_placeholder">
                    <PlaceHolder />
                </div>
        )
    }

    function returnDesktopView() {
        return (
            props.introductionLoaded ?
                <div>
                    <p className="recipe_introduction_text_show">
                        {props.text}
                    </p>
                </div>
                :
                <div className="recipe_introduction_placeholder">
                    <PlaceHolder />
                </div>
        )
    }
    return (
        <div className="recipe_introduction_container">
            {props.introductionLoaded ?
                <>
                    {isLatestVersion ? null : <> <span className="recipe_stats_new_version_message" onClick={() => showNewVersionPanel()}>New Version Available</span></>}
                    <h1 className="recipe_name">{convertToTitleCase(props.name)}</h1>
                    <div className="recipe_introduction_statistic_container">
                        <h2 className="recipe_author">
                            By <span className="recipe_author_link" onClick={() => goToAuthorCollection()}>{props.handle}</span>
                            <span className="recipe_stats">
                                
                                &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {props.ratings === 0 ? "No ratings yet" : props.ratings + " Stars"}
                                &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {props.views === 1 ? "1 View" : props.views + " Views"}
                                {props.collectionCount === 0 ? null : <>&nbsp;&nbsp;&#8226;&nbsp;&nbsp; {props.collectionCount} {props.collectionCount === 1 ? "Collection" : " Collections"}</>}
                                {props.variationCount === 0 ? null : <>&nbsp;&nbsp;&#8226;&nbsp;&nbsp; <span className="recipe_stats_variations" onClick={() => setShowVariations(true)}>{props.variationCount} {props.variationCount === 1 ? " Variation" : " Variations"}</span></>}
                                
                            </span>
                        </h2>
                        
                    </div>
                </>
                :
                <div className="recipe_title_placeholder"><PlaceHolder /></div>
            }
            {isMobile ? returnMobileView() : returnDesktopView()}
            {showVariations ? <RecipeVariations recipe_id={props.recipe_id} hideVariations={() => setShowVariations(false)}/>: null}
            {showNewVersion ? <RecipeNewVersion recipe_id={props.recipe_id} versions={versionsData} hideVersions={() => setShowNewVersion(false)} showAlertMessage={props.showAlertMessage}/>: null}
        </div>
    )

}

export default RecipeIntroduction;