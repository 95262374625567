import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'
import './star_rating.css'
import { translate } from '../../../translations/translations.js'

function StarRating(props) {

    const [rating, setRating] = useState()
    const [rated, setRated] = useState(false)
    const session_id = Cookies.get("session_id")
    

    useEffect(() => {

            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({session_id: session_id, recipe_id: props.recipe_id}),
            };

            fetch(process.env.REACT_APP_API_URL + "/api/getUserRecipeRating", options)
            .then(response => response.json())
            .then(json => {
                fillRating(json)
            })

    },[props.recipe_id, session_id])

    function fillRating(fetched_rating) {

        setRating(fetched_rating)
        if (fetched_rating !== 0) {
            setRated(true)
        } else {
            setRated(false)
        }
    }

    function handleClick(newRating) {

        if (typeof session_id !== "undefined") {

            setRating(newRating);
            setRated(true)

            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({ session_id: session_id, recipe_id: props.recipe_id, rating: newRating}),
            };

            fetch(process.env.REACT_APP_API_URL + "/api/updateUserRecipeRating", options)
            .then((response) => response.json())
            .then(json => {
                if (json['status'] === false) {
                    props.showAlertMessage(translate('alert', 'to rate this recipe.'), 'sign_up_log_in')
                    setRating(0);
                    setRated(false)
                } else {
                    props.getRecipeStatistics()
                }
            })
        } else {
            props.showAlertMessage(translate('alert', 'to rate this recipe.'), 'sign_up_log_in')
        }
    }
  
    return (
        <div className="rate_recipe_container">
            <h3 className="rate_recipe_title">{rated ? "You rated this recipe " + rating + " stars" : translate('recipe', "Rate this recipe")}</h3>
            <div className="star_rating">
                {[1, 2, 3, 4, 5].map((star, index) => {
                    const active = rating >= star;
                    return (
                        <span 
                            key={index}
                            onClick={() => handleClick(star)}
                            style={{ color: active ? 'green' : 'rgb(220,220,220)'}}
                            className="star_single"
                        >
                            &#9733;
                        </span>
                    )
                })}
            </div>
        </div>
    );
  
}

export default StarRating;
