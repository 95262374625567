export function getUOMOptions(uom) {

    const dictionary = {
        'a handful': 'a handful', 
        'a pinch': 'a pinch', 
        'as needed': 'as needed', 
        'as required': 'as required', 
        'bunch': 'bunches', 
        'clove': 'cloves', 
        'cm': 'cms', 
        'cm piece': 'cm pieces', 
        'cup': 'cups', 
        'dash': 'dashes', 
        'fillet': 'fillets', 
        'for deep-frying': 'for deep-frying', 
        'for frying': 'for frying', 
        'for garnish': 'for garnish', 
        'gram': 'grams', 
        'handful': 'handfuls', 
        'head': 'heads', 
        'inch': 'inches', 
        'kilogram': 'kilograms', 
        'kg': 'kgs', 
        'liter': 'liters', 
        'litre': 'litres', 
        'millilitre': 'millilitre', 
        'ml': 'ml', 
        'ounce': 'ounces',
        'pound': 'pounds',
        'piece': 'pieces', 
        'pinch': 'pinches', 
        'scoop': 'scoops', 
        'slice': 'slices', 
        'sprig': 'sprigs', 
        'stalk': 'stalks', 
        'tablespoon': 'tablespoons', 
        'tb': 'tbs', 
        'teaspoon': 'teaspoons', 
        'tsp': 'tsps', 
        'tin': 'tins', 
        'to garnish': 'to garnish', 
        'to taste': 'to taste', 
        'whole': 'whole'
    }

    return dictionary;

}
