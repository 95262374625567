import './dropdown_selection.css';
import React from 'react';

function DropDownSelection(props) {


    return (
        
        <div className="dropdown_container" >
            <ul className="dropdown_list_container">
                {props.options.map((option, index) => {
                    const label = option[0];
                    const action = option[1];
                    return (
                        <li key={label} className="dropdown_list_item" onClick={() => action(label)}>{label}</li>
                    );
                })}
            </ul>
        </div>
    
    );

}

export default DropDownSelection;