import './demo_video.css';
import React from 'react';
import YouTubeVideo from '../components/youtube/youtube.js'; // Adjust the path accordingly

function DemoVideo() {

    const videoId = 'l3SxC6VYsGI'; // This is the video ID from YouTube

    return (
        <div className="demo_video_container">
            {/* <div className="demo_video_content_container"> */}
                <YouTubeVideo videoId={videoId} />
                {/* <video className="demo_video_content" controls autoplay>
                    <source src="https://thesiliconkitchenimages.blob.core.windows.net/videos/garlic-basil-demo-mobile.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video> */}
            {/* </div> */}
        </div>
    );
}

export default DemoVideo;