import './recipe_share.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
// import { translate, translateShareOptions } from '../../../../translations/translations.js'
import { translate } from '../../../../translations/translations.js'
import { useLocation } from 'react-router-dom'
import PopUp from '../../../../components/pop_up/pop_up.js'

function RecipeShare(props) {

    const language = Cookies.get('language') || "english"
    const showShareOptions = true
    // const [shareLanguage, setShareLanguage] = useState(Cookies.get('language') || "english")
    // const shareOptions = translateShareOptions()
    const [copyStatus, setCopyStatus] = useState(false)
    const location = useLocation()
    const [shareUrl, setShareURL] = useState()

    useEffect(() => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            const currentUrl = new URL(process.env.REACT_APP_FRONT_END_URL + location.pathname + location.search);
            if (currentUrl.searchParams.has('lng')) {
                currentUrl.searchParams.set('lng', language);
            } else {
                currentUrl.searchParams.append('lng', language);
            }
            navigator.clipboard.writeText(currentUrl.href);
            setCopyStatus(true)    
        } else {
            const currentUrl = new URL(process.env.REACT_APP_FRONT_END_URL + location.pathname + location.search);
            if (currentUrl.searchParams.has('lng')) {
                currentUrl.searchParams.set('lng', language);
            } else {
                currentUrl.searchParams.append('lng', language);
            }
            setShareURL(currentUrl.href)
        }
    },[location.pathname, location.search, language])

    // function changeTargetLanguage(event, language) {
    //     event.stopPropagation()
    //     setCopyStatus(false)
    //     setShareLanguage(language);
    //     if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    //         const currentUrl = new URL(process.env.REACT_APP_FRONT_END_URL + location.pathname + location.search);
    //         if (currentUrl.searchParams.has('lng')) {
    //             currentUrl.searchParams.set('lng', language);
    //         } else {
    //             currentUrl.searchParams.append('lng', language);
    //         }
    //         navigator.clipboard.writeText(currentUrl.href);
    //         setCopyStatus(true)    
    //     } else {
    //         const currentUrl = new URL(process.env.REACT_APP_FRONT_END_URL + location.pathname + location.search);
    //         if (currentUrl.searchParams.has('lng')) {
    //             currentUrl.searchParams.set('lng', language);
    //         } else {
    //             currentUrl.searchParams.append('lng', language);
    //         }
    //         setShareURL(currentUrl.href)
    //     }
    // }

    // function hideOptions() {
    //     props.hide()
    // }

    // function preventClose(event) {
    //     event.stopPropagation()
    // }

    function copyLinkDisplay() {
        return (
            <>
                <p className="share_option_linked_copied"><span className="material-symbols-outlined linked_copied_icon">done</span>{translate('recipe', 'link copied')}</p> 
                <p className="share_option_linked_copied_message">Share this recipe by pasting the link to your family and friends.</p>
            </>
        )
    }

    function urlLinkDisplay() {
        return (
            <>
                <div className="share_option_url_container"><input className="share_option_url_link" type="text" value={shareUrl}/></div>
                <p className="share_option_linked_copied_message">Share this recipe by pasting the link to your family and friends.</p>
            </>
        )
    }

    // const emojiIcon = (language) => {
    //     if (language === 'english') {
    //         return '🇬🇧'
    //     } else if (language === 'vietnamese') {
    //         return '🇻🇳'
    //     } else if (language === 'chinese') {
    //         return '🇨🇳'
    //     } else if (language === 'korean') {
    //         return '🇰🇷'
    //     } else if (language === 'japanese') {
    //         return '🇯🇵'
    //     } else if (language === 'french') {
    //         return '🇫🇷'
    //     } else if (language === 'spanish') {
    //         return '🇪🇸'
    //     } else if (language === 'italian') {
    //         return '🇮🇹'
    //     } else if (language === 'german') {
    //         return '🇩🇪'
    //     }

    //     return ''
    // }

    return (
        <div className="share_options_container">
            <PopUp showPopUp={showShareOptions} hidePopUp={() => props.hide()} title="Sharing" body={copyStatus ? copyLinkDisplay(): urlLinkDisplay()} width="300px"/>
            {/* <div className="share_options_selection_container" onClick={(e) => preventClose(e)}>
                <p className="share_options_title">{translate('recipe', 'Share this recipe in')}:</p>
                <div>
                    <p className="share_options_target_language">{shareOptions[shareLanguage]}</p><span className="share_options_target_flag_icon">{emojiIcon(shareLanguage)}</span>
                </div>
                {copyStatus ? copyLinkDisplay(): urlLinkDisplay() }
                <div className="share_options_language_bottom_border"></div>
                <div className="share_options_languages_container" >
                    <p className="share_options_language_options_title">{translate('recipe', 'Share in a different language')}:</p>
                    {Object.keys(shareOptions).map((languageOption) => {
                        return(
                            <div key={"share_keys_" + languageOption} className="share_options_item" onClick={(e) => changeTargetLanguage(e, languageOption)}>
                                <span className={language === languageOption ? "share_options_item_selected" : "share_options_item_unselected"}>{shareOptions[languageOption]} <span className="share_options_flag_icon">{emojiIcon(languageOption)}</span></span>
                            </div>
                        )
                    })}
                    
                </div>
                <div className="share_options_language_bottom_border"></div>
                <div className="share_copy_link_container">
                    <button className="share_copy_link_button" onClick={() => hideOptions()}>{translate('misc', 'done')}</button>
                </div>
            </div> */}
        </div>
    );
}

export default RecipeShare;