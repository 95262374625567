import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import '../sign_up_log_in.css'
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/loading_spinner/loading_spinner.js';
import { translate } from '../../translations/translations.js'

function SignUp(props) {

    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const emailAddressRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const firstNameRef = useRef();

    const [validEmail, setValidEmail] = useState(false)
    const [validPassword, setValidPassword] = useState(false)
    const [validConfirmPassword, setValidConfirmPassword] = useState(false)
    const [validFirstName, setValidFirstName] = useState(false)
    const [submitActive, setSubmitActive] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [termsOfUseAgree, setTermsOfUseAgree] = useState(true);
    
    const emailRegex = useMemo(() => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, []);
    const passwordRegex = useMemo(() => /^[\w!@#$%^&*()\-+=[\]{};':"\\|,.<>/?]{8,}$/, []); 
    const nameRegex = useMemo(() => /^[A-Za-z\d]{1,}$/, []);

    const handleChange = () => {

        const isValidEmail = emailRegex.test(emailAddressRef.current.value);
        const isValidPassword = passwordRegex.test(passwordRef.current.value);
        const isValidName = nameRegex.test(firstNameRef.current.value);
        let isValidConfirmPassword = false;

        setValidEmail(isValidEmail)
        setValidPassword(isValidPassword)
        setValidFirstName(isValidName)

        if (passwordRef.current.value === confirmPasswordRef.current.value && passwordRegex.test(confirmPasswordRef.current.value)) {
            isValidConfirmPassword = true;
            setValidConfirmPassword(isValidConfirmPassword)
        } else {
            isValidConfirmPassword = false;
            setValidConfirmPassword(false)
        }

        if (isValidEmail && isValidPassword && isValidConfirmPassword && isValidName && termsOfUseAgree) {
            setSubmitActive(true)
        } else {
            setSubmitActive(false)
        }
    }

    const handleChangeCallback = useCallback(handleChange, [emailAddressRef, passwordRef, confirmPasswordRef, firstNameRef, termsOfUseAgree, emailRegex, passwordRegex, nameRegex])

    useEffect(() => {
        handleChangeCallback()
    }, [handleChangeCallback, termsOfUseAgree, emailRegex, passwordRegex, nameRegex])


    const handleSubmit = (event) => {
        // Prevent the default form submission behavior
        event.preventDefault();

        setSubmitting(true);

        const emailAddressValue = emailAddressRef.current.value;
        const passwordValue = passwordRef.current.value;
        const firstNameValue = firstNameRef.current.value;

        const checkbox = document.querySelector('.marketing_message_checkbox');
        let isChecked = 0;
        if (checkbox.checked) {
            isChecked = 1
        } else {
            isChecked = 0
        }

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ email_address: emailAddressValue, password: passwordValue, first_name: firstNameValue, email_marketing: isChecked}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/createUserAccount", options)
        .then((response) => response.json())
        .then((json) => {
            handleResponse(json)
        })
    }

    function handleResponse(body) {
        if (body['status'] === 'true') {
            Cookies.set("session_id", body['session_id'], { expires: 7 });
            navigate("/recipes-search?logged-in=true");
            props.userLoggedIn()
            props.showAlertMessage(translate('alert', "Welcome, ") + firstNameRef.current.value + "! Please complete your registration via your email.", '', false)
        } else {
            setMessage(body['message']);
            setSubmitting(false);
        }
    }

    return (

        <div className="option_content_container">
            <div className="option_content_container_absolute">

                <h1 className="sign_up_title">Join our community today</h1>
                <p className="sign_up_info">
                {/* {translate('log in sign up', 'sign up intro')} */}
                    Sign up and begin curating, creating and sharing your personalised recipe collection!
                </p>
                <div className="sign_up_form_container">
                <form className="sign_up_form" onSubmit={handleSubmit}>
                    <div style={{position:"relative"}}>
                        <label>
                            <input className="sign_up" type="text" name="name" placeholder={translate('log in sign up', 'email address')} ref={emailAddressRef} onChange={handleChange} autoComplete="off"/>
                        </label><span className="material-symbols-outlined criteria_unconfirmed" style={validEmail ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br></br>
                    
                    </div>
                    <div style={{position:"relative"}}>
                        <label>
                            <input className="sign_up"  type="password" name="name" placeholder={translate('log in sign up', 'password')} ref={passwordRef} onChange={handleChange} />
                        </label><span className="material-symbols-outlined criteria_unconfirmed" style={validPassword ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br></br>
                    </div>
                    <div style={{position:"relative"}}>
                        <label>
                            <input className="sign_up" type="password" name="name" placeholder={translate('log in sign up', 'confirm password')} ref={confirmPasswordRef} onChange={handleChange} />
                        </label><span className="material-symbols-outlined criteria_unconfirmed" style={validConfirmPassword ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br></br>
                    </div>
                    <div style={{position:"relative"}}>
                        <label>
                            <input className="sign_up" type="text" name="name" placeholder={translate('log in sign up', 'first name')} ref={firstNameRef} onChange={handleChange} autoComplete="off"/>
                        </label><span className="material-symbols-outlined criteria_unconfirmed" style={validFirstName ? {color: "var(--primary-color"}: {color: "rgb(180,180,180)"}}>task_alt</span><br></br>
                    </div>
                    <div className="marketing_message_parent_container">
                        <label className="marketing_message_container">
                            <input className="marketing_message_checkbox" type="checkbox" defaultChecked={true}/>
                            <span className="marketing_message_checkmark"></span>
                            <p className="marketing_message_text">{translate('log in sign up', 'email me')}</p>
                        </label>
                    </div>
                    <div className="marketing_message_parent_container">
                        <label className="marketing_message_container">
                            <input className="marketing_message_checkbox" type="checkbox" defaultChecked={true} onChange={() => setTermsOfUseAgree(!termsOfUseAgree)}/>
                            <span className="marketing_message_checkmark"></span>
                            <p className="marketing_message_text">I have read and agree with the <a className="sign_up_terms_of_use" href="/terms-of-use" target="_blank">Terms of Use</a></p>
                        </label>
                    </div>
                    <br/>
                    <button type="submit" className={submitActive || submitting ? "sign_up_submit":"sign_up_submit_deactivate"} disabled={submitActive || submitting ? false: true } > {submitting ? <span style={{ display: "inline-block", textAlign: "center", top:"2px" }}> <LoadingSpinner /> </span>: translate('log in sign up', 'sign up')} </button>

                </form>
                </div>
                <p className="sign_up_message">{message}</p>
                <p className="privacy_policy_message">{translate('log in sign up', 'privacy policy info')}</p>
            </div>  
        </div>
    )
}

export default SignUp;