import './youtube.css';
import React from 'react';

const YouTubeVideo = ({ videoId }) => {
    const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

    return (
        <div className="video-container">
            <iframe
                width="255" // You can adjust these dimensions as needed
                height="552"
                src={embedUrl}
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
};

export default YouTubeVideo;
