import './create_tmp_recipe.css';
import React from 'react'; 
import ButtonPrimary from '../../components/button/button_primary.js';
import ButtonCancel from '../../components/button/button_cancel.js';
import { useNavigate } from 'react-router-dom';
import PopUp from '../../components/pop_up/pop_up.js';

function CreateTmpRecipe(props) {

    const navigate = useNavigate();

    function createRecipeFoundPopUpBody() {

        return (
            <>
                <div className="create_tmp_recipe_info_container">
                    <p className="create_tmp_recipe_info">We noticed you were creating a new recipe. Go to your studio to continue working on this recipe.</p>
                </div>
                <div className="create_tmp_recipe_info_button_container">
                    <ButtonPrimary name="Go to studio" margin={window.innerWidth < 768 ? null : "right"} action={() => navigate('/recipe-studio')} icon="edit_square" />
                    <ButtonCancel name="No thanks" action={() => props.hidePopUp()} icon="undo" />
                </div>
            </>
        )

    }

    return (
        <PopUp showPopUp={props.showPopUp} hidePopUp={() => props.hidePopUp()} title="Recipe found" body={createRecipeFoundPopUpBody()} width="450px" />
    )
}

export default CreateTmpRecipe;