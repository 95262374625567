import React, { useEffect } from 'react'
import './sign_up_log_in.css'
import SignUp from './sign_up/sign_up.js'
import LogIn from './log_in/log_in.js'
import ForgotPassword from './forgot_password/forgot_password.js'

function SignUpLogIn(props) {
    
    useEffect(()=> {
        document.title = "Garlic Basil - Log In"
    },[])

    useEffect(() => {
        const content = document.querySelector(".option_child_container"); 
        content.style.transform = 'translateX(' + props.startPosition + ')';
    })

    function goToSignIn() {
        const content = document.querySelector(".option_child_container"); 
        if (window.innerWidth < 768) {
            content.style.transform = 'translateX(-200vw)';
        } else {
            content.style.transform = 'translateX(-1000px)';
        }
    }

    function goToLogIn() {
        const content = document.querySelector(".option_child_container"); 
        if (window.innerWidth < 768) {
            content.style.transform = 'translateX(-100vw)';
        } else {
            content.style.transform = 'translateX(-500px)';
        }
    }

    function goToForgotPassword(event) {
        event.preventDefault()
        const content = document.querySelector(".option_child_container"); 
        content.style.transform = 'translateX(0px)';
    }

    return (
        <div className="sign_up_container">
            <img className="sign_up_hero_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/sign_up_hero.jpg" alt="sign_up_image" />
            <div className="option_container">
                <div className="option_child_container">
                    <ForgotPassword goToLogIn={goToLogIn}/>
                    <LogIn userLoggedIn={props.userLoggedIn} goToSignIn={goToSignIn} goToForgotPassword={goToForgotPassword}/>
                    <SignUp userLoggedIn={props.userLoggedIn} goToLogIn={goToLogIn} showAlertMessage={props.showAlertMessage}/>
                </div>
            </div>
        </div>
    )
}

export default SignUpLogIn;