import './loading_spinner.css'
import React from 'react'

function LoadingSpinner() {

    return (
        <div className="loader_container">
            <div className="loader_center_container">
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default LoadingSpinner;