import React, { useState, useEffect } from "react"
import RecipesItem from "./recipes_item/recipes_item.js"
import { translate } from "../../translations/translations.js"
import "./recipes_list.css"

function RecipesList(props) {

    const [recipesListArray, setRecipesListArray] = useState([])

    useEffect(() => {
        const tmpRecipesListArray = []
        for (const key in props.recipesList) {
            tmpRecipesListArray.push({"recipe_id": props.recipesList[key].recipe_id, "recipe_name": props.recipesList[key].recipe_name, "servings": props.recipesList[key].servings,  "ingredient_count": props.recipesList[key].ingredient_count, "completed": props.recipesList[key].completed})
        }
        setRecipesListArray(tmpRecipesListArray)
    }, [props.recipesList])
    
    function updateShoppingList() {
        props.updateShoppingList()
    }

    function shoppingListInfo() {
        if (recipesListArray.length === 0) {
            return "Go to a recipe to add ingredients to your shoppping list"
        }
        if (recipesListArray.length === 1) {
            return translate('basket', 'single recipe count')
        } else {
            return (<>{translate('basket', 'there is')} {recipesListArray.length} {translate('basket', 'recipes in your basket')}</>)
        }
    }

    return (
        <div className="recipes_list_container">
            <p className="recipes_list_title">{shoppingListInfo()}</p>
            {recipesListArray.map(item => (
                <RecipesItem key={item.recipe_name + "recipe_item"} recipe_id={item.recipe_id} name={item.recipe_name} servings={item.servings} ingredient_count={item.ingredient_count} completed={item.completed} updateShoppingList={updateShoppingList} updateIngredientsQuantity={props.updateIngredientsQuantity}/>
            ))}
        </div>
    )
}

export default RecipesList;