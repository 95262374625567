import './desktop_search.css';
import React, { useState, useRef } from 'react';
import { translate } from '../../../translations/translations.js';

function DesktopSearch(props) {

    const [openSearch, setOpenSearch] = useState(false);
    const [searchWidth, setSearchWidth] = useState();
    const [searchLeft, setSearchLeft] = useState();

    const elementRef = useRef(null);

    const handleClickOutside = (event) => {  
      if (elementRef.current && !elementRef.current.contains(event.target)) { 
        closeSearch()
      }
    }; 

    const showSearch = () => {
        setOpenSearch(true);
        const pageLinksWidth = document.getElementById('nav_links_container_id').offsetWidth
        setSearchWidth(pageLinksWidth - 60);
        setSearchLeft(pageLinksWidth - 100);
        const searchInput = document.getElementById('desktop_search_input');
        searchInput.focus();
        document.addEventListener('click', handleClickOutside); 
    }

    function searchRecipe(event) {
        event.preventDefault();
        const searchInput = document.getElementById('desktop_search_input');
        const searchQuery = searchInput.value;
        closeSearch()
        if (searchQuery !== '') {
            window.location.href = `/recipes-search?search=${searchQuery}`;
        }
    }

    function setFocusToSearchInput() {
        const searchInput = document.getElementById('desktop_search_input');
        searchInput.focus();
    }

    function closeSearch() {
        document.removeEventListener('click', handleClickOutside);
        setOpenSearch(false);
        
    }

    return (
        <div className="desktop_search_button_container" onClick={() => showSearch()} ref={elementRef}>
            <span className="material-symbols-outlined page_links_search_icon" style={props.createMode ? {color:'white'}:null}>search</span>
            <div className="desktop_search_container" style={{left:'-'+ searchLeft + 'px'}} onClick={() => setFocusToSearchInput()}>
                <span className="desktop_search_input_container" style={openSearch ? {width:searchWidth + 'px', opacity:'1'} : {width: '0px', opacity:'0'}}>
                    <span className="material-symbols-outlined desktop_search_icon">search</span>
                    <form className="desktop_search_form" onSubmit={(e) => searchRecipe(e)}>
                        <input id="desktop_search_input" type="text" className="desktop_search_input" placeholder={translate('recipes search', 'search recipes')} />
                    </form>
                    <span className="material-symbols-outlined desktop_search_close_icon" onClick={(e) => {e.stopPropagation(); setOpenSearch(false)}}>close</span>
                </span>
            </div>
        </div>
    )
}

export default DesktopSearch;