import './recipes_filter.css'
import React, { useState, useRef, useEffect } from 'react'
import FilterDisplay from './filter_display.js'
import ActiveFilterItem from './active_filter_item.js'
import SearchUI from '../../../components/search/search.js'
import SortBy from './recipes_filter_components/sort_by/sort_by.js'
import ResultsColumns from './recipes_filter_components/results_columns/results_columns.js'
// import Cookies from 'js-cookie'
import { translate, translateTags } from '../../../translations/translations'
import { useLocation } from 'react-router-dom'

function RecipesFilter(props) {

    
    const meal_filters = translateTags('meal_filters')
    const cuisine_filters = translateTags('cuisine_filters')
    const ingredient_filters = translateTags('ingredient_filters')
    const method_filters = translateTags('method_filters')
    const protein_filters = translateTags('protein_filters')
    const occasion_filters = translateTags('occasion_filters')
    const cooking_time_filters = translateTags('cooking_time_filters')
    // const meal_filters = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Dessert', 'Snack', 'Drinks']
    // const cuisine_filters = ['American', 'Asian', 'British', 'Caribbean', 'Chinese', 'German', 'Greek', 'Hawaiian', 'Indian', 'Italian', 'Japanese', 'Korean', 'Malaysian', 'Mediterranean', 'Mexican', 'Middle Eastern', 'Spanish', 'Thai', 'Vietnamese']
    // const ingredient_filters = ['Almond', 'Avocado', 'Balsamic Vinegar', 'Basil', 'Bay Leave', 'Bread', 'Brown Sugar', 'Butter', 'Carrot', 'Cayenne Pepper', 'Celery', 'Cheese', 'Chili Powder', 'Corriander', 'Cinnamon', 'Coconut Milk', 'Cornstarch', 'Cream', 'Cumin', 'Dijon Mustard', 'Egg', 'Flour', 'Garlic', 'Ginger', 'Honey', 'Lemon', 'Lime', 'Maple Syrup', 'Mayonnaise', 'Milk', 'Mustard', 'Olive Oil', 'Onion', 'Oregano', 'Paprika', 'Parsley', 'Pasta', 'Potatoes', 'Rice', 'Soy Sauce', 'Thyme', 'Tomatoes', 'Vinegar']
    // const method_filters = ['Baking', 'Barbecue', 'Frying', 'Roasting', 'Sauteing', 'Slow cooking', 'Steaming', 'Stir frying']
    // const protein_filters = ['Bean', 'Beef', 'Chicken', 'Chickpea', 'Clam', 'Crab', 'Duck', 'Egg', 'Fish', 'Kangaroo', 'Lamb', 'Lentil', 'Lobster', 'Milk', 'Mussel', 'Oyster', 'Pork', 'Prawn', 'Scallop', 'Tofu', 'Turkey', 'Veal', 'Yoghurt']
    // const occasion_filters = ['Appetisers', 'Banquet', 'Celebration', 'Cocktail Party', 'Crowd Pleaser', 'Picnic', 'Potluck']
    // const cooking_time_filters = ['< 10 mins', '< 15 mins', '< 20 mins', '< 30 mins', '< 45 mins', '< 60 mins']

    // const [activeFavourites, setActiveFavourites] = useState(false)

    const [showSortByDisplay, setSortByDisplay] = useState(false)
    const [showColumnsCountDisplay, setColumnsCountDisplay] = useState(false)

    const [showMealTypeDisplay, setShowMealTypeDisplay] = useState(false)
    const [showCuisineDisplay, setShowCuisineDisplay] = useState(false)
    const [showIngredientDisplay, setShowIngredientDisplay] = useState(false)
    const [showMethodDisplay, setShowMethodDisplay] = useState(false)
    const [showProteinDisplay, setShowProteinDisplay] = useState(false)
    const [showOccasionDisplay, setShowOccasionDisplay] = useState(false)
    const [showCookingTimeDisplay, setShowCookingTimeDisplay] = useState(false)

    const [searchValue, setSearchValue] = useState('');
    const location = useLocation();

    useEffect(() => {

        const urlParams = new URLSearchParams(location.search);
        const search = urlParams.get('search');
        if (search) {
            setSearchValue(search)
        }
    }, [location]);

    // function toggleFavourites() {
        
    //     const session_id = Cookies.get('session_id')
    //     const options = {
    //         method: 'POST',
    //         headers: {'content-type': 'application/json'},
    //         body: JSON.stringify({ session_id: session_id }),
    //     }

    //     fetch(process.env.REACT_APP_API_URL + '/api/checkUserLoggedIn', options)
    //     .then(response => response.json())
    //     .then(json => { 

    //         if (json['status']) {
    //             props.addToFiltersAndUpdateRecipes('favourite')
    //             setActiveFavourites(current => !current)                
    //         } else {
    //             props.showAlertMessage(translate('alert', 'to see your favourite recipes.'), 'sign_up_log_in')
    //         }
    //     })

    // }

    function showSortByFilters() {
        hideFilters('sortBy')
        setSortByDisplay(current => !current)
    }

    function showColumnsCountFilters() {
        hideFilters('columnsCount')
        setColumnsCountDisplay(current => !current)
    }

    function showMealTypeFilters() {
        hideFilters('mealType')
        setShowMealTypeDisplay(current => !current)
    }

    function showCuisineFilters() {
        hideFilters('cuisine')
        setShowCuisineDisplay(current => !current)
    }

    function showIngredientFilters() {
        hideFilters('ingredient')
        setShowIngredientDisplay(current => !current)
    }

    function showMethodFilters() {
        hideFilters('method')
        setShowMethodDisplay(current => !current)
    }

    function showProteinFilters() {
        hideFilters('protein')
        setShowProteinDisplay(current => !current)
    }

    function showOccasionFilters() {
        hideFilters('occasion')
        setShowOccasionDisplay(current => !current)
    }

    function showCookingTimeFilters() {
        hideFilters('cooking_time')
        setShowCookingTimeDisplay(current => !current)
    }

    function addFilter(filterType, filter, displayName) {
        props.addToFiltersAndUpdateRecipes(filterType, filter, displayName)
    }

    function hideFilters(keepFilter) {
        if (keepFilter !== 'sortBy') { setSortByDisplay(false) }
        if (keepFilter !== 'columnsCount') { setColumnsCountDisplay(false) }

        if (keepFilter !== 'mealType') { setShowMealTypeDisplay(false) }
        if (keepFilter !== 'cuisine') { setShowCuisineDisplay(false) }            
        if (keepFilter !== 'ingredient') { setShowIngredientDisplay(false) }  
        if (keepFilter !== 'method') { setShowMethodDisplay(false) }
        if (keepFilter !== 'protein') { setShowProteinDisplay(false) }
        if (keepFilter !== 'occasion') { setShowOccasionDisplay(false) }         
        if (keepFilter !== 'cooking_time') { setShowCookingTimeDisplay(false) }
    }

    function removeFilter(filterType, filter) {
        props.addToFiltersAndUpdateRecipes(filterType, filter)
    }

    function sortRecipes(type) {
        props.sortRecipes(type)
    }

    function setColumns(columns) {
        props.setColumns(columns)
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                hideFilters('')
            }
          }
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='recipe_filter_container'>

                <div className='recipe_filter_top_row'>
                    <div className='recipe_filter_search_ui'>
                        <SearchUI updateRecipes={addFilter} recipesSearchUrl={true} currentValue={searchValue}/>
                    </div>
                    <div className='recipe_filter_sort_by'>
                        <SortBy sortRecipes={sortRecipes} hideFilters={hideFilters} showSortByFilters={showSortByFilters} showSortByDisplay={showSortByDisplay}/>
                    </div>
                    {/* <div className='recipe_filter_results'>
                        <ResultsCount hideFilters={hideFilters} showResultsCountFilters={showResultsCountFilters} showResultsCountDisplay={showResultsCountDisplay}/>
                    </div> */}
                    <div className='recipe_filter_columns'>
                        <ResultsColumns setColumns={setColumns}  hideFilters={hideFilters} showColumnsCountFilters={showColumnsCountFilters} showColumnsCountDisplay={showColumnsCountDisplay}/>
                    </div>
                    {/* <div className='recipe_filter_favourite'>
                        <button className={activeFavourites ? 'recipe_filter_favourite_button_active favourite_button_fill' : 'recipe_filter_favourite_button favourite_button_fill'} onClick={toggleFavourites}>{translate('recipes search', 'favourites')}  <span className='material-symbols-outlined inline-icon'>favorite</span></button>
                    </div> */}
                </div>
                
                <div className="loading_container">
                    <div className='break_line_filters'></div>
                    {/* <div className="loader_line"><div className="bar" style={props.searching ? {opacity: "100%", left: "-100%" } : {opacity: "0%"} }></div></div> */}
                    {props.searching ? <div className="loader_line"><div className="bar" style={props.searchingFinish ? {opacity: "0%"} : null }></div></div> : null}
                </div>
                
                <div className='recipe_filter_second_row'>
                    <ul className='filters_list'>
                        <li className='filters_item' onClick={showMealTypeFilters}>
                            {translate('recipes search', 'meal')}<span className='material-symbols-outlined expand_more'>expand_more</span>
                            {showMealTypeDisplay ? <div ref={wrapperRef}><FilterDisplay filters={meal_filters} addFilter={addFilter} filterType={'mealType'} hideFilters={hideFilters}/></div> : null }
                        </li>
                        <li className='filters_item'  onClick={showCuisineFilters}>
                            {translate('recipes search', 'cuisine')}<span className='material-symbols-outlined expand_more'>expand_more</span>
                            {showCuisineDisplay ? <div ref={wrapperRef}><FilterDisplay filters={cuisine_filters} addFilter={addFilter} filterType={'cuisine'} hideFilters={hideFilters}/> </div>: null }
                        </li>
                        <li className='filters_item' onClick={showProteinFilters}>
                            {translate('recipes search', 'protein')}<span className='material-symbols-outlined expand_more'>expand_more</span> 
                            {showProteinDisplay ? <div ref={wrapperRef}><FilterDisplay filters={protein_filters} addFilter={addFilter} filterType={'protein'} hideFilters={hideFilters}/> </div>: null }
                        </li>
                        <li className='filters_item' onClick={showIngredientFilters}>
                            {translate('recipes search', 'ingredient')}<span className='material-symbols-outlined expand_more'>expand_more</span>    
                            {showIngredientDisplay ? <div ref={wrapperRef}><FilterDisplay filters={ingredient_filters} addFilter={addFilter} filterType={'ingredient'} hideFilters={hideFilters}/></div>: null } 
                        </li>
                        <li className='filters_item' onClick={showMethodFilters}>
                            {translate('recipes search', 'method')}<span className='material-symbols-outlined expand_more'>expand_more</span> 
                            {showMethodDisplay ? <div ref={wrapperRef}><FilterDisplay filters={method_filters} addFilter={addFilter} filterType={'method'} hideFilters={hideFilters}/> </div>: null }
                        </li>
                        <li className='filters_item' onClick={showOccasionFilters}>
                            {translate('recipes search', 'occasion')}<span className='material-symbols-outlined expand_more'>expand_more</span> 
                            {showOccasionDisplay ? <div ref={wrapperRef}><FilterDisplay filters={occasion_filters} addFilter={addFilter} filterType={'occasion'} hideFilters={hideFilters}/></div> : null }
                        </li>
                        <li className='filters_item' onClick={showCookingTimeFilters}>
                            {translate('recipes search', 'cooking time')}<span className='material-symbols-outlined expand_more'>expand_more</span> 
                            {showCookingTimeDisplay ? <div ref={wrapperRef}><FilterDisplay filters={cooking_time_filters} addFilter={addFilter} filterType={'cooking_time'} hideFilters={hideFilters}/></div> : null }
                        </li>
                    </ul>
                </div>

                {   props.activeFilters.length === 0 || (
                    props.activeFilters[0][1].length === 0 && 
                    props.activeFilters[1][1].length === 0 && 
                    props.activeFilters[2][1].length === 0 &&
                    props.activeFilters[3][1].length === 0 &&
                    props.activeFilters[4][1].length === 0 &&
                    props.activeFilters[5][1].length === 0 &&
                    props.activeFilters[6][1].length === 0
                    )
                ? null:

                    <div className='recipe_filter_third_row'>
                        {props.activeFilters ? props.activeFilters.map(filterType => (
                            <span key={filterType}>
                                {filterType[1] ? filterType[1].map((filter)=> (
                                    <ActiveFilterItem key={filter} filterType={filterType[0]} activeFilter={filter} removeFilter={removeFilter}/>
                                )): null }
                            </span>
                        )): null }
                        <button className='filters_clear_all' onClick={props.clearAllFilters}>{translate('recipes search', 'clear all')}</button>
                    </div>
                }
                
        </div>
    )
}

export default RecipesFilter;