import './edit_first_name_panel.css'
import React, { useEffect, useState, useRef } from 'react'
import ButtonPrimary from '../../components/button/button_primary.js'
import Cookies from 'js-cookie'
import ButtonCancel from '../../components/button/button_cancel';

function EditFirstNamePanel(props) {

    const [firstName, setFirstName] = useState('');
    const firstNameRef = useRef();
    const session_id = Cookies.get('session_id');

    useEffect(() => {
        setFirstName(props.firstName);
    }, [props.firstName]) 

    function saveFirstName() {

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ session_id: session_id, first_name: firstNameRef.current.value })
        }

        fetch(process.env.REACT_APP_API_URL + "/api/updateUserFirstName", options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    props.setFirstName(firstName);
                    props.setShowFirstNamePanel(false); 
                }
            })
    }


    return (
        <div className="edit_first_name_panel_container" onClick={() => props.setShowFirstNamePanel(false)}>
            <div className="edit_first_name_panel_content" onClick={(e) => e.stopPropagation()}>
                <div className="edit_first_name_panel_header">
                    <h1 className="edit_first_name_panel_header_title">Edit First Name</h1>
                </div>
                <div className="edit_first_name_panel_body">
                    <div className="edit_first_name_panel_body_item"> 
                        <div className="edit_first_name_panel_body_item_content">
                            <input ref={firstNameRef} className="edit_first_name_panel_item_input" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                        </div> 
                    </div>
                </div>
                <div className="edit_first_name_panel_footer">
                    <ButtonPrimary name={"Update First Name"} margin="right" action={() => saveFirstName()} icon="save" />
                    <ButtonCancel name="Nevermind" icon="undo" action={() => props.setShowFirstNamePanel(false)} />
                </div>
            </div>
        </div>
    )

}

export default EditFirstNamePanel;