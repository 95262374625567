import './home.css';
import React, { useEffect } from "react";
import Carousel from "../components/carousel/carousel.js"
import HomeTitle from './home_title/home_title.js'
import { Helmet } from 'react-helmet';
import { translate } from '../translations/translations.js';   
import KeyFeatures from './key_features/key_features.js';  

function Home(props) {


    useEffect(() => {
        document.title = "Garlic Basil"
    },) 

    return (
        <>
            <Helmet>
                <meta name="description" content="Recipes sourced from artificial intelligence, website designed by humans. Let us inspire your next home cooked meal." />
            </Helmet>
            <div className="home_container">

                <div className="home_hero_image_container" >
                    <picture>
                        <source media="(min-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/2048x1024_desktop_home_hero.jpg" />
                        {/* <source media="(min-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1920x1069_desktop_home_hero.webp" /> */}
                        {/* <source media="(min-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1920x1080_home_hero.webp" /> */}
                        {/* <source media="(min-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1920x1080_home_hero.jpg" /> */}
                        <source media="(max-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/795x1458_mobile_home_hero.jpg" />
                        {/* <source media="(max-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/2048x1024_desktop_home_hero.jpg" /> */}
                        <source media="(max-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/mobile_home_hero.webp" />
                        <source media="(max-width: 768px)" srcSet="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/mobile_home_hero.jpg" />
                        <img className="home_hero_image" src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1920x1080_home_hero.jpg" alt="home_image" />
                    </picture>
                    
                    <HomeTitle />
                </div>

                <KeyFeatures />

                <div id="home_carousel"></div>
                <Carousel name="trending" title={translate('explore', 'trending')} numberOfGroups={2} trending={true} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="dinner" title={translate('explore', 'dinner')} numberOfGroups={5} tags={'dinner'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="salad" title={translate('explore', 'salad')} numberOfGroups={5} tags={'salad'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="classic" title={translate('explore', 'classic')} numberOfGroups={2} tags={'classic'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="lunch" title={translate('explore', 'lunch')} numberOfGroups={5} tags={'lunch'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="dessert" title={translate('explore', 'dessert')} numberOfGroups={5} tags={'dessert'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="crowerPleaser" title={translate('explore', 'crowd pleaser')} numberOfGroups={3} tags={'crowd pleaser'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="breakfast" title={translate('explore', 'breakfast')} numberOfGroups={5} tags={'breakfast'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />
                <div className="break_line"></div>
                <Carousel name="drinks" title={translate('explore', 'drinks')} numberOfGroups={5} tags={'drink'} showAlertMessage={props.showAlertMessage} shuffleResults={true} />

            </div>
        </>
    );
}

export default Home;