
import React from 'react';
import IntroductionDesktop from './introduction_desktop/introduction_desktop.js';
import IntroductionMobile from './introduction_mobile/introduction_mobile.js';

function Introduction() {
    return (
        window.innerWidth < 768 ? <IntroductionMobile /> : <IntroductionDesktop />
    )
}

export default Introduction;