import './mobile_sort_by.css'
import React from 'react'
import { translate } from '../../../../translations/translations.js'

function MobileSortBy(props) {

    return (
        <div className="mobile_sort_by_container">
            <div className="mobile_sort_by_header_container">
                <div className="mobile_sort_by_title">
                    {translate('recipes search', 'sort by')}
                </div>
                <div className="mobile_sort_by_close_container" onClick={props.closeMobileSortBy}>
                    {translate('misc', 'done')}
                </div>
            </div>
            <div className="mobile_sort_by_items_container">
                <button className="mobile_sort_by_item" onClick={() => props.sortRecipes("A – Z")}>A – Z</button>
                <button className="mobile_sort_by_item" onClick={() => props.sortRecipes("Ratings")}>{translate('recipes search', 'ratings')}</button>
                <button className="mobile_sort_by_item" onClick={() => props.sortRecipes("Views")}>{translate('recipes search', 'views')}</button>
            </div>
        </div>
    )
}

export default MobileSortBy;