import './ingredient_add.css';
import React, { useState, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Cookies from 'js-cookie'; 
import { createUOMOptions } from './uom_options.js';

function IngredientAdd(props) {

    const session_id = Cookies.get('session_id'); 
    const measurementOptions = createUOMOptions();
    const servingsRef = useRef();
    const [showIngredientInfo, setShowIngredientInfo] = useState(false);

    const quantityOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        // Add more options as needed
    ];

    const handleIngredientChange = (selectedOption) => {
        if (selectedOption !== null) {
            props.updateIngredient(selectedOption['value'], selectedOption['label']);
        }
    };

    const handleQuantityChange = (selectedOption) => {
        if (selectedOption !== null) {
            props.updateQuantity(selectedOption['value'], selectedOption['label']);
        }
    };

    const handleMeasurementChange = (selectedOption) => {
        if (selectedOption !== null) {
            props.updateMeasurement(selectedOption['value'], selectedOption['label']);
        }
    };

    function addIngredient() {

        if (props.selectedIngredient === null || props.selectedQuantity === null || props.selectedMeasurement === null) {
            alert('Please select an option for each field');
        } else {
            if (props.createRecipeID !== "0") {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        session_id: session_id
                        , create_recipe_id: props.createRecipeID
                        , create_ingredient_id: props.selectedIngredient.value
                        , create_quantity: props.selectedQuantity.value
                        , create_uom: props.selectedMeasurement.value
                    }),
                };

                fetch(process.env.REACT_APP_API_URL + '/api/createAddIngredient', options)
                    .then(response => response.json())
                    .then(() => {
                        props.refreshIngredientsList();
                    });
            } else {
                let ingredientsArray = Cookies.get('create_ingredients')
                if (ingredientsArray === undefined) {
                    ingredientsArray = []
                } else {
                    ingredientsArray = JSON.parse(ingredientsArray);
                } 
                const ingredientID = props.selectedIngredient.value;
                let itemExists = false;
                for (let i = 0; i < ingredientsArray.length; i++) { 
                    if (parseInt(ingredientsArray[i][1], 10) === parseInt(ingredientID, 10)) {
                        itemExists = true;

                        ingredientsArray[i][2] = props.selectedQuantity.value;
                        ingredientsArray[i][3] = props.selectedMeasurement.value;
                        break;
                    }
                } 
                if (itemExists === false) {
                    ingredientsArray.push([0, props.selectedIngredient.value, props.selectedQuantity.value, props.selectedMeasurement.value]);
                }
                Cookies.set('create_ingredients', JSON.stringify(ingredientsArray));
                props.refreshIngredientsList();
            }
        }
    }



    const isNumber = (value) => {
        return /^\d+(\.\d+)?$/.test(value); // Matches only numeric values
      };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'green' : state.isFocused ? 'rgb(0, 168, 0);' : 'initial',
            color: state.isSelected ? 'white' : state.isFocused ? 'white' : 'initial', // Replace 'your-color' with the desired text color for the selected item
            "&:active": {
                backgroundColor: "green", // Replace 'green' with the desired outline color when clicked
                
              }
        })
    };

    function updateServings() {
         
        props.setServings(servingsRef.current.value);

        if (props.createRecipeID !== "0") {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({session_id: session_id, create_recipe_id: props.createRecipeID, create_servings: servingsRef.current.value}),
            };

            fetch(process.env.REACT_APP_API_URL + '/api/createUpdateServings', options)
                .then(response => response.json())
                .then(() => {});
        } else {

            Cookies.set('create_servings', servingsRef.current.value);
        }
    }

    return (
        <div className="create_content_ingredients" id="ingredients_add_panel">
            <div className="create_content_ingredients_header_container">
                <div className="create_content_ingredients_title_container">
                    <h2 className="create_content_ingredients_title">Ingredients</h2>
                </div>
                <div className="create_content_ingredients_servings_container">
                    <p className="create_content_ingredients_servings_label">Servings:</p>
                    <input id="create_content_servings_value_element" className="create_content_ingredients_servings_input" inputMode="numeric" onBlur={() => updateServings()} ref={servingsRef} style={parseInt(props.servings, 10) === 0 ? {border: '2px solid var(--cancel-color)', backgroundColor: 'rgb(255,225,225)'} : null } defaultValue={props.servings === 0 ? null : props.servings} placeholder="0"/>
                </div>
            </div>
            <div className="create_content_ingredients_list">
                <div className="create_content_ingredients_add_item_container">
                    <div className="create_content_ingredients_item_selection">
                        <div>
                            <p className="create_content_ingredients_add_item_title">INGREDIENT</p>
                        </div>
                        <div className="create_content_ingredients_add_item_selection">
                            <Select options={props.ingredientOptions} placeholder="Select an option" isClearable={true} onChange={handleIngredientChange} styles={customStyles} value={props.selectedIngredient}/>
                        </div>
                    </div>
                    <div className="create_content_ingredients_item_selection">
                        <div>
                            <p className="create_content_ingredients_add_item_title">QUANTITY</p>
                        </div>
                        <div className="create_content_ingredients_add_item_selection">
                            <CreatableSelect options={quantityOptions} createOptionPosition="first" placeholder="Select an option" inputMode="numeric" isClearable={true} onChange={handleQuantityChange} styles={customStyles} value={props.selectedQuantity} isValidNewOption={isNumber}/>
                        </div>
                    </div>
                    <div className="create_content_ingredients_item_selection">
                        <div>
                            <p className="create_content_ingredients_add_item_title">MEASUREMENT</p>
                        </div>
                        <div className="create_content_ingredients_add_item_selection">
                            <Select options={measurementOptions}  placeholder="Select an option" isClearable={true} onChange={handleMeasurementChange} styles={customStyles} value={props.selectedMeasurement}/>
                        </div>
                    </div>
                    <div className="create_content_ingredients_item_selection">
                        <div>
                            <p className="create_content_ingredients_add_item_title">SUBMIT</p>
                        </div>
                        <button className="create_content_ingredients_add_item_button" onClick={addIngredient}>Add Ingredient</button>
                    </div>
                </div>
                {showIngredientInfo ? null : <p className="create_content_ingredients_how_to create_content_ingredients_how_to_button" onClick={() => setShowIngredientInfo(true)}>How to add ingredients</p>}
                {showIngredientInfo ? <p className="create_content_ingredients_how_to">To add an ingredient, simply select the desired ingredient from the provided drop-down menu. Then, choose the appropriate quantity and measurement from the available options in their respective drop-down boxes. If you prefer not to include a measurement, you can select the 'Blank' option. In case the specific quantity you need is not listed, you have the flexibility to manually type it into the input box. Once you've made your selections, click on the 'Add Ingredient' button, and the ingredient will be added to your recipe. <span className="create_content_ingredients_how_to_button" onClick={() => setShowIngredientInfo(false)}>Hide</span> </p>: null}
            </div>
        </div>
    );

}

export default IngredientAdd;