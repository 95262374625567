import './admin_panel.css'
import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';

function AdminPanel() {
    
    const session_id = Cookies.get('session_id');
    const [recipeName, setRecipeName] = useState();
    const [showRecipe, setShowRecipe] = useState();
    const [cookingTime, setCookingTime] = useState();
    const [tags, setTags] = useState([]);

    const recipe_id = new URLSearchParams(window.location.search).get('recipe_id');

    let recipeNameRef = useRef();
    let cookingTimeRef = useRef();
    let tagTypeRef = useRef();
    let tagValueRef = useRef();

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({ session_id: session_id, recipe_id: recipe_id}),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/getRecipeAdminInfo", options)
        .then((response) => response.json())
        .then((json) => {
            fillInfo(json['recipe_info'][0])
            fillTags(json['recipe_tags'])
        });
    }, [session_id, recipe_id]);

    function fillInfo(response) {
        if (response.display === 1) {
            setShowRecipe(true)
        } else {
            setShowRecipe(false)
        }
        setCookingTime(response.cooking_time)
        setRecipeName(response.name)
    }

    function fillTags(response) {
        setTags(response)
    }

    function toggleShowRecipe() {

        if (showRecipe) {

            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({recipe_id: recipe_id, showRecipe: false})
            };

            fetch(process.env.REACT_APP_API_URL + "/api/setRecipeDisplay", options)

            setShowRecipe(false)

        } else {

            const options = {
                method: 'POST',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({recipe_id: recipe_id, showRecipe: true})
            };

            fetch(process.env.REACT_APP_API_URL + "/api/setRecipeDisplay", options)

            setShowRecipe(true)

        }
    }

    function updateRecipeName() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({recipe_id: recipe_id, recipe_name: recipeNameRef.current.value})
        };

        fetch(process.env.REACT_APP_API_URL + "/api/updateRecipeName", options)
        .then(() => window.location.reload())
        
    }

    function updateCookingTime() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({recipe_id: recipe_id, cooking_time: cookingTimeRef.current.value})
        };

        fetch(process.env.REACT_APP_API_URL + "/api/setRecipeCookingTime", options)
        .then(() => window.location.reload())
        
    }

    function addNewTag() {

        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({recipe_id: recipe_id, type:tagTypeRef.current.value, tag: tagValueRef.current.value})
        };

        fetch(process.env.REACT_APP_API_URL + "/api/addNewTagValue", options)
        .then(() => window.location.reload())

    }

    function deleteTag(type, tag) {
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({recipe_id: recipe_id, type: type, tag: tag})
        };

        fetch(process.env.REACT_APP_API_URL + "/api/deleteTagValue", options)
        .then(() => window.location.reload())
    }
    const [value, setValue] = useState('');

    function handleChange(event) {
      setValue(event.target.value);
    }
    const typeOptions = ['category', 'classic', 'cuisine', 'ingredient', 'meal_type', 'method', 'occasion', 'recipe_name', 'roasting', 'salad', 'vegetarian']
    .filter((item) => item)
    .map((item) => <option key={item} value={item}>{item}</option>);

    return (
        <div className="admin_panel_container">
            <h1>Admin Panel</h1>
            <button className="admin_show_recipe_button" style={showRecipe ? {backgroundColor:"var(--primary-color)", color:"white"}: {backgroundColor:"lightGrey", color:"black"}} onClick={() => toggleShowRecipe()}>Show Recipe</button>
            
            <h3 className="admin_panel_section_name">Recipe Name:</h3>
            <input type="text" className="admin_input" defaultValue={recipeName} ref={recipeNameRef}></input> <button className="admin_update_button" onClick={() => updateRecipeName()}>Update</button>
            
            <h3 className="admin_panel_section_name">Cooking Time:</h3>
            <input type="text" className="admin_input" defaultValue={cookingTime} ref={cookingTimeRef}></input> <button className="admin_update_button" onClick={() => updateCookingTime()}>Update</button>
            
            <h3 className="admin_panel_section_name">Tags:</h3>
            
            <select className="admin_input" value={value} ref={tagTypeRef} onChange={handleChange}>
                {typeOptions}
            </select><br />
            
            <input type="text" className="admin_input" placeholder="tag" ref={tagValueRef}></input> <button className="admin_update_button" onClick={() => addNewTag()}>Add Tag</button><br />
            {tags.map((tag, index) => (
                <div key={"tag_" + index} className="admin_panel_tag">
                    <p className="admin_panel_tag_info">{tag[1] + ": " + tag[2]}</p>
                    <button className="admin_delete_button" onClick={()=> deleteTag(tag[1], tag[2])}>X</button>
                </div>
            ))}

        </div>
    )}

export default AdminPanel;