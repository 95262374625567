import './my_collection.css';
import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import RecipeTile from '../components/recipe_tile/recipe_tile.js';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../components/button/button_primary.js';
import ButtonSecondary from '../components/button/button_secondary';
import DropDownSelection from '../components/dropdown_selection/dropdown_selection.js';
import CreateTmpRecipe from './create_tmp_recipe/create_tmp_recipe.js';

function MyCollection(props) {

    const [firstName, setFirstName] = useState('')
    const [handle, setHandle] = useState('')
    const [dataFetched, setDataFetched] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [allRecipes, setAllRecipes] = useState([]);
    const [recipes, setRecipes] = useState([]);
    // const [showUserCreations, setShowUserCreations] = useState(false);
    const showUserCreations = false;
    const [showSortDisplay, setShowSortDisplay] = useState(false);
    const session_id = Cookies.get('session_id');
    const navigate = useNavigate();
    const [recipesStats, setRecipesStats] = useState()

    const [showCreateTmpMessage, setShowCreateTmpMessage] = useState(false);

    useEffect(()=> {
        document.title = "Garlic Basil - My Collection"
    },[])


    useEffect(() => {

        if (session_id === undefined) {
            setUserLoggedIn(false)
            setDataFetched(true)
        } else {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ session_id: session_id }),
            }

            fetch(process.env.REACT_APP_API_URL + '/api/getUserNameAndHandle', options)
                .then(response => response.json())
                .then(data => {
                    if (data.status) {
                        setFirstName(data.first_name)
                        setHandle(data.handle)
                    }
                })

            fetch(process.env.REACT_APP_API_URL + '/api/getMyCollection', options)
                .then(response => response.json())
                .then(data => { 
                    if (data.status) {
                        setUserLoggedIn(true)
                        const recipes = data.recipes
                        const sortedRecipes = [...recipes]
                            .filter(recipe => recipe.collection === "1")
                            .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
                        setRecipes(sortedRecipes)
                        setAllRecipes(data.recipes)
                        setRecipesStats(data.stats)
                    } else {
                        setUserLoggedIn(false)
                    }
                    setDataFetched(true)
                });
            }

    }, [session_id]);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const fromLoggedin = urlParams.get('loggedin');
        if (fromLoggedin) {
            if (fromLoggedin === 'true') {
                const createName = Cookies.get('create_name');
                if (createName !== undefined) {
                    const createIntroduction = Cookies.get('create_introduction') || ''
                    const createIngredients = Cookies.get('create_ingredients') || JSON.stringify([])
                    const createInstructions = Cookies.get('create_instructions') || JSON.stringify([])
                    const createServings = Cookies.get('create_servings') || 0
                    const createCookingTime = Cookies.get('create_cooking_time') || 0
                    const createImageHash = Cookies.get('create_image_hash') || ''

                    const options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ session_id: session_id, create_name: createName, create_introduction: createIntroduction, create_ingredients: createIngredients, create_instructions: createInstructions, create_servings: createServings, create_cooking_time: createCookingTime, create_image_hash: createImageHash }),
                    }

                    fetch(process.env.REACT_APP_API_URL + '/api/createMoveTmpRecipe', options)
                        .then(response => response.json())
                        .then(data => {
                            if (data.status) {
                                setShowCreateTmpMessage(true)
                                Cookies.remove('create_name')
                                Cookies.remove('create_introduction')
                                Cookies.remove('create_ingredients')
                                Cookies.remove('create_instructions')
                                Cookies.remove('create_servings')
                                Cookies.remove('create_cooking_time')
                                Cookies.remove('create_image_hash')
                            }
                        });
                }
            }
        }
    }, [session_id]);



    function hideNewRecipe() {
        setShowCreateTmpMessage(false)
    }

    function createNewRecipe() {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ session_id: session_id }),
        }

        fetch(process.env.REACT_APP_API_URL + '/api/createNewRecipe', options)
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    window.location.href = '/create/' + data.create_recipe_id + '/new-recipe';
                } else {
                    props.showAlertMessage(data.message, undefined, undefined, 10000)
                }
            });
    }

    // function toggleUserCreations() {

    //     if (showUserCreations) {
    //         const filteredRecipes = [...allRecipes]
    //             .filter(recipe => recipe.collection === "1")
    //             .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
    //         setRecipes(filteredRecipes);
    //     } else {
    //         const filteredRecipes = [...allRecipes].filter(recipe => recipe.user_creation === "1");
    //         setRecipes(filteredRecipes);

    //     }
    //     setShowUserCreations(!showUserCreations);

    // }

    function goToRecipeStudio() {
        navigate('/recipe-studio')
    }

    function toggleSortDisplay(currentStatus) {
        if (currentStatus) {
            document.removeEventListener('click', handleClickOutside);
        } else {
            document.addEventListener('click', handleClickOutside);
        }
        setShowSortDisplay(!currentStatus);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowSortDisplay(false)
        }
    };

    function sortBy(value) {
        if (value === 'Views') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.views > b.views) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Variations') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.variation_count > b.variation_count) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Ratings') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.ratings > b.ratings) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Date Created') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.date_created > b.date_created) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Last Viewed') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.last_viewed > b.last_viewed) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'Collections') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.collection_count > b.collection_count) ? -1 : 1);
            setRecipes(sortedRecipes);
        } else if (value === 'A - Z') {
            const sortedRecipes = [...allRecipes]
                .filter(recipe => { if (showUserCreations) { return recipe.user_creation === "1"; } else { return recipe.collection === "1"; } })
                .sort((a, b) => (a.recipe_name > b.recipe_name) ? 1 : -1);
            setRecipes(sortedRecipes);
        }
        setShowSortDisplay(false)
    }

    const dropdownRef = useRef();

    function shareCollection() {
        navigator.clipboard.writeText("https://www.garlicbasil.com/collection/" + handle);
        props.showAlertMessage("Link copied to clipboard!", undefined, undefined, 5000)
    }

    return (
        <div className="my_collection_parent_container">
            {userLoggedIn ? <div className="my_collection_title_image_container">
                <img src="https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/1192x600_my_collection_header_background.webp" alt="My Collection Background" className="my_collection_title_image" />
            </div>
                : null}
            <div className="my_collection_background"></div>
            {dataFetched ?
                userLoggedIn ?
                    <div className="my_collection_title_container">
                        <div className="my_collection_page_header_container">
                            <span className="my_collection_page_header_title">
                                {window.innerWidth < 768 ? null : <div className="my_collection_page_header_handle_container">{handle}</div>}
                                {showUserCreations ?
                                    window.innerWidth < 768 ?
                                        <h1 className="my_collection_title">{firstName}'s<br />Published Recipes</h1>
                                        :
                                        <h1 className="my_collection_title">{firstName !== '' ? firstName + "'s Published Recipes" : null}</h1>
                                    :
                                    window.innerWidth < 768 ?
                                        <h1 className="my_collection_title">{firstName}'s<br />Recipe Collection</h1>
                                        :
                                        <h1 className="my_collection_title">{firstName !== '' ? firstName + "'s Recipe Collection" : null}</h1>
                                }
                            </span>
                            {window.innerWidth < 768 ? null :
                                <span className="my_collection_statistics">
                                    <span title="This is the number of recipes in your collection.">
                                        {recipesStats.recipes_count} {recipesStats.recipes_count === 1 ? "Recipe" : "Recipes"}
                                    </span>
                                    <span title="This is the number of recipes you have published.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.recipes_published} {recipesStats.recipes_publisehd === 1 ? "Creation" : "Creations"}
                                        {/* &nbsp;&nbsp;&#8226;&nbsp;&nbsp; 2 Creations */}
                                    </span>
                                    <span title="This is the total number of collections all your recipes are in.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.collection_count} {recipesStats.collection_count === 1 ? "Collection" : "Collections"}
                                    </span>
                                    <span title="This is the number of published variations of the recipes you have published.">
                                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp; {recipesStats.variation_count} {recipesStats.variation_count === 1 ? "Variation" : "Variations"}
                                    </span>
                                </span>
                            }
                            {/* <span className="my_collection_page_header_my_wip">
                                <ButtonSecondary name="See Recipe Studio" margin="left" action={() => goToRecipeStudio()} icon="folder_open"/>
                                <ButtonPrimary name="Create Recipe" margin="left" action={() => createNewRecipe()} icon="new_window"/>
                            </span> */}
                        </div>
                        <div className="my_collection_page_header_my_wip">
                            <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Share" : "Share Collection"} margin="right" action={() => shareCollection()} icon="ios_share" />
                            <span className="my_collection_sort_container" ref={dropdownRef}>
                                <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Sort" : "Sort Recipes"} margin="right" action={() => toggleSortDisplay(showSortDisplay)} icon="sort" />
                                {showSortDisplay ? <DropDownSelection options={[['A - Z', (value) => sortBy(value)], ['Collections', (value) => sortBy(value)], ['Date Created', (value) => sortBy(value)], ['Last Viewed', (value) => sortBy(value)], ['Ratings', (value) => sortBy(value)], ['Variations', (value) => sortBy(value)], ['Views', (value) => sortBy(value)]]} /> : null}
                            </span>
                            <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Create" : "Create Recipe"} margin="right" action={() => createNewRecipe()} icon="new_window" />

                            {/* {showUserCreations ? */}
                                {/* // <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Coll." : "See My Collection"} margin="right" action={() => toggleUserCreations()} icon="undo" /> : */}
                                {window.innerWidth < 768 ? null : <ButtonSecondary width={window.innerWidth < 768 ? '100%' : null} name={window.innerWidth < 768 ? "Pub." : "My Studio"} margin="right" action={() => goToRecipeStudio()} icon="folder_open" />}
                            {/* } */}
                            
                        </div>
                        <div className="my_collection_container">
                            {recipes.map((recipe, index) => {
                                const recipe_id = recipe['recipe_id']
                                const name = recipe['recipe_name']
                                const image = recipe['image']
                                return (
                                    <div key={name + "_" + index} className="my_collection_recipe_tile_container">
                                        <RecipeTile recipe_id={recipe_id} name={name} imageSize="medium" width="100%" creation={false} image={image} />
                                    </div>
                                )
                            })}
                        </div>

                        {recipes.length === 0 ?
                            <div className="my_collection_no_recipes_container">
                                <h2 className="my_collection_no_recipes_title">You have no recipes in your collection.</h2>
                                <p style={{ lineHeight: '150%' }}>Begin curating your recipe collection by clicking the <span style={{ fontWeight: '500' }}>Add to My Collection</span> button on any recipe. <br />Or begin by creating your own recipe!</p>
                                <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Explore Recipes" margin={window.innerWidth < 768 ? "bottom" : "right"} action={() => navigate('/')} icon="travel_explore" />
                                <ButtonPrimary width={window.innerWidth < 768 ? '100%' : null} name="Create New Recipe" action={() => createNewRecipe()} icon="new_window" />
                            </div>
                            :
                            null
                        }
                    </div>
                :
                    <div className="my_collection_sign_up_container">
                        <span className="my_collection_sign_up_title_container">
                            <h1 className="my_collection_sign_up_title">Sign up or log in to start curating your collection.</h1>
                            <div className="my_collection_sign_up_button_containers">
                                <ButtonPrimary width={window.innerWidth < 768 ? "100%" : null} name="Sign Up" icon="travel_explore" margin={window.innerWidth < 768 ? "bottom" : "right"} className="my_collection_sign_up_button" action={() => navigate('/sign-up')} />
                                <ButtonSecondary width={window.innerWidth < 768 ? "100%" : null} name="Log In" icon="login" className="my_collection_sign_up_button" action={() => navigate('/log-in')} />
                            </div>
                        </span>
                    </div>
                :
                null
            }
            {showCreateTmpMessage ? <CreateTmpRecipe showPopUp={showCreateTmpMessage} hidePopUp={hideNewRecipe} /> : null}
        </div>

    );
}

export default MyCollection;