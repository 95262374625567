import './terms_of_use.css'
import React, { useEffect } from "react";

function TermsOfUse() {

    useEffect(()=> {
        document.title = "Garlic Basil - Terms of Use"
    },[])

    return (
        <div className="terms_of_use_container">
            <div className="terms_of_use_content">
                <span style={{fontWeight:"500"}}>Terms of Use</span>
                <br/><br/>
                <span style={{fontWeight:"500"}}>Last updated: 15 June 2023</span>
                <br/><br/>  
                Welcome to Garlic Basil! These terms of use ("Terms") outline the rules and guidelines for using our website. By accessing and using www.thesiliconkitchen.com ("website"), you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our website.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Acceptance of Terms</span><br/>
                By using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Intellectual Property</span><br/>
                Garlic Basil's website contains protected intellectual property, including recipes, images, logos, and trademarks. Users are granted a limited license to use this content within the website for personal and commercial purposes, solely for engaging with the website's features and functionalities. Any use, copying, modification, or distribution of the content outside of the website requires explicit permission from Garlic Basil. 
                <br/><br/>
                By accessing the website, users agree to respect the intellectual property rights of Garlic Basil and the content owners. It is important to note that while users can create variations of recipes and engage with the website's features, such activities must remain within the website's functionalities and are not permitted for use outside of the website without proper authorisation.
                <br/><br/>
                <span style={{fontWeight:"500"}}>User Obligations</span><br/>
                <span style={{fontWeight:"500"}}>1. User-Published Content:</span> If you choose to create and publish recipes or variations of existing recipes or edit your recipes, you understand and agree that you are solely responsible for the content you contribute. You represent and warrant that you have all necessary rights, permissions, and licenses to publish and share this content.
                <br/><br/>
                <span style={{fontWeight:"500"}}>2. Grant of License:</span> By publishing your recipes or variations of existing recipes, you grant Garlic Basil and its users a non-exclusive, royalty-free, worldwide license to use, modify, reproduce, distribute, display, and create derivative works based on your published content within the website. This license allows other users of the website to create variations and duplications of your recipes, as permitted by the functionality of the website.
                <br/><br/>
                <span style={{fontWeight:"500"}}>3. Offensive Content:</span> Users are strictly prohibited from publishing or sharing any content that is offensive, defamatory, obscene, abusive, discriminatory, or otherwise inappropriate. This includes but is not limited to offensive words, images, or any content that promotes violence, hatred, or discrimination based on race, ethnicity, gender, religion, or any other protected characteristic.
                <br/><br/>
                <span style={{fontWeight:"500"}}>4. Respect for Others:</span> You agree to respect the rights and dignity of other users. Do not engage in personal attacks, harassment, or any form of harmful behavior towards other users.
                <br/><br/>
                <span style={{fontWeight:"500"}}>5. Lawful Use:</span> Users are required to use our website in compliance with applicable laws and regulations. You agree not to engage in any activity that may harm, disrupt, or infringe upon the rights of others, including hacking, spreading malware, or unauthorised access to other users' accounts.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Disclaimers</span><br/>
                <span style={{fontWeight:"500"}}>1. General Information:</span> The information provided on our website, including recipes, cooking tips, and nutritional information, is for general purposes only. It should not be considered professional advice. For specific dietary or health concerns, consult a qualified professional.
                <br/><br/>
                <span style={{fontWeight:"500"}}>2. Accuracy of Content:</span> While we strive to provide accurate and up-to-date information, we do not warrant the completeness, reliability, or accuracy of the content on our website. Use the information at your own risk.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Limitation of Liability</span><br/>
                In no event shall Garlic Basil or its owners, partners, employees, or affiliates be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use our website, including but not limited to damages for loss of profits, data, or other intangible losses.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Termination of Account</span><br/>
                Garlic Basil reserves the right to suspend, terminate, or restrict access to our website, without prior notice, if users violate these Terms or engage in harmful behavior that threatens the security or integrity of the website or its users.
                <br/><br/> 
                <span style={{fontWeight:"500"}}>Termination of Service</span><br/>
                Garlic Basil reserves the right to suspend, terminate, or discontinue the operation of our website at any time, with or without prior notice. This includes the possibility of closing the platform entirely. You acknowledge and agree that Garlic Basil shall not be liable to you or any third party for any compensation, damages, or losses arising from such termination or discontinuation of the website's services. Any content or data you have contributed to the website may be permanently deleted in the event of termination, and Garlic Basil shall have no obligation to retain or provide you with access to such content.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Modifications</span><br/>
                Garlic Basil reserves the right to modify, update, or replace these Terms at any time. By continuing to use our website after any changes are made, you agree to be bound by the revised Terms.
                <br/><br/> 
                By using our website, you agree to these Terms and acknowledge that they constitute a legally binding agreement between you and Garlic Basil.
                <br/><br/> 
                We may update this Terms of Use document from time to time. We encourage you to check this page regularly for updates.
                <br/><br/>
                <span style={{fontWeight:"500"}}>Contact us</span>
                <br/>
                If you have any questions or concerns about our Terms of Use, please contact us at <a className="enquries_email" href="mailto:thesiliconkitchen@gmail.com">thesiliconkitchen@gmail.com</a>.
            </div>
        </div>
    );
}

export default TermsOfUse;

