export function createUOMOptions() {

    const uomOptions = [
        {value: 'teaspoon', label: 'Teaspoon'},
        {value: 'tablespoon', label: 'Tablespoon'},
        {value: 'cup', label: 'Cup'},
        {value: 'gram', label: 'Gram'},
        // {value: 'kilogram', label: 'Kilogram'},
        {value: 'ounce', label: 'Ounce'},
        // {value: 'litre', label: 'Litre'},
        {value: 'millilitre', label: 'Millilitre'},
        {value: 'whole', label: 'Whole'},
        {value: 'blank', label: 'Blank'},
        // {value: 'a handful', label: 'A Handful'},
        // {value: 'a pinch', label: 'A Pinch'},
        // {value: 'as needed', label: 'As Needed'},
        // {value: 'as required', label: 'As Required'},
        {value: 'bunch', label: 'Bunch'},
        {value: 'clove', label: 'Clove'},
        // {value: 'cm', label: 'Cm'},
        // {value: 'cm piece', label: 'Cm Piece'},
        {value: 'dash', label: 'Dash'},
        {value: 'fillet', label: 'Fillet'},
        {value: 'for deep-frying', label: 'For Deep-Frying'},
        {value: 'for frying', label: 'For Frying'},
        {value: 'for garnish', label: 'For Garnish'},
        // {value: 'handful', label: 'Handful'},
        {value: 'head', label: 'Head'},
        // {value: 'inch', label: 'Inch'},
        // {value: 'liter', label: 'Liter'},
        {value: 'piece', label: 'Piece'},
        {value: 'pinch', label: 'Pinch'},
        {value: 'scoop', label: 'Scoop'},
        {value: 'slice', label: 'Slice'},
        {value: 'sprig', label: 'Sprig'},
        {value: 'stalk', label: 'Stalk'},
        {value: 'tin', label: 'Tin'},
        // {value: 'to garnish', label: 'To Garnish'},
        {value: 'to taste', label: 'To Taste'}
    ];

    return uomOptions
}