import './user_info.css'
import React, { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import { translate } from "../../translations/translations.js"
import { useLocation, useNavigate } from 'react-router-dom'
import DropDownSelection from '../../components/dropdown_selection/dropdown_selection.js';

function UserInfo(props) {

    const [showUserInfo, setShowUserInfo] = useState(false)
    const [firstName, setFirstName] = useState()
    const session_id = Cookies.get("session_id")
    const firstnamecookie = Cookies.get("firstname")
    const location = useLocation();
    const navigate = useNavigate();

    const language = Cookies.get('language') || 'english';
    const [navFontSize, setNavFontSize] = useState();

    useEffect(() => {
        if (language === 'chinese' || language === "korean" || language === "japanese") {
            setNavFontSize('16px');
        }
    }, [language]);

    useEffect(() => {

        if (firstnamecookie !== undefined) {
            setFirstName(firstnamecookie)
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ session_id: session_id }),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/getUserInfo", options)
            .then((response) => response.json())
            .then((json) => {
                setFirstName(json);
                Cookies.set("firstname", json);
            });

    }, [session_id, firstnamecookie]);

    function logUserOut() {

        props.userLoggedOut()

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ session_id: session_id }),
        };

        fetch(process.env.REACT_APP_API_URL + "/api/logUserOut", options)

        if (location.pathname === "/recipes-search") {
            navigate("/recipes-search")
        }

        if (location.pathname === "/recipe" || location.pathname === "/shopping-list") {
            window.location.reload()
        }

        if (location.pathname === "/recipe-studio") {
            navigate("/")
        }

        const firstItem = location.pathname.split("/")[1]
        if (firstItem === "create") {
            navigate("/")
        }

    }

    function toggleShowUserInfo(currentValue) {
        const handleOutsideClick = (e) => {
            if (e.target.className !== "user_info_welcome" && e.target.className !== "user_info_welcome_create") {
                setShowUserInfo(false)
                document.removeEventListener('click', handleOutsideClick);
            }
        }
        if (currentValue === false) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
        setShowUserInfo(prev => !prev)
    }

    return (
        <div className="user_info_parent_container" style={{ fontSize: navFontSize }}>
            <span className="log_out_container">
                <button className={props.createMode ? "user_info_log_out_create" : "user_info_log_out"} onClick={logUserOut}>{translate('navigation', 'LOG OUT')}</button>
            </span>
            <span className="user_info_container">
                <div className="user_info_dropdown_container">

                    <button className={props.createMode ? "user_info_welcome_create" : "user_info_welcome"} onClick={() => toggleShowUserInfo(showUserInfo)}>
                        <span className="material-symbols-outlined sign_up_button_icon">person</span>
                        {translate('navigation', 'Welcome,')} {firstName}
                    </button>
                    {showUserInfo ?
                        <DropDownSelection options={[['My Studio', () => { navigate('/recipe-studio'); setShowUserInfo(false) }], ['Settings', () => { navigate('/settings'); setShowUserInfo(false) }]]} />
                        :
                        null
                    }
                </div>
            </span>
        </div>
    )
}

export default UserInfo;