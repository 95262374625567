import Cookies from 'js-cookie';

export async function getFavouriteRecipes(session_id) {

    const language = Cookies.get('language') || 'english';

    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        body: JSON.stringify({session_id: session_id, language: language}),
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getUserFavouriteRecipes", options);
    return await response.json();

}

export async function getFilteredRecipes(tags, shuffle = false) {

    const language = Cookies.get('language') || 'english';

    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({tagFilters: tags, shuffle: shuffle, limit: 25, language: language}),
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getFilteredRecipes", options);
    const json = await response.json();
    return json;  

}

export async function getSimilarRecipes(recipe_id) {

    const language = Cookies.get('language') || 'english';

    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({recipe_id: recipe_id, limit: 25, language: language}),
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getSimilarRecipes", options);
    const json = await response.json(); 
    
    return json;

}

export async function getTrendingRecipes(shuffle = false) {

    const language = Cookies.get('language') || 'english';

    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({language: language}),
    };
    
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getTrendingRecipes", options);
    const json = await response.json(); 

    return json;

}