import React, { useEffect, useState } from "react"
import IngredientsItem from "./ingredients_item/ingredients_item.js"
import "./ingredients_list.css"
import { translate } from "../../translations/translations.js"  

function IngredientsList(props) {

    const [notCompleted, setNotCompleted] = useState({})
    const [completed, setCompleted] = useState({})
    const [removedItems, setRemovedItem] = useState([])

    useEffect(() => {

        const data = props.shoppingList
        let tmpNotCompleted = {}
        let tmpCompleted = {}

        for (let i = 0; i < data.length; i++) {

            let keyName = data[i].ingredient + "_" + data[i].uom
            if (data[i].completed) {
                if (keyName in tmpCompleted) {
                    tmpCompleted[keyName] = parseFloat(tmpCompleted[keyName].quantity) + (parseFloat(data[i].quantity) * (data[i].servings / data[i].recipe_servings))
                } else {
                    tmpCompleted[keyName] = data[i].quantity * (data[i].servings /  data[i].recipe_servings)
                }
                tmpCompleted[keyName] = {"name": data[i].ingredient, "plural_description": data[i].plural_description, "hash_id": data[i].hash_id, "quantity": tmpCompleted[keyName], "uom": data[i].uom}
            } else {
                if (keyName in tmpNotCompleted) {
                    tmpNotCompleted[keyName] = parseFloat(tmpNotCompleted[keyName].quantity) + ((parseFloat(data[i].quantity) * (parseFloat(data[i].servings) / data[i].recipe_servings)))
                } else {
                    tmpNotCompleted[keyName] = parseFloat(data[i].quantity) * (parseFloat(data[i].servings) /  data[i].recipe_servings)
                } 
                tmpNotCompleted[keyName] = {"name": data[i].ingredient, "plural_description": data[i].plural_description, "hash_id": data[i].hash_id, "quantity": tmpNotCompleted[keyName], "uom": data[i].uom}
            }
            
        } 
        tmpNotCompleted = sortDictionary(tmpNotCompleted)
        tmpCompleted = sortDictionary(tmpCompleted)

        setNotCompleted(tmpNotCompleted)
        setCompleted(tmpCompleted) 

    }, [props.shoppingList]) 
 

    function toggleComplete(ingredientKey, ingredientName, ingredientUOM, status) {
        props.updateRecipesList(ingredientName, ingredientUOM, status)
    }

    function sortDictionary(unsortedDict) {
        const sortedKeys = Object.keys(unsortedDict).sort();
        const sortedDict = {};
        sortedKeys.forEach(key => {
            sortedDict[key] = unsortedDict[key];
        });

        return sortedDict
    }

    function removeItem(ingredient) {
        const tmpNotCompleted = notCompleted.filter(item => item !== ingredient)
        const tmpRemovedItems = [...removedItems, ingredient]
        setNotCompleted(tmpNotCompleted)
        setRemovedItem(tmpRemovedItems)
    }

    return (
        <div className="ingredients_list_container">
            <div className="completed_container">
                <p className="ingredients_list_title">{translate('basket', 'not complete description')}</p>
                <div className="ingredients_items_container">
                    {Object.keys(notCompleted).map((key, index) => 
                        <IngredientsItem key={notCompleted[key] + index} keyID={key} toggleComplete={toggleComplete} removeItem={removeItem} name={notCompleted[key]['name']} name_plural={notCompleted[key]['plural_description']} hash_id={notCompleted[key]['hash_id']} quantity={notCompleted[key]['quantity']} uom={notCompleted[key]['uom']} status={"not_completed"} />
                    )}
                </div> 
            </div>
            <div className="not_completed_container">
                <p className="ingredients_list_title">{translate('basket', 'complete description')}</p>
                <div className="ingredients_items_container">
                {Object.keys(completed).map((key, index) => 
                    <IngredientsItem key={completed[key] + index} keyID={key} toggleComplete={toggleComplete} removeItem={removeItem} name={completed[key]['name']} name_plural={completed[key]['plural_description']} hash_id={completed[key]['hash_id']} quantity={completed[key]['quantity']} uom={completed[key]['uom']} status={"completed"} />
                )}
                </div>
            </div>
        </div>
    )
}

export default IngredientsList;