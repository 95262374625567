import './button_cancel.css';
import React, { useEffect, useState } from "react"

function ButtonCancel(props) {

    const [theme, setTheme] = useState('button_cancel_class')

    useEffect(() => {
        if (props.theme === 'grey') {
            setTheme('button_cancel_class_grey')
        }
    }, [props.theme])

    function getMargin() {
        if (props.margin === "left") {
            return {marginLeft: '5px', width: props.width}
        } else if (props.margin === "right") {
            return {marginRight: '5px', width: props.width}
        } else if (props.margin === "bottom") { 
            return {marginBottom: '5px', width: props.width}
        } else {
            return {margin: '0px', width: props.width}
        }
    }

    return (
        <div className="button_cancel_container" style={getMargin()}>
            <button className={props.isLoading ? "button_cancel_disabled": theme} onClick={props.isLoading ? null : props.action}>
                <span>
                    <span className="material-symbols-outlined button_cancel_icon">
                        {props.icon}
                    </span>
                
                    {props.name}
                </span>
            </button>
        </div>
    )

}

export default ButtonCancel;